import { Box, LinearProgress, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, Inputfile } from 'components/Form';
import CommentSection from 'components/Form/CommentSection';
import { addDays } from 'date-fns';
import dayjs from 'dayjs';
import { ErrorMessage, Field, FieldProps } from 'formik';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { DateRangePicker } from 'react-date-range';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/Hooks';
import { formCorrectionSelector } from 'store/slices/FormCorrection';
import { prestudySelector } from 'store/slices/Prestudy';
import { userSelector } from 'store/slices/User';
import { validateFiles } from 'utils/Helper';
import { crossBlack, documentBlack } from 'utils/Images';
import { setFeatureLoaded } from "store/slices/Prestudy";

const Days90Selection: React.FC<any> = ({ lastEntryDays, setLastEntryDays, setLastEntryExitDays, setLeftDays, lastEntryExitDays, setExpriyDate, leftDays, expirydate, values, uploadDocument, userType, setModal, setField, field, modal, showComments, studentData, highlightedIndex, setHighlightedIndex, getCount }) => {
  const { stepCorrectionData } = useAppSelector(formCorrectionSelector);
  const { stepData } = useAppSelector(prestudySelector);
  const DateInput = DatePicker as any;
  const DateRangeInput = DateRangePicker as any;
  const [selectionRange, setSelectionRange] = useState<any>(
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  );
  const { currentStep } =
    useAppSelector(prestudySelector);
  const { currentStep: lawyercurrentstep } =
    useAppSelector(formCorrectionSelector);

  const { user } = useAppSelector(userSelector);
  const [dateRange, setDateRange] = useState<any>(null);
  const [progress, setProgress] = React.useState(0);
  const [uploading, setUploading] = useState(false);
  const intervalIdRef = useRef<any>();
  const [fileError, setfileError] = useState<string | null>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const [months, setMonths] = useState<number>(window.innerWidth >= 1024 ? 2 : 1);
  const daterangePickerRef = useRef<any>(null);
  const [maxDate1, setmaxdate1] = useState<any>(null)

  const dispatch = useDispatch()


  useEffect(() => {
    if (progress > 100) {
      setUploading(false);
      setProgress(0);
      clearInterval(intervalIdRef?.current);
    }
  }, [progress]);

  useEffect(() => {
    if (progress > 0) {
      dispatch(setFeatureLoaded(true));
    } else {
      dispatch(setFeatureLoaded(false));
    }
  }, [progress]);

  useEffect(() => {
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, []);

  useEffect(() => {
    const chengeMonth = () => {
      setMonths(2)
    };
    if (window.innerWidth >= 1024) {
      chengeMonth();
    }
    return () => {
      setMonths(1);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (daterangePickerRef.current && !daterangePickerRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const uploadFiles = async (selectedFile: FileList | null, form: any) => {
    const allowedTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
    ];
    const isValidate = await validateFiles(selectedFile, allowedTypes);
    if (isValidate === "true") {
      if (selectedFile) {
        const newFiles = Array.from(selectedFile).filter(
          (newFile) =>
            !files ||
            !files.some((existingFile) => existingFile.name === newFile.name)
        );
        const updatedFiles = files ? [...files, ...newFiles] : newFiles;
        form.setFieldValue("passportFile", updatedFiles);
        setFiles(updatedFiles);
        const formData: any = new FormData();
        updatedFiles?.forEach((file: any) => {
          formData.append("passportFile", file);
        });
        if (userType === 'lawyer') {
          lawyercurrentstep && formData.append("step", (lawyercurrentstep - 1).toString());
          studentData && formData.append("userId", studentData?._id);
        } else {
          user && formData.append("userId", user?._id);
          currentStep && formData.append("step", (currentStep - 1).toString());
        }
        formData.append("isRequired", true);
        formData.append(
          "label",
          "Upload ALL the pages of your passport proving your entries and exits from Schengen area"
        );
        await uploadDocument(formData);
      } else {
        setFiles(null);
      }
    }
  };

  const [open, setOpen] = useState<boolean>(false);

  const fieldRefs = useRef<any>({});

  useEffect(() => {
    if (fieldRefs.current[highlightedIndex]) {
      fieldRefs.current[highlightedIndex].focus();

      const container = document.getElementById('container');

      // Scroll the container to make 'fieldRefs.current[highlightedIndex]' visible
      fieldRefs.current[highlightedIndex].scrollIntoView({
        behavior: 'smooth',  // Optional: Smooth scrolling
        block: 'nearest',    // Optional: Adjust where element aligns
        inline: 'start'      // Optional: Adjust where element aligns
      });
    }
  }, [fieldRefs, highlightedIndex]);

  const calculate = (startDate: Date, endDate: Date) => {
    const differenceInMilliseconds =
      endDate.getTime() -
      startDate.getTime();
    const differenceInDays = Math.round(differenceInMilliseconds / (1000 * 3600 * 24));
    let days = 90 - (leftDays + differenceInDays);
    setLeftDays(days > 0 ? Math.round(days) : 0);
    let originalDate = moment.utc(new Date(startDate));
    let updatedDate = originalDate.add(90, 'days');
    const formattedDate = updatedDate.format('YYYY-MM-DD');
    setExpriyDate(formattedDate);
    return differenceInDays;
  }

  return (
    <div id='container'>
      <hr className="bg-[#E1E5EA] my-5" />
      <p className="text-[#71859A] text-[14px]">
        Enter the most recent entry-exit first. For the last entry (first
        line), you can only select one date.
      </p>
      <p className="text-[#71859A] text-[14px]">
        For the previous entry-exit (all other lines), you must select 2
        dates. Make sure that the entry-exit match the stamps on your
        passport.{" "}
      </p>
      <div className="mb-2 mt-4 flex justify-start gap-2 items-center">
        {userType === 'lawyer' &&
          <input
            type="checkbox"
            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] "
            onClick={(e: any) => {
              setModal(e.target.checked);
              setField('entryexit')
            }}
            checked={modal && field === 'entryexit'}
          />}
        <div className='flex items-center gap-2'>
          <label
            htmlFor={"entryexit"}
            className="text-[#0B0D0F] text-[14px]"
          >
            Enter your last entry and all the entries-exits in the Schengen
            area for the last 180 days
            <span className="text-[#FF4444] ml-1">*</span>
          </label>

          {getCount && getCount('entryexit') > 0 &&
            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
              {getCount('entryexit')}
            </div>
          }

        </div>
      </div>
      <div className={`flex lg:flex-row flex-col mx-8 gap-2 xl:ml-2 p-2 border-2 ${highlightedIndex == 'option2Field1' ? 'border-2 border-green-300 rounded-md' : ' border-transparent'}`}>
        <label
          htmlFor={"lastEntry"}
          className="text-[#71859A] text-[14px] mb-2 lg:mb-0 mt-0 lg:mt-2"
        >
          Last Entry
        </label>
        <div className={`ml-0 w-full lg:w-1/3 mt-0 lg:mt-2`}>
          <Field name="lastEntry"
            ref={(el: any) => { fieldRefs.current['option2Field1'] = el }}
          // onFocus={() => setHighlightedIndex('option2Field1')}
          >
            {({ field, form }: FieldProps) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateInput
                  {...field}
                  label="YYYY-MM-DD"
                  renderInput={(props: any) => <TextField {...props} />}
                  format="YYYY-MM-DD"
                  value={values.lasEntry}
                  onChange={async (newDate: any) => {
                    form.setFieldValue("lastEntry", newDate);
                    setDateRange(null);
                    setLeftDays(0);
                    setExpriyDate("");
                    setLastEntryExitDays(0)
                    setSelectionRange(
                      {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: 'selection'
                      }
                    );
                    const selectedDate = new Date(newDate);
                    const currentDate = new Date();
                    const days = await calculate(selectedDate, currentDate);
                    setLastEntryDays(days);
                  }}
                  className={
                    "w-full py-0 border-[2px] border-[#E1E5EA] rounded-[12px]"
                  }
                  disableFuture
                  ref={(el: any) => { fieldRefs.current['option2Field1'] = el }}
                />
              </LocalizationProvider>
            )}
          </Field>
          <ErrorMessage
            name="lastEntry"
            component="div"
            className="text-red-600"
          />
          {userType === 'student'
            ? showComments('lastEntry') &&
            <CommentSection field={'lastEntry'} step={currentStep - 1} userType="student" />
            : <></>}
        </div>
        <div className={`ml-0 w-full lg:mt-0 mt-2 lg:w-1/3`}>
          <input
            type="text"
            name="lastEntryDays"
            value={lastEntryDays}
            placeholder="Days"
            className="password-input border-[2px] border-[#E1E5EA] rounded-md w-full placeholder-[#71859A] placeholder:text-[14px] focus:outline-none py-[12px]"
          />
        </div>
      </div>
      {!!lastEntryDays &&
        <div className={`flex lg:flex-row flex-col mx-8 gap-2 xl:ml-2 p-2 border-2 ${highlightedIndex === 'option2Field2' ? 'border-2 !border-green-300 rounded-md' : ' border-transparent'}`}>
          <label
            htmlFor={"lastExit"}
            className="text-[#71859A] text-[14px] mb-2 lg:mb-0 mt-0 lg:mt-2"
          >
            Entry Exit 1
          </label>
          <div className="ml-0 lg:w-1/3 mt-0 lg:mt-2 w-full">
            <div
              className={`flex border-[2px] border-[#E1E5EA] rounded-[12px] max-w-full  p-3 cursor-pointer ${!dateRange ? "text-[#71859A]" : "text-black"
                }`}
              onClick={() => {
                setOpen((prev: boolean) => !prev);
              }}
              ref={(el: any) => { fieldRefs.current['option2Field2'] = el }}
            >
              {dateRange ? dateRange : "YYYY-MM-DD"}
            </div>
            {open && (
              <Field name="lastExit"
                ref={(el: any) => { fieldRefs.current['option2Field2'] = el }}
              //  onFocus={() => setHighlightedIndex('option2Field2')}
              >
                {({ field, form }: FieldProps) => (
                  <div
                    className="daterangepicker"
                    ref={daterangePickerRef}
                  >
                    <DateRangeInput
                      {...field}
                      ranges={[selectionRange]}
                      onChange={async (ranges: any) => {
                        setSelectionRange(ranges.selection)
                        const formattedDateRange = `${moment(
                          ranges.selection.startDate
                        ).format("YYYY-MM-DD")} - ${moment(
                          ranges.selection.endDate
                        ).format("YYYY-MM-DD")}`;
                        form.setFieldValue("lastExit", formattedDateRange);
                        setDateRange(formattedDateRange);
                        setmaxdate1(ranges.selection.startDate)
                        const days = await calculate(ranges.selection.startDate, ranges.selection.endDate)
                        setLastEntryExitDays(days);
                      }}
                      minDate={new Date(new Date(values.lastEntry).getTime() - (180 * 24 * 60 * 60 * 1000))}
                      maxDate={
                        values.lastEntry
                          ? new Date(values.lastEntry)
                          :
                          new Date()
                      }
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      direction="horizontal"
                      months={months}
                      ref={(el: any) => { fieldRefs.current['option2Field2'] = el }}
                    />
                  </div>
                )}
              </Field>
            )}
            <ErrorMessage
              name="lastExit"
              component="div"
              className="text-red-600"
            />
            {userType === 'student' ?
              showComments('lastExit') &&
              <CommentSection field={'lastExit'} step={currentStep - 1} userType="student" />
              :
              <></>
            }
          </div>
          <div className="ml-0 w-full lg:mt-0 mt-2 lg:w-1/3">
            <input
              type="text"
              name="lastExitDays"
              value={lastEntryExitDays}
              placeholder="Days"
              className="border-[2px] border-[#E1E5EA] rounded-md w-full placeholder-[#71859A] placeholder:text-[14px] focus:outline-none py-[12px]"
            />
          </div>
        </div>
      }
      <div className="w-full flex justify-center mt-5">
        <Field name="lastEntry">
          {({ field, form }: FieldProps) => (
            <Button
              {...field}
              type="button"
              label="Reset all"
              disabled={false}
              handleClick={() => {
                setLastEntryExitDays(0);
                setLastEntryDays(0);
                setExpriyDate("");
                setLeftDays(0);
                setDateRange(null);
                form.setFieldValue("lastEntry", null);
              }}
              cssClass="bg-[#71859A] text-[#FFFFFF] py-[10px] px-[24px] w-fit rounded-lg text-center text-[16px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed mb-2"
            />
          )}
        </Field>
      </div>
      <hr className="bg-[#E1E5EA] my-5" />
      <div className="flex lg:flex-row xl:flex-row 2xl:flex-row flex-col gap-2">
        <div className={`days lg:w-1/2 xl:w-1/2 2xl:w-1/2 sm:w-full p-2 border-2 ${highlightedIndex === 'option2Field3' ? 'border-2 !border-green-300 rounded-md' : ' border-transparent'}`}>
          {/* {userType === 'lawyer' &&
            <input
              type="checkbox"
              className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] "
              onClick={(e: any) => {
                setModal(e.target.checked);
                setField('leftDays')
              }}
              checked={modal && field === 'leftDays'}
            />} */}
          <label
            htmlFor={"Schengen-area"}
            className="text-[#C3CCD5] text-[14px] ml-2" ref={(el: any) => { fieldRefs.current['option2Field3'] = el }}
          >
            Day left on Schengen area{" "}
            <span className="text-[#FF4444] ml-1">*</span>
          </label>
          <input
            type="text"
            value={leftDays}
            className="border-[2px] border-[#E1E5EA] rounded-md w-full text-[#C3CCD5] placeholder:text-[14px] focus:outline-none mt-2"
            disabled
            ref={(el: any) => { fieldRefs.current['option2Field3'] = el }}
          // onFocus={() => setHighlightedIndex('option2Field3')}
          />
        </div>
        <div className={`days lg:w-1/2 xl:w-1/2 2xl:w-1/2 sm:w-full p-2 border-2 ${highlightedIndex === 'option2Field4' ? 'border-2 !border-green-300 rounded-md' : ' border-transparent'}`}>
          {/* {userType === 'lawyer' &&
            <input
              type="checkbox"
              className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] "
              onClick={(e: any) => {
                setModal(e.target.checked);
                setField('expirydate')
              }}
              checked={modal && field === 'expirydate'}
            />} */}
          <label
            htmlFor={"Schengen-area"}
            className="text-[#C3CCD5] text-[14px] ml-2" ref={(el: any) => { fieldRefs.current['option2Field4'] = el }}
          >
            Days period expiry date{" "}
            <span className="text-[#FF4444] ml-1">*</span>
          </label>
          <input
            type="text"
            value={expirydate}
            className="border-[2px] border-[#E1E5EA] rounded-md w-full text-[#C3CCD5] placeholder:text-[14px] focus:outline-none mt-2"
            disabled
            ref={(el: any) => { fieldRefs.current['option2Field4'] = el }}
          // onFocus={() => setHighlightedIndex('option2Field4')}
          />
        </div>
      </div>
      <hr className="bg-[#E1E5EA] my-5" />
      <div className={`p-2 border-2 ${highlightedIndex === 'option2Field5' ? 'border-2 !border-green-300 rounded-md' : ' border-transparent'}`}>
        <div className="mb-2 mt-4 flex justify-start gap-2 items-center">
          {userType === 'lawyer' &&
            <input
              type="checkbox"
              className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] "
              onClick={(e: any) => {
                setModal(e.target.checked);
                setField('passportFile')
              }}
              checked={modal && field === 'passportFile'}
            />}
          <div className='flex items-center gap-2'>
            <label
              htmlFor={"passportFile"}
              className="font-400 text-[14px] text-[#0B0D0F]"
              ref={(el: any) => { fieldRefs.current['option2Field5'] = el }}
            >
              Upload ALL the pages of your passport proving your entries and
              exits from Schengen area
            </label>
            {getCount && getCount('passportFile') > 0 &&
              <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                {getCount('passportFile')}
              </div>
            }
          </div>
        </div>
        <Field name="passportFile"
          ref={(el: any) => { fieldRefs.current['option2Field5'] = el }}
        // onFocus={() => setHighlightedIndex('option2Field5')}
        >
          {({ field, form }: FieldProps) => (
            <>
            <div ref = {(el:any) => {fieldRefs.current['option2Field5'] = el}}>
              <Inputfile
                {...field}
                mainClass="lg:w-1/2 xl:w-1/2 w-full"
                btnClass={null}
                handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setProgress(0);
                  setUploading(true);
                  setfileError(null);
                  intervalIdRef.current = setInterval(() => {
                    setProgress((prevProgress) => prevProgress + 10);
                  }, 500);
                  uploadFiles(event.target.files, form);
                }}
                multiple={true}
              />
              </div>
              {values.passportFile &&
                values.passportFile.length > 0 &&
                values.passportFile.map(
                  (item: { name: string; location: string }) => (
                    <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                      <img src={documentBlack} alt="documentBlack" />
                      <label className="flex-1">{item.name}</label>
                      <img
                        src={crossBlack}
                        alt="documentBlack"
                        onClick={async () => {
                          const updatedFiles = values.passportFile?.filter(
                            (val: { name: string; location: string }) =>
                              val.name !== item.name
                          );
                          form.setFieldValue("passportFile", updatedFiles);
                          const formData: any = new FormData();
                          updatedFiles?.forEach((file: any) => {
                            formData.append("passportFile", file);
                          });
                          if (userType === 'lawyer') {
                            currentStep && formData.append("step", (currentStep - 1).toString());
                            studentData && formData.append("userId", studentData?._id);
                          } else {
                            user && formData.append("userId", user?._id);
                            lawyercurrentstep && formData.append("step", (lawyercurrentstep - 1).toString());
                          }
                          formData.append("isRequired", true);
                          formData.append(
                            "label",
                            "Upload ALL the pages of your passport proving your entries and exits from Schengen area"
                          );
                          await uploadDocument(formData);
                          setFiles(updatedFiles);
                          if (updatedFiles.length === 0) {
                            form.setFieldValue("passportFile", null);
                            setFiles(null);
                          }
                        }}
                        className="cursor-pointer"
                      />
                    </div>
                  )
                )}
              {uploading && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress variant="determinate" value={progress} />
                </Box>
              )}
            </>
          )}
        </Field>
        {fileError ? (
          <div className="text-red-500 text-sm md:text-base fileError">
            {fileError}
          </div>
        ) : (
          <ErrorMessage
            name="passportFile"
            component="div"
            className="text-red-600 fileError"
          />
        )}
        {userType === 'student' ?
          showComments('passportFile') &&
          <CommentSection field={'passportFile'} step={currentStep - 1} userType="student" />
          : <></>
        }
      </div>
    </div>
  )
}

export default Days90Selection