import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getValue } from '@testing-library/user-event/dist/utils';
import { Input, RadioGroup } from 'components/Form';
import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { ChangeEvent } from 'react'
import { CountryDropdown } from 'react-country-region-selector';
import { useAppSelector } from 'store/Hooks';
import { prestudySelector } from 'store/slices/Prestudy';

interface ChildrenCompProps {
    values: any;
    modal?: boolean;
    setModal?: any;
    field?: string;
    setField?: any;
    showField?: (args: string) => boolean;
    showComments?: (args: string) => boolean;
    handleChange: any;
    userType?: boolean;
    getCount?: (args: string) => number;
}

const ChildrenComp: React.FC<ChildrenCompProps> = ({ values, modal, setModal, field, setField, showField, showComments, handleChange, userType, getCount }) => {
    console.log("showComments children comp",showComments)
    const { currentStep } = useAppSelector(prestudySelector);
    const DateInput = DatePicker as any;
    const CountrySelect = CountryDropdown as any;
    const genderOptions = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Unknown", label: "Unknown" },
    ]
    return (
        <>
            <div className={`ChildrenComp  px-4 mb-3`}>
                <div className="flex justify-between items-center mb-2">
                    <label
                        htmlFor={"children"}
                        className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                        Children 1
                    </label>
                </div>
                <div className='flex flex-col lg:flex-row gap-2'>
                    <div className={!showField ? "block" : showField(`c1Lame`) ? "block" : 'hidden'}>
                        <div className="flex items-center my-3">
                            <label
                                htmlFor={`c1Lame`}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('c1Lame')
                                        }}
                                        checked={modal && field === 'c1Lame'}
                                    />
                                }
                                Children 1 - Last name
                            </label>
                            {getCount && getCount("c1Lame") > 0 && (
                                <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                    {getCount("c1Lame")}
                                </div>
                            )}
                        </div>
                        <Input
                            type="text"
                            fieldName={`c1Lame`}
                            setValue={values.c1Lame}
                            className={null}
                            handleChange={handleChange}
                        />
                        {showComments && showComments(`c1Lame`) &&
                            <CommentSection field={`c1Lame`} step={currentStep - 1} userType="student" />
                        }
                    </div>
                    <div className={!showField ? "block" : showField('c1Fname') ? "block" : 'hidden'}>
                        <div className="flex justify-between items-center my-3">
                            <label
                                htmlFor={"c1Fname"}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('c1Fname')
                                        }}
                                        checked={modal && field === 'c1Fname'}
                                    />
                                }

                                Children 1  - First name
                            </label>
                            {getCount && getCount("c1Fname") > 0 && (
                                <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                    {getCount("c1Fname")}
                                </div>
                            )}
                        </div>
                        <Input
                            type="text"
                            fieldName="c1Fname"
                            setValue={values.c1Fname}
                            className={null}
                            handleChange={handleChange}
                        />
                        {showComments && showComments('c1Fname') &&
                            <CommentSection field={'c1Fname'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className={!showField ? "block" : showField('c1Gender') ? "block" : 'hidden'}>
                        <div className="flex items-center mb-2">
                            <label
                                htmlFor={"c1Gender"}
                                className="font-semibold text-[16px]  text-[#0B0D0F]"
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('c1Gender')
                                        }}
                                        checked={modal && field === 'c1Gender'}
                                    />
                                }
                                Children 1 - Gender
                            </label>
                            {getCount && getCount("c1Gender") > 0 && (
                                <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                    {getCount("c1Gender")}
                                </div>
                            )}
                        </div>
                        <Field name="c1Gender">
                            {({ field, form }: FieldProps) => (
                                <div className="flex flex-col gap-3 justify-center">
                                    <RadioGroup
                                        {...field}
                                        fieldName="c1Gender"
                                        options={genderOptions}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            form.setFieldValue("c1Gender", event.target.value);
                                        }}
                                        className={
                                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                                        }
                                        checkedField={values.c1Gender}
                                    />
                                </div>
                            )}
                        </Field>
                        <ErrorMessage
                            name="c1Gender"
                            component="div"
                            className="text-red-500"
                        />
                        {showComments && showComments('c1Gender') &&
                            <CommentSection field={'c1Gender'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                    <div className='flex flex-col gap-2'>
                        <div className='flex lg:flex-row flex-col gap-2'>
                            <div className={!showField ? "block" : showField('c1dob') ? "block" : 'hidden'}>
                                <div className="flex items-center my-3">
                                    <label
                                        htmlFor={"c1dob"}
                                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                                    >
                                        {userType &&
                                            <input
                                                type="checkbox"
                                                className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                                onClick={(e: any) => {
                                                    setModal(e.target.checked);
                                                    setField('c1dob')
                                                }}
                                                checked={modal && field === 'c1dob'}
                                            />
                                        }
                                        Children 1 - Date of birth(YYYY-MM-DD)
                                    </label>
                                    {getCount && getCount("c1dob") > 0 && (
                                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                            {getCount("c1dob")}
                                        </div>
                                    )}
                                </div>
                                <Field name="c1dob">
                                    {({ field, form }: FieldProps) => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateInput
                                                {...field}
                                                label="YYYY-MM-DD"
                                                renderInput={(props: any) => (
                                                    <TextField {...props} />
                                                )}
                                                format="YYYY-MM-DD"
                                                value={values.c1dob}
                                                onChange={(newDate: any) => {
                                                    form.setFieldValue("c1dob", newDate);
                                                }}
                                                className={
                                                    "password-input"
                                                }
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="c1dob"
                                    component="div"
                                    className="text-red-600"
                                />
                                {showComments && showComments('c1dob') &&
                                    <CommentSection field={'c1dob'} step={currentStep - 1} userType="student" />
                                }
                            </div>
                            <div className={!showField ? "block" : showField('c1Birthplcae') ? "block" : 'hidden'}>
                                <div className="flex items-center my-3">
                                    <label
                                        htmlFor={"c1Birthplcae"}
                                        className="font-400 text-[14px] text-[#0B0D0F]"
                                    >
                                        {userType &&
                                            <input
                                                type="checkbox"
                                                className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                                onClick={(e: any) => {
                                                    setModal(e.target.checked);
                                                    setField('c1Birthplcae')
                                                }}
                                                checked={modal && field === 'c1Birthplcae'}
                                            />
                                        }
                                        Children 1 - Place of birth
                                    </label>
                                    {getCount && getCount("c1Birthplcae") > 0 && (
                                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                            {getCount("c1Birthplcae")}
                                        </div>
                                    )}
                                </div>
                                <Input
                                    type="text"
                                    fieldName="c1Birthplcae"
                                    setValue={values.c1Birthplcae}
                                    className={null}
                                    handleChange={handleChange}
                                />
                                {showComments && showComments('c1Birthplcae') &&
                                    <CommentSection field={'c1Birthplcae'} step={currentStep - 1} userType="student" />
                                }
                            </div>
                        </div>
                        <div className={!showField ? "block" : showField('c1BirthCountry') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex items-center">
                                <label
                                    htmlFor={"c1BirthCountry"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('c1BirthCountry')
                                            }}
                                            checked={modal && field === 'c1BirthCountry'}
                                        />
                                    }
                                    Children 1 - Country of birth
                                </label>
                                {getCount && getCount("c1BirthCountry") > 0 && (
                                    <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                        {getCount("c1BirthCountry")}
                                    </div>
                                )}
                            </div>
                            <div className="w-full">
                                <Field name="c1BirthCountry">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.motherBirthCountry}
                                            onChange={(val: string) =>
                                                form.setFieldValue("c1BirthCountry", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="c1BirthCountry"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('c1BirthCountry') &&
                                <CommentSection field={'c1BirthCountry'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                        <div className={!showField ? "block" : showField('c1Citizenship') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex items-center">
                                <label
                                    htmlFor={"c1Citizenship"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('c1Citizenship')
                                            }}
                                            checked={modal && field === 'c1Citizenship'}
                                        />
                                    }
                                    Children 1 - Citizenship
                                </label>
                                {getCount && getCount("c1Citizenship") > 0 && (
                                    <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                        {getCount("c1Citizenship")}
                                    </div>
                                )}
                            </div>
                            <div className="w-full">
                                <Field name="c1Citizenship">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.c1Citizenship}
                                            onChange={(val: string) =>
                                                form.setFieldValue("c1Citizenship", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="c1Citizenship"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('c1Citizenship') &&
                                <CommentSection field={'c1Citizenship'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                        <div className={!showField ? "block" : showField('c1ResCountry') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex items-center">
                                <label
                                    htmlFor={"c1ResCountry"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('c1ResCountry')
                                            }}
                                            checked={modal && field === 'c1ResCountry'}
                                        />
                                    }
                                    Children 1 - Actual country of residence
                                </label>
                                {getCount && getCount("c1ResCountry") > 0 && (
                                    <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                        {getCount("c1ResCountry")}
                                    </div>
                                )}
                            </div>
                            <div className="w-full">
                                <Field name="c1ResCountry">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.c1ResCountry}
                                            onChange={(val: string) =>
                                                form.setFieldValue("c1ResCountry", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="c1ResCountry"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('c1ResCountry') &&
                                <CommentSection field={'c1ResCountry'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`ChildrenComp  px-4 mb-3`}>
                <div className="flex justify-between items-center mb-2">
                    <label
                        htmlFor={"children"}
                        className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                        Children 2
                    </label>
                </div>
                <div className='flex flex-col lg:flex-row gap-2'>
                    <div className={!showField ? "block" : showField(`c2Lame`) ? "block" : 'hidden'}>
                        <div className="flex items-center my-3">
                            <label
                                htmlFor={`c2Lame`}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('c2Lame')
                                        }}
                                        checked={modal && field === 'c2Lame'}
                                    />
                                }
                                Children 2 - Last name
                            </label>
                            {getCount && getCount("c2Lame") > 0 && (
                                <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                    {getCount("c2Lame")}
                                </div>
                            )}

                        </div>
                        <Input
                            type="text"
                            fieldName={`c2Lame`}
                            setValue={values.c2Lame}
                            className={null}
                            handleChange={handleChange}
                        />
                        {showComments && showComments(`c2Lame`) &&
                            <CommentSection field={`c2Lame`} step={currentStep - 1} userType="student" />
                        }
                    </div>
                    <div className={!showField ? "block" : showField('c2Fname') ? "block" : 'hidden'}>
                        <div className="flex items-center my-3">
                            <label
                                htmlFor={"c2Fname"}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('c2Fname')
                                        }}
                                        checked={modal && field === 'c2Fname'}
                                    />
                                }

                                Children 2  - First name
                            </label>
                            {getCount && getCount("c2Fname") > 0 && (
                                <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                    {getCount("c2Fname")}
                                </div>
                            )}
                        </div>
                        <Input
                            type="text"
                            fieldName="c2Fname"
                            setValue={values.c2Fname}
                            className={null}
                            handleChange={handleChange}
                        />
                        {showComments && showComments('c2Fname') &&
                            <CommentSection field={'c2Fname'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className={!showField ? "block" : showField('c2Gender') ? "block" : 'hidden'}>
                        <div className="flex  items-center mb-2">
                            <label
                                htmlFor={"c2Gender"}
                                className="font-semibold text-[16px]  text-[#0B0D0F]"
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('c2Gender')
                                        }}
                                        checked={modal && field === 'c2Gender'}
                                    />
                                }
                                Children 2 - Gender
                            </label>
                            {getCount && getCount("c2Gender") > 0 && (
                                <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                    {getCount("c2Gender")}
                                </div>
                            )}
                        </div>
                        <Field name="c2Gender">
                            {({ field, form }: FieldProps) => (
                                <div className="flex flex-col gap-3 justify-center">
                                    <RadioGroup
                                        {...field}
                                        fieldName="c2Gender"
                                        options={genderOptions}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            form.setFieldValue("c2Gender", event.target.value);
                                        }}
                                        className={
                                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                                        }
                                        checkedField={values.c2Gender}
                                    />
                                </div>
                            )}
                        </Field>
                        <ErrorMessage
                            name="c2Gender"
                            component="div"
                            className="text-red-500"
                        />
                        {showComments && showComments('c2Gender') &&
                            <CommentSection field={'c2Gender'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                    <div className='flex flex-col gap-2'>
                        <div className='flex lg:flex-row flex-col gap-2'>
                            <div className={!showField ? "block" : showField('c1dob') ? "block" : 'hidden'}>
                                <div className="flex items-center my-3">
                                    <label
                                        htmlFor={"c2dob"}
                                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                                    >
                                        {userType &&
                                            <input
                                                type="checkbox"
                                                className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                                onClick={(e: any) => {
                                                    setModal(e.target.checked);
                                                    setField('c2dob')
                                                }}
                                                checked={modal && field === 'c2dob'}
                                            />
                                        }
                                        Children 2 - Date of birth(YYYY-MM-DD)
                                    </label>
                                    {getCount && getCount("c2dob") > 0 && (
                                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                            {getCount("c2dob")}
                                        </div>
                                    )}
                                </div>
                                <Field name="c2dob">
                                    {({ field, form }: FieldProps) => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateInput
                                                {...field}
                                                label="YYYY-MM-DD"
                                                renderInput={(props: any) => (
                                                    <TextField {...props} />
                                                )}
                                                format="YYYY-MM-DD"
                                                value={values.c2dob}
                                                onChange={(newDate: any) => {
                                                    form.setFieldValue("c2dob", newDate);
                                                }}
                                                className={
                                                    "password-input"
                                                }
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="c2dob"
                                    component="div"
                                    className="text-red-600"
                                />
                                {showComments && showComments('c2dob') &&
                                    <CommentSection field={'c2dob'} step={currentStep - 1} userType="student" />
                                }
                            </div>
                            <div className={!showField ? "block" : showField('c2Birthplcae') ? "block" : 'hidden'}>
                                <div className="flex items-center my-3">
                                    <label
                                        htmlFor={"c2Birthplcae"}
                                        className="font-400 text-[14px] text-[#0B0D0F]"
                                    >
                                        {userType &&
                                            <input
                                                type="checkbox"
                                                className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                                onClick={(e: any) => {
                                                    setModal(e.target.checked);
                                                    setField('c2Birthplcae')
                                                }}
                                                checked={modal && field === 'c2Birthplcae'}
                                            />
                                        }
                                        Children 2 - Place of birth
                                    </label>
                                    {getCount && getCount("c2Birthplcae") > 0 && (
                                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                            {getCount("c2Birthplcae")}
                                        </div>
                                    )}
                                </div>
                                <Input
                                    type="text"
                                    fieldName="c2Birthplcae"
                                    setValue={values.c2Birthplcae}
                                    className={null}
                                    handleChange={handleChange}
                                />
                                {showComments && showComments('c2Birthplcae') &&
                                    <CommentSection field={'c2Birthplcae'} step={currentStep - 1} userType="student" />
                                }
                            </div>
                        </div>
                        <div className={!showField ? "block" : showField('c2BirthCountry') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex items-center">
                                <label
                                    htmlFor={"c2BirthCountry"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('c2BirthCountry')
                                            }}
                                            checked={modal && field === 'c2BirthCountry'}
                                        />
                                    }
                                    Children 2 - Country of birth
                                </label>
                                {getCount && getCount("c2BirthCountry") > 0 && (
                                    <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                        {getCount("c2BirthCountry")}
                                    </div>
                                )}
                            </div>
                            <div className="w-full">
                                <Field name="c2BirthCountry">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.motherBirthCountry}
                                            onChange={(val: string) =>
                                                form.setFieldValue("c2BirthCountry", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="c2BirthCountry"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('c2BirthCountry') &&
                                <CommentSection field={'c2BirthCountry'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                        <div className={!showField ? "block" : showField('c2Citizenship') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex  items-center">
                                <label
                                    htmlFor={"c2Citizenship"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('c2Citizenship')
                                            }}
                                            checked={modal && field === 'c2Citizenship'}
                                        />
                                    }
                                    Children 2 - Citizenship
                                </label>
                                {getCount && getCount("c2Citizenship") > 0 && (
                                    <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                        {getCount("c2Citizenship")}
                                    </div>
                                )}
                            </div>
                            <div className="w-full">
                                <Field name="c2Citizenship">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.c2Citizenship}
                                            onChange={(val: string) =>
                                                form.setFieldValue("c2Citizenship", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="c2Citizenship"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('c2Citizenship') &&
                                <CommentSection field={'c2Citizenship'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                        <div className={!showField ? "block" : showField('c2ResCountry') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex items-center">
                                <label
                                    htmlFor={"c2ResCountry"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('c2ResCountry')
                                            }}
                                            checked={modal && field === 'c2ResCountry'}
                                        />
                                    }
                                    Children 2 - Actual country of residence
                                </label>
                                {getCount && getCount("c2ResCountry") > 0 && (
                                    <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                        {getCount("c2ResCountry")}
                                    </div>
                                )}
                            </div>
                            <div className="w-full">
                                <Field name="c2ResCountry">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.c2ResCountry}
                                            onChange={(val: string) =>
                                                form.setFieldValue("c2ResCountry", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="c2ResCountry"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('c2ResCountry') &&
                                <CommentSection field={'c2ResCountry'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={`ChildrenComp  px-4 mb-3`}>
                <div className="flex justify-between items-center mb-2">
                    <label
                        htmlFor={"children"}
                        className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                        Children 3
                    </label>
                </div>
                <div className='flex flex-col lg:flex-row gap-2'>
                    <div className={!showField ? "block" : showField(`c3Lame`) ? "block" : 'hidden'}>
                        <div className="flex items-center my-3">
                            <label
                                htmlFor={`c3Lame`}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('c3Lame')
                                        }}
                                        checked={modal && field === 'c3Lame'}
                                    />
                                }
                                Children 3 - Last name
                            </label>
                            {getCount && getCount("c3Lame") > 0 && (
                                <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                    {getCount("c3Lame")}
                                </div>
                            )}
                        </div>
                        <Input
                            type="text"
                            fieldName={`c3Lame`}
                            setValue={values.c3Lame}
                            className={null}
                            handleChange={handleChange}
                        />
                        {showComments && showComments(`c3Lame`) &&
                            <CommentSection field={`c3Lame`} step={currentStep - 1} userType="student" />
                        }
                    </div>
                    <div className={!showField ? "block" : showField('c3Fname') ? "block" : 'hidden'}>
                        <div className="flex items-center my-3">
                            <label
                                htmlFor={"c3Fname"}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('c3Fname')
                                        }}
                                        checked={modal && field === 'c3Fname'}
                                    />
                                }

                                Children 3  - First name
                            </label>
                            {getCount && getCount("c3Fname") > 0 && (
                                <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                    {getCount("c3Fname")}
                                </div>
                            )}
                        </div>
                        <Input
                            type="text"
                            fieldName="c3Fname"
                            setValue={values.c3Fname}
                            className={null}
                            handleChange={handleChange}
                        />
                        {showComments && showComments('c3Fname') &&
                            <CommentSection field={'c3Fname'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className={!showField ? "block" : showField('c3Gender') ? "block" : 'hidden'}>
                        <div className="flex items-center mb-2">
                            <label
                                htmlFor={"c3Gender"}
                                className="font-semibold text-[16px]  text-[#0B0D0F]"
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('c3Gender')
                                        }}
                                        checked={modal && field === 'c3Gender'}
                                    />
                                }
                                Children 3 - Gender
                            </label>
                            {getCount && getCount("c3Gender") > 0 && (
                                <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                    {getCount("c3Gender")}
                                </div>
                            )}
                        </div>
                        <Field name="c3Gender">
                            {({ field, form }: FieldProps) => (
                                <div className="flex flex-col gap-3 justify-center">
                                    <RadioGroup
                                        {...field}
                                        fieldName="c3Gender"
                                        options={genderOptions}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            form.setFieldValue("c3Gender", event.target.value);
                                        }}
                                        className={
                                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                                        }
                                        checkedField={values.c3Gender}
                                    />
                                </div>
                            )}
                        </Field>
                        <ErrorMessage
                            name="c3Gender"
                            component="div"
                            className="text-red-500"
                        />
                        {showComments && showComments('c3Gender') &&
                            <CommentSection field={'c3Gender'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                    <div className='flex flex-col gap-2'>
                        <div className='flex lg:flex-row flex-col gap-2'>
                            <div className={!showField ? "block" : showField('c3dob') ? "block" : 'hidden'}>
                                <div className="flex items-center my-3">
                                    <label
                                        htmlFor={"c3dob"}
                                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                                    >
                                        {userType &&
                                            <input
                                                type="checkbox"
                                                className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                                onClick={(e: any) => {
                                                    setModal(e.target.checked);
                                                    setField('c3dob')
                                                }}
                                                checked={modal && field === 'c3dob'}
                                            />
                                        }
                                        Children 3 - Date of birth(YYYY-MM-DD)
                                    </label>
                                    {getCount && getCount("c3dob") > 0 && (
                                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                            {getCount("c3dob")}
                                        </div>
                                    )}
                                </div>
                                <Field name="c3dob">
                                    {({ field, form }: FieldProps) => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateInput
                                                {...field}
                                                label="YYYY-MM-DD"
                                                renderInput={(props: any) => (
                                                    <TextField {...props} />
                                                )}
                                                format="YYYY-MM-DD"
                                                value={values.c3dob}
                                                onChange={(newDate: any) => {
                                                    form.setFieldValue("c3dob", newDate);
                                                }}
                                                className={
                                                    "password-input"
                                                }
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="c3dob"
                                    component="div"
                                    className="text-red-600"
                                />
                                {showComments && showComments('c3dob') &&
                                    <CommentSection field={'c3dob'} step={currentStep - 1} userType="student" />
                                }
                            </div>
                            <div className={!showField ? "block" : showField('c3Birthplcae') ? "block" : 'hidden'}>
                                <div className="flex items-center my-3">
                                    <label
                                        htmlFor={"c3Birthplcae"}
                                        className="font-400 text-[14px] text-[#0B0D0F]"
                                    >
                                        {userType &&
                                            <input
                                                type="checkbox"
                                                className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                                onClick={(e: any) => {
                                                    setModal(e.target.checked);
                                                    setField('c3Birthplcae')
                                                }}
                                                checked={modal && field === 'c3Birthplcae'}
                                            />
                                        }
                                        Children 3 - Place of birth
                                    </label>
                                    {getCount && getCount("c3Birthplcae") > 0 && (
                                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                            {getCount("c3Birthplcae")}
                                        </div>
                                    )}
                                </div>
                                <Input
                                    type="text"
                                    fieldName="c3Birthplcae"
                                    setValue={values.c3Birthplcae}
                                    className={null}
                                    handleChange={handleChange}
                                />
                                {showComments && showComments('c3Birthplcae') &&
                                    <CommentSection field={'c3Birthplcae'} step={currentStep - 1} userType="student" />
                                }
                            </div>
                        </div>
                        <div className={!showField ? "block" : showField('c3BirthCountry') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex items-center">
                                <label
                                    htmlFor={"c3BirthCountry"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('c3BirthCountry')
                                            }}
                                            checked={modal && field === 'c3BirthCountry'}
                                        />
                                    }
                                    Children 3 - Country of birth
                                </label>
                                {getCount && getCount("c3BirthCountry") > 0 && (
                                    <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                        {getCount("c3BirthCountry")}
                                    </div>
                                )}
                            </div>
                            <div className="w-full">
                                <Field name="c3BirthCountry">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.c3BirthCountry}
                                            onChange={(val: string) =>
                                                form.setFieldValue("c3BirthCountry", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="c3BirthCountry"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('c3BirthCountry') &&
                                <CommentSection field={'c3BirthCountry'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                        <div className={!showField ? "block" : showField('c3Citizenship') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex items-center">
                                <label
                                    htmlFor={"c3Citizenship"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('c3Citizenship')
                                            }}
                                            checked={modal && field === 'c3Citizenship'}
                                        />
                                    }
                                    Children 3 - Citizenship
                                </label>
                                {getCount && getCount("c3Citizenship") > 0 && (
                                    <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                        {getCount("c3Citizenship")}
                                    </div>
                                )}
                            </div>
                            <div className="w-full">
                                <Field name="c3Citizenship">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.c3Citizenship}
                                            onChange={(val: string) =>
                                                form.setFieldValue("c3Citizenship", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="c3Citizenship"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('c3Citizenship') &&
                                <CommentSection field={'c3Citizenship'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                        <div className={!showField ? "block" : showField('c3ResCountry') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex items-center">
                                <label
                                    htmlFor={"c3ResCountry"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('c3ResCountry')
                                            }}
                                            checked={modal && field === 'c3ResCountry'}
                                        />
                                    }
                                    Children 3 - Actual country of residence
                                </label>
                                {getCount && getCount("c3ResCountry") > 0 && (
                                    <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                        {getCount("c3ResCountry")}
                                    </div>
                                )}
                            </div>
                            <div className="w-full">
                                <Field name="c3ResCountry">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.c3ResCountry}
                                            onChange={(val: string) =>
                                                form.setFieldValue("c3ResCountry", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="c3ResCountry"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('c3ResCountry') &&
                                <CommentSection field={'c3ResCountry'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ChildrenComp