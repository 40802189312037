import { Button } from "components/Form";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import {
  clearPrestudy,
  prestudySelector,
  setFormData,
  setFormStatus,
  setSubmitSignature,
} from "store/slices/Prestudy";
import SignatureCanvas from "react-signature-canvas";
import { cleanAndLogout, convertbas64Tofile } from "utils/Helper";
import { submitSchema } from "schema/Prestudy";
import { useNavigate } from "react-router-dom";
import { formDataType, stepDataType, stepProps } from "utils/types";
import moment from "moment";
import { clearUser, userSelector } from "store/slices/User";
import prestudyService from "services/prestudy";
import { toast } from "react-toastify";
import CustomLoader from "components/Loader/CustomLoader";
import { clearFormcorrection } from "store/slices/FormCorrection";
import debounce from "lodash.debounce";
import { editIcon } from "utils/Images";

const SubmitForm: React.FC<stepProps> = ({ submitStepdata }) => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const navigate = useNavigate();
  const SignaturePad = SignatureCanvas as any;
  const { currentStep, qrImage, submitSignature, stepData } =
    useAppSelector(prestudySelector);
  const sigCanvas = useRef<any>(null);
  const { user } = useAppSelector(userSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [showData, setShowData] = useState<stepDataType[] | null>(null);
  const [stepFields, setStepFields] = useState<any>({
    signature: "",
  });

  const initialValues = {
    signature: stepFields?.signature ? stepFields?.signature : "",
  };

  const getFormData = async () => {
    try {
      setLoading(true);
      let response = await prestudyService.getFormData(user?._id);
      const {
        data: { success, status, data },
      } = response;
      if (success) {
        setLoading(false);
        if (data?.stepStatus) {
          dispatch(setFormStatus(data?.stepStatus));
        }
        if (data?.stepFields) {
          const result = data?.stepFields.flatMap(
            (obj: formDataType) => obj.data
          );
          setShowData(result.slice(0, 35));
          dispatch(setFormData(data?.stepFields));
        }
      }
      if (status === 401) {
        setLoading(false);
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (user?.formStatus === "SUBMITTED") {
      getFormData();
    }
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (submitSignature && sigCanvas.current) {
        sigCanvas.current.clear();
        sigCanvas.current.fromDataURL(submitSignature);
      }
    }, 300);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [submitSignature]);

  useEffect(() => {
    // Create a MediaQueryList object for touch capability
    const mediaQuery = window.matchMedia("(pointer: coarse)");

    // Function to update the state based on the media query match
    const handleChange = (event: MediaQueryListEvent | MediaQueryList) => {
      setIsTouchDevice(event.matches);
    };

    // Initial check
    handleChange(mediaQuery);

    // Add event listener to detect changes in the media query
    mediaQuery.addEventListener("change", handleChange);

    // Clean up the event listener when the component unmounts
    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  const handleSubmit = async (values: any) => {
    if (user?.formStatus !== "SUBMITTED") {
      if (values.signature === "") {
        toast.error("Please submit form using a touch screen device");
      } else if (typeof values.signature !== "string") {
        setLoading(true);
        const formData: any = new FormData();
        formData.append("finalSignature", values.signature);
        user && formData.append("userId", user?._id);
        currentStep && formData.append("step", (currentStep - 1).toString());
        formData.append("label", "Sign your application");
        formData.append("isRequired", true);
        let response = await prestudyService.uploadDocument(formData);
        const {
          data: { success, status },
        } = response;
        if (status === 200) {
          navigate("/dashboard");
        }
        if (status === 401) {
          setLoading(false);
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
          return false;
        }
        setLoading(false);
      } else {
        navigate("/dashboard");
      }
    }
  };

  const getValue = (value: any, name: string) => {
    let val;
    if (
      [
        "startDate",
        "dob",
        "registerDate",
        "validDate",
        "issueDate",
        "date",
        "lastEntry",
        "visaExpiryDate",
        "rpExpireDate",
        "fcDate",
        "deadLinedate",
      ].includes(name)
    ) {
      val = moment(value).format("YYYY-MM-DD");
    } else {
      switch (typeof value) {
        case "string":
          val = value;
          break;
        case "number":
          val = value;
          break;
        case "boolean":
          val = value ? "Yes" : "No";
          break;
        case "object":
          val = "Uploaded";
          break;
        default:
          break;
      }
    }
    return val;
  };

  const sendEmail = async () => {
    try {
      setLoading(true);
      if (user?.email) {
        let response = await prestudyService.sendEmail(user?.email);
        const {
          data: { message, success, status },
        } = response;
        if (success) {
          setLoading(false);
          if (message) {
            toast(message, { className: "toast-success" });
          }
        }
        if (status === 401) {
          setLoading(false);
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const sendSMS = async () => {
    try {
      setLoading(true);
      if (user?.phoneNumber) {
        let response = await prestudyService.sendSms(user);
        const {
          data: { message, success, status },
        } = response;
        if (success) {
          setLoading(false);
          if (message) {
            toast(message, { className: "toast-success" });
          }
        }
        if (status === 401) {
          setLoading(false);
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const updatedFields = { ...stepFields };
    stepData?.forEach((item) => {
      const name = item.name;
      switch (name) {
        case "finalSignature":
          updatedFields["signature"] =
            item?.value && item?.value[0]?.location
              ? item?.value[0]?.location
              : null;
          break;
        default:
          break;
      }
    });
    setStepFields(updatedFields);
  }, [stepData]);

  return (
    <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 h-full lg:ml-[6.3rem] ml-0">
      {loading ? (
        <CustomLoader />
      ) : (
        <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full p-5">
          {user?.formStatus !== "SUBMITTED" && (
            <>
              <label className="text-[#1492B8] font-semibold text-[20px]">
                Welcome
              </label>
              <p className="text-[#71859A] text-[15px] mt-8 lg:block hidden">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </>
          )}
          {user?.formStatus !== "SUBMITTED" ? (
            <>
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-5">
                <label className="text-[20px] font-semibold">
                  Date & final signature
                </label>
              </div>
              <div className="p-4">
                {!isTouchDevice && (
                  <>
                    <div className="qr-section">
                      <img
                        src={qrImage}
                        alt="QrImage"
                        className="mb-3 h-[250px] w-[250px]"
                      />
                      <p className="text-[#71859A] text-[15px] my-5 mr-5">
                        You need to sign the application form with a TOUCH
                        SCREEN in order to have a better quality of signature.
                        To do so, you can scan the QR-Code with your mobile OR
                        send the link to you by email OR by SMS by clicking the
                        following buttons.
                      </p>
                    </div>
                    <div className="buttons flex-row flex gap-5">
                      <Button
                        type="submit"
                        label={`Receive the link per Email to ${user?.email}`}
                        disabled={false}
                        cssClass="bg-[#1492B8] text-[15px] text-[#FFFFFF] py-2 lg:w-[241px] w-[220px] rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
                        handleClick={sendEmail}
                      />
                      <Button
                        type="submit"
                        label={`Receive the link per SMS to ${user?.phoneNumber}`}
                        disabled={false}
                        cssClass="bg-[#1492B8] text-[15px] text-[#FFFFFF] py-2 lg:w-[241px] w-[220px]  rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
                        handleClick={sendSMS}
                      />
                    </div>
                  </>
                )}
                <Formik
                  initialValues={initialValues}
                  validationSchema={
                    user?.formStatus === "SUBMITTED" ? "" : submitSchema
                  }
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                >
                  {({ values }: any) => (
                    <Form id={currentStep.toString()}>
                      <div className="date-signature mt-5 mb-4">
                        <div className="mb-2 mt-4 flex justify-between items-center">
                          <label
                            htmlFor={"signature"}
                            className="text-[16px] text-[#0B0D0F] font-semibold"
                          >
                            Date of signature (automatic field)
                            <span className="text-[#FF4444] ml-1">*</span>
                          </label>
                        </div>
                        <div className="lg:w-1/3 xl:w-1/3 2xl:w-1/3 w-full">
                          <div className="flex border-[2px] border-[#E1E5EA] rounded-[12px] py-1 max-w-full">
                            <input
                              type="text"
                              value={moment(new Date()).format("YYYY-MM-DD")}
                              className={"password-input border-none"}
                              readOnly
                            />
                          </div>
                        </div>
                        {isTouchDevice && (
                          <>
                            <div className="mb-2 mt-4 flex justify-between items-center ">
                              <label
                                htmlFor={"signature"}
                                className="font-semibold text-[16px] text-[#0B0D0F]"
                              >
                                Sign your application
                                <span className="text-[#FF4444] ml-1">*</span>
                              </label>
                            </div>
                            <div className="signature__pad border-[2px] border-[#E1E5EA] rounded-md lg:w-[550px] lg:h-[138px] w-full">
                              <Field name="signature">
                                {({ field, form }: FieldProps) => (
                                  <>
                                    <SignaturePad
                                      {...field}
                                      penColor="black"
                                      canvasProps={{
                                        style: {
                                          width: "100%",
                                          height: "100%",
                                          backgroundImage: `url(${values.signature})`,
                                          backgroundSize: "cover",
                                          crossOrigin: "anonymous",
                                        },
                                      }}
                                      ref={sigCanvas}
                                      onEnd={async () => {
                                        const yourBase64 = sigCanvas?.current
                                          ?.getTrimmedCanvas()
                                          .toDataURL("image/png");
                                        const signature =
                                          await convertbas64Tofile(yourBase64);
                                        dispatch(
                                          setSubmitSignature(yourBase64)
                                        );
                                        form.setFieldValue(
                                          "signature",
                                          signature
                                        );
                                      }}
                                    />
                                    <u
                                      className="float-right mt-1 mr-2 cursor-pointer text-[#71859A] font-semibold"
                                      onClick={() => {
                                        sigCanvas?.current?.clear();
                                        form.setFieldValue("signature", "");
                                      }}
                                    >
                                      clear
                                    </u>
                                  </>
                                )}
                              </Field>
                            </div>
                          </>
                        )}
                        {/* <ErrorMessage
                          name="signature"
                          component="div"
                          className="text-red-500"
                        /> */}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </>
          ) : (
            <div className="max-w-[500px] overflow-x-scroll min-w-full">
              <table className="min-w-full rounded-lg overflow-hidden bg-[#E8F8FD] border-[1px]">
                <thead>
                  <tr className="float-left">
                    <th className="py-3 px-3 ">Review your inputs</th>
                  </tr>
                </thead>
                <tbody className="px-3 bg-white">
                  <tr className={`px-3 text-[#0B0D0F] text-[14px] bg-white`}>
                    <td className="px-3 py-3 w-2/3">Username</td>
                    <td className="px-3 py-3">
                      <span className="text-[#0B0D0F] font-semibold">
                        {user?.studentId}
                      </span>
                    </td>
                    <td className="pl-3 py-3">
                      <div className="w-full flex justify-end">
                        <img
                          src={editIcon}
                          alt="editIcon"
                          className="w-4 h-4 cursor-pointer max-w-fit mr-5"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr
                    className={`px-3 text-[#0B0D0F] text-[14px] bg-[#F0F2F4]`}
                  >
                    <td className="px-3 py-3 w-2/3">Your email</td>
                    <td className="px-3 py-3">
                      <span className="text-[#0B0D0F] font-semibold">
                        {user?.email}
                      </span>
                    </td>
                    <td className="pl-3 py-3">
                      <div className="w-full flex justify-end">
                        <img
                          src={editIcon}
                          alt="editIcon"
                          className="w-4 h-4 cursor-pointer max-w-fit mr-5"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr className={`px-3 text-[#0B0D0F] text-[14px] bg-white`}>
                    <td className="px-3 py-3 w-2/3">University</td>
                    <td className="px-3 py-3">
                      <span className="text-[#0B0D0F] font-semibold">
                        {user?.university}
                      </span>
                    </td>
                    <td className="pl-3 py-3">
                      <div className="w-full flex justify-end">
                        <img
                          src={editIcon}
                          alt="editIcon"
                          className="w-4 h-4 cursor-pointer max-w-fit mr-5"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr
                    className={`px-3 text-[#0B0D0F] text-[14px] bg-[#F0F2F4]`}
                  >
                    <td className="px-3 py-3 w-2/3">consent_city</td>
                    <td className="px-3 py-3">
                      <span className="text-[#0B0D0F] font-semibold">
                        {user?.city}
                      </span>
                    </td>
                    <td className="pl-3 py-3">
                      <div className="w-full flex justify-end">
                        <img
                          src={editIcon}
                          alt="editIcon"
                          className="w-4 h-4 cursor-pointer max-w-fit mr-5"
                        />
                      </div>
                    </td>
                  </tr>
                  {showData &&
                    showData?.map((item: any, index: number) => (
                      <>
                        <tr
                          className={`px-3 text-[#0B0D0F] text-[14px] ${
                            index % 2 === 0 ? "bg-white" : "bg-[#F0F2F4]"
                          }`}
                          key={index}
                        >
                          <td className="px-3 py-3 w-2/3">{item.label}</td>
                          <td className="px-3 py-3">
                            <span className="text-[#0B0D0F] font-semibold">
                              {getValue(item.value, item.name)}
                            </span>
                          </td>
                          <td className="pl-3 py-3">
                            <div className="w-full flex justify-end">
                              <img
                                src={editIcon}
                                alt="editIcon"
                                className="w-4 h-4 cursor-pointer max-w-fit mr-5"
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SubmitForm;
