import { Box, LinearProgress, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Input, Inputfile, RadioGroup, SelectInput } from 'components/Form';
import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { CountryDropdown } from 'react-country-region-selector';
import { useAppSelector } from 'store/Hooks';
import { formCorrectionSelector } from 'store/slices/FormCorrection';
import { prestudySelector } from 'store/slices/Prestudy';
import { userSelector } from 'store/slices/User';
import { validateFiles } from 'utils/Helper';
import { crossBlack, documentBlack } from 'utils/Images';
import { cityOptions, genderOptions } from 'utils/data';

interface MarriedCompProps {
    values: any;
    modal?: boolean;
    setModal?: any;
    field?: string;
    setField?: any;
    showField?: (args: string) => boolean;
    showComments?: (args: string) => boolean;
    handleChange: any;
    userType?: boolean;
    studentData?: any;
    uploadDocument?: any;
}

const MarriedComp: React.FC<MarriedCompProps> = ({ values, showField, showComments, modal, setModal, field, setField, handleChange, userType, studentData, uploadDocument }) => {
    const DateInput = DatePicker as any;
    const { user } = useAppSelector(userSelector);
    const { currentStep } = useAppSelector(prestudySelector);
    const { currentStep: step } = useAppSelector(formCorrectionSelector);
    const CountrySelect = CountryDropdown as any;
    const [files1, setFiles1] = useState<File[] | null>(null);
    const [progress, setProgress] = React.useState(0);
    const [fileErr1, setfileErr1] = useState<string | null>(null);
    const [uploadingOne, setUploadingOne] = useState(false);
    const intervalIdRefone = useRef<any>();
    const genderOptions = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Unknown", label: "Unknown" },
    ]
    const zipcodeOption = [
        { value: "", label: "Select zipcode" },
        { value: "14467", label: "14467" },
        { value: "14469", label: "14469" },
        { value: "14471", label: "14471" },
        { value: "14473", label: "14473" },
        { value: "14476", label: "14476" },
        { value: "14478", label: "14478" },
        { value: "14480", label: "14480" },
        { value: "14482", label: "14482" },
    ];

    useEffect(() => {
        if (progress > 100) {
            setUploadingOne(false);
            setProgress(0);
            clearInterval(intervalIdRefone?.current);
        }
    }, [progress]);

    useEffect(() => {
        return () => {
            clearInterval(intervalIdRefone?.current);
        };
    }, []);

    const uploadFiles = async (
        selectedFile: FileList | null,
        fieldName: string,
        form: any
    ) => {
        const allowedTypes = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf",
        ];
        const isValidate = await validateFiles(selectedFile, allowedTypes);
        if (isValidate === "true") {
            if (selectedFile) {
                if (fieldName === "mrgFile") {
                    const newFiles = Array.from(selectedFile).filter(
                        (newFile) =>
                            !files1 ||
                            !files1.some(
                                (existingFile) => existingFile.name === newFile.name
                            )
                    );
                    const updatedFiles = files1
                        ? [...files1, ...newFiles]
                        : newFiles;
                    form.setFieldValue("mrgFile", updatedFiles);
                    setFiles1(updatedFiles);
                    const formData: any = new FormData();
                    updatedFiles?.forEach((file: any) => {
                        formData.append("mrgFile", file);
                    });
                    if (userType) {
                        studentData && formData.append("userId", studentData?._id);
                        step && formData.append("step", (step - 1).toString());

                    } else {
                        user && formData.append("userId", user?._id);
                        currentStep && formData.append("step", (currentStep - 1).toString());

                    }
                    await uploadDocument(formData)
                }
            }
        } else {
            if (fieldName === "mrgFile") {
                setFiles1(null);
                setfileErr1(isValidate);
            }
        }
    };

    const removeFile = async (fileName: string, fieldName: string) => {
        if (fieldName === "mrgFile") {
            const updatedFiles = files1?.filter(
                (item) => item.name !== fileName
            );
            if (updatedFiles && updatedFiles.length === 0)
                setfileErr1("Files are required");
            updatedFiles && setFiles1(updatedFiles);
        }
        setProgress(0);
    };

    return (
        <>
            <div className={`MarriedComp px-4 mb-3`}>
                <div className="flex justify-between items-center mb-2">
                    <label
                        htmlFor={"MarriedComp"}
                        className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                        Marriage information
                    </label>
                </div>
                <p className="text-[#FF4444] ml-1">Enter the information in this page as it is written on your marriage certificate.
                </p>
                <div className='flex flex-col lg:flex-row gap-2'>
                    <div className={!showField ? "block" : showField(`dom`) ? "block" : 'hidden'}>
                        <div className="flex justify-between items-center my-3">
                            <label
                                htmlFor={`dom`}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('dom')
                                        }}
                                        checked={modal && field === 'dom'}
                                    />
                                }
                                Date of marriage(YYYY-MM-DD)
                                <span className="text-[#FF4444] ml-1">*</span>
                            </label>
                        </div>
                        <Field name="dom">
                            {({ field, form }: FieldProps) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateInput
                                        {...field}
                                        label="YYYY-MM-DD"
                                        renderInput={(props: any) => (
                                            <TextField {...props} />
                                        )}
                                        format="YYYY-MM-DD"
                                        value={values.dom}
                                        onChange={(newDate: any) => {
                                            form.setFieldValue("dom", newDate);
                                        }}
                                        className={
                                            "password-input"
                                        }
                                        disableFuture
                                    />
                                </LocalizationProvider>
                            )}
                        </Field>
                        <ErrorMessage
                            name="dom"
                            component="div"
                            className="text-red-600"
                        />
                        {showComments && showComments('dom') &&
                            <CommentSection field={'dom'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                    <div className={!showField ? "block" : showField('mrgCity') ? "block" : 'hidden'}>
                        <div className="flex justify-between items-center my-3">
                            <label
                                htmlFor={"mrgCity"}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('mrgCity')
                                        }}
                                        checked={modal && field === 'mrgCity'}
                                    />
                                }
                                City of marriage
                                <span className="text-[#FF4444] ml-1">*</span>
                            </label>
                        </div>
                        <Input
                            type="text"
                            fieldName="mrgCity"
                            setValue={values.mrgCity}
                            className={null}
                            handleChange={handleChange}
                        />
                        {showComments && showComments('mrgCity') &&
                            <CommentSection field={'mrgCity'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className={!showField ? "block" : showField(`mrgCountry`) ? "block" : 'hidden'}>
                        <div className="flex justify-between items-center my-3">
                            <label
                                htmlFor={`mrgCountry`}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('mrgCountry')
                                        }}
                                        checked={modal && field === 'mrgCountry'}
                                    />
                                }
                                Country of marriage
                                <span className="text-[#FF4444] ml-1">*</span>
                            </label>
                        </div>
                        <div className="w-full lg:w-1/3">
                            <Field name="mrgCountry">
                                {({ field, form }: FieldProps) => (
                                    <CountrySelect
                                        {...field}
                                        value={values.mrgCountry}
                                        onChange={(val: string) =>
                                            form.setFieldValue("mrgCountry", val)
                                        }
                                        className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                                    />
                                )}
                            </Field>
                        </div>
                        <ErrorMessage
                            name="mrgCountry"
                            component="div"
                            className="text-red-600"
                        />
                        {showComments && showComments('mrgCountry') &&
                            <CommentSection field={'mrgCountry'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                    <div className={!showField ? "block" : showField('mrgFile') ? "block" : 'hidden'}>
                        <div className="flex justify-between items-center my-3">
                            <label
                                htmlFor={"mrgFile"}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('mrgFile')
                                        }}
                                        checked={modal && field === 'mrgFile'}
                                    />
                                }
                                Upload your certificate of marriage
                            </label>
                        </div>
                        <Field name="mrgFile">
                            {({ field, form }: FieldProps) => (
                                <>
                                    <Inputfile
                                        {...field}
                                        mainClass="lg:w-1/3 xl:w-1/3 w-full"
                                        btnClass={null}
                                        handleChange={(
                                            event: ChangeEvent<HTMLInputElement>
                                        ) => {
                                            setProgress(0);
                                            setUploadingOne(true);
                                            let selectedFile: FileList | null =
                                                event.target.files;
                                            selectedFile &&
                                                form.setFieldValue(
                                                    "mrgFile",
                                                    Array.from(selectedFile)
                                                );
                                            setfileErr1(null);
                                            intervalIdRefone.current = setInterval(() => {
                                                setProgress((prevProgress) => prevProgress + 10);
                                            }, 500);
                                            uploadFiles(
                                                event.target.files,
                                                "mrgFile",
                                                form
                                            );
                                        }}
                                        multiple={true}
                                    />
                                    {!fileErr1 &&
                                        values.mrgFile &&
                                        values.mrgFile.length > 0 &&
                                        values.mrgFile.map(
                                            (item: { name: string; location: string }) => (
                                                <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                                    <img
                                                        src={documentBlack}
                                                        alt="documentBlack"
                                                    />
                                                    <label className="flex-1">{item.name}</label>
                                                    <img
                                                        src={crossBlack}
                                                        alt="documentBlack"
                                                        onClick={async () => {
                                                            const updatedFiles =
                                                                values.mrgFile?.filter(
                                                                    (val: {
                                                                        name: string;
                                                                        location: string;
                                                                    }) => val.name !== item.name
                                                                );
                                                            form.setFieldValue(
                                                                "mrgFile",
                                                                updatedFiles
                                                            );
                                                            setFiles1(updatedFiles);
                                                            if (updatedFiles.length === 0) {
                                                                form.setFieldValue(
                                                                    "mrgFile",
                                                                    []
                                                                );
                                                                setFiles1(null);
                                                                setfileErr1(
                                                                    "Files are required"
                                                                );
                                                                setProgress(0);
                                                                const formData: any = new FormData();
                                                                updatedFiles?.forEach((file: any) => {
                                                                    formData.append("mrgFile", file);
                                                                });
                                                                if (userType) {
                                                                    studentData && formData.append("userId", studentData?._id);
                                                                    step && formData.append("step", (step - 1).toString());

                                                                } else {
                                                                    user && formData.append("userId", user?._id);
                                                                    currentStep && formData.append("step", (currentStep - 1).toString());

                                                                }
                                                                await uploadDocument(formData);
                                                            }
                                                        }}
                                                        className="cursor-pointer"
                                                    />
                                                </div>
                                            )
                                        )}
                                    {uploadingOne && (
                                        <Box sx={{ width: "100%" }}>
                                            <LinearProgress
                                                variant="determinate"
                                                value={progress}
                                            />
                                        </Box>
                                    )}
                                </>
                            )}
                        </Field>
                        {fileErr1 ? (
                            <div className="text-red-500 text-sm md:text-base fileError">
                                {fileErr1}
                            </div>
                        ) : (
                            <ErrorMessage
                                name="mrgFile"
                                component="div"
                                className="text-red-600 fileError"
                            />
                        )}
                        {showComments && showComments('mrgFile') &&
                            <CommentSection field={'mrgFile'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                </div>
                <div className="flex justify-between items-center my-2">
                    <label
                        htmlFor={"MarriedComp"}
                        className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                        Spouse information
                    </label>
                </div>
                <div className={!showField ? "block" : showField('spouseLname') ? "block" : 'hidden'}>
                    <p className="text-[#FF4444] ml-1">If your spouse has more than one last name, write them all as they are written on the marriage certificate.</p>
                    <div className="flex justify-between items-center my-3">
                        <label
                            htmlFor={"spouseLname"}
                            className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('spouseLname')
                                    }}
                                    checked={modal && field === 'spouseLname'}
                                />
                            }
                            Spouse - Last name(s)
                        </label>
                    </div>
                    <Input
                        type="text"
                        fieldName="spouseLname"
                        setValue={values.spouseLname}
                        className={"w-full lg:w-1/3 password-input border-[2px] border-[#E1E5EA] rounded-md placeholder-[#71859A] placeholder:text-[14px]"}
                        handleChange={handleChange}
                    />
                    {showComments && showComments('spouseLname') &&
                        <CommentSection field={'spouseLname'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className={!showField ? "block" : showField('spouseFname') ? "block" : 'hidden'}>
                    <p className="text-[#FF4444] ml-1">
                        If your spouse has more than one first name, write them all as they are written on the marriage certificate.                        </p>
                    <div className="flex justify-between items-center my-3">
                        <label
                            htmlFor={"spouseFname"}
                            className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('spouseFname')
                                    }}
                                    checked={modal && field === 'spouseFname'}
                                />
                            }
                            Spouse - First name(s)
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                    </div>
                    <Input
                        type="text"
                        fieldName="spouseFname"
                        setValue={values.spouseFname}
                        className={"w-full lg:w-1/3 password-input border-[2px] border-[#E1E5EA] rounded-md placeholder-[#71859A] placeholder:text-[14px]"}
                        handleChange={handleChange}
                    />
                    {showComments && showComments('spouseFname') &&
                        <CommentSection field={'spouseFname'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className={!showField ? "block" : showField('spouseName') ? "block" : 'hidden'}>
                    <p className="text-[#FF4444] ml-1">Enter the name at birth of your spouse, if different from the actual family name.</p>
                    <div className="flex justify-between items-center my-3">
                        <label
                            htmlFor={"spouseName"}
                            className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('spouseName')
                                    }}
                                    checked={modal && field === 'spouseName'}
                                />
                            }
                            Spouse - Name at birth
                        </label>
                    </div>
                    <Input
                        type="text"
                        fieldName="spouseName"
                        setValue={values.spouseName}
                        className={"w-full lg:w-1/3 password-input border-[2px] border-[#E1E5EA] rounded-md placeholder-[#71859A] placeholder:text-[14px]"}
                        handleChange={handleChange}
                    />
                    {showComments && showComments('spouseName') &&
                        <CommentSection field={'spouseName'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className={!showField ? "block" : showField('spouseGender') ? "block" : 'hidden'}>
                    <div className="flex justify-between items-center my-3">
                        <label
                            htmlFor={"spouseGender"}
                            className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('spouseGender')
                                    }}
                                    checked={modal && field === 'spouseGender'}
                                />
                            }
                            Spouse - Gender
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                    </div>
                    <Field name="spouseGender">
                        {({ field, form }: FieldProps) => (
                            <div className="flex flex-col gap-3 justify-center">
                                <RadioGroup
                                    {...field}
                                    fieldName="spouseGender"
                                    options={genderOptions}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        form.setFieldValue(
                                            "spouseGender",
                                            event.target.value
                                        );
                                    }}
                                    className={
                                        "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                                    }
                                    checkedField={values.spouseGender}
                                />
                            </div>
                        )}
                    </Field>
                    <ErrorMessage
                        name="spouseGender"
                        component="div"
                        className="text-red-500"
                    />
                    {showComments && showComments('spouseGender') &&
                        <CommentSection field={'spouseGender'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className='flex flex-col lg:flex-row gap-2'>
                    <div className={!showField ? "block" : showField(`spouseDob`) ? "block" : 'hidden'}>
                        <div className="flex justify-between items-center my-3">
                            <label
                                htmlFor={`spouseDob`}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('spouseDob')
                                        }}
                                        checked={modal && field === 'spouseDob'}
                                    />
                                }
                                Spouse - Date of birth (YYYY-MM-DD)
                                <span className="text-[#FF4444] ml-1">*</span>
                            </label>
                        </div>
                        <Field name="spouseDob">
                            {({ field, form }: FieldProps) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateInput
                                        {...field}
                                        label="YYYY-MM-DD"
                                        renderInput={(props: any) => (
                                            <TextField {...props} />
                                        )}
                                        format="YYYY-MM-DD"
                                        value={values.spouseDob}
                                        onChange={(newDate: any) => {
                                            form.setFieldValue("spouseDob", newDate);
                                        }}
                                        className={
                                            "password-input"
                                        }
                                        disableFuture
                                    />
                                </LocalizationProvider>
                            )}
                        </Field>
                        <ErrorMessage
                            name="spouseDob"
                            component="div"
                            className="text-red-600"
                        />
                        {showComments && showComments('spouseDob') &&
                            <CommentSection field={'spouseDob'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                    <div className={!showField ? "block" : showField('spousePob') ? "block" : 'hidden'}>
                        <div className="flex justify-between items-center my-3">
                            <label
                                htmlFor={"spousePob"}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('spousePob')
                                        }}
                                        checked={modal && field === 'spousePob'}
                                    />
                                }
                                Spouse - Place of birth
                                <span className="text-[#FF4444] ml-1">*</span>
                            </label>
                        </div>
                        <Input
                            type="text"
                            fieldName="spousePob"
                            setValue={values.spousePob}
                            className={null}
                            handleChange={handleChange}
                        />
                        {showComments && showComments('spousePob') &&
                            <CommentSection field={'spousePob'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className={!showField ? "block" : showField(`spouseCob`) ? "block" : 'hidden'}>
                        <div className="flex justify-between items-center my-3">
                            <label
                                htmlFor={`spouseCob`}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('spouseCob')
                                        }}
                                        checked={modal && field === 'spouseCob'}
                                    />
                                }
                                Spouse - Country of birth
                                <span className="text-[#FF4444] ml-1">*</span>
                            </label>
                        </div>
                        <div className="w-full lg:w-1/3">
                            <Field name="spouseCob">
                                {({ field, form }: FieldProps) => (
                                    <CountrySelect
                                        {...field}
                                        value={values.spouseCob}
                                        onChange={(val: string) =>
                                            form.setFieldValue("spouseCob", val)
                                        }
                                        className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                                    />
                                )}
                            </Field>
                        </div>
                        <ErrorMessage
                            name="spouseCob"
                            component="div"
                            className="text-red-600"
                        />
                        {showComments && showComments('spouseCob') &&
                            <CommentSection field={'spouseCob'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                    <div className={!showField ? "block" : showField(`spouseCitizenship`) ? "block" : 'hidden'}>
                        <div className="flex justify-between items-center my-3">
                            <label
                                htmlFor={`spouseCitizenship`}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('spouseCitizenship')
                                        }}
                                        checked={modal && field === 'spouseCitizenship'}
                                    />
                                }
                                Spouse - Citizenship
                                <span className="text-[#FF4444] ml-1">*</span>
                            </label>
                        </div>
                        <div className="w-full lg:w-1/3">
                            <Field name="spouseCitizenship">
                                {({ field, form }: FieldProps) => (
                                    <CountrySelect
                                        {...field}
                                        value={values.spouseCitizenship}
                                        onChange={(val: string) =>
                                            form.setFieldValue("spouseCitizenship", val)
                                        }
                                        className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                                    />
                                )}
                            </Field>
                        </div>
                        <ErrorMessage
                            name="spouseCitizenship"
                            component="div"
                            className="text-red-600"
                        />
                        {showComments && showComments('spouseCitizenship') &&
                            <CommentSection field={'spouseCitizenship'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className={!showField ? "block" : showField('spouseAddress') ? "block" : 'hidden'}>
                        <div className="flex justify-between items-center my-3">
                            <label
                                htmlFor={"spouseAddress"}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('spouseAddress')
                                        }}
                                        checked={modal && field === 'spouseAddress'}
                                    />
                                }
                                Spouse - Actual Address
                            </label>
                        </div>
                        <Input
                            type="text"
                            fieldName="spouseAddress"
                            setValue={values.spouseAddress}
                            className={"w-full lg:w-1/3 password-input border-[2px] border-[#E1E5EA] rounded-md placeholder-[#71859A] placeholder:text-[14px]"}
                            handleChange={handleChange}
                        />
                        {showComments && showComments('spouseAddress') &&
                            <CommentSection field={'spouseAddress'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                    <div className='flex flex-col lg:flex-row gap-2'>
                        <div className={!showField ? "block" : showField('spouseRes') ? "block" : 'hidden'}>
                            <div className="flex justify-between items-center my-3">
                                <label
                                    htmlFor={"spouseRes"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('spouseRes')
                                            }}
                                            checked={modal && field === 'spouseRes'}
                                        />
                                    }
                                    Spouse - Actual city of residence
                                </label>
                            </div>
                            <Field name="spouseRes">
                                {({ field, form }: FieldProps) => (
                                    <SelectInput
                                        {...field}
                                        fieldName="spouseRes"
                                        options={cityOptions}
                                        selectedOption={values.city}
                                        handleSelect={(event: ChangeEvent<HTMLInputElement>) => {
                                            form.setFieldValue("spouseRes", event.target.value);
                                            // form.setFieldValue("zipcode", '14467');
                                        }}
                                        className="w-full"
                                    />
                                )}
                            </Field>
                            {showComments && showComments('spouseRes') &&
                                <CommentSection field={'spouseRes'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                        <div className={!showField ? "block" : showField('spouseZipcode') ? "block" : 'hidden'}>
                            <div className="flex justify-between items-center my-3">
                                <label
                                    htmlFor={"spouseZipcode"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('spouseZipcode')
                                            }}
                                            checked={modal && field === 'spouseZipcode'}
                                        />
                                    }
                                    Spouse - Actual Zip/Postal code
                                </label>
                            </div>
                            {values.spouseRes !== "Potsdam" ? (
                                <Input
                                    type="text"
                                    fieldName="spouseZipcode"
                                    setValue={values.spouseZipcode}
                                    className={null}
                                    handleChange={handleChange}
                                />
                            ) : (
                                <>
                                    <Field name="spouseZipcode">
                                        {({ field, form }: FieldProps) => (
                                            <SelectInput
                                                {...field}
                                                fieldName="spouseZipcode"
                                                options={zipcodeOption}
                                                selectedOption={values.spouseZipcode}
                                                handleSelect={(
                                                    event: ChangeEvent<HTMLSelectElement>
                                                ) => {
                                                    form.setFieldValue("spouseZipcode", event.target.value);
                                                }}
                                                className="w-full"
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="spouseZipcode"
                                        component="div"
                                        className="text-red-500"
                                    />
                                </>
                            )}
                            {showComments && showComments('spouseZipcode') &&
                                <CommentSection field={'spouseZipcode'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                    </div>
                    <div className={!showField ? "block" : showField(`spouseRescountry`) ? "block" : 'hidden'}>
                        <div className="flex justify-between items-center my-3">
                            <label
                                htmlFor={`spouseRescountry`}
                                className="font-400 text-[14px] text-[#0B0D0F]                    "
                            >
                                {userType &&
                                    <input
                                        type="checkbox"
                                        className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                        onClick={(e: any) => {
                                            setModal(e.target.checked);
                                            setField('spouseRescountry')
                                        }}
                                        checked={modal && field === 'spouseRescountry'}
                                    />
                                }
                                Spouse - Actual country of residence
                                <span className="text-[#FF4444] ml-1">*</span>
                            </label>
                        </div>
                        <div className="w-full lg:w-1/3">
                            <Field name="spouseRescountry">
                                {({ field, form }: FieldProps) => (
                                    <CountrySelect
                                        {...field}
                                        value={values.spouseRescountry}
                                        onChange={(val: string) =>
                                            form.setFieldValue("spouseRescountry", val)
                                        }
                                        className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                                    />
                                )}
                            </Field>
                        </div>
                        <ErrorMessage
                            name="spouseRescountry"
                            component="div"
                            className="text-red-600"
                        />
                        {showComments && showComments('spouseRescountry') &&
                            <CommentSection field={'spouseRescountry'} step={currentStep - 1} userType="student" />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default MarriedComp