import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React from 'react'
import { CountryDropdown } from 'react-country-region-selector';
import { useAppSelector } from 'store/Hooks';
import { prestudySelector } from 'store/slices/Prestudy';

interface AuthorizationOneProps {
    values: any;
    modal?: boolean;
    setModal?: any;
    field?: string;
    setField?: any;
    showField?: (args: string) => boolean;
    showComments?: (args: string) => boolean;
    handleChange: any
    userType?: boolean;
    getCount?: (args: string) => number
}

const AuthorizationOne: React.FC<AuthorizationOneProps> = ({ values, showField, showComments, modal, setModal, field, setField, handleChange, userType, getCount }) => {
    console.log("showField",showField)
    const DateInput = DatePicker as any;
    const { currentStep } = useAppSelector(prestudySelector);
    const CountrySelect = CountryDropdown as any;

    return (
        <div className={`AuthorizationOne flex flex-col lg:flex-row gap-2 px-4 mb-3`}>
            <div className={!showField ? "block" : showField('authCountry') ? "block" : 'hidden'}>
                <div className="mb-2 mt-4 flex items-center">
                    <label
                        htmlFor={"authCountry"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                    >
                        {userType &&
                            <input
                                type="checkbox"
                                className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                onClick={(e: any) => {
                                    setModal(e.target.checked);
                                    setField('authCountry')
                                }}
                                checked={modal && field === 'authCountry'}
                            />
                        }
                        Authorisation to return to{" "}
                        <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                    {getCount && getCount("authCountry") > 0 && (
                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                            {getCount("authCountry")}
                        </div>
                    )}
                </div>
                <div className="w-full">
                    <Field name="authCountry">
                        {({ field, form }: FieldProps) => (
                            <CountrySelect
                                {...field}
                                value={values.authCountry}
                                onChange={(val: string) =>
                                    form.setFieldValue("authCountry", val)
                                }
                                className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                            />
                        )}
                    </Field>
                </div>
                <ErrorMessage
                    name="authCountry"
                    component="div"
                    className="text-red-500"
                />
                {showComments && showComments('authCountry') &&
                    <CommentSection field={'authCountry'} step={currentStep - 1} userType="student" />
                }
            </div>
            <div className={!showField ? "block" : showField('authDate') ? "block" : 'hidden'}>
                <div className="flex items-center my-3">
                    <label
                        htmlFor={"authDate"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                    >
                        {userType &&
                            <input
                                type="checkbox"
                                className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                onClick={(e: any) => {
                                    setModal(e.target.checked);
                                    setField('authDate')
                                }}
                                checked={modal && field === 'authDate'}
                            />
                        }
                        Valid until(YYYY-MM-DD)
                        <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                    {getCount && getCount("authDate") > 0 && (
                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                            {getCount("authDate")}
                        </div>
                    )}
                </div>
                <Field name="authDate">
                    {({ field, form }: FieldProps) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateInput
                                {...field}
                                label="YYYY-MM-DD"
                                renderInput={(props: any) => (
                                    <TextField {...props} />
                                )}
                                format="YYYY-MM-DD"
                                value={values.authDate}
                                onChange={(newDate: any) => {
                                    form.setFieldValue("authDate", newDate);
                                }}
                                className={
                                    "password-input"
                                }
                                disablePast
                            />
                        </LocalizationProvider>
                    )}
                </Field>
                <ErrorMessage
                    name="authDate"
                    component="div"
                    className="text-red-600"
                />
                {showComments && showComments('authDate') &&
                    <CommentSection field={'authDate'} step={currentStep - 1} userType="student" />
                }
            </div>
        </div>
    )
}

export default AuthorizationOne