import { Box, LinearProgress, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Inputfile } from 'components/Form';
import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Hooks';
import { formCorrectionSelector } from 'store/slices/FormCorrection';
import { prestudySelector } from 'store/slices/Prestudy';
import { userSelector } from 'store/slices/User';
import { validateFiles } from 'utils/Helper';
import { crossBlack, documentBlack } from 'utils/Images';
import { setFeatureLoaded } from "store/slices/Prestudy";


const FictionalCertificate: React.FC<any> = ({ values, uploadDocument, userType, setModal, setField, field, modal, showComments, studentData, highlightedIndex, setHighlightedIndex, getCount }) => {
    const [fcFileError, setFcfileError] = useState<string | null>(null);
    const [fcFiles, setFcfiles] = useState<File[] | null>(null);
    const [fcDocument, setFcdocument] = useState<File[] | null>(null);
    const [fcDocumentError, setFcdocumentError] = useState<string | null>(null);
    const { user } = useAppSelector(userSelector);
    const { currentStep } =
        useAppSelector(prestudySelector);
    const { currentStep: lawyercurentStep } =
        useAppSelector(formCorrectionSelector);

    const DateInput = DatePicker as any;
    const [progress, setProgress] = React.useState(0);
    const [uploading, setUploading] = useState(false);
    const intervalIdRef = useRef<any>();
    const [progressTwo, setProgressTwo] = React.useState(0);
    const [uploadingTwo, setUploadingtwo] = useState(false);
    const intervalIdRefTwo = useRef<any>();

    const fieldRefs = useRef<any>({});

    useEffect(() => {
        if (fieldRefs.current[highlightedIndex]) {
            fieldRefs.current[highlightedIndex].focus();

            const container = document.getElementById('container');

            // Scroll the container to make 'fieldRefs.current[highlightedIndex]' visible
            fieldRefs.current[highlightedIndex].scrollIntoView({
                behavior: 'smooth',  // Optional: Smooth scrolling
                block: 'nearest',    // Optional: Adjust where element aligns
                inline: 'start'      // Optional: Adjust where element aligns
            });
        }
    }, [fieldRefs, highlightedIndex]);

    useEffect(() => {
        if (progress > 100) {
            setUploading(false);
            setProgress(0);
            clearInterval(intervalIdRef?.current);
        }
    }, [progress]);

    useEffect(() => {
        if (progressTwo > 100) {
            setUploadingtwo(false);
            setProgressTwo(0);
            clearInterval(intervalIdRefTwo?.current);
        }
    }, [progressTwo]);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (progress > 0) {
            dispatch(setFeatureLoaded(true));
        } else {
            dispatch(setFeatureLoaded(false));
        }
    }, [progress]);

    useEffect(() => {
        return () => {
            clearInterval(intervalIdRef.current);
            clearInterval(intervalIdRefTwo?.current);
        };
    }, []);

    const uploadFiles = async (selectedFile: FileList | null, form: any, fieldName: string) => {
        const allowedTypes = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf",
        ];
        const isValidate = await validateFiles(selectedFile, allowedTypes);
        if (isValidate === "true") {
            if (fieldName === "fcFile") {
                if (selectedFile) {
                    const newFiles = Array.from(selectedFile).filter(
                        (newFile) =>
                            !fcFiles ||
                            !fcFiles.some((existingFile: any) => existingFile.name === newFile.name)
                    );
                    const updatedFiles = fcFiles ? [...fcFiles, ...newFiles] : newFiles;
                    form.setFieldValue("fcFile", updatedFiles);
                    setFcfiles(updatedFiles);
                    const formData: any = new FormData();
                    updatedFiles?.forEach((file: any) => {
                        formData.append("fcFile", file);
                    });
                    if (userType === 'lawyer') {
                        lawyercurentStep && formData.append("step", (lawyercurentStep - 1).toString());
                        studentData && formData.append("userId", studentData?._id);
                    } else {
                        user && formData.append("userId", user?._id);
                        currentStep && formData.append("step", (currentStep - 1).toString());
                    }
                    formData.append("isRequired", true);
                    formData.append(
                        "label",
                        "Upload a copy of your fictional certificate"
                    );
                    await uploadDocument(formData);
                } else {
                    setFcfiles(null);
                }
            }
            if (fieldName === "fcDocument") {
                if (selectedFile) {
                    const newFiles = Array.from(selectedFile).filter(
                        (newFile) =>
                            !fcDocument ||
                            !fcDocument.some((existingFile: any) => existingFile.name === newFile.name)
                    );
                    const updatedFiles = fcDocument ? [...fcDocument, ...newFiles] : newFiles;
                    form.setFieldValue("fcDocument", updatedFiles);
                    setFcdocument(updatedFiles);
                    const formData: any = new FormData();
                    updatedFiles?.forEach((file: any) => {
                        formData.append("fcDocument", file);
                    });
                    if (userType === 'lawyer') {
                        lawyercurentStep && formData.append("step", (lawyercurentStep - 1).toString());
                        studentData && formData.append("userId", studentData?._id);
                    } else {
                        user && formData.append("userId", user?._id);
                        currentStep && formData.append("step", (currentStep - 1).toString());
                    }
                    formData.append("isRequired", true);
                    formData.append(
                        "label",
                        "Upload, if available, any document supporting the situation BEFORE the fictional certificate"
                    );
                    await uploadDocument(formData);
                } else {
                    setFcdocument(null);
                }
            }
        }
    };

    return (
        <div className='resident-permit mt-3'>
            <div className='context bg-[#F6F6F6] p-3 text-[#0B0D0F] text-[14px] font-semibold'>
                <h1>Fictional certificate (Fiktionsbescheinigung):</h1>
                <p className='my-3'>
                    A fictional certificate is issued when it is not yet possible to decide on an application for a residence permit.
                    A fictional certificate may only be issued when residence is still lawful at the time of applying for the issue or renewal of a residence permit.
                    If a currently valid residence title (temporary residence permit or national visa for long-term duration) is available, a fictional certificate according to § 81 Section 4 of the Residence Act will be issued.
                    In this case, the residence title and all supplementary regulations remain in force until a decision has been made in relation to the application. Journeys abroad and re-entry into the area of the Republic are possible with a valid fictional certificate in accordance with § 81 Section 4 of the Residence Act.
                    If the applicant is lawfully resident without an entry visa in the Federal Republic, because he or she is entitled to be by virtue of his/her nationality, the fictional certificate will be issued in accordance with § 81 Section 3 of the Residence Act. This permits residence in the area of the Federal Republic until a decision has been made regarding the application. This fictional certification, however, does not permit re-entry into the territory of the Federal Republic. It also does not permit the commencement of gainful employment.
                </p>
            </div>
            <div className={`block mt-3 p-2 ${highlightedIndex === 'option5Field1' ? 'border-2 !border-green-300 rounded-md' : ' border-transparent'}`}>
                <div className="mb-2 mt-4 flex justify-start gap-2 items-center">
                    {userType === 'lawyer' &&
                        <input
                            type="checkbox"
                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px]"
                            onClick={(e: any) => {
                                setModal(e.target.checked);
                                setField('fcDate')
                            }}
                            checked={modal && field === 'fcDate'}
                        />}
                    <div className='flex items-center gap-2'>
                        <label
                            htmlFor={"fcDate"}
                            className="text-[#0B0D0F] font-semibold text-[16px]"
                            ref={(el: any) => { fieldRefs.current['option5Field1'] = el }}
                        >
                            When will your fictional certificate expire?
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount('fcDate') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('fcDate')}
                            </div>
                        }
                    </div>
                </div>
                <Field name="fcDate"
                    ref={(el: any) => { fieldRefs.current['option5Field1'] = el }}
                    onFocus={() => setHighlightedIndex('option5Field1')} >
                    {({ field, form }: FieldProps) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div ref = {(el:any) => {fieldRefs.current['option5Field1'] = el}}>
                            <DateInput
                                {...field}
                                label="YYYY-MM-DD"
                                renderInput={(props: any) => <TextField {...props} />}
                                format="YYYY-MM-DD"
                                value={values.fcDate}
                                onChange={(newDate: any) => {
                                    form.setFieldValue("fcDate", newDate);
                                }}
                                className={"lg:w-1/2 w-full"}
                            />
                            </div>
                        </LocalizationProvider>
                    )}
                </Field>
                <ErrorMessage
                    name="fcDate"
                    component="div"
                    className="text-red-600"
                />
                {userType === 'student' ?
                    showComments('fcDate') &&
                    <CommentSection field={'fcDate'} step={currentStep - 1} userType="student" />
                    : <></>
                }
            </div>
            <div className={`block mt-3 p-2 ${highlightedIndex === 'option5Field2' ? 'border-2 !border-green-300 rounded-md' : ' border-transparent'}`}>
                <div className="mb-2 mt-4 flex justify-start gap-2 items-center">
                    {userType === 'lawyer' &&
                        <input
                            type="checkbox"
                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] "
                            onClick={(e: any) => {
                                setModal(e.target.checked);
                                setField('fcFile')
                            }}
                            checked={modal && field === 'fcFile'}
                        />}
                    <div className='flex items-center gap-2'>
                        <label
                            htmlFor={"fcDate"}
                            className="text-[#0B0D0F] font-semibold text-[16px]"
                            ref={(el: any) => { fieldRefs.current['option5Field2'] = el }}
                        >
                            Upload a copy of your fictional certificate
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount('fcDate') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('fcDate')}
                            </div>
                        }
                    </div>
                </div>
                <Field name="fcFile"
                    ref={(el: any) => { fieldRefs.current['option5Field2'] = el }}
                    onFocus={() => setHighlightedIndex('option5Field2')}>
                    {({ field, form }: FieldProps) => (
                        <>
                        <div ref = {(el:any) => {fieldRefs.current['option5Field2'] = el}}>
                            <Inputfile
                                {...field}
                                mainClass="lg:w-1/2  w-full"
                                btnClass={null}
                                handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setProgress(0);
                                    setUploading(true);
                                    setFcfileError(null);
                                    intervalIdRef.current = setInterval(() => {
                                        setProgress((prevProgress) => prevProgress + 10);
                                    }, 500);
                                    uploadFiles(event.target.files, form, 'fcFile');
                                }}
                                multiple={true}
                            />
                            </div>
                            {values.fcFile &&
                                values.fcFile.length > 0 &&
                                values.fcFile.map(
                                    (item: { name: string; location: string }) => (
                                        <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                            <img src={documentBlack} alt="documentBlack" />
                                            <label className="flex-1">{item.name}</label>
                                            <img
                                                src={crossBlack}
                                                alt="documentBlack"
                                                onClick={async () => {
                                                    const updatedFiles = values.fcFile?.filter(
                                                        (val: { name: string; location: string }) =>
                                                            val.name !== item.name
                                                    );
                                                    const formData: any = new FormData();
                                                    updatedFiles?.forEach((file: any) => {
                                                        formData.append("fcFile", file);
                                                    });
                                                    if (userType === 'lawyer') {
                                                        lawyercurentStep && formData.append("step", (lawyercurentStep - 1).toString());
                                                        studentData && formData.append("userId", studentData?._id);
                                                    } else {
                                                        user && formData.append("userId", user?._id);
                                                        currentStep && formData.append("step", (currentStep - 1).toString());
                                                    }
                                                    formData.append("isRequired", true);
                                                    formData.append(
                                                        "label",
                                                        "Upload a copy of your fictional certificate"
                                                    );
                                                    await uploadDocument(formData);
                                                    setFcfiles(updatedFiles);
                                                    if (updatedFiles.length === 0) {
                                                        form.setFieldValue("fcFile", null);
                                                        setFcfiles(null);
                                                    }
                                                }}
                                                className="cursor-pointer"
                                            />
                                        </div>
                                    )
                                )}
                            {uploading && (
                                <Box sx={{ width: "100%" }}>
                                    <LinearProgress variant="determinate" value={progress} />
                                </Box>
                            )}
                        </>
                    )}
                </Field>
                {fcFileError ? (
                    <div className="text-red-500 text-sm md:text-base fileError">
                        {fcFileError}
                    </div>
                ) : (
                    <ErrorMessage
                        name="fcFile"
                        component="div"
                        className="text-red-600 fileError"
                    />
                )}
                {userType === 'student' ?
                    showComments('fcFile') &&
                    <CommentSection field={'fcFile'} step={currentStep - 1} userType="student" />
                    : <></>
                }
            </div>
            <div className={`block mt-3 p-2 ${highlightedIndex === 'option5Field3' ? 'border-2 !border-green-300 rounded-md' : ' border-transparent'}`}>
                <div className="mb-2 mt-4 flex justify-start gap-2 items-center">
                    {userType === 'lawyer' &&
                        <input
                            type="checkbox"
                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] focus:ring-0 focus:ring-offset-0"
                            onClick={(e: any) => {
                                setModal(e.target.checked);
                                setField('fcComment')
                            }}
                            checked={modal && field === 'fcComment'}
                            ref = {(el:any) => {fieldRefs.current['option5Field3'] = el}}
                        />}
                    <div className='flex items-center gap-2'>
                        <label
                            htmlFor={"fcComment"}
                            className="text-[#0B0D0F] font-semibold text-[16px]" ref={(el: any) => { fieldRefs.current['option5Field3'] = el }}
                        >
                            What was your legal status before obtaining the fictional certificate?
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount('fcComment') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('fcComment')}
                            </div>
                        }
                    </div>
                </div>
                <Field name="fcComment"
                    ref={(el: any) => { fieldRefs.current['option5Field3'] = el }}
                    onFocus={() => setHighlightedIndex('option5Field3')}
                >
                    {({ field, form }: FieldProps) => (
                        <>
                            <textarea
                                {...field}
                                name="messages"
                                placeholder="Write a Something..."
                                rows={4}
                                className="password-input border-[2px] border-[#E1E5EA] rounded-md w-full placeholder-[#71859A] placeholder:text-[14px] lg:w-1/2 mb-2"
                                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                    form.setFieldValue('fcComment', e.target?.value)
                                }}
                            />
                        </>
                    )}
                </Field>
                <ErrorMessage
                    name="fcComment"
                    component="div"
                    className="text-red-600"
                />
                {userType === 'student' ?
                    showComments('fcComment') &&
                    <CommentSection field={'fcComment'} step={currentStep - 1} userType="student" />
                    : <></>
                }

            </div>
            <div className={`block mt-3 p-2 ${highlightedIndex === 'option5Field4' ? 'border-2 !border-green-300 rounded-md' : ' border-transparent'}`}>
                <div className="mb-2 mt-4 flex justify-start gap-2 items-center">
                    {userType === 'lawyer' &&
                        <input
                            type="checkbox"
                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] "
                            onClick={(e: any) => {
                                setModal(e.target.checked);
                                setField('fcDocument')
                            }}
                            checked={modal && field === 'fcDocument'}
                        />}
                    <div className='flex items-center gap-2'>
                        <label
                            htmlFor={"fcComment"}
                            className="text-[#0B0D0F] font-semibold text-[16px]" ref={(el: any) => { fieldRefs.current['option5Field4'] = el }}
                        >
                            Upload, if available, any document supporting the situation BEFORE the fictional certificate
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount('fcComment') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('fcComment')}
                            </div>
                        }
                    </div>
                </div>
                <Field name="fcDocument"
                    ref={(el: any) => { fieldRefs.current['option5Field4'] = el }}
                    onFocus={() => setHighlightedIndex('option5Field4')}>
                    {({ field, form }: FieldProps) => (
                        <>
                        <div  ref = {(el:any) => {fieldRefs.current['option5Field4'] = el}}>
                            <Inputfile
                                {...field}
                                mainClass="lg:w-1/2  w-full"
                                btnClass={null}
                                handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setProgressTwo(0);
                                    setUploadingtwo(true);
                                    setFcdocumentError(null);
                                    intervalIdRefTwo.current = setInterval(() => {
                                        setProgressTwo((prevProgress) => prevProgress + 10);
                                    }, 500);
                                    uploadFiles(event.target.files, form, 'fcDocument');
                                }}
                                multiple={true}
                            />
                            </div>
                            {values.fcDocument &&
                                values.fcDocument.length > 0 &&
                                values.fcDocument.map(
                                    (item: { name: string; location: string }) => (
                                        <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                            <img src={documentBlack} alt="documentBlack" />
                                            <label className="flex-1">{item.name}</label>
                                            <img
                                                src={crossBlack}
                                                alt="documentBlack"
                                                onClick={async () => {
                                                    const updatedFiles = values.fcDocument?.filter(
                                                        (val: { name: string; location: string }) =>
                                                            val.name !== item.name
                                                    );
                                                    const formData: any = new FormData();
                                                    updatedFiles?.forEach((file: any) => {
                                                        formData.append("fcDocument", file);
                                                    });
                                                    if (userType === 'lawyer') {
                                                        lawyercurentStep && formData.append("step", (lawyercurentStep - 1).toString());
                                                        studentData && formData.append("userId", studentData?._id);
                                                    } else {
                                                        user && formData.append("userId", user?._id);
                                                        currentStep && formData.append("step", (currentStep - 1).toString());
                                                    }
                                                    formData.append("isRequired", true);
                                                    formData.append(
                                                        "label",
                                                        "Upload, if available, any document supporting the situation BEFORE the fictional certificate"
                                                    );
                                                    await uploadDocument(formData);
                                                    setFcdocument(updatedFiles);
                                                    if (updatedFiles.length === 0) {
                                                        form.setFieldValue("fcDocument", null);
                                                        setFcdocument(null);
                                                    }
                                                }}
                                                className="cursor-pointer"
                                            />
                                        </div>
                                    )
                                )}
                            {uploadingTwo && (
                                <Box sx={{ width: "100%" }}>
                                    <LinearProgress variant="determinate" value={progressTwo} />
                                </Box>
                            )}
                        </>
                    )}
                </Field>
                {fcDocumentError ? (
                    <div className="text-red-500 text-sm md:text-base fileError">
                        {fcDocumentError}
                    </div>
                ) : (
                    <ErrorMessage
                        name="fcDocument"
                        component="div"
                        className="text-red-600 fileError"
                    />
                )}
                {userType === 'student' ?
                    showComments('fcDocument') &&
                    <CommentSection field={'fcDocument'} step={currentStep - 1} userType="student" />

                    : <></>
                }
            </div>
        </div>
    )
}

export default FictionalCertificate