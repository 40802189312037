import { Button } from "components/Form";
import Layout from "components/UI/Layout";
import { useEffect, useState } from "react";
import { downArrow, eyeGray, upArrow } from "utils/Images";
import ReminderModal from "./ReminderModal";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import { clearUser, setUser, userSelector } from "store/slices/User";
import dashboardService from "services/DashboardService";
import { cleanAndLogout } from "utils/Helper";
import { clearPrestudy, prestudySelector } from "store/slices/Prestudy";
import { clearFormcorrection } from "store/slices/FormCorrection";
import { formStepType } from "services/types";
import CustomLoader from "components/Loader/CustomLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import authService from "services/AuthService";
import { toast } from "react-toastify";
import VerifyAccountModal from "components/Modals/VerifyAccountModal";

const Dashboard: React.FC = () => {
  type studentDataType = {
    stepName: string;
    comments: string[];
    status: string;
  };
  type statusTypes = {
    _id: string;
    step: number;
    status: string;
    label: string;
    comments: string[];
  };
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const { currentStep } = useAppSelector(prestudySelector);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAppointMent, setLoadingAppointment] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { sidebarOpen, user } = useAppSelector(userSelector);
  const [studentData, setStudentData] = useState<studentDataType[] | null>(
    null
  );
  const navigate = useNavigate();
  const incompleteStatus = [
    "Not Verified",
    "pending",
    "Inprogress",
    "Skipped",
    "open",
  ];
  const completeStatus = ["approved", "completed", "reviewed"];
  const [stepArr, setStepArr] = useState<statusTypes[] | null>(null);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const getColor = (status: string) => {
    let str =
      "text-[#FFFFFF] uppercase  w-fit px-2 py-1 rounded-lg text-center text-[12px] cursor-default";
    if (incompleteStatus.includes(status)) {
      str += " bg-[#E39D6B]";
    } else if (completeStatus.includes(status)) {
      str += " bg-[#0A4A5E]";
    } else {
      str += " bg-[#76B312]";
    }
    return str;
  };

  const getStudentData = async () => {
    try {
      setLoading(true);
      const response = await dashboardService.getStudentDetails(user?._id);
      const {
        data: { data, status },
      } = response;
      if (status === 200) {
        setLoading(false);
        setStudentData(data);
        const filteredItems = data.filter(
          (item: any) => item.stepName === "Main form"
        );
        const status = data.filter((item: any) => item.stepName === "Approval");
        if (status && status.length > 0) {
          dispatch(setUser({ ...user, status: status[0].status }));
        }
        if (Array.isArray(filteredItems)) {
          const mainFormStatusData =
            filteredItems &&
            filteredItems[0].status.map((statusItem: formStepType) => ({
              ...statusItem,
              comments: filteredItems[0].comments.find(
                (commentItem: {
                  stepNumber: number;
                  comments: string[] | [];
                }) => commentItem.stepNumber === 0
              ).comments,
            }));
          setStepArr(mainFormStatusData);
        }
      }
      if (status === 401) {
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const updateAppointmentData = async () => {
    const redirectFrom = searchParams.get("redirectFrom")

    if (redirectFrom === "appointment-email") {
      setLoadingAppointment(true);
      const response: any = await dashboardService.updateAppointmentStatusById(user?._id)

      const {
        data: { status, message },
      } = response;
      setLoadingAppointment(false);

      if (status === 200) {
        toast.success(message)
      }

      if (status === 401) {
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }

      if (status === 404) {
        toast.error(message);
      }

      if (status === 500) {
        toast.error(message);
      }
    }

  }

  useEffect(() => {
    updateAppointmentData()
    if (user && user?._id) {
      getStudentData();
    }
    if(user?.isFirstLogin && !user?.isEmailVerified){
      setOpen(true);
      setType('email')
    }
  }, []);

  useEffect(() => {
    const toggleBodyOverflow = () => {
      document.body.style.overflowY = (open || showModal) ? "hidden" : "auto";
    };
    toggleBodyOverflow();
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [open, showModal]);

  const CollapsibleTableRow = () => {
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const toggleCollapse = () => {
      setCollapsed(!collapsed);
    };
    return (
      <>
        <tr
          className={`px-3 text-[#0B0D0F] text-[14px]
                          bg-white border-b-[2px] border-[#C3CCD5]
                          }`}
        >
          <td className="px-3 py-3 w-1/3">
            Main form
            {stepArr && stepArr?.length > 0 && (
              <button
                className="focus:outline-none ml-1"
                onClick={toggleCollapse}
              >
                {collapsed ? (
                  <img src={downArrow} alt="downArrow" className="h-3 w-3" />
                ) : (
                  <img src={upArrow} alt="downArrow" className="h-3 w-3" />
                )}
              </button>
            )}
          </td>
          <td className="px-3 py-3 w-1/3" />
          <td className="px-3 py-3 w-1/3">
            {stepArr?.length === 0 && (
              <Button
                type="button"
                label={"pending"}
                disabled={false}
                cssClass={`${getColor("pending")}`}
              />
            )}
          </td>
        </tr>
        {!collapsed && (
          <>
            {stepArr &&
              stepArr?.map((item, index) => (
                <tr
                  className={`px-3 text-[#0B0D0F] text-[14px]
                          bg-white border-b-[2px] border-[#C3CCD5]
                          }`}
                  key={index}
                >
                  <td className="px-3 py-3 w-1/3">
                    {[0, 9].includes(item.step)
                      ? item.label
                      : `Step ${item.step}`}
                  </td>
                  <td className="px-3 py-3 w-1/3">
                    {item.comments.length > 0 && (
                      <ul>
                        {item.comments.map((comment: string) => (
                          <li>{comment}</li>
                        ))}
                      </ul>
                    )}
                  </td>
                  <td className="px-3 py-3 w-1/3">
                    <Button
                      type="button"
                      label={item.status}
                      disabled={false}
                      cssClass={`${getColor(item.status)}`}
                    />
                  </td>
                </tr>
              ))}
          </>
        )}
      </>
    );
  };

  const CollapsibleTableRowTwo = (item: any) => {
    const [collapsedTwo, setCollapsedTwo] = useState<boolean>(true);
    const toggleCollapse = () => {
      setCollapsedTwo(!collapsedTwo);
    };
    return (
      <>
        <tr
          className={`px-3 text-[#0B0D0F] text-[14px]
                          bg-white border-b-[2px] border-[#C3CCD5]
                          }`}
        >
          <td className="px-3 py-3 w-1/3">
            {item?.item?.stepName}
            <button
              className="focus:outline-none ml-1"
              onClick={toggleCollapse}
            >
              {collapsedTwo ? (
                <img src={downArrow} alt="downArrow" className="h-3 w-3" />
              ) : (
                <img src={upArrow} alt="downArrow" className="h-3 w-3" />
              )}
            </button>

          </td>
          <td className="px-3 py-3 w-1/3" />
          <td className="px-3 py-3 w-1/3" />
        </tr>
        {!collapsedTwo && (
          <tr
            className={`px-3 text-[#0B0D0F] text-[14px]
                                  bg-white border-b-[2px] border-[#C3CCD5]
                                  }`}
          >
            <td className="px-3 py-3 w-1/3">
              {item?.item?.stepName}
            </td>
            <td className="px-3 py-3 w-1/3">
              <div
                className="flex items-center gap-2 text-[#1492B8] cursor-pointer font-semibold"
                onClick={() => {
                  window.open(
                    item?.item?.data?.label,
                    "_blank"
                  );
                }}
              >
                {item?.item?.data?.value}
                {item?.item?.data?.value &&
                  <img
                    src={eyeGray}
                    alt="eye-icon"
                    className="cursor-pointer"
                  />
                }
              </div>

            </td>
            <td className="px-3 py-3 w-1/3">
              <Button
                type="button"
                label={item?.item?.status}
                disabled={false}
                cssClass={`${getColor(item?.item?.status)}`}
              />
            </td>
          </tr>
        )}
      </>
    )
  }
  return (
    <Layout>
      <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full p-4 h-full">
        {loading ? (
          <CustomLoader />
        ) : (
          <>
            <h1 className="text-[22px] font-semibold my-3">Dashboard</h1>
            <div className="overflow-x-auto min-w-full text-[14px] mb-14">
              <table className="min-w-full rounded-lg overflow-hidden bg-[#C3CCD5] border-[1px]">
                <thead>
                  <tr className="text-[#0A4A5E] font-semibold border-b-[2px] border-[#C3CCD5]">
                    <td className="py-3 px-3 w-1/3 ">Steps</td>
                    <td className="py-3 px-3 w-1/3">Comments</td>
                    <td className="py-3 px-3 w-1/3">Status</td>
                  </tr>
                </thead>
                <tbody className="px-3 bg-white">
                  {studentData?.map((item: studentDataType) => (
                    <>
                      {item.stepName === "Main form" ? (
                        <CollapsibleTableRow />
                      ) :
                        item?.stepName === "Proof of submission" ?
                          <CollapsibleTableRowTwo item={item} />
                          :
                          (
                            <tr
                              key={item.stepName}
                              className={`px-3 text-[#0B0D0F] text-[14px]
                            bg-white border-b-[2px] border-[#C3CCD5]
                            }`}
                            >
                              <td className="px-3 py-3 w-1/3">{item.stepName}</td>
                              <td className="px-3 py-3 w-1/3">
                                {item.stepName === "Approval" ? (
                                  <>
                                    <p className="mb-2">
                                      {!item.comments
                                        ? "-"
                                        : `Your approval is still pending for more than 48 hours. You can send a reminder to ${user?.university
                                          ? user?.university
                                          : "UNI1"
                                        }`}
                                    </p>
                                    {item.comments ? (
                                      <Button
                                        type="submit"
                                        label="Send Reminder"
                                        disabled={false}
                                        cssClass="bg-[#0A4A5E] text-[#FFFFFF] w-[130px] py-2 px-4  rounded-lg text-center text-[14px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
                                        handleClick={() =>
                                          setShowModal((prev) => !prev)
                                        }
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : item.comments?.length ? (
                                  item.comments
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td className="px-3 py-3 w-1/3">
                                <Button
                                  type="button"
                                  label={item.status}
                                  disabled={false}
                                  cssClass={`${getColor(item.status)}`}
                                />
                                {item.stepName === "Email Verification" &&
                                  incompleteStatus.includes(item.status) && (
                                    <Button
                                      type="button"
                                      label={"Verify"}
                                      disabled={false}
                                      cssClass={
                                        "text-[#FFFFFF] uppercase  w-fit px-2 py-1 rounded-lg text-center text-[12px] cursor-pointer bg-[#0A4A5E] lg:ml-1 lg:mt-0 mt-1"
                                      }
                                      handleClick={() => {
                                        setType('email')
                                        setOpen(true)
                                      }}
                                    />
                                  )}
                                {item.stepName === "Phone Verification" &&
                                  incompleteStatus.includes(item.status) && (
                                    <Button
                                      type="button"
                                      label={"Verify"}
                                      disabled={false}
                                      cssClass={
                                        "text-[#FFFFFF] uppercase  w-fit px-2 py-1 rounded-lg text-center text-[12px] cursor-pointer bg-[#0A4A5E] lg:ml-1 lg:mt-0 mt-1"
                                      }
                                      handleClick={() => {
                                        setType('phone');
                                        setOpen(true);
                                      }}
                                    />
                                  )}
                              </td>
                            </tr>
                          )}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
      <footer className="bg-white p-4 text-[#71859A] fixed right-0 bottom-0 w-full">
        <div className="w-full flex">
          <span
            className={`text-[15px] ${sidebarOpen ? "lg:ml-60" : "lg:ml-16"}`}
          >
            @2024 SIAP. All rights reserved.
          </span>
        </div>
      </footer>
      {showModal && <ReminderModal setShowModal={setShowModal} student={user} />}
      {open && <VerifyAccountModal type={type} setOpen={setOpen} setType={setType} getStudentData={getStudentData} student={user}/>}
    </Layout>
  );
};

export default Dashboard;
