import React, { useEffect, useState } from "react";
import universityService from "services/UniversityService";
import { useAppDispatch } from "store/Hooks";
import { clearFormcorrection } from "store/slices/FormCorrection";
import { clearPrestudy } from "store/slices/Prestudy";
import { clearUser } from "store/slices/User";
import { cleanAndLogout } from "utils/Helper";

interface BarChartModalProps {
  toggleModal: () => void;
  isModalOpen?: boolean;
}

type statisticsUniversityDataType = {
  state: string;
  count: number;
  percentage: string;
};

const BarChartModal = ({ toggleModal, isModalOpen }: BarChartModalProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [statisticsData, setStatisticsData] = useState<
    statisticsUniversityDataType[]
  >([]);


  const getStatisticsData = async () => {
    setLoading(true);
    try {
      const response = await universityService.getStatistics();

      const {
        data: { data, status },
      } = response;
      if (status === 200) {
        setStatisticsData(data);
      }
      if (status === 401) {
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      getStatisticsData();
    }
  }, [isModalOpen]);

  return (
    <>
      <div className="fixed inset-0 z-[99999999] flex lg:items-end 2xl:items-end xl:items-end justify-end px-4 py-10">
        <div
          className="fixed inset-0 bg-[#000] bg-opacity-60"
          onClick={toggleModal}
        ></div>
        <div className="relative z-10 w-full max-w-xl rounded-lg mt-auto overflow-y-auto">
          <div className="overflow-y-auto max-h-[80vh]">
            {/* Add max height for scrolling on smaller screens */}
            <div className="w-full flex flex-col">
              <div className="bg-white border-b-[0px] rounded-t-[12px] p-[12px] md:p-[24px] overflow-hidden">
                <div className="flex items-center justify-between">
                  <h1 className="text-[#0B0D0F] text-[16px] md:text-[24px] font-semibold">
                    Statistic
                  </h1>
                </div>
              </div>
              <div
                className={`bg-white p-[5px] md:p-[24px] ${loading
                    ? "flex justify-center"
                    : "grid grid-cols-2 md:grid-cols-4"
                  } border-[2px] border-t-[#C3CCD5] border-b-0`}
              >
                {loading ? (
                  <div className="flex justify-center">
                    <div className="spinner h-[40px] w-[40px] md:h-[50px] md:w-[50px] border-solid border-[4px] border-[#006497] rounded-full border-b-transparent border-r-transparent will-change-transform animate-spin" />
                  </div>
                ) : statisticsData?.length > 0 ? (
                  statisticsData?.map((data, index) => (
                    <div
                      key={index}
                      className="bg-white border-[#C3CCD5] rounded-[12px] p-3 flex flex-col flex-1 justify-between gap-2 md:gap-[15px]"
                    >
                      <p className="text-[13px] md:text-[14px] text-[#71859A] font-semibold">
                        {data?.state}
                      </p>
                      <p className="text-[18px] text-[#48C928] font-semibold">
                        {data?.count} ({data?.percentage}%)
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="text-[13px] md:text-[14px] text-[#71859A] font-semibold">
                    No data found!
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BarChartModal;
