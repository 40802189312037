import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDn9X-SJeF1Gqx2WRI3ywR2rcfG0y8fgF4",
  authDomain: "siap-e97f7.firebaseapp.com",
  projectId: "siap-e97f7",
  storageBucket: "siap-e97f7.appspot.com",
  messagingSenderId: "530770741154",
  appId: "1:530770741154:web:f949716341de4e86ed14fb",
};

const app = initializeApp(firebaseConfig);

let checkIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream
let messaging:any;
if (!checkIOSDevice) {
    messaging = getMessaging(app);
}

export default messaging;
