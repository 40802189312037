import { Box, LinearProgress } from '@mui/material';
import { Inputfile } from 'components/Form';
import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useAppSelector } from 'store/Hooks';
import { prestudySelector } from 'store/slices/Prestudy';
import { userSelector } from 'store/slices/User';
import { crossBlack, documentBlack } from 'utils/Images';

interface subfileOneprops {
    showField: (args: string) => boolean;
    setProgress: Dispatch<SetStateAction<number>>;
    setUploadingOne: Dispatch<SetStateAction<boolean>>;
    setfileError: Dispatch<SetStateAction<string | null>>;
    intervalIdRefone: any;
    uploadFiles: (selectedFile: FileList | null,
        fieldName: string,
        form: any) => void;
    fileError: string | null;
    showComments: (args: string) => boolean;
    files: File[] | null;
    uploadingOne: boolean;
    values: any;
    removeFile : (args1:string,args2:string) => void; 
    progress:number;
    uploadDocument:(formData : FormData) => void
}

const SubsistanceFileOne: React.FC<subfileOneprops> = ({ showField, setProgress, setUploadingOne, setfileError, intervalIdRefone, uploadFiles, fileError, showComments, files, uploadingOne, values,removeFile,progress,uploadDocument }) => {
    const { currentStep } = useAppSelector(prestudySelector);
    const { user } = useAppSelector(userSelector);
    return (
        <div className={showField('subsistenceFile') ? "block" : 'hidden'}>
            <div className="px-4 py-2">
                <p className="text-[#FF4444] text-[14px] font-semibold lg:w-3/4 xl:w-3/4 2xl:w-3/4 w-full">
                    You can also upload your blocked account from your HOME
                    COUNTRY. Therefore if you have a Blocked Account from your
                    HOME COUNTRY select Yes ATTENTION: BLOCKED ACCOUNT
                    CONFIRMATIONS FROM KOTAK ARE NOT ACCEPTED ANYMORE BY THE
                    FOREIGNERS OFFICE! You need to block the required amount in
                    another bank.
                </p>
                <div className="mb-2 mt-4 flex justify-between items-center">
                    <label
                        htmlFor={"subsistenceFile"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                    >
                        Upload proof of your Blocked Amount (if you do not have
                        this, you can contact your bank and ask for it)
                        <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                </div>
                <Field name="subsistenceFile">
                    {({ field, form }: FieldProps) => (
                        <>
                            <Inputfile
                                {...field}
                                mainClass="lg:w-1/3 xl:w-1/3 w-full"
                                btnClass={null}
                                handleChange={(
                                    event: ChangeEvent<HTMLInputElement>
                                ) => {
                                    setProgress(0);
                                    setUploadingOne(true);
                                    let selectedFile: FileList | null =
                                        event.target.files;
                                    selectedFile &&
                                        form.setFieldValue(
                                            "subsistenceFile",
                                            Array.from(selectedFile)
                                        );
                                    setfileError(null);
                                    intervalIdRefone.current = setInterval(() => {
                                        setProgress((prevProgress) => prevProgress + 10);
                                    }, 500);
                                    uploadFiles(
                                        event.target.files,
                                        "subsistenceFile",
                                        form
                                    );
                                }}
                                multiple={false}
                            />
                            {typeof values.subsistenceFile === "string" && (
                                <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                    <img src={documentBlack} alt="documentBlack" />
                                    <label className="flex-1">
                                        {values.subsistenceFile}
                                    </label>
                                    <img
                                        src={crossBlack}
                                        alt="documentBlack"
                                        onClick={async () => {
                                            form.setFieldValue("subsistenceFile", []);
                                            setfileError("Files are required");
                                            const formData: any = new FormData();
                                            user && formData.append("userId", user?._id);
                                            currentStep && formData.append("step", (currentStep - 1).toString());
                                            formData.append(
                                                "label",
                                                "Upload proof of your Blocked Amount (if you do not have this, you can contact your bank and ask for it)"
                                            );
                                            await uploadDocument(formData)
                                        }}
                                        className="cursor-pointer"
                                    />
                                </div>
                            )}
                            {uploadingOne && (
                                <Box sx={{ width: "100%" }}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={progress}
                                    />
                                </Box>
                            )}
                        </>
                    )}
                </Field>
                {files &&
                    files?.map((item) => (
                        <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                            <img src={documentBlack} alt="documentBlack" />
                            <label className="flex-1">{item.name}</label>
                            <img
                                src={crossBlack}
                                alt="documentBlack"
                                onClick={() =>
                                    removeFile(item.name, "subsistenceFile")
                                }
                                className="cursor-pointer"
                            />
                        </div>
                    ))}
                {fileError ? (
                    <div className="text-red-500 text-sm md:text-base fileError">
                        {fileError}
                    </div>
                ) : (
                    <ErrorMessage
                        name="subsistenceFile"
                        component="div"
                        className="text-red-600 fileError"
                    />
                )}
                {showComments('subsistenceFile') &&
                    <CommentSection field={'subsistenceFile'} step={currentStep - 1} userType="student" />
                }
            </div>
        </div>
    )
}

export default SubsistanceFileOne