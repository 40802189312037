import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Input } from 'components/Form';
import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React from 'react'
import { useAppSelector } from 'store/Hooks';
import { prestudySelector } from 'store/slices/Prestudy';

interface CriminalityCompOneProps {
    values: any;
    modal?: boolean;
    setModal?: any;
    field?: string;
    setField?: any;
    showField?: (args: string) => boolean;
    showComments?: (args: string) => boolean;
    handleChange: any;
    userType?: boolean;
    getCount?:(args:string) => number;
}
const CriminalityCompOne: React.FC<CriminalityCompOneProps> = ({ values, showField, showComments, handleChange, userType, modal, setModal, field, setField,getCount }) => {
    const { currentStep } = useAppSelector(prestudySelector);
    const DateInput = DatePicker as any;

    return (
        <div className={`CriminalityCompOne px-4 mb-3`}>
            <label
                htmlFor={"criminality"}
                className="font-semibold text-[16px]  text-[#0B0D0F]"
            >
                In Germany
            </label>
            <div className='flex flex-col gap-2'>
                <div className={!showField ? "block" : showField('conviction1') ? "block" : 'hidden'}>
                    <div className="flex  items-center my-3">
                        <label
                            htmlFor={"conviction1"}
                            className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('conviction1')
                                    }}
                                    checked={modal && field === 'conviction1'}
                                />
                            }

                            Enter the official place of conviction

                        </label>
                        {getCount && getCount('conviction1') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('conviction1')}
                            </div>
                        }

                    </div>
                    <Input
                        type="text"
                        fieldName="conviction1"
                        setValue={values.conviction1}
                        className={'md:w-1/3 password-input border-[2px] border-[#E1E5EA] rounded-md w-full placeholder-[#71859A] placeholder:text-[14px]'}
                        handleChange={handleChange}
                    />
                    {showComments && showComments('conviction1') &&
                        <CommentSection field={'conviction1'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className={!showField ? "block" : showField('convictionDate1') ? "block" : 'hidden'}>
                    <div className="flex  items-center my-3">
                        <label
                            htmlFor={"convictionDate1"}
                            className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('convictionDate1')
                                    }}
                                    checked={modal && field === 'convictionDate1'}
                                />
                            }
                            Enter the official date of conviction(YYYY-MM-DD)
                        </label>
                        {getCount && getCount('convictionDate1') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('convictionDate1')}
                            </div>
                        }
                    </div>
                    <Field name="convictionDate1">
                        {({ field, form }: FieldProps) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateInput
                                    {...field}
                                    label="YYYY-MM-DD"
                                    renderInput={(props: any) => (
                                        <TextField {...props} />
                                    )}
                                    format="YYYY-MM-DD"
                                    value={values.convictionDate1}
                                    onChange={(newDate: any) => {
                                        form.setFieldValue("convictionDate1", newDate);
                                    }}
                                    className={
                                        "password-input"
                                    }
                                />
                            </LocalizationProvider>
                        )}
                    </Field>
                    <ErrorMessage
                        name="convictionDate1"
                        component="div"
                        className="text-red-600"
                    />
                    {showComments && showComments('convictionDate1') &&
                        <CommentSection field={'convictionDate1'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className={!showField ? "block" : showField('resume1') ? "block" : 'hidden'}>
                    <div className="flex items-center my-3">
                        <label
                            htmlFor={"resume1"}
                            className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('resume1')
                                    }}
                                    checked={modal && field === 'resume1'}
                                />
                            }
                            Resume the concrete ground(s) of conviction
                        </label>
                        {getCount && getCount('resume1') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('resume1')}
                            </div>
                        }
                    </div>
                    <Input
                        type="text"
                        fieldName="resume1"
                        setValue={values.resume1}
                        className={'md:w-1/3 password-input border-[2px] border-[#E1E5EA] rounded-md w-full placeholder-[#71859A] placeholder:text-[14px]'}
                        handleChange={handleChange}
                    />
                    {showComments && showComments('resume1') &&
                        <CommentSection field={'resume1'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className={!showField ? "block" : showField('penalty1') ? "block" : 'hidden'}>
                    <div className="flex  items-center my-3">
                        <label
                            htmlFor={"penalty1"}
                            className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('penalty1')
                                    }}
                                    checked={modal && field === 'penalty1'}
                                />
                            }
                            Describe the nature and extent of the penalties as written in the official conviction letter

                        </label>
                        {getCount && getCount('penalty1') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('penalty1')}
                            </div>
                        }
                    </div>
                    <Input
                        type="text"
                        fieldName="penalty1"
                        setValue={values.penalty1}
                        className={'md:w-1/3 password-input border-[2px] border-[#E1E5EA] rounded-md w-full placeholder-[#71859A] placeholder:text-[14px]'}
                        handleChange={handleChange}
                    />
                    {showComments && showComments('penalty1') &&
                        <CommentSection field={'penalty1'} step={currentStep - 1} userType="student" />
                    }
                </div>
            </div>
        </div>
    )
}

export default CriminalityCompOne