import {
  Outlet,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import DashboardPage from "pages/Dashboard";
import LoginPage from "pages/Auth/Login";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import { clearUser, setUser, userSelector } from "store/slices/User";
import UniversityDashboard from "pages/UniversityDashboard";
import LawyerDashboardPage from "pages/LawyerDashboard";
import PrestudyPage from "pages/Prestudy";
import {
  authRoutes,
  lawyerRoutes,
  studentRoutes,
  universityRoutes,
} from "utils/data";
import Unauthorized from "components/Unauthorized";
import { cleanAndLogout } from "utils/Helper";
import prestudyService from "services/prestudy";
import { clearPrestudy, setCorrectionStep, setStep } from "store/slices/Prestudy";
import { clearFormcorrection } from "store/slices/FormCorrection";
import { useEffect, useState } from "react";
import CustomLoader from "components/Loader/CustomLoader";
import { useNavigate } from "react-router-dom";

const AuthenticationGuard = () => {
  const { user } = useAppSelector(userSelector);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const verifyToken = async () => {
    try {
      setLoading(true);
      let token = await localStorage.getItem("token");
      if (token) {
        let response = await prestudyService.verifyToken(token);
        const {
          data: {
            data: { user: userDetails },
            success,
            status,
          },
        } = response;
        if (success) {
          dispatch(
            setUser({ ...userDetails, isAuthenticated: true, isVerified: true })
          );
          dispatch(setStep({ step: 10, status: 'Inprogress' }));
        }
        if (status === 401) {
          setLoading(false);
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
        }
        setLoading(false);
      }
    } catch (error) {
      console.log("🚀 ~ verifyToken ~ error:", error);
      setLoading(false);
    }
  };

  const verifyCorrectionToken = async (correctionToken: string) => {
    try {
      setLoading(true);
      let token = await localStorage.getItem("Token");
      if (token || correctionToken) {
        localStorage.setItem("token", correctionToken);
        let response = await prestudyService.verifyCorrectionToken(correctionToken);
        console.log("🚀 ~ verifyCorrectionToken ~ response:", response)
        const {
          data: {
            data,
            success,
            status,
          },
        } = response;
        if (success) {
          dispatch(
            setUser({ ...data, isAuthenticated: true, isVerified: true })
          );
          dispatch(setCorrectionStep({ step: 0, status: 'Completed' }));
        }
        if (status === 401) {
          setLoading(false);
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
        }
        setLoading(false);
      }
    } catch (error) {
      console.log("🚀 ~ verifyToken ~ error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location?.pathname === "/") {
      navigate("/login")
    }
    if (location.pathname === "/pre-study") {
      let token: any = searchParams.get("token");
      let correctionToken: any = searchParams.get("Token");
      if (token) {
        localStorage.setItem("token", token);
        verifyToken();
      }
      if (correctionToken) {
        localStorage.setItem("Token", token);
        verifyCorrectionToken(correctionToken);
      }
    }
  }, []);

  return loading ? (
    <CustomLoader />
  ) : user && user.isAuthenticated ? (
    !authRoutes.includes(location.pathname) ? (
      (studentRoutes.includes(location.pathname) && user.role === "student") ||
        (universityRoutes.includes(location.pathname) &&
          user.role === "university") ||
        (lawyerRoutes.includes(location.pathname) && user.role === "lawyer") ? (
        loading ? (
          <CustomLoader />
        ) : (
          <Outlet />
        )
      ) : (
        <>
          <Navigate to="/unauthorized" />
          <Unauthorized />
        </>
      )
    ) : (
      <>
        {user?.role === "student" &&
          (user?.isVerified ? (
            <>
              <Navigate to="/pre-study" />
              <PrestudyPage />
            </>
          ) : (
            <>
              <Navigate to="/dashboard" />
              <DashboardPage />
            </>
          ))}
        {user?.role === "university" && (
          <>
            <Navigate to="/university-dashboard" />
            <UniversityDashboard />
          </>
        )}
        {user?.role === "lawyer" && (
          <>
            <Navigate to="/lawyer-dashboard" />
            <LawyerDashboardPage />
          </>
        )}
      </>
    )
  ) : authRoutes.includes(location.pathname) ? (
    loading ? (
      <CustomLoader />
    ) : (
      <Outlet />
    )
  ) : loading ? (
    <CustomLoader />
  ) : user && user.isAuthenticated && user.isVerified ? (
    <>
      <Navigate to="/pre-study" />
      <PrestudyPage />
    </>
  ) : loading ? (
    <CustomLoader />
  ) : (
    <>
      <Navigate to="/login" />
      <LoginPage />
    </>
  );
};

export default AuthenticationGuard;

