import React from "react";
import { RadioGroupProps } from "utils/types";

const RadioGroup: React.FC<RadioGroupProps> = ({
  fieldName,
  options,
  onChange,
  className,
  message,
  disabled,
  checkedField,
  ref,
  onFocus,
  setSelectedOption
}) => {
  return (
    <>
      {options.map((option) => (
        <div key={option.id}>
          <input
            type="radio"
            id={option.id}
            name={option.name ? option.name : fieldName}
            value={option.value}
            onChange={onChange}
            disabled={disabled}
            checked ={checkedField === option.value ? true : false}
            ref={ref}
            onFocus={onFocus}
          />
          <label htmlFor={option.id} className={`text-[14px] text-[#0B0D0F] password-input ${className && className}`}>
            {option.label}
          </label>
          <br />
          {message?.id && message?.id === option.id && message?.element}
        </div>
      ))}
    </>
  );
};

export default RadioGroup;
