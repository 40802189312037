import { Box, LinearProgress, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Inputfile } from 'components/Form';
import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Hooks';
import { formCorrectionSelector } from 'store/slices/FormCorrection';
import { prestudySelector } from 'store/slices/Prestudy';
import { userSelector } from 'store/slices/User';
import { validateFiles } from 'utils/Helper';
import { crossBlack, documentBlack } from 'utils/Images';
import { setFeatureLoaded } from "store/slices/Prestudy";

const OtherImmigration: React.FC<any> = ({ values, uploadDocument, userType, setModal, setField, field, modal, showComments, studentData, highlightedIndex, setHighlightedIndex, getCount }) => {
    const DateInput = DatePicker as any;
    const { currentStep } =
        useAppSelector(prestudySelector);
    const { currentStep: lawyercurentStep } =
        useAppSelector(formCorrectionSelector);
    const { user } = useAppSelector(userSelector);
    const [progress, setProgress] = React.useState(0);
    const [uploading, setUploading] = useState(false);
    const intervalIdRef = useRef<any>();
    const [fileError, setfileError] = useState<string | null>(null);
    const [files, setFiles] = useState<File[] | null>(null);

    const fieldRefs = useRef<any>({});
    useEffect(() => {
        if (fieldRefs.current[highlightedIndex]) {
            fieldRefs.current[highlightedIndex].focus();

            const container = document.getElementById('container');

            // Scroll the container to make 'fieldRefs.current[highlightedIndex]' visible
            fieldRefs.current[highlightedIndex].scrollIntoView({
                behavior: 'smooth',  // Optional: Smooth scrolling
                block: 'nearest',    // Optional: Adjust where element aligns
                inline: 'start'      // Optional: Adjust where element aligns
            });
        }
    }, [fieldRefs, highlightedIndex]);

    useEffect(() => {
        if (progress > 100) {
            setUploading(false);
            setProgress(0);
            clearInterval(intervalIdRef?.current);
        }
    }, [progress]);
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (progress > 0) {
            dispatch(setFeatureLoaded(true));
        } else {
            dispatch(setFeatureLoaded(false));
        }
    }, [progress]);

    useEffect(() => {
        return () => {
            clearInterval(intervalIdRef.current);
        };
    }, []);

    const uploadFiles = async (selectedFile: FileList | null, form: any) => {
        const allowedTypes = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf",
        ];
        const isValidate = await validateFiles(selectedFile, allowedTypes);
        if (isValidate === "true") {
            if (selectedFile) {
                const newFiles = Array.from(selectedFile).filter(
                    (newFile) =>
                        !files ||
                        !files.some((existingFile) => existingFile.name === newFile.name)
                );
                const updatedFiles = files ? [...files, ...newFiles] : newFiles;
                form.setFieldValue("document", updatedFiles);
                setFiles(updatedFiles);
                const formData: any = new FormData();
                updatedFiles?.forEach((file: any) => {
                    formData.append("document", file);
                });
                if (userType === 'lawyer') {
                    lawyercurentStep && formData.append("step", (lawyercurentStep - 1).toString());
                    studentData && formData.append("userId", studentData?._id);
                } else {
                    user && formData.append("userId", user?._id);
                    currentStep && formData.append("step", (currentStep - 1).toString());
                }
                formData.append("isRequired", true);
                formData.append(
                    "label",
                    "Upload any documents supporting your case"
                );
                await uploadDocument(formData);
            } else {
                setFiles(null);
            }
        }
    };

    return (
        <div className='other-immigration mt-3'>
            <div className={`block mt-3 p-2 ${highlightedIndex === 'option6Field1' ? 'border-2 !border-green-300 rounded-md' : ' border-transparent'}`}>
                <div className="mb-2 mt-4 flex justify-start gap-2 items-center">
                    {userType === 'lawyer' &&
                        <input
                            type="checkbox"
                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px]"
                            onClick={(e: any) => {
                                setModal(e.target.checked);
                                setField('deadLinedate')
                            }}
                            checked={modal && field === 'deadLinedate'}
                        />}
                    <div className='flex items-center gap-2'>
                        <label
                            htmlFor={"deadLinedate"}
                            className="text-[#0B0D0F] font-semibold text-[16px]"
                            ref={(el: any) => { fieldRefs.current['option6Field1'] = el }}
                        >
                            Specify if a deadline is applicable to your situation
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount('deadLinedate') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('deadLinedate')}
                            </div>
                        }
                    </div>
                </div>
                <Field name="deadLinedate"
                    ref={(el: any) => { fieldRefs.current['option6Field1'] = el }}
                    onFocus={() => setHighlightedIndex('option6Field1')} >
                    {({ field, form }: FieldProps) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div  ref = {(el:any) => {fieldRefs.current['option6Field1'] = el}}>
                            <DateInput
                                {...field}
                                label="YYYY-MM-DD"
                                renderInput={(props: any) => <TextField {...props} />}
                                format="YYYY-MM-DD"
                                value={values.deadLinedate}
                                onChange={(newDate: any) => {
                                    form.setFieldValue("deadLinedate", newDate);
                                }}
                                className={
                                    "lg:w-1/2 w-full py-0 border-[2px] border-[#E1E5EA] rounded-[12px]"
                                }
                                disablePast
                            />
                            </div>
                        </LocalizationProvider>
                    )}
                </Field>
                <ErrorMessage
                    name="deadLinedate"
                    component="div"
                    className="text-red-600"
                />
                {userType === 'student' ?
                    showComments('deadLinedate') &&
                    <CommentSection field={'deadLinedate'} step={currentStep - 1} userType="student" />
                    : <></>
                }
            </div>
            <div className={`block mt-3 p-2 ${highlightedIndex === 'option6Field2' ? 'border-2 !border-green-300 rounded-md' : ' border-transparent'}`}>
                <div className="mb-2 mt-4 flex justify-start gap-2 items-center">
                    {userType === 'lawyer' &&
                        <input
                            type="checkbox"
                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] "
                            onClick={(e: any) => {
                                setModal(e.target.checked);
                                setField('document')
                            }}
                            checked={modal && field === 'document'}
                        />}
                    <div className='flex items-center gap-2'>
                        <label
                            htmlFor={"document"}
                            className="text-[#0B0D0F] font-semibold text-[16px]"
                            ref={(el: any) => { fieldRefs.current['option6Field2'] = el }}
                        >
                            Upload any documents supporting your case
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount('document') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('document')}
                            </div>
                        }
                    </div>
                </div>
                <Field name="document"
                    ref={(el: any) => { fieldRefs.current['option6Field2'] = el }}
                    onFocus={() => setHighlightedIndex('option6Field2')} >
                    {({ field, form }: FieldProps) => (
                        <>
                        <div ref = {(el:any) => {fieldRefs.current['option6Field2'] = el}}>
                            <Inputfile
                                {...field}
                                mainClass="lg:w-1/2 xl:w-1/2 w-full"
                                btnClass={null}
                                handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setProgress(0);
                                    setUploading(true);
                                    setfileError(null);
                                    intervalIdRef.current = setInterval(() => {
                                        setProgress((prevProgress) => prevProgress + 10);
                                    }, 500);
                                    uploadFiles(event.target.files, form);
                                }}
                                multiple={true}
                            />
                            </div>
                            {values.document &&
                                values.document.length > 0 &&
                                values.document.map(
                                    (item: { name: string; location: string }) => (
                                        <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                            <img src={documentBlack} alt="documentBlack" />
                                            <label className="flex-1">{item.name}</label>
                                            <img
                                                src={crossBlack}
                                                alt="documentBlack"
                                                onClick={async () => {
                                                    const updatedFiles = values.document?.filter(
                                                        (val: { name: string; location: string }) =>
                                                            val.name !== item.name
                                                    );
                                                    form.setFieldValue("document", updatedFiles);
                                                    const formData: any = new FormData();
                                                    updatedFiles?.forEach((file: any) => {
                                                        formData.append("document", file);
                                                    });
                                                    if (userType === 'lawyer') {
                                                        studentData && formData.append("userId", studentData?._id);
                                                        lawyercurentStep && formData.append("step", (lawyercurentStep - 1).toString());
                                                    } else {
                                                        user && formData.append("userId", user?._id);
                                                        currentStep && formData.append("step", (currentStep - 1).toString());
                                                    }
                                                    formData.append("isRequired", true);
                                                    formData.append(
                                                        "label",
                                                        "Upload any documents supporting your case"
                                                    );
                                                    await uploadDocument(formData);
                                                    setFiles(updatedFiles);
                                                    if (updatedFiles.length === 0) {
                                                        form.setFieldValue("document", null);
                                                        setFiles(null);
                                                    }
                                                }}
                                                className="cursor-pointer"
                                            />
                                        </div>
                                    )
                                )}
                            {uploading && (
                                <Box sx={{ width: "100%" }}>
                                    <LinearProgress variant="determinate" value={progress} />
                                </Box>
                            )}
                        </>
                    )}
                </Field>
                {fileError ? (
                    <div className="text-red-500 text-sm md:text-base fileError">
                        {fileError}
                    </div>
                ) : (
                    <ErrorMessage
                        name="rpExpireFile"
                        component="div"
                        className="text-red-600 fileError"
                    />
                )}
                {userType === 'student' ?
                    showComments('rpExpireFile') &&
                    <CommentSection field={'rpExpireFile'} step={currentStep - 1} userType="student" />
                    : <></>
                }
            </div>
            <div className={`block mt-3 p-2 ${highlightedIndex === 'option6Field3' ? 'border-2 !border-green-300 rounded-md' : ' border-transparent'}`}>
                <div className="mb-2 mt-4 flex justify-start gap-2 items-center">
                    {userType === 'lawyer' &&
                        <input
                            type="checkbox"
                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] focus:ring-0 focus:ring-offset-0"
                            onClick={(e: any) => {
                                setModal(e.target.checked);
                                setField('situation')
                            }}
                            checked={modal && field === 'situation'}
                            ref = {(el:any) => {fieldRefs.current['option6Field3'] = el}}
                        />}
                    <div className='flex items-center gap-2'>
                        <label
                            htmlFor={"document"}
                            className="text-[#0B0D0F] font-semibold text-[16px]"
                            ref={(el: any) => { fieldRefs.current['option6Field3'] = el }}
                        >
                            Explain your situation
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount('situation') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('situation')}
                            </div>
                        }
                    </div>
                </div>
                <Field name="situation"
                    ref={(el: any) => { fieldRefs.current['option6Field3'] = el }}
                    onFocus={() => setHighlightedIndex('option6Field3')}
                >
                    {({ field, form }: FieldProps) => (
                        <>
                            <textarea
                                {...field}
                                name="messages"
                                placeholder="Write a Something..."
                                rows={4}
                                className="password-input border-[2px] border-[#E1E5EA] rounded-md w-full placeholder-[#71859A] placeholder:text-[14px] lg:w-1/2 mb-2"
                                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                    form.setFieldValue('situation', e.target?.value)
                                }}
                            />
                        </>
                    )}
                </Field>
                <ErrorMessage
                    name="situation"
                    component="div"
                    className="text-red-600"
                />
                {userType === 'student' ?
                    showComments('situation') &&
                    <CommentSection field={'situation'} step={currentStep - 1} userType="student" />
                    : <></>
                }
            </div>
        </div>
    )
}

export default OtherImmigration