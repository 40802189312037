import Layout from "components/UI/Layout";
import { useEffect, useState } from "react";
import { chart } from "utils/Images";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import { clearUser, setRegistrationData, setStudentId, userSelector } from "store/slices/User";
import { useNavigate } from "react-router-dom";
import BarChartModal from "components/Modals/BarChartModal";
import CustomLoader from "components/Loader/CustomLoader";
import universityDashboardServices from "services/UniversityDashboardServices";
import { clearPrestudy } from "store/slices/Prestudy";
import { clearFormcorrection } from "store/slices/FormCorrection";
import { cleanAndLogout } from "utils/Helper";
import universityService from "services/UniversityService";

const UniversityDashboard: React.FC = () => {
  type dataType = {
    studentId: string;
    study: string;
    city: string;
    financialDocStatus: string;
    internationalDocStatus: string;
  };
  type studyType = {
    service: number;
    result: string;
  };
  type universityDataType = {
    openApplications: dataType[];
    openDocuments: dataType[];
    RPreceived: dataType[];
    Study: studyType[];
    PostStudy: studyType[];
  };
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<universityDataType | null>(null);
  const { sidebarOpen,user } = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const toggleBodyOverflow = () => {
      document.body.style.overflowY = isModalOpen ? "hidden" : "auto";
    };
    toggleBodyOverflow();
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [isModalOpen]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  const getUniversityData = async () => {
    try {
      setLoading(true);
      const response = await universityDashboardServices.getDashboardData();
      const {
        data: { data, status },
      } = response;
      if (status === 200) {
        setLoading(false);
        setData(data);
      }
      if (status === 401) {
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getOpenRegistrations = async () => {
    try {
      let query = null ;
      const response = await universityService.getOpenRegistrations(
        user?._id,
        query
      );
      const {
        data: { data, status },
      } = response;
      if (status === 200) {
        dispatch(setRegistrationData(data?.results));
      }
      if (status === 401) {
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    getOpenRegistrations();
    getUniversityData();
  }, []);
  
  useEffect(() => {
    const toggleBodyOverflow = () => {
      document.body.style.overflowY = "hidden";
    };
    toggleBodyOverflow();
    return () => {
      document.body.style.overflowY = "auto";
    };
  });

  return (
    <Layout>
      <div className="pb-16 max-h-[750px] overflow-y-scroll no-scrollbar">
        {loading ? (
          <CustomLoader />
        ) : (
          <>
            <h1 className="p-2 md:p-4 text-[24px] font-semibold sticky top-0 z-[50] bg-[#E1E5EA] py-4">
              Dashboard Overview
            </h1>
            <div className="flex flex-col xl:flex-row">
              <div className="sm:w-full xl:w-1/3 p-2 lg:p-4">
                <h1 className="text-xl md:text-[24px] font-semibold my-3 martel-sans">
                  Open
                </h1>
                <div className="overflow-x-scroll overflow-y-scroll no-scrollbar max-h-[192px] min-w-full text-[15px] md:overflow-x-hidden rounded-[8px] border-[1px]">
                  <table className="min-w-full h-[192px]">
                    <thead className="bg-[#C3CCD5] sticky top-0">
                      <tr className="text-[#0A4A5E] font-semibold border-b-[2px] border-[#C3CCD5]">
                        <td className="py-3 px-3 w-1/3">Student ID</td>
                        <td className="py-3 px-3 w-1/3">Process</td>
                        <td className="py-3 px-3 w-1/3">City</td>
                      </tr>
                    </thead>
                    {data?.openApplications &&
                    data?.openApplications?.length > 0 ? (
                      <tbody className="px-3 bg-white max-h-full">
                        {data?.openApplications?.map((item: dataType) => (
                          <tr
                            className={`px-3 text-[#0B0D0F]
                                        bg-white border-b-[2px] border-[#C3CCD5]
                                        } cursor-pointer`}
                            onClick={() => {
                              dispatch(setStudentId(item.studentId));
                              navigate("/review-form")
                            }}
                          >
                            <td className="px-3 py-3 w-1/3">
                              {item.studentId}
                            </td>
                            <td className="px-3 py-3 w-1/3">{item.study}</td>
                            <td className="px-3 py-3 w-1/3">{item.city}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody className="px-3 text-[#0A4A5E] font-semibold text-[14px] text-center bg-white max-h-full">
                        <tr>
                          <td colSpan={10} className="p-3">
                            No Record Found!
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <div className="sm:w-full xl:w-1/3 p-2 lg:p-4">
                <h1 className="text-xl md:text-[24px] font-semibold my-3 martel-sans">
                  Documents
                </h1>
                <div className="overflow-x-scroll overflow-y-scroll no-scrollbar max-h-[192px] min-w-full text-[15px] md:overflow-x-hidden rounded-[8px] border-[1px]">
                  <table className="min-w-full h-[192px]">
                    <thead className="bg-[#C3CCD5] sticky top-0">
                      <tr className="text-[#0A4A5E] font-semibold border-b-[2px] border-[#C3CCD5]">
                        <td className="py-3 px-3 w-1/3">Student ID</td>
                        <td className="py-3 px-3 w-1/3">Process</td>
                        <td className="py-3 px-3 w-1/3">City</td>
                      </tr>
                    </thead>
                    {data?.openDocuments && data?.openDocuments?.length > 0 ? (
                      <tbody className="px-3 bg-white max-h-full">
                        {data?.openDocuments?.map((item: dataType) => (
                          <tr
                            className={`px-3 text-[#0B0D0F]
                                                            bg-white border-b-[2px] border-[#C3CCD5]
                                                            } cursor-pointer`}
                            onClick={() => navigate("/approval-document")
                          }
                          >
                            <td className="px-3 py-3 w-1/3">
                              {item.studentId}
                            </td>
                            <td className="px-3 py-3 w-1/3">{item.study}</td>
                            <td className="px-3 py-3 w-1/3">{item.city}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody className="px-3 text-[#0A4A5E] font-semibold text-[14px] text-center bg-white max-h-full">
                        <tr>
                          <td colSpan={10} className="p-3">
                            No Record Found!
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <div className="sm:w-full xl:w-1/3 p-2 lg:p-4">
                <h1 className="text-xl md:text-[24px] font-semibold my-3 martel-sans">
                  RP Received
                </h1>
                <div className="overflow-x-scroll overflow-y-scroll no-scrollbar max-h-[192px] min-w-full text-[15px] md:overflow-x-hidden rounded-[8px] border-[1px]">
                  <table className="min-w-full h-[192px]">
                    <thead className="bg-[#C3CCD5] sticky top-0">
                      <tr className="text-[#0A4A5E] font-semibold border-b-[2px] border-[#C3CCD5]">
                        <td className="py-3 px-3 w-1/3">Student ID</td>
                        <td className="py-3 px-3 w-1/3">Process</td>
                        <td className="py-3 px-3 w-1/3">City</td>
                      </tr>
                    </thead>
                    {data?.RPreceived && data?.RPreceived?.length > 0 ? (
                      <tbody className="px-3 bg-white max-h-full">
                        {data?.RPreceived?.map((item: dataType) => (
                          <tr
                            className={`px-3 text-[#0B0D0F]
                                          bg-white border-b-[2px] border-[#C3CCD5]
                                          }`}
                          >
                            <td className="px-3 py-3 w-1/3">
                              {item.studentId}
                            </td>
                            <td className="px-3 py-3 w-1/3">{item.study}</td>
                            <td className="px-3 py-3 w-1/3">{item.city}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody className="px-3 text-[#0A4A5E] font-semibold text-[14px] text-center bg-white max-h-full">
                        <tr>
                          <td colSpan={10} className="p-3">
                            No Record Found!
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
            <div className="flex sm:flex-col xl:flex-row">
              <div className="sm:w-full xl:w-1/3 p-2 lg:p-4">
                <h1 className="text-xl md:text-[24px] font-semibold my-3 martel-sans">
                  Study
                </h1>
                <div className="overflow-x-scroll min-w-full text-[15px] md:overflow-x-hidden max-h-[340px] overflow-y-scroll no-scrollbar cursor-pointer">
                  <table className="min-w-full rounded-[8px] overflow-hidden bg-[#C3CCD5] border-[1px] h-[340px]">
                    {data?.Study && data?.Study?.length > 0 ? (
                      <tbody className="px-3 bg-white">
                        {data?.Study?.map((item: studyType) => (
                          <tr
                            className={`px-3 text-[#0B0D0F]
                                bg-white border-b-[2px] border-[#C3CCD5]
                                }`}
                          >
                            <td className="px-3 py-3 w-1/3">{item.service}</td>
                            <td className="px-3 py-3 w-1/3">{item.result}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody className="px-3 text-[#0A4A5E] font-semibold text-[14px] text-center bg-white max-h-full">
                        <tr>
                          <td colSpan={10} className="p-3">
                            No Record Found!
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <div className="sm:w-full xl:w-1/3 p-2 lg:p-4">
                <h1 className="text-xl md:text-[24px] font-semibold my-3 martel-sans">
                  Post-Study
                </h1>
                <div className="overflow-x-scroll min-w-full text-[15px] md:overflow-x-hidden overflow-y-scroll max-h-[340px] no-scrollbar">
                  <table className="min-w-full rounded-[8px] overflow-hidden bg-[#C3CCD5] border-[1px] h-[340px]">
                    {data?.PostStudy && data?.PostStudy?.length > 0 ? (
                      <tbody className="px-3 bg-white max-h-full">
                        {data?.PostStudy?.map((item) => (
                          <tr
                            className={`px-3 text-[#0B0D0F]
                                      bg-white border-b-[2px] border-[#C3CCD5]
                                      }`}
                          >
                            <td className="px-3 py-3 w-1/3">{item.result}</td>
                            <td className="px-3 py-3 w-1/3">{item.service}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody className="px-3 text-[#0A4A5E] font-semibold text-[14px] text-center bg-white max-h-full">
                        <tr>
                          <td colSpan={10} className="p-3">
                            No Record Found!
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </>
        )}

        {isModalOpen && <BarChartModal toggleModal={toggleModal} isModalOpen={isModalOpen} />}

        <footer className="bg-white p-4 text-[#71859A] fixed right-0 bottom-0 w-full">
          <div className="w-full flex items-center justify-between">
            <span
              className={`text-[15px] ${sidebarOpen ? "lg:ml-60" : "lg:ml-16"}`}
            >
              @2024 SIAP. All rights reserved.
            </span>
            <img
              src={chart}
              alt=""
              className="w-6 cursor-pointer"
              onClick={toggleModal}
            />
          </div>
        </footer>
      </div>
    </Layout>
  );
};

export default UniversityDashboard;
