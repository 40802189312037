import { Box, LinearProgress } from '@mui/material';
import { Inputfile } from 'components/Form';
import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useAppSelector } from 'store/Hooks';
import { prestudySelector } from 'store/slices/Prestudy';
import { userSelector } from 'store/slices/User';
import { crossBlack, documentBlack } from 'utils/Images';

interface HealthInsFileOneprops {
    showField: (args: string) => boolean;
    setProgress: Dispatch<SetStateAction<number>>;
    setUploadingTwo: Dispatch<SetStateAction<boolean>>;
    setHealthfileError: Dispatch<SetStateAction<string | null>>;
    intervalIdReftwo: any;
    uploadFiles: (selectedFile: FileList | null,
        fieldName: string,
        form: any) => void;
    healthfileError: string | null;
    showComments: (args: string) => boolean;
    uploadingTwo: boolean;
    values: any;
    removeFile: (args1: string, args2: string) => void;
    progress: number;
    uploadDocument: (formData: FormData) => void;
    setHealthFiles:Dispatch<SetStateAction<any>>;
}

const HealthInsFileOne: React.FC<HealthInsFileOneprops> = ({ showField, setProgress, setUploadingTwo, setHealthfileError, intervalIdReftwo, uploadFiles, healthfileError, showComments,uploadingTwo, values,removeFile,progress,uploadDocument,setHealthFiles }) => {
    const { currentStep } = useAppSelector(prestudySelector);
    const { user } = useAppSelector(userSelector);
    return (
        <div className={showField('healthInsuranceFile') ? "block" : 'hidden'}>
            <div className="flex flex-col gap-2 text-[14px] my-2">
                <p className=" text-[#71859A]">
                    Make sure that the contract or declaration meets the
                    requirements explained above.{" "}
                </p>
                <p className=" text-[#FF4444] font-semibold lg:w-3/4 xl:w-3/4 2xl:w-3/4 w-full">
                    Private health insurance coverage must be valid for at
                    least ONE YEAR from the moment of your residence permit
                    application (a very recent start of the ONE year
                    validity is required).{" "}
                </p>
            </div>
            <div className="mb-2 mt-4 flex justify-between items-center">
                <label
                    htmlFor={"healthInsuranceFile"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                >
                    Upload a copy of your health insurance certificate (both
                    sides of your insurance card OR contract OR confirmation
                    from the insurance company)
                    <span className="text-[#FF4444] ml-1">*</span>
                </label>
            </div>
            <Field name="healthInsuranceFile">
                {({ field, form }: FieldProps) => (
                    <>
                        <Inputfile
                            {...field}
                            mainClass="lg:w-1/3 xl:w-1/3 w-full"
                            btnClass={null}
                            handleChange={(
                                event: ChangeEvent<HTMLInputElement>
                            ) => {
                                setProgress(0);
                                setUploadingTwo(true);
                                setHealthfileError(null);
                                intervalIdReftwo.current = setInterval(() => {
                                    setProgress(
                                        (prevProgress) => prevProgress + 10
                                    );
                                }, 500);
                                uploadFiles(
                                    event.target.files,
                                    "healthInsuranceFile",
                                    form
                                );
                            }}
                            multiple={true}
                        />
                        {!healthfileError &&
                            values.healthInsuranceFile &&
                            values.healthInsuranceFile.length > 0 &&
                            values.healthInsuranceFile.map(
                                (item: { name: string; location: string }) => (
                                    <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                        <img
                                            src={documentBlack}
                                            alt="documentBlack"
                                        />
                                        <label className="flex-1">{item.name}</label>
                                        <img
                                            src={crossBlack}
                                            alt="documentBlack"
                                            onClick={async () => {
                                                const updatedFiles =
                                                    values.healthInsuranceFile?.filter(
                                                        (val: {
                                                            name: string;
                                                            location: string;
                                                        }) => val.name !== item.name
                                                    );
                                                form.setFieldValue(
                                                    "healthInsuranceFile",
                                                    updatedFiles
                                                );
                                                setHealthFiles(updatedFiles);
                                                if (updatedFiles.length === 0) {
                                                    form.setFieldValue(
                                                        "healthInsuranceFile",
                                                        []
                                                    );
                                                    setHealthFiles(null);
                                                    setHealthfileError(
                                                        "Files are required"
                                                    );
                                                    setProgress(0);
                                                    const formData: any = new FormData();
                                                    updatedFiles?.forEach((file: any) => {
                                                        formData.append("healthInsuranceFile", file);
                                                    });
                                                    user && formData.append("userId", user?._id);
                                                    currentStep && formData.append("step", (currentStep - 1).toString());
                                                    formData.append(
                                                        "label",
                                                        "Upload a copy of your health insurance certificate (both sides of your insurance card OR contract OR confirmation from the insurance company)"
                                                    );
                                                    await uploadDocument(formData);
                                                }
                                            }}
                                            className="cursor-pointer"
                                        />
                                    </div>
                                )
                            )}
                        {uploadingTwo && (
                            <Box sx={{ width: "100%" }}>
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                />
                            </Box>
                        )}
                    </>
                )}
            </Field>
            {healthfileError ? (
                <div className="text-red-500 text-sm md:text-base fileError">
                    {healthfileError}
                </div>
            ) : (
                <ErrorMessage
                    name="healthInsuranceFile"
                    component="div"
                    className="text-red-600 fileError"
                />
            )}
            {showComments('healthInsuranceFile') &&
                <CommentSection field={'healthInsuranceFile'} step={currentStep - 1} userType="student" />
            }
        </div>
    )
}

export default HealthInsFileOne