import Api from "./Api";

type hsNonValidateDataType = {
  correctedHSNumber?: string | number | readonly string[] | undefined;
  studentId: string;
  visaExpiryDate: string;
  user: string;
  leaNumber: string;
  hsNumber: string;
  isHSNumberValid: boolean;
};

class LawyerService {
  public async getServiceListing(query?: string | null) {
    let URL;
    if (query) {
      URL = `/lawyer/search?${query}`;
    } else {
      URL = `/lawyer/search`;
    }
    return await Api.get(URL);
  }
  public async getLawyerDashboard() {
    return await Api.get("lawyer/dashboard");
  }

  // Get Call History based on user Id
  public async getCallHistoryById(query?: string | null) {
    if (query) {
      return await Api.get(`lawyer/phone-calls/${query}`);
    }
  }

  public async getComments(userId: string |null,field:string) {
    if (userId ) {
      return await Api.get(`prestudy/field-predefined-comments/${userId}?field=${field}`);
    }
  }
  // Get Docs data by Id
  public async getPhoneDocsById(query?: string | null) {
    if (query) {
      return await Api.get(`lawyer/phonecalls/docs/${query}`);
    }
  }

  // Get Call History based on user Id
  public async getCommentsById(query?: string | null) {
    if (query) {
      return await Api.get(`lawyer/phonecalls/comments/${query}`);
    }
  }

  // Get HsNumber Email apis for users Id
  public async getHsNumberEmailById(query?: string | null) {
    if (query) {
      return await Api.get(`lawyer/hsnumber-email?user=${query}`);
    }
  }

  // addCallHistoryById
  public async addCallHistoryById(
    data: {
      when?: string;
      duration?: number | string;
      comments?: string;
      nextCallReminder?: Date | string;
      status?: string;
    },
    query?: string
  ) {
    if (data) {
      return await Api.post(`/lawyer/phone-calls/${query}`, data);
    }
  }

  // Get Lists of invitations emails
  public async getInvitationEmails(){
    return await Api.get("lawyer/invitation-emails");
  }

  // Get Lists Hs Number non-validate Data
  public async getNonValidateHsData( query?: { page: number; limit: number; role: string } | null){
    let URL;
    if (query) {
      URL = `lawyer/not-validate/hsnumbers?page=${query.page}&limit=${query.limit}&role=${query.role}`;
    } else {
      URL = `lawyer/not-validate/hsnumbers`;
    }
    return await Api.get(URL);
  }
  // Set Lists Hs Number non-validate Data
  public async setNonValidateHsData(data: hsNonValidateDataType){
    return await Api.put("lawyer/validate-hsnumber", data);
  }

  // Get Appointment data 
  public async getAppointmentDatas(query?: { page?: number; limit?: number; role?: string; when?: string; search?: string } | null) {
    let URL = 'lawyer/dashboard/appointment';

    const params: any = {};

    if (query) {
      if (query.when !== undefined) params.when = query.when;
        if (query.page !== undefined) params.page = query.page;
        if (query.limit !== undefined) params.limit = query.limit;
        if (query.role !== undefined) params.role = query.role;
        if (query.search !== undefined) params.search = query.search;

    }

    return await Api.get(URL, { params });
}

public async uploadRpDocs(studentId:any,payload:any) {
  return await Api.post(`lawyer/rp/documents/${studentId}`,payload);
}

}

const lawyerService = new LawyerService();
export default lawyerService;
