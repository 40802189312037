import Api from "./Api";

class UniversityDashboardServices {
  public async getOpenRegistrations(
    userId: string | undefined,
    query?: { page: number; limit: number; role: string } | null
  ) {
    let URL;
    if (query) {
      URL = `/university/students/open-registration/${userId}?page=${query.page}&limit=${query.limit}&role=${query.role}`;
    } else {
      URL = `/university/students/open-registration/${userId}`;
    }
    return await Api.get(URL);
  }

  public async getDashboardData(){
    return await Api.get('university/dashboard-overview');
  }
}

const universityDashboardServices = new UniversityDashboardServices();
export default universityDashboardServices;
