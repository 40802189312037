import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../Store";

type formStepType = {
  step: number;
  label: string;
  color?:string;
  errorAvailable?:number |string
};

export type initialStateType = {
  currentStep: number;
  documentList:any;
  formStatus: formStepType[] | null;
  stepCorrectionData : any;
  deletedPages:any;
  nextActive:boolean
};

const initialState: initialStateType = {
  currentStep: 1,
  documentList: null,
  formStatus: Array(8)
    .fill(0)
    .map((_, index) => ({
      step: index,
      label: index === 0 ? "Start" : index === 7 ? "Submit" : `0${index}`,
      color : index === 0 ? "#0A4A5E" :'#F0F2F4'
    })),
    stepCorrectionData : null ,
    deletedPages : null,
    nextActive : false
};

export const formCorrectionSlice = createSlice({
  name: "formCorrect",
  initialState,
  reducers: {
    nextStep: (state) => {
      state.currentStep += 1;
    },
    previousStep: (state) => {
      state.currentStep -= 1;
    },
    setFormStatus : (state,action) => {
      state.formStatus = action.payload
    },
    setStepcorrectionData : (state,action) => {
      state.stepCorrectionData = action.payload
    },
    setDeletedPages:(state,action) => {
      state.deletedPages = action.payload;
    },
    setStep:(state,action) => {
      state.currentStep = action.payload;
    },
    setNextactive:(state,action) => {
      state.nextActive = action.payload;
    },
    setDocumentList:(state,action) => {
      state.documentList = action.payload
    },
    clearFormcorrection: (state) => {
      state.currentStep = 1;
      state.formStatus = null;
      state.stepCorrectionData = null;
      state.deletedPages = null;
    },
  },
});

export const { nextStep, previousStep,clearFormcorrection ,setFormStatus,setStepcorrectionData,setDeletedPages,setStep,setNextactive,setDocumentList} =
  formCorrectionSlice.actions;
export const formCorrectionSelector = (state: RootState) =>
  state.formCorrectionReducer;
export default formCorrectionSlice.reducer;
