import { Button } from "components/Form";
import CustomLoader from "components/Loader/CustomLoader";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import universityService from "services/UniversityService";
import { useAppDispatch } from "store/Hooks";
import { clearFormcorrection } from "store/slices/FormCorrection";
import { clearPrestudy } from "store/slices/Prestudy";
import { clearUser } from "store/slices/User";
import { cleanAndLogout } from "utils/Helper";
import { cross, user } from "utils/Images";

type modalPropTypes = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  student: any
};

const ReminderModal = ({ setShowModal, student }: modalPropTypes) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any | null>(
    null
  );

  const getStudentRegisterData = async (studentId: string) => {
    try {
      setLoading(true);
      const response = await universityService.getStudentRegisterData(
        studentId
      );
      const {
        data: { data, status },
      } = response;
      if (status === 200) {
        setData(data);
        setLoading(false);
      }
      if (status === 401) {
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getStudentRegisterData(student?.studentId);
  }, []);

  return (
    <div className="fixed inset-0 z-[9999999] flex items-center justify-center bg-black bg-opacity-50">
      {loading ?
        <CustomLoader />
        :
        <div className="bg-white rounded-lg max-w-full w-[450px] md:w-[550px]  border-[2px] border-[#C3CCD5] lg:mx-0 mx-2 lg:mt-0 mt-[5.5rem] lg:mb-0 mb-[5.5rem]">
          <div className="modal-heading flex items-center justify-between mx-5 my-3">
            <h2 className="text-base md:text-xl font-bold text-[#0B0D0F] w-fit">
              Send Reminder
            </h2>
            <img
              src={cross}
              alt="cross"
              className="float-right cursor-pointer w-[35px] md:w-[40px]"
              onClick={() => {
                setShowModal(false);
              }}
            />
          </div>
          <hr />
          <div className="chat-section m-5 flex flex-col gap-[10px] text-[14px]">
            {data && data?.length > 0 ?
              data?.map((item: any) =>
                item.role !== "student" ? (
                  <div className="flex items-start break-all">
                    <div className="flex items-start lawyer-message  bg-[#F0F2F4] w-1/2 rounded-lg p-2">
                      <img src={user} alt="user" />
                      <label className="ml-1">{item.text}</label>
                    </div>
                  </div>
                ) :
                  (
                    <div className="flex items-end justify-end break-all">
                      <div className="flex justify-between lawyer-message bg-[#F0F2F4] w-1/2 rounded-lg p-2">
                        <label className="ml-1">{item.text}</label>
                        <img src={user} alt="user" />
                      </div>
                    </div>
                  )
              ) : <></>
            }
          </div>
          <div className="send-message m-5 text-[14px] flex flex-col">
            <label>Messages</label>
            <textarea
              name="messages"
              placeholder="Type a Messages..."
              rows={5}
              className="password-input border-[2px] border-[#E1E5EA] rounded-md placeholder-[#71859A] placeholder:text-[14px] w-full mt-1"
            />
            <Button
              type="submit"
              label="Send"
              disabled={false}
              cssClass="bg-[#0A4A5E] text-[15px] text-[#FFFFFF] py-2 w-[141px] rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed mt-5"
            // handleClick={currentStep > 1 ? handlePrevious : () => {}}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default ReminderModal;
