import React, { ChangeEvent, useEffect, useState } from "react";
import { RadioGroup } from "components/Form";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import { stepFiveSchema } from "schema/Prestudy";
import { stepFiveTypes, stepProps } from "utils/types";
import { addressOptions } from "utils/data";
import { CountryDropdown } from "react-country-region-selector";
import { prestudySelector, setCitizenship } from "store/slices/Prestudy";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import { CorrectionstepfiveSchema } from "schema/FormCorrection";
import { userSelector } from "store/slices/User";
import CommentSection from "components/Form/CommentSection";
import { setFeatureLoaded } from "store/slices/Prestudy";

const StepFive: React.FC<stepProps> = ({
  submitStepdata,
  nextActive,
  verifyCorrection,
}) => {
  const CountrySelect = CountryDropdown as any;
  const { user } = useAppSelector(userSelector);
  const { currentStep, stepData } = useAppSelector(prestudySelector);
  const dispatch = useAppDispatch();
  const [stepFields, setStepFields] = useState<stepFiveTypes>({
    citizinShip: "",
    additionalCitizinShip: "",
    citizenship1: "",
    citizenship2: "",
    citizenship3: "",
  });

  const initialValues: stepFiveTypes = {
    citizinShip: stepFields.citizinShip !== "" ? stepFields.citizinShip : "",
    additionalCitizinShip:
      stepFields.additionalCitizinShip !== ""
        ? stepFields.additionalCitizinShip
        : "",
    citizenship1: stepFields.citizenship1 !== "" ? stepFields.citizenship1 : "",
    citizenship2: stepFields.citizenship2 !== "" ? stepFields.citizenship2 : "",
    citizenship3: stepFields.citizenship3 !== "" ? stepFields.citizenship3 : "",
  };
  useEffect(() => {
    const updatedFields = { ...stepFields };
    stepData?.forEach((item) => {
      const name = item.name as keyof stepFiveTypes;
      switch (name) {
        case "citizinShip":
        case "additionalCitizinShip":
        case "citizenship1":
        case "citizenship2":
        case "citizenship3":
          updatedFields[name] = item.value;
          break;
        default:
          break;
      }
    });
    setStepFields(updatedFields);
  }, [stepData]);

  const handleSubmit = async (values: stepFiveTypes) => {
    dispatch(setCitizenship(values.citizinShip));
    if (user?.formStatus === "SUBMITTED") {
      let payload = [
        {
          name: "citizinShip",
          value: values.citizinShip,
        },
        {
          name: "additionalCitizinShip",
          value: values.additionalCitizinShip,
        },
      ];
      if (values?.additionalCitizinShip === "Yes") {
        if (values.citizenship1) {
          payload = [
            ...payload,
            {
              name: "citizenship1",
              value: values.citizenship1,
            },
          ];
        }
        if (values.citizenship2) {
          payload = [
            ...payload,
            {
              name: "citizenship2",
              value: values.citizenship2,
            },
          ];
        }
        if (values.citizenship3) {
          payload = [
            ...payload,
            {
              name: "citizenship3",
              value: values.citizenship3,
            },
          ];
        }
      } else {
        payload = [
          ...payload,
          {
            name: "citizenship1",
            value: "",
          },
        ];
        payload = [
          ...payload,
          {
            name: "citizenship2",
            value: "",
          },
        ];
        payload = [
          ...payload,
          {
            name: "citizenship3",
            value: "",
          },
        ];
      }
      const uniqueNames: { [name: string]: boolean } = {};
      let updatedArr: { name: string; value: string | null }[] = [];
      payload.map((item) => {
        let itemName = item.name as keyof stepFiveTypes;
        const oldValue = stepFields[itemName];
        if (!oldValue) {
          uniqueNames[item.name] = true;
          updatedArr.push({ ...item, value: item.value });
        }
        if (oldValue && oldValue !== item.value) {
          if (!uniqueNames[item.name]) {
            uniqueNames[item.name] = true;
            updatedArr.push({ ...item, value: item.value });
          }
        }
      });
      await verifyCorrection(updatedArr);
    } else {
      let payload = [
        {
          name: "citizinShip",
          label: "Citizenship",
          value: values.citizinShip,
          isShow: true,
        },
        {
          name: "additionalCitizinShip",
          label: "Additional citizenship?",
          value: values.additionalCitizinShip,
          isShow: true,
        },
      ];
      if (values?.additionalCitizinShip === "Yes") {
        if (values.citizenship1) {
          payload = [
            ...payload,
            {
              name: "citizenship1",
              label: "citizenship1",
              value: values.citizenship1,
              isShow: true,
            },
          ];
        }
        if (values.citizenship2) {
          payload = [
            ...payload,
            {
              name: "citizenship2",
              label: "citizenship2",
              value: values.citizenship2,
              isShow: true,
            },
          ];
        }
        if (values.citizenship3) {
          payload = [
            ...payload,
            {
              name: "citizenship3",
              label: "citizenship3",
              value: values.citizenship3,
              isShow: true,
            },
          ];
        }
      } else {
        payload = [
          ...payload,
          {
            name: "citizenship1",
            label: "citizenship1",
            value: "",
            isShow: true,
          },
        ];
        payload = [
          ...payload,
          {
            name: "citizenship2",
            label: "citizenship2",
            value: "",
            isShow: true,
          },
        ];
        payload = [
          ...payload,
          {
            name: "citizenship3",
            label: "citizenship3",
            value: "",
            isShow: true,
          },
        ];
      }
      await submitStepdata(payload, null);
    }
  };

  const showField = (field: string) => {
    if (!stepData) return true;
    let data = stepData?.find((item) => item.name === field);
    return !user?.formStatus ? true : !data ? false : data?.isShow;
    // return !data ? false : !user?.formStatus ? true : data?.isShow
  };

  const showComments = (field: string) => {
    if (!stepData) return false;
    let data = stepData?.find(
      (item) =>
        item.name === field && item?.comments && item?.comments?.length > 0
    );
    return !data ? false : true;
  };

  return (
    <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 h-full lg:ml-[6.3rem] ml-0">
      <label className="text-[#0B0D0F] font-semibold text-[20px]">
        Citizenships
      </label>
      <p className="text-[#71859A] leading-[27.36px] text-[15px] mt-5 lg:w-1/2 xl:w-1/2 w-full">
        If you have more than one citizenship, write here the one you use for
        your visa.{" "}
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={
          nextActive
            ? user?.formStatus === "SUBMITTED"
              ? ""
              : stepFiveSchema
            : ""
        }
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values }: any) => (
          <Form className="mx-auto" id={currentStep.toString()}>
            <>
              <div className={showField("citizinShip") ? "block" : "hidden"}>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"citizinShip"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Citizenship <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <div className="lg:w-1/2 xl:w-1/2 w-full">
                  <Field name="city">
                    {({ field, form }: FieldProps) => (
                      <CountrySelect
                        {...field}
                        value={values.citizinShip}
                        onChange={(val: string) =>
                          form.setFieldValue("citizinShip", val)
                        }
                        className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                      />
                    )}
                  </Field>
                </div>
                <ErrorMessage
                  name={"citizinShip"}
                  component="div"
                  className="text-red-500"
                />
                {showComments("citizinShip") && (
                  <CommentSection
                    field={"citizinShip"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
                <hr className="bg-[#E1E5EA] my-5" />
              </div>
              <div
                className={
                  showField("additionalCitizinShip") ? "block" : "hidden"
                }
              >
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"additionalCitizinShip"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Additional citizenship?
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="additionalCitizinShip">
                  {({ field, form }: FieldProps) => (
                    <div className="flex gap-8">
                      <RadioGroup
                        {...field}
                        fieldName="additionalCitizinShip"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue(
                            "additionalCitizinShip",
                            event.target.value
                          );
                        }}
                        className={"ml-1"}
                        checkedField={values.additionalCitizinShip}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="additionalCitizinShip"
                  component="div"
                  className="text-red-500"
                />
                {showComments("additionalCitizinShip") && (
                  <CommentSection
                    field={"additionalCitizinShip"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
                {values.additionalCitizinShip === "Yes" && (
                  <>
                    <div className={"block"}>
                      <div className="mb-2 mt-4 flex justify-between items-center">
                        <label
                          htmlFor={"citizenship1"}
                          className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                          Additional citizenship1{" "}
                        </label>
                      </div>
                      <div className="lg:w-1/2 xl:w-1/2 w-full">
                        <Field name="citizenship1">
                          {({ field, form }: FieldProps) => (
                            <CountrySelect
                              {...field}
                              value={values.citizenship1}
                              onChange={(val: string) =>
                                form.setFieldValue("citizenship1", val)
                              }
                              className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                            />
                          )}
                        </Field>
                        {showComments("citizenship1") && (
                          <CommentSection
                            field={"citizenship1"}
                            step={currentStep - 1}
                            userType="student"
                          />
                        )}
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className="mb-2 mt-4 flex justify-between items-center">
                        <label
                          htmlFor={"citizenship2"}
                          className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                          Additional citizenship2{" "}
                        </label>
                      </div>
                      <div className="lg:w-1/2 xl:w-1/2 w-full">
                        <Field name="citizenship2">
                          {({ field, form }: FieldProps) => (
                            <CountrySelect
                              {...field}
                              value={values.citizenship2}
                              onChange={(val: string) =>
                                form.setFieldValue("citizenship2", val)
                              }
                              className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                            />
                          )}
                        </Field>
                        {showComments("citizenship2") && (
                          <CommentSection
                            field={"citizenship2"}
                            step={currentStep - 1}
                            userType="student"
                          />
                        )}
                      </div>
                    </div>
                    <div className={"block"}>
                      <div className="mb-2 mt-4 flex justify-between items-center">
                        <label
                          htmlFor={"citizenship3"}
                          className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                          Additional citizenship3{" "}
                        </label>
                      </div>
                      <div className="lg:w-1/2 xl:w-1/2 w-full">
                        <Field name="citizenship3">
                          {({ field, form }: FieldProps) => (
                            <CountrySelect
                              {...field}
                              value={values.citizenship3}
                              onChange={(val: string) =>
                                form.setFieldValue("citizenship3", val)
                              }
                              className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                            />
                          )}
                        </Field>
                      </div>
                      <ErrorMessage
                        name={"citizenship1"}
                        component="div"
                        className="text-red-500"
                      />
                      {showComments("citizenship3") && (
                        <CommentSection
                          field={"citizenship2"}
                          step={currentStep - 1}
                          userType="student"
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StepFive;
