import { Box, LinearProgress } from '@mui/material';
import { Inputfile } from 'components/Form';
import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useAppSelector } from 'store/Hooks';
import { formCorrectionSelector } from 'store/slices/FormCorrection';
import { prestudySelector } from 'store/slices/Prestudy';
import { userSelector } from 'store/slices/User';
import { validateFiles } from 'utils/Helper';
import { crossBlack, documentBlack } from 'utils/Images';

interface subfileTwoprops {
    showField?: (args: string) => boolean;
    showComments?: (args: string) => boolean;
    values: any;
    uploadDocument: (formData: FormData) => void;
    handleChange: any;
    userType?: boolean;
    modal?: boolean;
    setModal?: Dispatch<SetStateAction<boolean>>;
    field?: string;
    setField?: Dispatch<SetStateAction<string>>;
    studentData?: any;
    check1:boolean;
    check2:boolean;
    check3:boolean;
    setCheck1:Dispatch<SetStateAction<boolean>>;
    setCheck2:Dispatch<SetStateAction<boolean>>;
    setCheck3:Dispatch<SetStateAction<boolean>>;
}


const SubsistanceFileTwo: React.FC<subfileTwoprops> = ({ showField, showComments, values, uploadDocument, handleChange, userType, modal, setModal, field, setField, studentData, check1,check2,check3,setCheck1,setCheck2,setCheck3}) => {
    console.log("check1 SubsistanceFileTwo",check1)
    const [files1, setFiles1] = useState<File[] | null>(null);
    const [files2, setFiles2] = useState<File[] | null>(null);
    const [files3, setFiles3] = useState<File[] | null>(
        null
    );
    const [files4, setFiles4] = useState<File[] | null>(
        null
    ); const [files5, setFiles5] = useState<File[] | null>(
        null
    );
    const [progress, setProgress] = React.useState(0);
    const [fileErr1, setfileErr1] = useState<string | null>(null);
    const [fileErr2, setfileErr2] = useState<string | null>(null);
    const [fileErr3, setfileErr3] = useState<string | null>(null);
    const [fileErr4, setfileErr4] = useState<string | null>(null);
    const [fileErr5, setfileErr5] = useState<string | null>(null);
    const { user } = useAppSelector(userSelector);
    const { currentStep } = useAppSelector(prestudySelector);
    const { currentStep: step } = useAppSelector(formCorrectionSelector);
    const [uploadingOne, setUploadingOne] = useState(false);
    const [uploadingTwo, setUploadingTwo] = useState(false);
    const [uploadingThree, setUploadingThree] = useState(false);
    const [uploadingFour, setUploadingFour] = useState(false);
    const [uploadingFive, setUploadingFive] = useState(false);
    const intervalIdRefone = useRef<any>();
    const intervalIdReftwo = useRef<any>();
    const intervalIdRefthree = useRef<any>();
    const intervalIdReffour = useRef<any>();
    const intervalIdReffive = useRef<any>();
    useEffect(() => {
        if (progress > 100) {
            setUploadingOne(false);
            setUploadingTwo(false);
            setUploadingThree(false);
            setUploadingFour(false);
            setUploadingFive(false);
            setProgress(0);
            clearInterval(intervalIdRefone?.current);
            clearInterval(intervalIdReftwo?.current);
            clearInterval(intervalIdRefthree?.current);
            clearInterval(intervalIdReffour?.current);
            clearInterval(intervalIdReffive?.current);
        }
    }, [progress]);

    useEffect(() => {
        return () => {
            clearInterval(intervalIdRefone?.current);
            clearInterval(intervalIdReftwo?.current);
            clearInterval(intervalIdRefthree?.current);
            clearInterval(intervalIdReffour?.current);
            clearInterval(intervalIdReffive?.current);
        };
    }, []);

    const uploadFiles = async (
        selectedFile: FileList | null,
        fieldName: string,
        form: any
    ) => {
        const allowedTypes = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf",
        ];
        const isValidate = await validateFiles(selectedFile, allowedTypes);
        if (isValidate === "true") {
            if (selectedFile) {
                if (fieldName === "scholarshipFile") {
                    const newFiles = Array.from(selectedFile).filter(
                        (newFile) =>
                            !files1 ||
                            !files1.some(
                                (existingFile) => existingFile.name === newFile.name
                            )
                    );
                    const updatedFiles = files1
                        ? [...files1, ...newFiles]
                        : newFiles;
                    form.setFieldValue("scholarshipFile", updatedFiles);
                    setFiles1(updatedFiles);
                    const formData: any = new FormData();
                    updatedFiles?.forEach((file: any) => {
                        formData.append("scholarshipFile", file);
                    });
                    if (userType) {
                        studentData && formData.append("userId", studentData?._id);
                        step && formData.append("step", (step - 1).toString());

                    } else {
                        user && formData.append("userId", user?._id);
                        currentStep && formData.append("step", (currentStep - 1).toString());

                    }
                    await uploadDocument(formData)
                }
                if (fieldName === "declarationFile") {
                    const newFiles = Array.from(selectedFile).filter(
                        (newFile) =>
                            !files2 ||
                            !files2.some(
                                (existingFile) => existingFile.name === newFile.name
                            )
                    );
                    const updatedFiles = files2
                        ? [...files2, ...newFiles]
                        : newFiles;
                    form.setFieldValue("declarationFile", updatedFiles);
                    setFiles2(updatedFiles);
                    const formData: any = new FormData();
                    updatedFiles?.forEach((file: any) => {
                        formData.append("declarationFile", file);
                    });
                    if (userType) {
                        studentData && formData.append("userId", studentData?._id);
                        step && formData.append("step", (step - 1).toString());

                    } else {
                        user && formData.append("userId", user?._id);
                        currentStep && formData.append("step", (currentStep - 1).toString());

                    }
                    await uploadDocument(formData)
                }
                if (fieldName === "parentDeclareFile1") {
                    const newFiles = Array.from(selectedFile).filter(
                        (newFile) =>
                            !files3 ||
                            !files3.some(
                                (existingFile) => existingFile.name === newFile.name
                            )
                    );
                    const updatedFiles = files3
                        ? [...files3, ...newFiles]
                        : newFiles;
                    form.setFieldValue("parentDeclareFile1", updatedFiles);
                    setFiles3(updatedFiles);
                    const formData: any = new FormData();
                    updatedFiles?.forEach((file: any) => {
                        formData.append("parentDeclareFile1", file);
                    });
                    if (userType) {
                        studentData && formData.append("userId", studentData?._id);
                        step && formData.append("step", (step - 1).toString());

                    } else {
                        user && formData.append("userId", user?._id);
                        currentStep && formData.append("step", (currentStep - 1).toString());

                    }
                    await uploadDocument(formData)
                }
                if (fieldName === "parentDeclareFile2") {
                    const newFiles = Array.from(selectedFile).filter(
                        (newFile) =>
                            !files4 ||
                            !files4.some(
                                (existingFile) => existingFile.name === newFile.name
                            )
                    );
                    const updatedFiles = files4
                        ? [...files4, ...newFiles]
                        : newFiles;
                    form.setFieldValue("parentDeclareFile2", updatedFiles);
                    setFiles4(updatedFiles);
                    const formData: any = new FormData();
                    updatedFiles?.forEach((file: any) => {
                        formData.append("parentDeclareFile2", file);
                    });
                    if (userType) {
                        studentData && formData.append("userId", studentData?._id);
                        step && formData.append("step", (step - 1).toString());

                    } else {
                        user && formData.append("userId", user?._id);
                        currentStep && formData.append("step", (currentStep - 1).toString());

                    }
                    await uploadDocument(formData)
                }
                if (fieldName === "parentDeclareFile3") {
                    const newFiles = Array.from(selectedFile).filter(
                        (newFile) =>
                            !files5 ||
                            !files5.some(
                                (existingFile) => existingFile.name === newFile.name
                            )
                    );
                    const updatedFiles = files5
                        ? [...files5, ...newFiles]
                        : newFiles;
                    form.setFieldValue("parentDeclareFile3", updatedFiles);
                    setFiles5(updatedFiles);
                    const formData: any = new FormData();
                    updatedFiles?.forEach((file: any) => {
                        formData.append("parentDeclareFile3", file);
                    });
                    if (userType) {
                        studentData && formData.append("userId", studentData?._id);
                        step && formData.append("step", (step - 1).toString());

                    } else {
                        user && formData.append("userId", user?._id);
                        currentStep && formData.append("step", (currentStep - 1).toString());

                    }
                    await uploadDocument(formData)
                }
            }
        } else {
            if (fieldName === "scholarshipFile") {
                setFiles1(null);
                setfileErr1(isValidate);
            }
            if (fieldName === "declarationFile") {
                setFiles2(null);
                setfileErr2(isValidate);
            }
            if (fieldName === "parentDeclareFile1") {
                setFiles3(null);
                setfileErr3(isValidate);
            }
            if (fieldName === "parentDeclareFile2") {
                setFiles4(null);
                setfileErr4(isValidate);
            } if (fieldName === "parentDeclareFile3") {
                setFiles4(null);
                setfileErr4(isValidate);
            }
        }
    };

    const removeFile = async (fileName: string, fieldName: string) => {
        console.log("🚀 ~ removeFile ~ fileName fieldName:", fileName, fieldName)
        if (fieldName === "scholarshipFile") {
            const updatedFiles = files1?.filter(
                (item) => item.name !== fileName
            );
            if (updatedFiles && updatedFiles.length === 0)
                setfileErr1("Files are required");
            updatedFiles && setFiles1(updatedFiles);
        }
        setProgress(0);
    };

    return (
        <div className={!showField ? "block" : showField('subsistenceFile') ? "block" : 'hidden'}>
            <div className="px-4 py-2">
                <div className='flex flex-col gap-2'>
                    <p className="text-[#FF4444] text-[14px] font-semibold lg:w-3/4 xl:w-3/4 2xl:w-3/4 w-full">
                        If you do not have any Blocked Account and you have applied for your visa at the German embassy in your home country, please choose the means of subsistence that was approved by the German Embassy.
                    </p>
                    <p className="text-[#FF4444] text-[14px] font-semibold lg:w-3/4 xl:w-3/4 2xl:w-3/4 w-full">
                        If you are entitled to apply for your residency permit from the German territory, please organise as soon as possible one of the means of subsistence.
                    </p>
                    <p className="text-[#FF4444] text-[14px] font-semibold lg:w-3/4 xl:w-3/4 2xl:w-3/4 w-full">
                        For more information about the Declaration of Commitment please follow this link:
                        <label className='underline cursor-pointer text-[#2e56a5]'> https://service.berlin.de/dienstleistung/326540/en/</label>
                    </p>
                    <p className="text-[#FF4444] text-[14px] font-semibold lg:w-3/4 xl:w-3/4 2xl:w-3/4 w-full">
                        If you have a notarised declaration from your parents securing your livelihood for the duration of your studies with proof of their income in the last six months, you can get additional information on the Foreigners Office website office or at the German Embassy in the place where your parents are residing.
                    </p>
                </div>
                <div className='flex flex-col checkbox-section gap-2 my-3'>
                    <h1 className='text-[#0B0D0F] text-[16px] font-semibold'>What are your means of subsistence? Several choices allowed</h1>
                    <div className='section-one items-center'>
                        <input
                            type="checkbox"
                            name="check1"
                            className="border-[#E1E5EA] cursor-pointer"
                            checked={check1}
                            onChange={(e: any) => setCheck1(e.target.checked)}
                        />
                        <label
                            htmlFor={"scholarship"}
                            className="text-[#0B0D0F] text-[16px] ml-3"
                        >
                            Certificate of scholarship
                        </label>
                    </div>
                    <div className='section-two items-center'>
                        <input
                            type="checkbox"
                            name="check2"
                            className="border-[#E1E5EA] cursor-pointer"
                            checked={check2}
                            onChange={(e: any) => setCheck2(e.target.checked)}
                        />
                        <label
                            htmlFor={"Declaration"}
                            className="text-[#0B0D0F] text-[16px] ml-3"
                        >
                            Declaration of commitment on official form (called Verpflichtungserklärung)
                        </label>

                    </div>
                    <div className='section-three items-center'>
                        <input
                            type="checkbox"
                            name="check3"
                            className="border-[#E1E5EA] cursor-pointer"
                            checked={check3}
                            onChange={(e: any) => setCheck3(e.target.checked)}
                        />                        <label
                            htmlFor={"scholarship"}
                            className="text-[#0B0D0F] text-[16px] ml-3"
                        >
                            Notarised declaration from parents
                        </label>

                    </div>
                    {check1 &&
                        <>
                            <div className="mb-2 mt-4 flex justify-between items-center">
                                <label
                                    htmlFor={"scholarshipFile"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal && setModal(e.target.checked);
                                                setField && setField('scholarshipFile')
                                            }}
                                            checked={modal && field === 'scholarshipFile'}
                                        />
                                    }

                                    Upload your certificate of scholarship
                                    <span className="text-[#FF4444] ml-1">*</span>
                                </label>
                            </div>
                            <Field name="scholarshipFile">
                                {({ field, form }: FieldProps) => (
                                    <>
                                        <Inputfile
                                            {...field}
                                            mainClass="lg:w-1/3 xl:w-1/3 w-full"
                                            btnClass={null}
                                            handleChange={(
                                                event: ChangeEvent<HTMLInputElement>
                                            ) => {
                                                setProgress(0);
                                                setUploadingOne(true);
                                                let selectedFile: FileList | null =
                                                    event.target.files;
                                                selectedFile &&
                                                    form.setFieldValue(
                                                        "scholarshipFile",
                                                        Array.from(selectedFile)
                                                    );
                                                setfileErr1(null);
                                                intervalIdRefone.current = setInterval(() => {
                                                    setProgress((prevProgress) => prevProgress + 10);
                                                }, 500);
                                                uploadFiles(
                                                    event.target.files,
                                                    "scholarshipFile",
                                                    form
                                                );
                                            }}
                                            multiple={true}
                                        />
                                        {!fileErr1 &&
                                            values.scholarshipFile &&
                                            values.scholarshipFile.length > 0 &&
                                            values.scholarshipFile.map(
                                                (item: { name: string; location: string }) => (
                                                    <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                                        <img
                                                            src={documentBlack}
                                                            alt="documentBlack"
                                                        />
                                                        <label className="flex-1">{item.name}</label>
                                                        <img
                                                            src={crossBlack}
                                                            alt="documentBlack"
                                                            onClick={async () => {
                                                                const updatedFiles =
                                                                    values.scholarshipFile?.filter(
                                                                        (val: {
                                                                            name: string;
                                                                            location: string;
                                                                        }) => val.name !== item.name
                                                                    );
                                                                form.setFieldValue(
                                                                    "scholarshipFile",
                                                                    updatedFiles
                                                                );
                                                                setFiles1(updatedFiles);
                                                                if (updatedFiles.length === 0) {
                                                                    form.setFieldValue(
                                                                        "scholarshipFile",
                                                                        []
                                                                    );
                                                                    setFiles1(null);
                                                                    setfileErr1(
                                                                        "Files are required"
                                                                    );
                                                                    setProgress(0);
                                                                    const formData: any = new FormData();
                                                                    updatedFiles?.forEach((file: any) => {
                                                                        formData.append("scholarshipFile", file);
                                                                    });
                                                                    if (userType) {
                                                                        studentData && formData.append("userId", studentData?._id);
                                                                        step && formData.append("step", (step - 1).toString());

                                                                    } else {
                                                                        user && formData.append("userId", user?._id);
                                                                        currentStep && formData.append("step", (currentStep - 1).toString());

                                                                    }
                                                                    await uploadDocument(formData);
                                                                }
                                                            }}
                                                            className="cursor-pointer"
                                                        />
                                                    </div>
                                                )
                                            )}
                                        {uploadingOne && (
                                            <Box sx={{ width: "100%" }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={progress}
                                                />
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Field>
                            {fileErr1 ? (
                                <div className="text-red-500 text-sm md:text-base fileError">
                                    {fileErr1}
                                </div>
                            ) : (
                                <ErrorMessage
                                    name="scholarshipFile"
                                    component="div"
                                    className="text-red-600 fileError"
                                />
                            )}
                            {showComments && showComments('scholarshipFile') &&
                                <CommentSection field={'scholarshipFile'} step={currentStep - 1} userType="student" />
                            }
                        </>
                    }
                    {check2 &&
                        <>
                            <div className="mb-2 mt-4 flex justify-between items-center">
                                <label
                                    htmlFor={"declarationFile"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal && setModal(e.target.checked);
                                                setField && setField('declarationFile')
                                            }}
                                            checked={modal && field === 'declarationFile'}
                                        />
                                    }

                                    Upload the declaration of commitment called Verpflichtungserklärung from BOTH SIDES
                                    <span className="text-[#FF4444] ml-1">*</span>
                                </label>
                            </div>
                            <Field name="declarationFile">
                                {({ field, form }: FieldProps) => (
                                    <>
                                        <Inputfile
                                            {...field}
                                            mainClass="lg:w-1/3 xl:w-1/3 w-full"
                                            btnClass={null}
                                            handleChange={(
                                                event: ChangeEvent<HTMLInputElement>
                                            ) => {
                                                setProgress(0);
                                                setUploadingTwo(true);
                                                let selectedFile: FileList | null =
                                                    event.target.files;
                                                selectedFile &&
                                                    form.setFieldValue(
                                                        "declarationFile",
                                                        Array.from(selectedFile)
                                                    );
                                                setfileErr2(null);
                                                intervalIdReftwo.current = setInterval(() => {
                                                    setProgress((prevProgress) => prevProgress + 10);
                                                }, 500);
                                                uploadFiles(
                                                    event.target.files,
                                                    "declarationFile",
                                                    form
                                                );
                                            }}
                                            multiple={true}
                                        />
                                        {!fileErr2 &&
                                            values.declarationFile &&
                                            values.declarationFile.length > 0 &&
                                            values.declarationFile.map(
                                                (item: { name: string; location: string }) => (
                                                    <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                                        <img
                                                            src={documentBlack}
                                                            alt="documentBlack"
                                                        />
                                                        <label className="flex-1">{item.name}</label>
                                                        <img
                                                            src={crossBlack}
                                                            alt="documentBlack"
                                                            onClick={async () => {
                                                                const updatedFiles =
                                                                    values.declarationFile?.filter(
                                                                        (val: {
                                                                            name: string;
                                                                            location: string;
                                                                        }) => val.name !== item.name
                                                                    );
                                                                form.setFieldValue(
                                                                    "declarationFile",
                                                                    updatedFiles
                                                                );
                                                                setFiles2(updatedFiles);
                                                                if (updatedFiles.length === 0) {
                                                                    form.setFieldValue(
                                                                        "declarationFile",
                                                                        []
                                                                    );
                                                                    setFiles2(null);
                                                                    setfileErr2(
                                                                        "Files are required"
                                                                    );
                                                                    setProgress(0);
                                                                    const formData: any = new FormData();
                                                                    updatedFiles?.forEach((file: any) => {
                                                                        formData.append("declarationFile", file);
                                                                    });
                                                                    if (userType) {
                                                                        studentData && formData.append("userId", studentData?._id);
                                                                        step && formData.append("step", (step - 1).toString());

                                                                    } else {
                                                                        user && formData.append("userId", user?._id);
                                                                        currentStep && formData.append("step", (currentStep - 1).toString());

                                                                    }

                                                                    await uploadDocument(formData);
                                                                }
                                                            }}
                                                            className="cursor-pointer"
                                                        />
                                                    </div>
                                                )
                                            )}
                                        {uploadingTwo && (
                                            <Box sx={{ width: "100%" }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={progress}
                                                />
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Field>
                            {fileErr2 ? (
                                <div className="text-red-500 text-sm md:text-base fileError">
                                    {fileErr2}
                                </div>
                            ) : (
                                <ErrorMessage
                                    name="declarationFile"
                                    component="div"
                                    className="text-red-600 fileError"
                                />
                            )}
                            {showComments && showComments('declarationFile') &&
                                <CommentSection field={'declarationFile'} step={currentStep - 1} userType="student" />
                            }
                        </>
                    }
                    {
                        check3 &&
                        <>
                            <div className="mb-2 mt-4 flex justify-between items-center">
                                <label
                                    htmlFor={"parentDeclareFile1"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal && setModal(e.target.checked);
                                                setField && setField('parentDeclareFile1')
                                            }}
                                            checked={modal && field === 'parentDeclareFile1'}
                                        />
                                    }

                                    Upload the notarised declaration by your parents                                    <span className="text-[#FF4444] ml-1">*</span>
                                </label>
                            </div>
                            <Field name="parentDeclareFile1">
                                {({ field, form }: FieldProps) => (
                                    <>
                                        <Inputfile
                                            {...field}
                                            mainClass="lg:w-1/3 xl:w-1/3 w-full"
                                            btnClass={null}
                                            handleChange={(
                                                event: ChangeEvent<HTMLInputElement>
                                            ) => {
                                                setProgress(0);
                                                setUploadingThree(true);
                                                let selectedFile: FileList | null =
                                                    event.target.files;
                                                selectedFile &&
                                                    form.setFieldValue(
                                                        "parentDeclareFile1",
                                                        Array.from(selectedFile)
                                                    );
                                                setfileErr3(null);
                                                intervalIdRefthree.current = setInterval(() => {
                                                    setProgress((prevProgress) => prevProgress + 10);
                                                }, 500);
                                                uploadFiles(
                                                    event.target.files,
                                                    "parentDeclareFile1",
                                                    form
                                                );
                                            }}
                                            multiple={true}
                                        />
                                        {!fileErr3 &&
                                            values.parentDeclareFile1 &&
                                            values.parentDeclareFile1.length > 0 &&
                                            values.parentDeclareFile1.map(
                                                (item: { name: string; location: string }) => (
                                                    <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                                        <img
                                                            src={documentBlack}
                                                            alt="documentBlack"
                                                        />
                                                        <label className="flex-1">{item.name}</label>
                                                        <img
                                                            src={crossBlack}
                                                            alt="documentBlack"
                                                            onClick={async () => {
                                                                const updatedFiles =
                                                                    values.parentDeclareFile1?.filter(
                                                                        (val: {
                                                                            name: string;
                                                                            location: string;
                                                                        }) => val.name !== item.name
                                                                    );
                                                                form.setFieldValue(
                                                                    "parentDeclareFile1",
                                                                    updatedFiles
                                                                );
                                                                setFiles3(updatedFiles);
                                                                if (updatedFiles.length === 0) {
                                                                    form.setFieldValue(
                                                                        "parentDeclareFile1",
                                                                        []
                                                                    );
                                                                    setFiles3(null);
                                                                    setfileErr3(
                                                                        "Files are required"
                                                                    );
                                                                    setProgress(0);
                                                                    const formData: any = new FormData();
                                                                    updatedFiles?.forEach((file: any) => {
                                                                        formData.append("parentDeclareFile1", file);
                                                                    });
                                                                    if (userType) {
                                                                        studentData && formData.append("userId", studentData?._id);
                                                                        step && formData.append("step", (step - 1).toString());

                                                                    } else {
                                                                        user && formData.append("userId", user?._id);
                                                                        currentStep && formData.append("step", (currentStep - 1).toString());

                                                                    }
                                                                    await uploadDocument(formData);
                                                                }
                                                            }}
                                                            className="cursor-pointer"
                                                        />
                                                    </div>
                                                )
                                            )}
                                        {uploadingThree && (
                                            <Box sx={{ width: "100%" }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={progress}
                                                />
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Field>
                            {fileErr3 ? (
                                <div className="text-red-500 text-sm md:text-base fileError">
                                    {fileErr3}
                                </div>
                            ) : (
                                <ErrorMessage
                                    name="parentDeclareFile1"
                                    component="div"
                                    className="text-red-600 fileError"
                                />
                            )}
                            {showComments && showComments('parentDeclareFile1') &&
                                <CommentSection field={'parentDeclareFile1'} step={currentStep - 1} userType="student" />
                            }
                            <p className="text-[#FF4444] text-[14px] font-semibold lg:w-3/4 xl:w-3/4 2xl:w-3/4 w-full">
                                The notarized declaration of your parents needs to be translated by a certified translator to English or German if it is written in another language.
                            </p>

                            <div className="mb-2 mt-4 flex justify-between items-center">
                                <label
                                    htmlFor={"parentDeclareFile2"}
                                    className="font-400 text-[14px] text-[#0B0D0F]"
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal && setModal(e.target.checked);
                                                setField && setField('parentDeclareFile2')
                                            }}
                                            checked={modal && field === 'parentDeclareFile2'}
                                        />
                                    }
                                    Upload the proof of your parents' income for the past six months
                                    <span className="text-[#FF4444] ml-1">*</span>
                                </label>
                            </div>
                            <Field name="parentDeclareFile2">
                                {({ field, form }: FieldProps) => (
                                    <>
                                        <Inputfile
                                            {...field}
                                            mainClass="lg:w-1/3 xl:w-1/3 w-full"
                                            btnClass={null}
                                            handleChange={(
                                                event: ChangeEvent<HTMLInputElement>
                                            ) => {
                                                setProgress(0);
                                                setUploadingFour(true);
                                                let selectedFile: FileList | null =
                                                    event.target.files;
                                                selectedFile &&
                                                    form.setFieldValue(
                                                        "parentDeclareFile2",
                                                        Array.from(selectedFile)
                                                    );
                                                setfileErr4(null);
                                                intervalIdReffour.current = setInterval(() => {
                                                    setProgress((prevProgress) => prevProgress + 10);
                                                }, 500);
                                                uploadFiles(
                                                    event.target.files,
                                                    "parentDeclareFile2",
                                                    form
                                                );
                                            }}
                                            multiple={true}
                                        />
                                        {!fileErr4 &&
                                            values.parentDeclareFile2 &&
                                            values.parentDeclareFile2.length > 0 &&
                                            values.parentDeclareFile2.map(
                                                (item: { name: string; location: string }) => (
                                                    <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                                        <img
                                                            src={documentBlack}
                                                            alt="documentBlack"
                                                        />
                                                        <label className="flex-1">{item.name}</label>
                                                        <img
                                                            src={crossBlack}
                                                            alt="documentBlack"
                                                            onClick={async () => {
                                                                const updatedFiles =
                                                                    values.parentDeclareFile2?.filter(
                                                                        (val: {
                                                                            name: string;
                                                                            location: string;
                                                                        }) => val.name !== item.name
                                                                    );
                                                                form.setFieldValue(
                                                                    "parentDeclareFile2",
                                                                    updatedFiles
                                                                );
                                                                setFiles4(updatedFiles)

                                                                if (updatedFiles.length === 0) {
                                                                    form.setFieldValue(
                                                                        "parentDeclareFile2",
                                                                        []
                                                                    );
                                                                    setFiles4(null)

                                                                    setfileErr4(
                                                                        "Files are required"
                                                                    );
                                                                    setProgress(0);
                                                                    const formData: any = new FormData();
                                                                    updatedFiles?.forEach((file: any) => {
                                                                        formData.append("parentDeclareFile2", file);
                                                                    });
                                                                    if (userType) {
                                                                        studentData && formData.append("userId", studentData?._id);
                                                                        step && formData.append("step", (step - 1).toString());

                                                                    } else {
                                                                        user && formData.append("userId", user?._id);
                                                                        currentStep && formData.append("step", (currentStep - 1).toString());

                                                                    }
                                                                    await uploadDocument(formData);
                                                                }
                                                            }}
                                                            className="cursor-pointer"
                                                        />
                                                    </div>
                                                )
                                            )}
                                        {uploadingFour && (
                                            <Box sx={{ width: "100%" }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={progress}
                                                />
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Field>
                            {fileErr4 ? (
                                <div className="text-red-500 text-sm md:text-base fileError">
                                    {fileErr4}
                                </div>
                            ) : (
                                <ErrorMessage
                                    name="parentDeclareFile2"
                                    component="div"
                                    className="text-red-600 fileError"
                                />
                            )}
                            {showComments && showComments('parentDeclareFile2') &&
                                <CommentSection field={'parentDeclareFile2'} step={currentStep - 1} userType="student" />
                            }
                            <div className="mb-2 mt-4 flex justify-between items-center">
                                <label
                                    htmlFor={"parentDeclareFile3"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal && setModal(e.target.checked);
                                                setField && setField('parentDeclareFile3')
                                            }}
                                            checked={modal && field === 'parentDeclareFile3'}
                                        />
                                    }

                                    Upload the passport copy of the parent/s who did the declaration
                                    <span className="text-[#FF4444] ml-1">*</span>
                                </label>
                            </div>
                            <Field name="parentDeclareFile3">
                                {({ field, form }: FieldProps) => (
                                    <>
                                        <Inputfile
                                            {...field}
                                            mainClass="lg:w-1/3 xl:w-1/3 w-full"
                                            btnClass={null}
                                            handleChange={(
                                                event: ChangeEvent<HTMLInputElement>
                                            ) => {
                                                setProgress(0);
                                                setUploadingFive(true);
                                                let selectedFile: FileList | null =
                                                    event.target.files;
                                                selectedFile &&
                                                    form.setFieldValue(
                                                        "parentDeclareFile3",
                                                        Array.from(selectedFile)
                                                    );
                                                setfileErr5(null);
                                                intervalIdReffive.current = setInterval(() => {
                                                    setProgress((prevProgress) => prevProgress + 10);
                                                }, 500);
                                                uploadFiles(
                                                    event.target.files,
                                                    "parentDeclareFile3",
                                                    form
                                                );
                                            }}
                                            multiple={true}
                                        />
                                        {!fileErr5 &&
                                            values.parentDeclareFile3 &&
                                            values.parentDeclareFile3.length > 0 &&
                                            values.parentDeclareFile3.map(
                                                (item: { name: string; location: string }) => (
                                                    <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                                        <img
                                                            src={documentBlack}
                                                            alt="documentBlack"
                                                        />
                                                        <label className="flex-1">{item.name}</label>
                                                        <img
                                                            src={crossBlack}
                                                            alt="documentBlack"
                                                            onClick={async () => {
                                                                const updatedFiles =
                                                                    values.parentDeclareFile3?.filter(
                                                                        (val: {
                                                                            name: string;
                                                                            location: string;
                                                                        }) => val.name !== item.name
                                                                    );
                                                                form.setFieldValue(
                                                                    "parentDeclareFile3",
                                                                    updatedFiles
                                                                );
                                                                setFiles5(updatedFiles);
                                                                if (updatedFiles.length === 0) {
                                                                    form.setFieldValue(
                                                                        "parentDeclareFile3",
                                                                        []
                                                                    );
                                                                    setFiles5(null)
                                                                    setfileErr5(
                                                                        "Files are required"
                                                                    );
                                                                    setProgress(0);
                                                                    const formData: any = new FormData();
                                                                    updatedFiles?.forEach((file: any) => {
                                                                        formData.append("parentDeclareFile3", file);
                                                                    });
                                                                    if (userType) {
                                                                        studentData && formData.append("userId", studentData?._id);
                                                                        step && formData.append("step", (step - 1).toString());

                                                                    } else {
                                                                        user && formData.append("userId", user?._id);
                                                                        currentStep && formData.append("step", (currentStep - 1).toString());

                                                                    }
                                                                    await uploadDocument(formData);
                                                                }
                                                            }}
                                                            className="cursor-pointer"
                                                        />
                                                    </div>
                                                )
                                            )}
                                        {uploadingFive && (
                                            <Box sx={{ width: "100%" }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={progress}
                                                />
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Field>
                            {fileErr5 ? (
                                <div className="text-red-500 text-sm md:text-base fileError">
                                    {fileErr5}
                                </div>
                            ) : (
                                <ErrorMessage
                                    name="parentDeclareFile3"
                                    component="div"
                                    className="text-red-600 fileError"
                                />
                            )}
                            {showComments && showComments('parentDeclareFile3') &&
                                <CommentSection field={'parentDeclareFile3'} step={currentStep - 1} userType="student" />
                            }

                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default SubsistanceFileTwo