import { Link } from "react-router-dom";
import {
  lawyerSidemenuItems,
  sideMenuItems,
  universitySideMenuItems,
} from "utils/data";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import { setLawyerCorrectionStatus } from "store/slices/Prestudy";
import { setSidebar, userSelector } from "store/slices/User";
import { useLocation } from "react-router-dom";
import prestudyService from "services/prestudy";
import { useNavigate } from "react-router-dom";


const Sidebar: React.FC = () => {
  const { sidebarOpen, user } = useAppSelector(userSelector);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  type itemType = {
    icon: string;
    name: string;
    route?: string;
    activeIcon: string;
    childRoutes?: string[] | null;
    showCount?: boolean
  };
  type menuItemsMapType = {
    [key: string]: itemType[];
  };

  const setCount = (name: string) => {
    if (name === 'Open') {
      return user?.openRegistrationData
    } else if (name === 'Documents') {
      return user?.documentsData
    } else if (name === 'Active Services') {
      return user?.activeServicesData
    } else {
      return null
    }
  }
  const setIcon = (data: itemType): string => {
    if (data.route === "/pre-study" && user?.status !== "approved")
      return data.icon;
    if (data.route === pathname) return data.activeIcon;
    else if (data.childRoutes && data.childRoutes.includes(pathname))
      return data.activeIcon;
    else return data.icon;
  };

  const handleNavigation = (item: any) => {
    if (item) {
      navigate(item);
    }
  };

  const getPreStudyFormData = async (user: any) => {
    let response = await prestudyService.getStepdata(
      user, 0
    );
    const { data } = response

    if (!response.data.data) {
      navigate("/pre-study");
      return;
    }
    
    dispatch(setLawyerCorrectionStatus(data?.data?.isCorrectionStartedFromLawyer));
  };
  
  const menuItemsMap: menuItemsMapType = {
    student: sideMenuItems,
    university: universitySideMenuItems,
    lawyer: lawyerSidemenuItems,
  };
  const items = user && user.role ? menuItemsMap[user.role] || [] : [];
  return (
    <>
      <div className="flex flex-col items-start space-y-4">
        <span
          className={`font-semibold text-[#71859A] mt-2 uppercase ${sidebarOpen ? "ml-5" : "ml-3"
            }`}
        >
          Main
        </span>
        <div className="grid grid-cols-2 gap-4">
          {items.map((item: itemType, i) => (
            <>
              <div
                className={`flex py-1 rounded-m ${sidebarOpen ? "px-6" : "px-5"
                  }`}
                key={item.name}
              >
                <Link
                          to={
                            item.route
                              ? item.route === "/pre-study" && user?.status !== "approved"
                                ? ""
                                : item.route
                              : ""
                          }
                          className="flex"
                          onClick={async (event) => {
                            if (user?.role === "student" && item.name === "Form 1" && user?.status === "approved") {
                              event.preventDefault(); 
                              await getPreStudyFormData(user._id);
                              handleNavigation("/pre-study")
                            }

                            if (window.innerWidth < 1024) {
                              dispatch(setSidebar(window.innerWidth > 1024));
                            }
                          }}
                        >
                  <img
                    src={setIcon(item)}
                    className={`cursor-pointer`}
                    alt="icon"
                  />
                  <h2
                    className={`whitespace-pre ml-2 font-semibold
                ${!(
                        item.route === "/pre-study" && user?.status !== "approved"
                      ) &&
                      (pathname === item.route ||
                        (item.childRoutes &&
                          item.childRoutes.includes(pathname))) &&
                      "text-[#0A4A5E]"
                      }
                 ${!sidebarOpen && "opacity-0 translate-x-28 overflow-hidden"}`}
                  >
                    {item.name}
                  </h2>
                  <h2
                    className={`${sidebarOpen && "hidden"
                      } absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit  `}
                  >
                    {item.name}
                  </h2>
                </Link>
              </div>
              <div className="flex justify-end py-1 rounded-m px-6">
                {(item?.showCount && setCount(item.name)) &&
                  <div className="details-count border-[2px] rounded-[2px] border-[#EFC6A9] bg-[#EFC6A9] h-[24px] w-[24px] text-[#0B0D0F] flex items-center justify-center font-semibold text-[14px]">
                    {setCount(item.name)}
                  </div>
                }
              </div>
            </>
          ))}
        </div>

      </div>
    </>
  );
};

export default Sidebar;
