import { useState } from "react";
import { Field, ErrorMessage, FieldProps } from "formik";
import { Link } from "react-router-dom";
import { inputPropsTypes } from "utils/types";
import { eye, eyeOff } from "utils/Images";

const Inputpassword: React.FC<inputPropsTypes> = ({
  type,
  label,
  fieldName,
  placeholder,
  showLink,
  className,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <div className="mb-2 md:mb-4 relative">
        <Field name={fieldName}>
          {({ field }: FieldProps) => (
            <div className="border-[2px] border-[#E1E5EA] rounded-md w-full flex items-center">
              <input
                type={showPassword ? "text" : "password"}
                {...field}
                placeholder={placeholder}
                className={
                  className
                    ? className
                    : "border-none rounded-md w-full placeholder-[#71859A] placeholder:text-[14px] password-input"
                }
              />
              <div className="p-2 rounded-r-md bg-white">
                {showPassword ? (
                  <img
                    src={eye}
                    className="cursor-pointer w-5 h-5"
                    onClick={togglePasswordVisibility}
                    alt="eye"
                  />
                ) : (
                  <img
                    src={eyeOff}
                    className="cursor-pointer w-5 h-5"
                    onClick={togglePasswordVisibility}
                    alt="eye-off"
                  />
                )}
              </div>
            </div>
          )}
        </Field>
        <div className="flex flex-col">
          <ErrorMessage
            name={fieldName}
            component="div"
            className="text-red-500 mb-1 text-sm md:text-base flex-1"
          />
          {showLink && (
            <p className="flex justify-end mt-2 mb-4">
              <Link
                to="/forgot-password"
                className="text-[#E39D6B] text-[16px]"
              >
                Forgot password?
              </Link>
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default Inputpassword;
