import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { agreementSchema } from "schema/Prestudy";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import { prestudySelector, setAgreementSignature, setCorrectionStep } from "store/slices/Prestudy";
import { userSelector } from "store/slices/User";
import { convertbas64Tofile } from "utils/Helper";
import { agreementTypes, stepProps } from "utils/types";
import moment from "moment";
import debounce from "lodash.debounce";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import MandatoryFieldsModal from "components/Modals/MandatoryFieldsModal";

const AgreementForm: React.FC<stepProps> = ({ submitStepdata }) => {
  const SignaturePad = SignatureCanvas as any;
  const sigCanvas = useRef<any>(null);
  const DateInput = DatePicker as any;
  const { currentStep, stepData, agreementSignature, formStatus } =
    useAppSelector(prestudySelector);
  const { user } = useAppSelector(userSelector);
  const dispatch = useAppDispatch();
  const [base64, setBase64] = useState<any>(null);
  const [stepFields, setStepFields] = useState<agreementTypes>({
    isDataprocess: false,
    isImmigration: false,
    isAttorney: false,
    startDate: "",
    signature: "",
  });

  const initialValues: agreementTypes = {
    isDataprocess: stepFields.isDataprocess ? stepFields.isDataprocess : false,
    isImmigration: stepFields.isImmigration ? stepFields.isImmigration : false,
    isAttorney: stepFields.isAttorney ? stepFields.isAttorney : false,
    startDate: stepFields.startDate ? stepFields.startDate : "",
    signature: agreementSignature ? convertbas64Tofile(agreementSignature) : stepFields?.signature,
  };

  useEffect(() => {
    const img = new Image();
    img.src = agreementSignature ? agreementSignature : initialValues?.signature;
    img.onload = () => {
      if (sigCanvas.current) {
        sigCanvas.current.clear();
        sigCanvas.current.fromDataURL(agreementSignature ? agreementSignature : initialValues?.signature);
        setBase64(agreementSignature ? agreementSignature : initialValues?.signature)
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (base64 && sigCanvas.current) {
        sigCanvas.current.clear();
        sigCanvas.current.fromDataURL(base64);
      }
    }, 300);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [base64]);

  useEffect(() => {
    const updatedFields = { ...stepFields };
    stepData?.forEach((item) => {
      const name = item.name as keyof agreementTypes;
      switch (name) {
        case "isDataprocess":
        case "isImmigration":
        case "isAttorney":
          updatedFields[name] = !!item.value;
          break;
        case "startDate":
          if (typeof item.value === "string") {
            updatedFields[name] = item?.value !== '' ? dayjs(moment(item.value).format("YYYY-MM-DD")) : '';
          }
          break;
        case "signature":
          updatedFields[name] = agreementSignature !== ''
            ? convertbas64Tofile(agreementSignature)
            : item?.value[0]?.location ? item?.value[0]?.location : null;
          break;
        default:
          break;
      }
    });
    console.log("updatedFields ", updatedFields)
    setStepFields(updatedFields);
  }, [stepData]);

  const handleSubmit = async (values: agreementTypes) => {
    try {
        if (user?.formStatus !== 'SUBMITTED') {
          const formData: any = new FormData();
          formData.append("signature", values.signature);
          user && formData.append("userId", user?._id);
          currentStep && formData.append("step", (currentStep - 1).toString());
          formData.append("label", "Signature (PoA)");
          formData.append("isRequired", true);
          let payload = [
            {
              name: "isDataprocess",
              label:
                "I confirm the receipt of the information about the data proccessing",
              value: values.isDataprocess,
              isShow: true,
            },
            {
              name: "isImmigration",
              label:
                "I confirm the receipt of the information about the usage of the immigration service package",
              value: values.isImmigration,
              isShow: true,
            },
            {
              name: "isAttorney",
              label:
                "I hereby give Power of Attorney to SERS RA GmbH represented by Surin Ersöz for",
              value: values.isAttorney,
              isShow: true,
            },
            {
              name: "startDate",
              label: "Date & signature (PoA)*",
              value: values.startDate
                ? new Date(
                  moment(new Date(values.startDate)).format("YYYY-MM-DD")
                ).toISOString()
                : "",
              isShow: true,
            },
          ];
          dispatch(setAgreementSignature(base64))
          await submitStepdata(
            payload,
            typeof values?.signature === "string" ? null : formData
          );
        } else {
          const currentIndex = formStatus?.findIndex(item => item.step === currentStep - 1);
          if (currentIndex === 0 || currentIndex) {
            if (currentIndex !== -1 && formStatus) {
              dispatch(setCorrectionStep({ step: formStatus[currentIndex + 1].step }));
            }

          }
        }
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error)
    }
  };

  return (
    <>
      <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 h-full lg:ml-[6.3rem] ml-0">
        <label className="text-[#1492B8] font-semibold text-[20px]">
          {user?.formStatus === "SUBMITTED" ? 'Welcome Back' : 'Welcome'}
        </label>
        <p className="text-[#71859A] text-[15px] mt-8">
          {user?.formStatus === "SUBMITTED" ?
            'Your form requires your attention, please go through all the steps and preform the adequate correction'
            :
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          }
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={user?.formStatus === "SUBMITTED" ? '' : agreementSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ values, handleChange }: any) => (
            <Form id={currentStep.toString()}>
              {user?.formStatus !== "SUBMITTED" &&
                <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 mt-5">
                  <label className="text-[#0B0D0F] font-semibold text-[20px]">
                    Agreement on data processing
                  </label>
                  <div className="English-version mt-5">
                    <label className="text-[#0B0D0F] font-semibold text-[16px]">
                      English version
                    </label>
                    <div className="border-[2px] border-[#E1E5EA] w-full bg-white p-4 mt-5 overflow-y-auto max-h-[400px] lg:max-h-[600px] flex flex-col gap-3 text-[15px] text-[#71859A]">
                      <p>
                        The following notes on data processing in the mandate
                        relationship serve the fulfillment of our duties to inform
                        under Art. 13 and 14 DSGVO.
                      </p>
                      <p>This privacy policy applies to data processing by</p>
                      <div>
                        <label className="text-[#0B0D0F] text-[14px] font-semibold">
                          SERS Rechtsanwaltsges. mbH,
                        </label>
                        <br />
                        <p>Kurfürstendamm 167-168, 10707 Berlin,</p>
                        <p>Email:info@sers-ra-gmbh.de</p>
                        <p>Phone: 03091689840</p>
                        <p>Fax: 03013880876</p>
                      </div>
                      <p className="text-[#0B0D0F] text-[14px] font-semibold">
                        1. Collection and storage of personal data as well as nature
                        and purpose and their use
                      </p>
                      <p>If you mandate me, I raise the following information:</p>
                      <p>
                        Salutation, first name, surname, a valid e-mail address,
                        address, telephone number, information necessary for the
                        assertion and defence of your rights under the mandate. The
                        collection of this data takes place in order to identify you
                        as a client, in order to be able to advise and represent you
                        adequately, to correspondence with you on invoicing, to
                        settle possible liability claims and to assert any claims
                        against you.
                      </p>
                      <p>
                        The data processing takes place at your request and is
                        required by Article 6 (1) sentence 1 lit.b GDPR for the
                        purposes described above for the appropriate processing of
                        the mandate and for the mutual fulfillment of obligations
                        under the mandate contract.
                      </p>
                      <p>
                        The personal data collected by me for the purposes of
                        soliciting my mandate will be stored until the end of the
                        legal retention period for lawyers (6 years after the end of
                        the calendar year in which the mandate was terminated) and
                        then deleted, unless I have been required under Article 6
                        (1) sentence 1 lit.c DSGVO to a longer storage due to tax
                        and commercial requirements for storage and documentation
                        (from HGB, StGB or AO) or you have consented to a further
                        storage according to Art. 6 para. 1 sentence 1 lit a DSGVO.
                      </p>
                      <p className="text-[#0B0D0F] text-[14px] font-semibold">
                        1. Collection and storage of personal data as well as nature
                        and purpose and their use
                      </p>
                      <p>If you mandate me, I raise the following information:</p>
                      <p>
                        Salutation, first name, surname, a valid e-mail address,
                        address, telephone number, information necessary for the
                        assertion and defence of your rights under the mandate. The
                        collection of this data takes place in order to identify you
                        as a client, in order to be able to advise and represent you
                        adequately, to correspondence with you on invoicing, to
                        settle possible liability claims and to assert any claims
                        against you.
                      </p>
                      <p>
                        The data processing takes place at your request and is
                        required by Article 6 (1) sentence 1 lit.b GDPR for the
                        purposes described above for the appropriate processing of
                        the mandate and for the mutual fulfillment of obligations
                        under the mandate contract.
                      </p>
                      <p>
                        The personal data collected by me for the purposes of
                        soliciting my mandate will be stored until the end of the
                        legal retention period for lawyers (6 years after the end of
                        the calendar year in which the mandate was terminated) and
                        then deleted, unless I have been required under Article 6
                        (1) sentence 1 lit.c DSGVO to a longer storage due to tax
                        and commercial requirements for storage and documentation
                        (from HGB, StGB or AO) or you have consented to a further
                        storage according to Art. 6 para. 1 sentence 1 lit a DSGVO.
                      </p>
                    </div>
                  </div>
                  <div className="german-version mt-5">
                    <label className="text-[#0B0D0F] font-semibold text-[16px]">
                      German version
                    </label>
                    <div className="border-[2px] border-[#E1E5EA] w-full bg-white p-4 mt-5 overflow-y-auto max-h-[400px] lg:max-h-[600px] flex flex-col gap-3 text-[15px] text-[#71859A]">
                      <p>
                        The following notes on data processing in the mandate
                        relationship serve the fulfillment of our duties to inform
                        under Art. 13 and 14 DSGVO.
                      </p>
                      <p>This privacy policy applies to data processing by</p>
                      <div>
                        <label className="text-[#0B0D0F] font-semibold text-[14px]">
                          SERS Rechtsanwaltsges. mbH,
                        </label>
                        <br />
                        <p>Kurfürstendamm 167-168, 10707 Berlin,</p>
                        <p>Email:info@sers-ra-gmbh.de</p>
                        <p>Phone: 03091689840</p>
                        <p>Fax: 03013880876</p>
                      </div>
                      <p className="text-[#0B0D0F] font-semibold">
                        1. Collection and storage of personal data as well as nature
                        and purpose and their use
                      </p>
                      <p>If you mandate me, I raise the following information:</p>
                      <p>
                        Salutation, first name, surname, a valid e-mail address,
                        address, telephone number, information necessary for the
                        assertion and defence of your rights under the mandate. The
                        collection of this data takes place in order to identify you
                        as a client, in order to be able to advise and represent you
                        adequately, to correspondence with you on invoicing, to
                        settle possible liability claims and to assert any claims
                        against you.
                      </p>
                      <p>
                        The data processing takes place at your request and is
                        required by Article 6 (1) sentence 1 lit.b GDPR for the
                        purposes described above for the appropriate processing of
                        the mandate and for the mutual fulfillment of obligations
                        under the mandate contract.
                      </p>
                      <p>
                        The personal data collected by me for the purposes of
                        soliciting my mandate will be stored until the end of the
                        legal retention period for lawyers (6 years after the end of
                        the calendar year in which the mandate was terminated) and
                        then deleted, unless I have been required under Article 6
                        (1) sentence 1 lit.c DSGVO to a longer storage due to tax
                        and commercial requirements for storage and documentation
                        (from HGB, StGB or AO) or you have consented to a further
                        storage according to Art. 6 para. 1 sentence 1 lit a DSGVO.
                      </p>
                      <p className="text-[#0B0D0F] font-semibold text-[14px]">
                        1. Collection and storage of personal data as well as nature
                        and purpose and their use
                      </p>
                      <p>If you mandate me, I raise the following information:</p>
                      <p>
                        Salutation, first name, surname, a valid e-mail address,
                        address, telephone number, information necessary for the
                        assertion and defence of your rights under the mandate. The
                        collection of this data takes place in order to identify you
                        as a client, in order to be able to advise and represent you
                        adequately, to correspondence with you on invoicing, to
                        settle possible liability claims and to assert any claims
                        against you.
                      </p>
                      <p>
                        The data processing takes place at your request and is
                        required by Article 6 (1) sentence 1 lit.b GDPR for the
                        purposes described above for the appropriate processing of
                        the mandate and for the mutual fulfillment of obligations
                        under the mandate contract.
                      </p>
                      <p>
                        The personal data collected by me for the purposes of
                        soliciting my mandate will be stored until the end of the
                        legal retention period for lawyers (6 years after the end of
                        the calendar year in which the mandate was terminated) and
                        then deleted, unless I have been required under Article 6
                        (1) sentence 1 lit.c DSGVO to a longer storage due to tax
                        and commercial requirements for storage and documentation
                        (from HGB, StGB or AO) or you have consented to a further
                        storage according to Art. 6 para. 1 sentence 1 lit a DSGVO.
                      </p>
                    </div>
                  </div>
                  <div className="my-5 border-[2px] border-[#E8F8FD] rounded-md bg-[#E8F8FD] p-4">
                    <input
                      type="checkbox"
                      name="isDataprocess"
                      value={values.isDataprocess}
                      className="border-[#E1E5EA] cursor-pointer"
                      onChange={handleChange}
                      checked={values.isDataprocess}
                    />
                    <label
                      htmlFor="terms"
                      className="text-[#0B0D0F] text-[16px] ml-3 font-semibold"
                    >
                      I confirm the receipt of the information about the data
                      proccessing <span className="text-[#FF4444]">*</span>
                    </label>
                    <ErrorMessage
                      component={"div"}
                      className="text-red-500"
                      name="isDataprocess"
                    />
                  </div>
                  <label className="text-[#0B0D0F] font-semibold text-[20px]">
                    Agreement on usage of the Immigration Assistance
                  </label>
                  <div className="no-gurantee mt-5 flex gap-5 flex-col">
                    <label className="text-[#0B0D0F] font-semibold text-[14px]">
                      1. NO GUARANTEES
                    </label>
                    <p className="text-[14px] text-[#71859A]">
                      The Student acknowledges that SERS law firm has not and cannot
                      guarantee any outcome for any matters in which SERS law firm
                      represents the Student. While no guarantee of outcome has been
                      made, the SERS law firm will diligently represent the
                      Student’s interests and will endeavour to perform only those
                      services required by the facts of the case and the law as
                      determined by SERS law firm. SERS law firm cannot guarantee
                      that the laws or regulations which concern the whole
                      application process can not be changed at any time.
                    </p>
                  </div>
                  <div className="no-gurantee mt-5 flex gap-5 flex-col">
                    <label className="text-[#0B0D0F] font-semibold text-[14px]">
                      2. FIRM PROPERTY
                    </label>
                    <p className="text-[14px] text-[#71859A]">
                      The Student understands and acknowledges that any
                      documentation (and drafts of any documentation) created by
                      SERS law firm is the exclusive property of SERS and SERS
                      issues the Student a limited and revocable license for use of
                      the document for the sole purpose of the matter set forth
                      above. The Student may not use, distribute, disseminate or
                      copy any of SERS’s documentation or drafts without express
                      authorisation in writing and signed by SERS’s managing
                      attorney.
                    </p>
                  </div>
                  <div className="no-gurantee mt-5 flex gap-5 flex-col">
                    <label className="text-[#0B0D0F] font-semibold text-[14px]">
                      3. STUDENT’S OBLIGATIONS TO FIRM
                    </label>
                    <p className="text-[14px] text-[#71859A]">
                      The Student’s obligations will include the following:
                    </p>
                    <div className="text-[14px] text-[#71859A]">
                      <p>
                        Complying with the terms and conditions of this agreement
                      </p>
                      <p>
                        Ensuring that the Firm has the current and working contact
                        information (email, phone number, address) for the Student.
                      </p>
                      <p>
                        Timely cooperation with any request for
                        documents/forms/signatures and similar by the Firm related
                        to the representation, checking mails on daily basis.
                      </p>
                      <p>
                        Reasonably and timely responding to communications from SERS
                        law firm and requests for meetings communicated by SERS law
                        firm.
                      </p>
                      <p>
                        If the Student is unable to attend any appointment
                        communicated by SERS law firm or any authority involved in
                        the service described, for ex. due to illness or travels,
                        Students must communicate this by email immediately after
                        receipt of the appointment and before the appointment date.
                      </p>
                      <p>
                        In case of no attendance or late arrival to any appointment,
                        new appointments might be not possible.
                      </p>
                      <p>
                        Any information exchanged with SERS law firm is to be
                        treated as confidential.
                      </p>
                    </div>
                  </div>
                  <div className="no-gurantee mt-5 flex gap-5 flex-col">
                    <label className="text-[#0B0D0F] font-semibold text-[14px]">
                      4. EXCLUSIONS MAY APPLY TO
                    </label>
                    <p className="text-[14px] text-[#71859A]">
                      1. Students who have recently arrived in Germany with a
                      different institution sponsored German entry visa/free 90 days
                      and who changed the course of study to the current institution
                      and who may or may not have started their studies with another
                      institution can benefit from the service as described subject
                      to not having a resident permit in place, being in the first
                      term of the scheduled studies and having a minimum of 2 months
                      left from their German entry visa/90 free days. Students with
                      shorter visa validity/free 90 days periods can benefit from
                      the service upon approval by SERS law firm.
                    </p>
                    <p className="text-[14px] text-[#71859A]">
                      2. Students who start studying at the current institution but
                      already have a fictional certificate, a residency permit in
                      place or another kind of residence title (for ex.
                      Humanitarian) issued in Germany or by a different EU/Schengen
                      authority or students who have a foreign UE/Schengen entry
                      visa can benefit from the lectures and group consultations we
                      organize. They may be eligible for the residency application
                      upon express confirmation by SERS law firm.
                    </p>
                    <p className="text-[14px] text-[#71859A]">
                      3. Students must have a minimum of 2 months left from their
                      German entry visa/free 90 days to benefit from the service.
                      Students with shorter visa validity periods can benefit from
                      the service upon approval by SERS law firm.
                    </p>
                    <p className="text-[14px] text-[#71859A]">
                      4. Students living outside Berlin or Potsdam cannot benefit
                      from the service
                    </p>
                  </div>
                  <div className="my-5 border-[2px] border-[#E8F8FD] rounded-md bg-[#E8F8FD] p-4">
                    <input
                      type="checkbox"
                      name="isImmigration"
                      value={values.isImmigration}
                      className="border-[#E1E5EA] cursor-pointer"
                      onChange={handleChange}
                      checked={values.isImmigration}
                    />
                    <label
                      htmlFor="terms"
                      className="text-[#0B0D0F] text-[16px] ml-3 font-semibold"
                    >
                      I confirm the receipt of the information about the usage of
                      the immigration service package{" "}
                      <span className="text-[#FF4444]">*</span>
                    </label>
                    <ErrorMessage
                      component={"div"}
                      className="text-red-500"
                      name="isImmigration"
                    />
                  </div>
                  <div className="mt-5">
                    <label className="text-[#0B0D0F] font-semibold text-[16px]">
                      Power of Attorney
                    </label>
                    <p className="text-[#71859A] text-[15px] mt-8">
                      To conduct application procedures at the Immigration Offices
                      in Germany The power of attorney applies to any accessory and
                      subsequent proceedings The said power of attorney comprises in
                      particular the authority to effect and accept notifications,
                      to transfer this power of attorney in whole or in part to
                      other parties (substitute power of attorney), to file,
                      withdraw or waive appeals. To settle the lawsuit or
                      extrajudicial negotiation by means of compromise, waiver or
                      acknowledgment, to accept valuables and documents, in
                      particular also the matter in dispute, as well as amounts
                      reimbursed by the opposing party, the judicial cashier or any
                      other authorities and inspect the files.
                    </p>
                    <p className="text-[#71859A] text-[15px] mt-8">
                      Zur Durchführung von Antragsverfahren bei Ausländerbehörden in
                      Deutschland Die Vollmacht gilt für alle Neben- und
                      Folgeverfahren. Sie umfasst insbesondere die Vollmacht,
                      Mitteilungen zu erwirken und entgegenzunehmen, diese Vollmacht
                      ganz oder teilweise auf andere zu übertragen
                      (Ersatzvollmacht), zu hinterlegen, Einsprüche zurückzuziehen
                      oder darauf zu verzichten. Zur Beilegung des Rechtsstreits
                      oder der außergerichtlichen Verhandlung durch Vergleich,
                      Verzicht oder Anerkenntnis, zur Entgegennahme von
                      Wertgegenständen und Dokumenten, insbesondere auch der
                      Streitsache.{" "}
                    </p>
                  </div>
                  <div className="mt-5 border-[2px] border-[#E8F8FD] rounded-md bg-[#E8F8FD] p-4">
                    <input
                      type="checkbox"
                      name="isAttorney"
                      value={values.isAttorney}
                      className="border-[#E1E5EA] cursor-pointer"
                      onChange={handleChange}
                      checked={values.isAttorney}
                    />
                    <label
                      htmlFor=""
                      className="text-[#0B0D0F] text-[16px] ml-3 font-semibold"
                    >
                      I hereby give Power of Attorney to SERS RA GmbH represented by
                      Surin Ersöz for:
                      <span className="text-[#FF4444]">*</span>
                    </label>
                    <ErrorMessage
                      component={"div"}
                      className="text-red-500"
                      name="isAttorney"
                    />
                  </div>
                  <div className="date-signature mt-5 mb-4">
                    <label className="text-[#0B0D0F] font-semibold text-[16px]">
                      Date & signature
                    </label>
                    <div className="mb-2 mt-4 flex justify-between items-center">
                      <label
                        htmlFor={"signature"}
                        className="font-400 text-[14px] text-[#0B0D0F]"
                      >
                        Date & signature (PoA)
                        <span className="text-[#FF4444] ml-1">*</span>
                      </label>
                    </div>
                    <Field name="startDate">
                      {({ field, form }: FieldProps) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateInput
                            {...field}
                            label="YYYY-MM-DD"
                            renderInput={(props: any) => <TextField
                              {...props}
                            />}
                            format="YYYY-MM-DD"
                            value={values.startDate}
                            onChange={(newDate: any) => {
                              form.setFieldValue("startDate", newDate);
                            }}
                            className={
                              "lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full password-input"
                            }
                            disablePast
                          />
                        </LocalizationProvider>
                      )}
                    </Field>
                    <ErrorMessage
                      name="startDate"
                      component="div"
                      className="text-red-600"
                    />

                    <div className="mb-2 mt-4 flex justify-between items-center">
                      <label
                        htmlFor={"signature"}
                        className="font-400 text-[14px] text-[#0B0D0F]"
                      >
                        Signature (PoA)
                        <span className="text-[#FF4444] ml-1">*</span>
                      </label>
                    </div>
                    <div className="signature__pad border-[2px] border-[#E1E5EA] rounded-md lg:w-[550px] lg:h-[138px] w-full">
                      <Field name="signature">
                        {({ field, form }: FieldProps) => (
                          <>
                            <SignaturePad
                              {...field}
                              penColor="black"
                              canvasProps={{
                                style: { width: "100%", height: "100%", backgroundImage: `url(${values.signature})`, backgroundSize: 'cover' },
                              }}
                              ref={sigCanvas}
                              onEnd={async () => {
                                const yourBase64 = sigCanvas?.current
                                  ?.getTrimmedCanvas()
                                  .toDataURL("image/png");
                                setBase64(yourBase64)
                                const signature = await convertbas64Tofile(
                                  yourBase64
                                );
                                form.setFieldValue("signature", signature);
                              }}
                            />
                            <u
                              className="float-right mt-1 mr-2 cursor-pointer text-[#71859A]"
                              onClick={() => {
                                sigCanvas?.current?.clear();
                                form.setFieldValue("signature", "");
                              }}
                            >
                              clear
                            </u>
                          </>
                        )}
                      </Field>
                    </div>
                    <ErrorMessage
                      name="signature"
                      component="div"
                      className="text-red-500"
                    />
                    <div className="text-[#FF4444] text-[13px] mt-5">
                      Please use a touch screen and a pen or your finger to sign the
                      form. Make sure you save your progress [Save Progress] before
                      changing device.
                    </div>
                  </div>
                </div>
              }
            </Form>
          )}
        </Formik>

      </div>
    </>
  );
};

export default AgreementForm;
