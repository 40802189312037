import Api from "./Api";
import { saveStepDataType, userType } from "./types";
class PrestudyService {
  public async getFormData(userId: string | undefined) {
    return await Api.get(`/prestudy?userId=${userId}`);
  }
  public async getStepdata(
    userId: string | undefined,
    step: number | undefined
  ) {
    return await Api.get(`/prestudy/${userId}?step=${step}`);
  }
  public async uploadDocument(payload: FormData | null) {
    return await Api.post(`/prestudy/upload`, payload);
  }
  public async saveStepData(
    userId: string | undefined,
    step: number | undefined,
    payload: saveStepDataType
  ) {
    return await Api.patch(`/prestudy/${userId}?step=${step}`, payload);
  }
  public async sendEmail(email: string) {
    return await Api.post(`/prestudy/send-email`, { email });
  }
  public async sendSms(user: userType) {
    return await Api.post(`/prestudy/send-sms/${user?._id}`, { phoneNumber: user?.phoneNumber });
  }
  public async verifyToken(token: string) {
    return await Api.post(`/prestudy/verify-QR?token=${token}`);
  }
  public async verifyCorrectionToken(token: string) {
    return await Api.post(`/users/verify-prestudy-token?Token=${token}`);
  }
  public async submitCorrection(userId: string) {
    return await Api.post(`/prestudy/submit-correction/${userId}`);
  }
  public async updateFormCorrectionStatus({
    userId,
    role,
    isCorrectionStartedFromLawyer,
    isCorrectionStartedFromStudent
  }: {
    userId: string,
    role: string,
    isCorrectionStartedFromLawyer?: boolean,
    isCorrectionStartedFromStudent?: boolean
  }) {
    return await Api.put(`/prestudy/form-correction-status`, {
      userId,
      role,
      isCorrectionStartedFromLawyer,
      isCorrectionStartedFromStudent
    });
  }
}

const prestudyService = new PrestudyService();
export default prestudyService;
