import Api from "./Api";

class ReadyTocheckService {
  public async getCorrectionStatus(userId: string | null) {
    return await Api.get(`prestudy/correction-status/${userId}`);
  }
  public async updateCorrectionStatus(userId:string,status:string | null){
    return await Api.patch(`prestudy/correction-status/${userId}`,{correction_status :status});
  }
  public async getAllstatus() {
    return await Api.get(`prestudy/get-all-correctionStatus`);
  }
  public async addStatus(status :string| null){
    return await Api.post(`prestudy/add-correctionStatus`,{status_title : status});
  }
  public async search(text:string|null){
    return await Api.get(`prestudy/get-all-correctionStatus?search=${text}`)
  }
  public async getInternalCommunication(userId:string|null,unilawyerCommunication = false){
    return await Api.get(`internalCommunication/${userId}?unilawyerCommunication=${unilawyerCommunication}`);
  }
  public async addInternalComment(userId:string|null,comment:string|null){
    return await Api.post(`internalCommunication/${userId}`,{internalComment : comment});
  }
  public async addInvoiceComment(userId:string|null,comment:string|null){
    return await Api.post(`internalCommunication/${userId}`,{invoiceComment : comment});
  }
  public async checkVED(date:string|null,userId:string|null){
    return await Api.patch(`lawyer/confirm-ved/${userId}`,{ved : date});
  }
  public async getTemplates(templateFor:string){
    return await Api.get(`lawyer/get-all-template-for?templateFor=${templateFor}`)
  }
  public async getEmailTemplateName(templateFor: string, templateName: string){
    return await Api.get(`lawyer/get-template?templateFor=${templateFor}&templateName=${templateName}`)
  }
  public async saveTemplate(payload:any){
    return await Api.post(`lawyer/save-template`,payload);
  }
  public async sendMailwithTemplate(userId:string | undefined,payload:any){
    return await Api.post(`lawyer/send-mail/${userId}`,payload);
  }
}

const readyTocheckService = new ReadyTocheckService();
export default readyTocheckService;
