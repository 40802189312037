import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Modal from './Modal';
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { useAppDispatch, useAppSelector } from 'store/Hooks';
import { setUser, userSelector } from 'store/slices/User';
import { useNavigate } from 'react-router-dom';
import { otpSchema } from 'schema/Auth';
import OTPInput from 'react-otp-input';
import { Button } from 'components/Form';
import authService from 'services/AuthService';
import { toast } from "react-toastify";
import Loader from 'components/Loader';

type verifyAccountModalProps = {
    type: string;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setType: Dispatch<SetStateAction<string>>;
    getStudentData: any;
    student: any;
}
const VerifyAccountModal = ({ type, setOpen, setType, getStudentData, student }: verifyAccountModalProps) => {
    const initialValues: { otp: string } = {
        otp: "",
    };
    const [submit, setIssubmit] = useState(false);
    const [timeDisplay, setTimeDisplay] = useState('');
    const { user } = useAppSelector(userSelector);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleSubmit = async (
        values: { otp: string },
        { setSubmitting, resetForm }: FormikHelpers<{ otp: string }>
    ) => {
        try {
            setSubmitting(true);
            if (user) {
                let response;
                if (type === "email") {
                    response = await authService.verifyEmail({
                        otp: values.otp,
                        userId: user?._id,
                    });
                } else {
                    response = await authService.verifyPhone({
                        otp: values.otp,
                        userId: user?._id,
                    });
                }
                const { message, success, data } = response.data;
                if (success) {
                    resetForm();
                    toast(message, { className: "toast-success" });
                    setSubmitting(false);
                    if (user?.isFirstLogin && !data?.isPhoneNumberVerified) {
                        setType('phone')
                        dispatch(setUser({ ...data, isFirstLogin: false, isAuthenticated: true }))
                    } else {
                        setType('')
                        getStudentData()
                        setOpen(false);
                    }
                }
            }
        } catch (error) {
            setSubmitting(false);
        }
    };

    const resendOtp = async (resetForm?: any) => {
        setLoading(true);
        try {
            if (user) {
                const response = await authService.resendOtp({
                    userId: user?._id,
                    type: type === "email" ? "EMAIL" : "PHONE",
                });
                const { message, success } = response.data;
                if (success) {
                    if (resetForm) {
                        resetForm();
                    }
                    setLoading(false);
                    setIssubmit(true)
                    startTimer();
                    setTimeDisplay('');
                    // setIsResend(false);
                    toast(message, { className: "toast-success" });
                } else {
                    setLoading(false);
                }
            }
        } catch (error) {
            setLoading(false);
            console.log("🚀 ~ resendOtp ~ error:", error);
        }
    };

    const skipVerification = async () => {
        try {
            if (user?.studentId) {
                const response = await authService.skipVerification(user?.studentId);
                const { success } = response.data;
                if (success) {
                    if (user?.isFirstLogin && type === 'email') {
                        setType('phone')
                    } else {
                        setType('')
                        setOpen(false);
                        dispatch(setUser({ ...user, isFirstLogin: false }))
                    }
                    await getStudentData();
                }
            }
        } catch (error) {
            console.log("🚀 ~ skipVerification ~ error:", error)

        }
    }

    const startTimer = () => {
        let countdown: any;
        let timerDuration = 60;
        const updateTimer = () => {
            let minutes = Math.floor(timerDuration / 60);
            let seconds = timerDuration % 60;
            let displayMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
            let displaySeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
            setTimeDisplay(`${displayMinutes}:${displaySeconds}`);
            if (timerDuration <= 0) {
                clearInterval(countdown);
                setTimeDisplay('00:00');
                setIssubmit(false);
            }
            timerDuration--;
        }
        clearInterval(countdown);
        setTimeDisplay('');
        countdown = setInterval(updateTimer, 1000);
    }

    useEffect(() => {
        resendOtp()
    }, [type])

    return (
        <Modal
            title={type === "email" ? "Verify email" : "Verify phone number"}
            handleClick={() => {
                setOpen(false);
            }}
        >
            {loading ? <Loader /> :
                <Formik
                    initialValues={initialValues}
                    validationSchema={otpSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, values, resetForm }: any) => (
                        <Form className="">
                            <div className="flex flex-col justify-between items-center my-5">
                                <Field name="otp">
                                    {({ field, form }: FieldProps) => (
                                        <OTPInput
                                            {...field}
                                            value={values.otp}
                                            onChange={(value: string) => {
                                                form.setFieldValue("otp", value);
                                            }}
                                            numInputs={6}
                                            renderSeparator={<span>-</span>}
                                            renderInput={(props) => (
                                                <input
                                                    {...props}
                                                    className="border border-[#C3CCD5] rounded-lg lg:w-12 lg:h-12 w-10 h-10 text-[1rem] text-center focus:outline-none otp-input"
                                                />
                                            )}
                                        />
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="otp"
                                    component="div"
                                    className="text-red-600 mt-1"
                                />
                            </div>
                            <div className="w-full flex flex-col items-center justify-center">
                                <Button
                                    type="submit"
                                    label={isSubmitting ? " " : "Verify"}
                                    disabled={isSubmitting}
                                    cssClass="bg-[#0A4A5E] text-[#FFFFFF]  h-12 w-48 rounded-lg text-center text-[16px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed box-border"
                                >
                                    {isSubmitting && <div className="loader mx-auto" />}
                                </Button>
                                <div className="text-center mt-5 text-[13px] font-semibold">
                                    Don't receive an otp?{" "}otp exires in <span className={'text-[#E39D6B]'}
                                        id="timer">{timeDisplay}</span>
                                </div>
                                <div className="flex text-[13px] font-semibold justify-around gap-2 md:flex-row flex-col">
                                    <div
                                        className={
                                            !isSubmitting
                                                ? "cursor-pointer text-[#E39D6B] flex gap-1 md:flex-row flex-col md:items-start items-center"
                                                : "cursor-not-allowed opacity-75 text-[#E39D6B] flex gap-1 md:flex-row flex-col md:items-start items-center"
                                        }
                                        onClick={() => {
                                            resendOtp(resetForm)
                                        }}
                                    >
                                        <label
                                            className={submit ? 'text-[#E39D6B] opacity-50 cursor-not-allowed underline' : 'text-[#E39D6B] cursor-pointer underline'}>Resend now</label>
                                        <label className="text-black">or{" "}</label>
                                    </div>
                                    <div
                                        className={
                                            !isSubmitting
                                                ? "cursor-pointer text-[#E39D6B]  flex gap-1 md:flex-row flex-col md:items-start items-center"
                                                : "cursor-not-allowed opacity-75 text-[#E39D6B]  flex gap-1 md:flex-row flex-col md:items-start items-center"
                                        }
                                        onClick={() => {
                                            navigate("/profile");
                                            dispatch(setUser({ ...user, isFirstLogin: false }))
                                        }
                                        }
                                    >
                                        <label className="underline md:text-left text-center cursor-pointer">
                                            update your{" "}
                                            {type === "email" ? `email address (${user?.email})` : `phone number (${user?.phoneNumber})`}
                                        </label>
                                        <label className="text-black">or{" "}</label>
                                    </div>
                                    <div
                                        className={
                                            !isSubmitting
                                                ? "cursor-pointer text-[#E39D6B] flex gap-1 md:flex-row flex-col md:items-start items-center"
                                                : "cursor-not-allowed opacity-75 text-[#E39D6B] flex gap-1 md:flex-row flex-col md:items-start items-center"
                                        }
                                        onClick={() => {
                                            skipVerification()
                                        }}
                                    >
                                        <label className="underline cursor-pointer">

                                            skip for now</label>
                                    </div>
                                </div>

                            </div>
                        </Form>
                    )}
                </Formik>
            }
        </Modal>
    )
}

export default VerifyAccountModal