import {
  appointment,
  clipboardActive,
  clipbord,
  faq,
  faqActive,
  formActive,
  home,
  homeActive,
  list,
  listActive,
  profile,
  profileActive,
  setting,
  settingActive,
} from "utils/Images";

export const sideMenuItems = [
  {
    icon: home,
    activeIcon: homeActive,
    name: "Dashboard",
    route: "/dashboard",
    childRoutes: null,
  },
  {
    icon: profile,
    activeIcon: profileActive,
    name: "Profile",
    route: "/profile",
    childRoutes: null,
  },
  {
    icon: clipbord,
    activeIcon: formActive,
    name: "Form 1",
    route: "/pre-study",
    childRoutes: null,
  },
  {
    icon: faq,
    activeIcon: faqActive,
    name: "FAQ",
    route: "/faq",
    childRoutes: null,
  },
];

export const universitySideMenuItems = [
  {
    icon: home,
    activeIcon: homeActive,
    name: "Dashboard",
    route: "/university-dashboard",
    childRoutes: null,
  },
  {
    icon: profile,
    activeIcon: profileActive,
    name: "Open",
    route: "/approval-open",
    showCount: true,
    childRoutes: ["/review-form"],
  },
  {
    icon: clipbord,
    activeIcon: clipboardActive,
    name: "Documents",
    route: "/approval-document",
    showCount: true,
    childRoutes: ["/send-International-document", "/send-financial-document"],
  },
  {
    icon: setting,
    activeIcon: settingActive,
    name: "Active Services",
    route: "/active-services",
    showCount: true,
    childRoutes: ["/overview"],
  },
];

export const lawyerSidemenuItems = [
  {
    icon: home,
    activeIcon: homeActive,
    name: "Dashboard",
    route: "/lawyer-dashboard",
    childRoutes: null,
  },
  {
    icon: list,
    activeIcon: listActive,
    name: "List",
    route: "/service-listing",
    childRoutes: ["/correction"],
  },
  {
    icon: appointment,
    activeIcon: appointment,
    name: "Appointment",
    route: "/appointment",
    childRoutes: null,
  },
];

export const options = [
  {
    id: "1",
    name: "visa",
    value: "german-entry-visa",
    label:
      "German Study Entry Visa (issued by the German Embassy in your home country)",
  },
  {
    id: "2",
    name: "visa",
    value: "free-entry-visa",
    label: "90-days free entry allowance",
  },
];

export const addressOptions = [
  {
    value: "No",
    label: "No",
  },
  {
    value: "Yes",
    label: "Yes",
  }
];

export const visaOptions = [
  {
    value: "You need an entry visa",
    label: "You need an entry visa",
  },
  {
    value: "You DO NOT need an entry visa",
    label: "You DO NOT need an entry visa",
  },
  {
    value: "You have no restriction to be on the German territory",
    label: "You have no restriction to be on the German territory",
  },
];

export const statusOptions = [
  {
    value: "Study Entry Visa (issued by the Embassy in your home country)",
    label: "Study Entry Visa (issued by the Embassy in your home country)",
  },
  {
    value: "90-days free entry allowance",
    label: "90-days free entry allowance",
  },
  {
    value:
      "Residence permit for study preparation (Visa already extended with the immigration office IN Germany)",
    label:
      "Residence permit for study preparation (Visa already extended with the immigration office IN Germany)",
  },
  {
    value:
      "Residence permit for study (Visa already extended with the immigration office IN Germany)",
    label:
      "Residence permit for study (Visa already extended with the immigration office IN Germany)",
  },
  {
    value:
      "Fictional Certificate (issued by the immigration office in Germany)",
    label:
      "Fictional Certificate (issued by the immigration office in Germany)",
  },
  {
    value: "Other immigration status",
    label: "Other immigration status",
  },
];

export const genderOptions = [
  { value: "", label: "Select Gender" },
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Unknown", label: "Unknown" },
];

export const cityOptions = [
  { value: "", label: "Select city" },
  { value: "Berlin", label: "Berlin" },
  { value: "Potsdam", label: "Potsdam" },
  { value: "Other", label: "Other" },
];

export const zipcodeOption = [
  { value: "", label: "Select Code" },
  { value: "option1", label: "Option 1" },
  { value: "option2", label: "Option 2" },
  { value: "option3", label: "Option 3" },
];

export const documentTypeOptions = [
  { value: "", label: "Select Type" },
  { value: "Passport", label: "Passport" },
  { value: "ID", label: "ID" },
];

export const documentTypeNumbers = [
  { value: "123", label: "123" },
  { value: "456", label: "456" },
  { value: "789", label: "789" },
];

export const maritalOptions = [
  { value: "Single", label: "Single" },
  { value: "Married", label: "Married" },
  { value: "Divorced", label: "Divorced" },
  { value: "Widowed", label: "Widowed" },
];

export const VEDcontextMenu = [
  {
    id: "attributes",
    value: "Change Attributes",
    hasSubmenu: true,
  },
  {
    id: "invoice",
    value: "Add info invoice",
    hasSubmenu: false,
    route: '/add-info-invoice'
  },
  {
    id: "VED",
    value: "Check VED",
    hasSubmenu: false,
    route: '/check-ved'
  },
  {
    id: "call",
    value: "",
    hasSubmenu: false,
    route: "/phone-call"
  },
  {
    id: "email",
    value: "Send Email",
    hasSubmenu: true,
    route: "/send-email"
  },
];

export const changeAttributesMenu = [
  {
    id: "studentId",
    value: "Q1231234",
  },
  {
    id: "status",
    value: "Status",
  },
  {
    id: "proceeds",
    value: "Proceeds",
  },
  {
    id: "correctionStatus",
    value: "Correction Status",
  },
  {
    id: "city",
    value: "City",
  },
];

export const reminderMenuItems = [
  ...VEDcontextMenu,
  {
    id: "reminderOpen",
    value: "Send Reminder",
    hasSubmenu: true,
  },
];

export const readyMenuItems = [
  ...VEDcontextMenu,
  {
    id: "correct",
    value: "Correct",
    hasSubmenu: false,
    route: '/correction'
  },
  {
    id: "uploadDocs",
    value: "Upload Docs",
    hasSubmenu: true,
  },
];

export const openMenuItems = [
  ...VEDcontextMenu,
  {
    id: "reminderOpen",
    value: "Send Reminder",
    hasSubmenu: true,
  },
  {
    id: "uniDocs",
    value: "Send uni docs reminder",
    hasSubmenu: true,
  }
]

export const waitingCorrectionsMenuItems = [
  ...VEDcontextMenu,
  {
    id: "reminderOpen",
    value: "Send Reminder",
    hasSubmenu: true,
  },
  {
    id: "correct",
    value: "Correct",
    hasSubmenu: false,
    route: '/correction'
  },
  {
    id: "uploadDocs",
    value: "Upload Docs",
    hasSubmenu: true,
  },
]

export const readyForleaOptions = [
  ...VEDcontextMenu,
  {
    id: "sendToLea",
    value: "Send file to LEA",
    hasSubmenu: false,
    route: "/send-to-lea"
  },
  {
    id: "correct",
    value: "Correct",
    hasSubmenu: false,
    route: '/correction'
  },
  {
    id: "uploadDocs",
    value: "Upload Docs",
    hasSubmenu: true,
  },
]

export const invitedOptions = [
  ...VEDcontextMenu,
  {
    id: "approvalStudent",
    value: "Send approval Student",
    hasSubmenu: false,
    route: "/approval-to-student"
  },
  {
    id: "addhsnumber",
    value: "Add HS number",
    hasSubmenu: false,
    route: "/add-hs-number"
  },
  {
    id: "sendhsnumber",
    value: "Send HS Student",
    hasSubmenu: false,
    route: "/send-hs-number"
  },
  {
    id: 'sendFingerPrintAppointment',
    value: "Send fingerprint appointment",
    hasSubmenu: false,
    route: "/send-fingerprint-appointment"
  }

]

export const changeAppointmentStatusMenu = [
  {
    id: "COMING",
    value: "Coming",
  },
  {
    id: "NOT_COMING",
    value: "Not Coming",
  },
  {
    id: "NOT_CONFIRMED",
    value: "Not Confirmed",
  },
];
export const atleaOptions = [
  ...VEDcontextMenu,
  {
    id: "approvalStudent",
    value: "Send approval Student",
    hasSubmenu: false,
    route: "/approval-to-student"
  },
  {
    id: "addhsnumber",
    value: "Add HS number",
    hasSubmenu: false,
    route: "/add-hs-number"
  },
  {
    id: "sendhsnumber",
    value: "Send HS Student",
    hasSubmenu: false,
    route: "/send-hs-number"
  },
  {
    id: 'sendFingerPrintAppointment',
    value: "Send fingerprint appointment",
    hasSubmenu: false,
    route: "/send-fingerprint-appointment"
  }
]
export const docs =
  [
    {
      step: 0,
      fileName: 'signature'
    },
    {
      step: 1,
      fileName: "addressFile"
    },
    {
      step: 2,
      fileName: "file"
    },
    {
      step: 3,
      fileName: "stampFile"
    },
    {
      step: 5,
      fileName: "passportFile"
    },
    {
      step: 6,
      fileName: "healthInsuranceFile"
    },
    {
      step: 6,
      fileName: "matriculationFile"
    },
    {
      step: 6,
      fileName: "subsistenceFile"
    },

    {
      step: 9,
      fileName: "finalSignature"
    },
  ]

export const otherOptions = [
  {
    id: "attributes",
    value: "Change Attributes",
    hasSubmenu: true,
  },
  {
    id: "invoice",
    value: "Add info invoice",
    hasSubmenu: false,
    route: '/add-info-invoice'
  },
  {
    id: "email",
    value: "Send Email",
    hasSubmenu: true,
    route: "/send-email"
  },
  {
    id: "reminderOpen",
    value: "Send Reminder",
    hasSubmenu: true,
  },
] 

export const authRoutes = [
  "/login",
  "/forgot-password",
  "/auth/reset-password",
  "/register",
  "/verify-email",
  "/verify-phone"
];
export const studentRoutes = ["/", "/dashboard", "/pre-study", "/faq", "/profile"];
export const universityRoutes = ["/", "/university-dashboard", "/send-International-document", "/send-financial-document", "/overview", "/approval-open", "/approval-document", "/review-form", "/review-form", "/active-services", "/profile"]
export const lawyerRoutes = ["/", "/correction-list", "/send-fingerprint-appointment", "/send-hs-number", "/add-hs-number", "/approval-to-student", "/send-to-lea", "/phone-call", "/send-uni-doc-reminder", "/send-reminder", "/send-email", "/check-ved", "/add-info-invoice", "/correction", "/appointment", "/service-listing", "/lawyer-dashboard", "/profile", "/crop-organize"]
export const cityoptions = [
  { value: "Berlin", label: "Berlin" },
  { value: "Potsdam", label: "Potsdam" },
];

export const processOptions = [
  { value: "pre-study", label: "Study" },
  { value: "post-study", label: "Post-Study" },
];

export const labels = [
  { value: "OPEN", label: "Open" },
  { value: "READY_TO_CHECK", label: "Ready	to check" },
  { value: "WAITING_FOR_CORRECTION", label: "Waiting for correction" },
  { value: "CORRECTION_READY", label: "Correction ready" },
  { value: "READY_FOR_LEA", label: "Ready for LEA" },
  { value: "AT_LEA", label: "At LEA" },
  { value: "INVITED", label: "Invited" },
  { value: "READY_FOR_INVOICE", label: "Ready for invoice" },
  { value: "INVOICED", label: "Invoiced" }
];

export const statusLables = [
  { value: "INACTIVE", label: "Inactive" },
  { value: "INPROGRESS", label: "In progress" },
  { value: "SUBMITTED", label: "Submitted" },
  { value: "COMPLETED", label: "Completed" },
  { value: "INVOICED", label: "Invoiced" },
  { value: "OUT", label: "Out" },
  { value: "REJECTED", label: "Rejected" }
];
export const statusList = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
  { value: "Rejected", label: "Rejected" },
];

export const uniList = [
  { value: "Berlin", label: "Berlin" },
  { value: "Potsdam", label: "Potsdam" },
];

export const studentTypes = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Unknown", label: "Unknown" },
];


export const servicesData = [
  { value: "OPEN", label: "Open" },
  { value: "READY_TO_CHECK", label: "Ready to check" },
  { value: "WAITING_FOR_CORRECTION", label: "Waiting for correction" },
  { value: "CORRECTION_READY", label: "Correction ready" },
  { value: "READY_FOR_LEA", label: "Ready for LEA" },
  { value: "AT_LEA", label: "At LEA" },
  { value: "INVITED", label: "Invited" },
  { value: "READY_FOR_INVOICE", label: "Ready for invoice" },
  { value: "INVOICED", label: "invoiced" },
  { value: "Out", label: "out" },
];
