import { Box, LinearProgress, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Inputfile } from 'components/Form';
import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/Hooks';
import { formCorrectionSelector } from 'store/slices/FormCorrection';
import { prestudySelector } from 'store/slices/Prestudy';
import { userSelector } from 'store/slices/User';
import { validateFiles } from 'utils/Helper';
import { crossBlack, documentBlack } from 'utils/Images';
import { setFeatureLoaded } from "store/slices/Prestudy";

const RpStudypreparation: React.FC<any> = ({ values, uploadDocument, userType, setModal, setField, field, modal, showComments, studentData, highlightedIndex, setHighlightedIndex, getCount }) => {
    console.log("🚀 ~ studentData:", studentData)
    const { currentStep } =
        useAppSelector(prestudySelector);
    const { currentStep: lawyercurentStep } =
        useAppSelector(formCorrectionSelector);

    const { user } = useAppSelector(userSelector);
    const DateInput = DatePicker as any;
    const [progress, setProgress] = React.useState(0);
    const [uploading, setUploading] = useState(false);
    const intervalIdRef = useRef<any>();
    const [fileError, setfileError] = useState<string | null>(null);
    const [files, setFiles] = useState<File[] | null>(null);

    useEffect(() => {
        if (progress > 100) {
            setUploading(false);
            setProgress(0);
            clearInterval(intervalIdRef?.current);
        }
    }, [progress]);
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (progress > 0) {
            dispatch(setFeatureLoaded(true));
        } else {
            dispatch(setFeatureLoaded(false));
        }
    }, [progress]);

    useEffect(() => {
        return () => {
            clearInterval(intervalIdRef.current);
        };
    }, []);

    const uploadFiles = async (selectedFile: FileList | null, form: any) => {
        const allowedTypes = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf",
        ];
        const isValidate = await validateFiles(selectedFile, allowedTypes);
        if (isValidate === "true") {
            if (selectedFile) {
                const newFiles = Array.from(selectedFile).filter(
                    (newFile) =>
                        !files ||
                        !files.some((existingFile) => existingFile.name === newFile.name)
                );
                const updatedFiles = files ? [...files, ...newFiles] : newFiles;
                form.setFieldValue("rpExpireFile", updatedFiles);
                setFiles(updatedFiles);
                const formData: any = new FormData();
                updatedFiles?.forEach((file: any) => {
                    formData.append("rpExpireFile", file);
                });
                if (userType === 'lawyer') {
                    lawyercurentStep && formData.append("step", (lawyercurentStep - 1).toString());
                    studentData && formData.append("userId", studentData?._id);
                } else {
                    user && formData.append("userId", user?._id);
                    currentStep && formData.append("step", (currentStep - 1).toString());
                }
                formData.append("isRequired", true);
                formData.append(
                    "label",
                    "Upload a copy of your residence permit"
                );
                await uploadDocument(formData);
            } else {
                setFiles(null);
            }
        }
    };

    const fieldRefs = useRef<any>({});

    useEffect(() => {
        if (fieldRefs.current[highlightedIndex]) {
            fieldRefs.current[highlightedIndex].focus();

            const container = document.getElementById('container');

            // Scroll the container to make 'fieldRefs.current[highlightedIndex]' visible
            fieldRefs.current[highlightedIndex].scrollIntoView({
                behavior: 'smooth',  // Optional: Smooth scrolling
                block: 'nearest',    // Optional: Adjust where element aligns
                inline: 'start'      // Optional: Adjust where element aligns
            });
        }
    }, [fieldRefs, highlightedIndex]);

    return (
        <div className={`resident-permit mt-3`}>
            <div className='context bg-[#F6F6F6] p-3 text-[#0B0D0F] text-[14px] font-semibold'>
                <h1>Residence permit for study preparation (Aufenthaltserlaubnis wegen Studienvorbereitung):</h1>
                <p className='my-3'>
                    In order to prepare your studies (for ex. language course) and stay here for a longer time you will have to change your visa into a residence permit once you arrive in Germany. This is done at the Foreigner's Office (Ausländerbehörde). In case you have got already your residence permit for your study preparation, choose this option.
                </p>
            </div>
            <div className={`block mt-3 p-2 ${highlightedIndex === 'option3Field1' ? 'border-2 !border-green-300 rounded-md' : ' border-transparent'}`}>
                <div className="mb-2 mt-4 flex justify-start gap-2 items-center">
                    {userType === 'lawyer' &&
                        <input
                            type="checkbox"
                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] "
                            onClick={(e: any) => {
                                setModal(e.target.checked);
                                setField('rpExpireDate')
                            }}
                            checked={modal && field === 'rpExpireDate'}
                        />}
                    <div className='flex items-center gap-2'>
                        <label
                            htmlFor={"rpExpireDate"}
                            className="text-[#0B0D0F] font-semibold text-[16px]" ref={(el: any) => { fieldRefs.current['option3Field1'] = el }}
                        >
                            When will your residence permit expire?
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount('rpExpireDate') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('rpExpireDate')}
                            </div>
                        }

                    </div>
                </div>
                <Field name="rpExpireDate"
                    ref={(el: any) => { fieldRefs.current['option3Field1'] = el }}
                    onFocus={() => setHighlightedIndex('option3Field1')}>
                    {({ field, form }: FieldProps) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateInput
                                {...field}
                                label="YYYY-MM-DD"
                                renderInput={(props: any) => <TextField {...props} />}
                                format="YYYY-MM-DD"
                                value={values.rpExpireDate}
                                onChange={(newDate: any) => {
                                    form.setFieldValue("rpExpireDate", newDate);
                                }}
                                className={
                                    "lg:w-1/2 w-full py-0 border-[2px] border-[#E1E5EA] rounded-[12px]"
                                }
                            />
                        </LocalizationProvider>
                    )}
                </Field>
                <ErrorMessage
                    name="rpExpireDate"
                    component="div"
                    className="text-red-600"
                />
                {userType === 'student' ?
                    showComments('rpExpireDate') &&
                    <CommentSection field={'rpExpireDate'} step={currentStep - 1} userType="student" />
                    : <>
                    </>
                }
            </div>
            <div className={`p-2 border-2 ${highlightedIndex === 'option3Field2' ? 'border-2 !border-green-300 rounded-md' : ' border-transparent'}`}>
                <div className='block mt-3'>
                    <div className="mb-2 mt-4 flex justify-start gap-2 items-center">
                        {userType === 'lawyer' &&
                            <input
                                type="checkbox"
                                className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] "
                                onClick={(e: any) => {
                                    setModal(e.target.checked);
                                    setField('rpExpireFile')
                                }}
                                checked={modal && field === 'rpExpireFile'}
                            />}
                        <div className='flex items-center gap-2'>
                            <label
                                htmlFor={"rpExpireFile"}
                                className="text-[#0B0D0F] font-semibold text-[16px]" ref={(el: any) => { fieldRefs.current['option3Field2'] = el }}
                            >
                                Upload a copy of your residence permit
                                <span className="text-[#FF4444] ml-1">*</span>
                            </label>
                            {getCount && getCount('rpExpireFile') > 0 &&
                                <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                    {getCount('rpExpireFile')}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <Field name='rpExpireFile'
                    ref={(el: any) => { fieldRefs.current['option3Field2'] = el }}
                    onFocus={() => setHighlightedIndex('option3Field2')}>
                    {({ field, form }: FieldProps) => (
                        <>
                            <Inputfile
                                {...field}
                                mainClass="lg:w-1/2 xl:w-1/2 w-full"
                                btnClass={null}
                                handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setProgress(0);
                                    setUploading(true);
                                    setfileError(null);
                                    intervalIdRef.current = setInterval(() => {
                                        setProgress((prevProgress) => prevProgress + 10);
                                    }, 500);
                                    uploadFiles(event.target.files, form);
                                }}
                                multiple={true}
                            />
                            {values.rpExpireFile &&
                                values.rpExpireFile.length > 0 &&
                                values.rpExpireFile.map(
                                    (item: { name: string; location: string }) => (
                                        <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                            <img src={documentBlack} alt="documentBlack" />
                                            <label className="flex-1">{item.name}</label>
                                            <img
                                                src={crossBlack}
                                                alt="documentBlack"
                                                onClick={async () => {
                                                    const updatedFiles = values.rpExpireFile?.filter(
                                                        (val: { name: string; location: string }) =>
                                                            val.name !== item.name
                                                    );
                                                    form.setFieldValue("rpExpireFile", updatedFiles);
                                                    const formData: any = new FormData();
                                                    updatedFiles?.forEach((file: any) => {
                                                        formData.append("rpExpireFile", file);
                                                    });
                                                    if (userType === 'lawyer') {
                                                        lawyercurentStep && formData.append("step", (lawyercurentStep - 1).toString());
                                                        studentData && formData.append("userId", studentData?._id);
                                                    } else {
                                                        user && formData.append("userId", user?._id);
                                                        currentStep && formData.append("step", (currentStep - 1).toString());
                                                    }
                                                    formData.append("isRequired", true);
                                                    formData.append(
                                                        "label",
                                                        "Upload a copy of your residence permit"
                                                    );
                                                    await uploadDocument(formData);
                                                    setFiles(updatedFiles);
                                                    if (updatedFiles.length === 0) {
                                                        form.setFieldValue("rpExpireFile", null);
                                                        setFiles(null);
                                                    }
                                                }}
                                                className="cursor-pointer"
                                            />
                                        </div>
                                    )
                                )}
                            {uploading && (
                                <Box sx={{ width: "100%" }}>
                                    <LinearProgress variant="determinate" value={progress} />
                                </Box>
                            )}
                        </>
                    )}
                </Field>
                {fileError ? (
                    <div className="text-red-500 text-sm md:text-base fileError">
                        {fileError}
                    </div>
                ) : (
                    <ErrorMessage
                        name="rpExpireFile"
                        component="div"
                        className="text-red-600 fileError"
                    />
                )}
            </div>
            {userType === 'student' ?
                showComments('rpExpireFile') &&
                <CommentSection field={'rpExpireFile'} step={currentStep - 1} userType="student" />
                : <></>
            }
        </div>
    )
}

export default RpStudypreparation