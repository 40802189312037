import { ReactNode, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "store/Hooks";
import { setSidebar, userSelector } from "store/slices/User";
import Sidebar from "./Sidebar";
import LogoutModal from "components/Modals/LogoutModal";
import Header from "./Header";
import { useLocation } from "react-router-dom";

type LayoutProps = {
  children: ReactNode;
  cssClass?: string;
  currDiv? : string
  setCurrDiv?: any
};

const Layout: React.FC<LayoutProps> = ({ children, cssClass,currDiv,setCurrDiv }) => {
  const { sidebarOpen } = useAppSelector(userSelector);
  const [modal, setModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const toggleSidebar = (): void => {
    dispatch(setSidebar(!sidebarOpen));
  };

  useEffect(() => {
    const handleResize = () => {
      dispatch(setSidebar(window.innerWidth > 1024));
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const location = useLocation();

  return (
    <div className="layout">
      <Header
        toggleSidebar={toggleSidebar}
        setModal={setModal}
        modal={modal}
        applyPadding={location.pathname === "/correction" ? false : true}
        setCurrDiv={setCurrDiv}
      />
      <div className="flex overflow-x-hidden">
        <div
          className={`bg-white min-h-screen text-gray-300 w-60 space-y-6 py-7 fixed left-0 transform transition duration-500 ease-in-out z-[999999] border-r-[1px] border-solid border-r-[#E1E5EA] ${
            sidebarOpen ? "w-60 translate-x-0" : "lg:w-16 -translate-x-full"
          }
          lg:translate-x-0 `}
          onClick={() => {
            setCurrDiv && setCurrDiv('');
           }}
          id="sidebar"
        >
          <Sidebar />
        </div>
        <div
          className={`sub-layout bg-[#E1E5EA] flex-1 min-h-screen w-full h-full relative ${currDiv ? currDiv==='stepsDiv' ? 'border-green-500 p-2 rounded-md lg:border-2 ' : 'border-transparent p-2 rounded-md lg:border-2 '  : ''} ${
            sidebarOpen ? "lg:ml-60" : "lg:ml-16"
          }`}
          onClick={() => {
           setCurrDiv && setCurrDiv('stepsDiv');
          }}
        >
          <div
            className={`overlay ${
              sidebarOpen ? "block lg:hidden" : "hidden"
            } bg-black absolute top-0 right-0 left-0 w-full h-full z-50 opacity-50`}
          ></div>
          {children}
        </div>
      </div>
      {modal && <LogoutModal setModal={setModal} />}
    </div>
  );
};

export default Layout;
