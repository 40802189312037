import { Button } from "components/Form";
import React, { Dispatch, SetStateAction } from "react";
import { cross } from "utils/Images";

interface ModalProps {
  title: string;
  children?: React.ReactNode;
  classname?: string;
  handleClick?:any
}

const Modal = ({ title, children, classname,handleClick }: ModalProps) => {
  return (
    <div
      className={`fixed inset-0 z-[999999] flex items-center justify-center bg-black bg-opacity-50 ${classname}`}
    >
      <div className="bg-white rounded-xl max-w-full w-[450px] md:w-[615px]  border-[2px] border-[#C3CCD5] lg:mx-0 mx-2 lg:mt-0  py-5">
        <div className="modal-heading flex items-center justify-between my-3 px-5">
          <h2 className="text-base md:text-[24px] font-bold text-[#0B0D0F] w-fit">
            {title}
          </h2>
          <img
            src={cross}
            alt="cross"
            className="float-right cursor-pointer w-[35px] md:w-[40px]"
            onClick={handleClick}
          />
        </div>
        <hr />
        <div className="my-2 flex flex-col px-5">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
