export const convertbas64Tofile = (data: string) => {
  console.log("🚀 ~ convertbas64Tofile ~ data:", data,typeof data)
  if (data ) {
    let arr: string[] = data.split(","),
      mime = (arr[0]?.match(/:(.*?);/) ?? [])[1] || "defaultMime",
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const signature = new File([u8arr], "signature", {
      type: mime,
    });
    return signature;
  }
};

export const validateFiles = (
  files: FileList | null | File[],
  allowedTypes: string[]
) => {
  const maxSizeMB = 20;
  const maxFiles = 20;
  const isValidType =
    files &&
    Array.from(files).every((file: File) => allowedTypes.includes(file.type));
  const isValidSize =
    files &&
    Array.from(files).every(
      (file: File) => file.size / 1024 / 1024 <= maxSizeMB
    );
  const isValidNumberOfFiles = files && files.length <= maxFiles;
  if (!isValidType) {
    return "Please upload only jpg,jpeg,pdf or png files";
  } else if (!isValidSize) {
    return "File size exceeds the maximum limit of 5 MB";
  } else if (!isValidNumberOfFiles) {
    return "Please upload maximum 20 files at a time";
  } else {
    return "true";
  }
};

export const cleanAndLogout = async () => {
  await localStorage.removeItem("token");
  await localStorage.removeItem("fcmToken");
};

export const convertToUTCFormat = (dateString: any) => {
  // Parse the date string in the format "DD/MM/YYYY, HH:mm:ss"
  const [datePart, timePart] = dateString.split(', ');
  const [day, month, year] = datePart.split('/').map(Number);
  const [hours, minutes, seconds] = timePart.split(':').map(Number);

  // Create a new Date object using the parsed values (UTC)
  const date = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

  // Format the date to the required ISO string format
  const utcDateString = date.toISOString();

  return utcDateString;
};

export const getDifferenceInMinutes = (dateAdd:any, dateSave: any) => {
  const diffInMs = dateSave - dateAdd; // Difference in milliseconds
  const diffInMinutes = diffInMs / (1000 * 60); // Convert milliseconds to minutes
  return diffInMinutes;
};

export const generalDateFormat = (date: Date) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: timeZone,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });

  const formattedDate = formatter.format(date);
  const parts = formattedDate.split(', ');
  const [datePart, timePart] = parts;
  const [month, day, year] = datePart.split('/');
  const [hour, minute, second] = timePart.split(':');
  const formattedDateTime = `${day}/${month}/${year}, ${hour}:${minute}:${second}`;

  return formattedDateTime;
};

  // Function to parse dateString1 into a Date object
  export const parseDate1 = (dateString: string) => {
    // Split the dateString into its components
      const [dayMonthYear, timePeriod] = dateString.split(', ');
      const [year,month,day] = dayMonthYear.split('-');
      const [time, period] = timePeriod.split(' ');
    
      // Convert 12-hour time to 24-hour time if necessary
      let [hours, minutes, seconds] = time.split(':');
      if (period === "PM" && hours !== "12") {
        hours = String(Number(hours) + 12); // Convert PM hours to 24-hour format
      } else if (period === "AM" && hours === "12") {
        hours = "00"; // Convert 12 AM to 00 hours
      }
    
      // Create a new Date object with parsed components
      const date = new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
    
      return date;
  };


  export const formatSeconds = (seconds: number): string => {
    if (seconds < 60) {
      return `${seconds.toFixed(0)} s`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes} m ${remainingSeconds > 0 ? `${remainingSeconds.toFixed(0)} s`: ''}`;
    } else {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      return `${hours} h ${minutes > 0 ? `${minutes.toFixed(0)} m`: ''}`;
    }
  };

  export const localIsoString = (time: number | Date | string) => {
    var tzoffset = (new Date()).getTimezoneOffset() * 60000;
    var localISOTime = (new Date(new Date(time).getTime() - tzoffset)).toISOString().slice(0, -1);

    return localISOTime
  }

