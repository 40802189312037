import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Input, RadioGroup } from 'components/Form';
import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { ChangeEvent } from 'react'
import { CountryDropdown } from 'react-country-region-selector';
import { useAppSelector } from 'store/Hooks';
import { prestudySelector } from 'store/slices/Prestudy';
import { addressOptions } from 'utils/data';

interface FatherInfoCompProps {
    values: any;
    modal?: boolean;
    setModal?: any;
    field?: string;
    setField?: any;
    showField?: (args: string) => boolean;
    showComments?: (args: string) => boolean;
    handleChange: any;
    userType?: boolean;
    getCount?: (args: string) => number;
}
const MotherInfoComp: React.FC<FatherInfoCompProps> = ({ values, showField, showComments, handleChange, userType, modal, setModal, field, setField, getCount }) => {
    const { currentStep } = useAppSelector(prestudySelector);
    const DateInput = DatePicker as any;
    const CountrySelect = CountryDropdown as any;

    return (
        <div className={`MotherInfoComp  px-4 mb-3`}>
            <div className='flex flex-col lg:flex-row gap-2'>
                <div className={!showField ? "block" : showField('motherLname') ? "block" : 'hidden'}>
                    <div className="flex items-center my-3">
                        <label
                            htmlFor={"motherLname"}
                            className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('motherLname')
                                    }}
                                    checked={modal && field === 'motherLname'}
                                />
                            }

                            Mother - Lastname
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount('motherLname') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('motherLname')}
                            </div>
                        }

                    </div>
                    <Input
                        type="text"
                        fieldName="motherLname"
                        setValue={values.motherLname}
                        className={null}
                        handleChange={handleChange}
                    />
                    {showComments && showComments('motherLname') &&
                        <CommentSection field={'motherLname'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className={!showField ? "block" : showField('motherFname') ? "block" : 'hidden'}>
                    <div className="flex items-center my-3">
                        <label
                            htmlFor={"motherFname"}
                            className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('motherFname')
                                    }}
                                    checked={modal && field === 'motherFname'}
                                />
                            }
                            Mother - First name
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount('motherFname') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('motherFname')}
                            </div>
                        }
                    </div>
                    <Input
                        type="text"
                        fieldName="motherFname"
                        setValue={values.motherFname}
                        className={null}
                        handleChange={handleChange}
                    />
                    {showComments && showComments('motherFname') &&
                        <CommentSection field={'motherFname'} step={currentStep - 1} userType="student" />
                    }
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                <div className={!showField ? "block" : showField('motherInfo') ? "block" : 'hidden'}>
                    <div className="flex items-center mb-2">
                        <label
                            htmlFor={"motherInfo"}
                            className="font-semibold text-[16px]  text-[#0B0D0F]"
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('motherInfo')
                                    }}
                                    checked={modal && field === 'motherInfo'}
                                />
                            }
                            Do you have information about her?
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount('motherInfo') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('motherInfo')}
                            </div>
                        }

                    </div>
                    <Field name="motherInfo">
                        {({ field, form }: FieldProps) => (
                            <div className="flex flex-col gap-3 justify-center">
                                <RadioGroup
                                    {...field}
                                    fieldName="motherInfo"
                                    options={addressOptions}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        form.setFieldValue("motherInfo", event.target.value);
                                    }}
                                    className={
                                        "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                                    }
                                    checkedField={values.motherInfo}
                                />
                            </div>
                        )}
                    </Field>
                    <ErrorMessage
                        name="motherInfo"
                        component="div"
                        className="text-red-500"
                    />
                    {showComments && showComments('motherInfo') &&
                        <CommentSection field={'motherInfo'} step={currentStep - 1} userType="student" />
                    }
                </div>
                {values.motherInfo === "Yes" &&
                    <div className='flex flex-col gap-2'>
                        <div className='flex lg:flex-row flex-col gap-2'>
                            <div className={!showField ? "block" : showField('motherDob') ? "block" : 'hidden'}>
                                <div className="flex items-center my-3">
                                    <label
                                        htmlFor={"motherDob"}
                                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                                    >
                                        {userType &&
                                            <input
                                                type="checkbox"
                                                className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                                onClick={(e: any) => {
                                                    setModal(e.target.checked);
                                                    setField('motherDob')
                                                }}
                                                checked={modal && field === 'motherDob'}
                                            />
                                        }
                                        Mother - Date of birth(YYYY-MM-DD)
                                        <span className="text-[#FF4444] ml-1">*</span>
                                    </label>
                                    {getCount && getCount('motherDob') > 0 &&
                                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                            {getCount('motherDob')}
                                        </div>
                                    }
                                </div>
                                <Field name="motherDob">
                                    {({ field, form }: FieldProps) => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateInput
                                                {...field}
                                                label="YYYY-MM-DD"
                                                renderInput={(props: any) => (
                                                    <TextField {...props} />
                                                )}
                                                format="YYYY-MM-DD"
                                                value={values.motherDob}
                                                onChange={(newDate: any) => {
                                                    form.setFieldValue("motherDob", newDate);
                                                }}
                                                className={
                                                    "password-input"
                                                }
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="motherDob"
                                    component="div"
                                    className="text-red-600"
                                />
                                {showComments && showComments('motherDob') &&
                                    <CommentSection field={'motherDob'} step={currentStep - 1} userType="student" />
                                }
                            </div>
                            <div className={!showField ? "block" : showField('motherBirthplcae') ? "block" : 'hidden'}>
                                <div className="flex items-center my-3">
                                    <label
                                        htmlFor={"motherBirthplcae"}
                                        className="font-400 text-[14px] text-[#0B0D0F]"
                                    >
                                        {userType &&
                                            <input
                                                type="checkbox"
                                                className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                                onClick={(e: any) => {
                                                    setModal(e.target.checked);
                                                    setField('motherBirthplcae')
                                                }}
                                                checked={modal && field === 'motherBirthplcae'}
                                            />
                                        }
                                        Mother - Place of birth
                                        <span className="text-[#FF4444] ml-1">*</span>
                                    </label>
                                    {getCount && getCount('motherBirthplcae') > 0 &&
                                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                            {getCount('motherBirthplcae')}
                                        </div>
                                    }
                                </div>
                                <Input
                                    type="text"
                                    fieldName="motherBirthplcae"
                                    setValue={values.motherBirthplcae}
                                    className={null}
                                    handleChange={handleChange}
                                />
                                {showComments && showComments('motherBirthplcae') &&
                                    <CommentSection field={'motherBirthplcae'} step={currentStep - 1} userType="student" />
                                }
                            </div>
                        </div>
                        <div className={!showField ? "block" : showField('motherBirthCountry') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex items-center">
                                <label
                                    htmlFor={"motherBirthCountry"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('motherBirthCountry')
                                            }}
                                            checked={modal && field === 'motherBirthCountry'}
                                        />
                                    }
                                    Mother - Country of birth{" "}
                                    <span className="text-[#FF4444] ml-1">*</span>
                                </label>
                                {getCount && getCount('motherBirthCountry') > 0 &&
                                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                            {getCount('motherBirthCountry')}
                                        </div>
                                    }
                            </div>
                            <div className="w-full">
                                <Field name="motherBirthCountry">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.motherBirthCountry}
                                            onChange={(val: string) =>
                                                form.setFieldValue("motherBirthCountry", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="motherBirthCountry"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('motherBirthCountry') &&
                                <CommentSection field={'motherBirthCountry'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                        <div className={!showField ? "block" : showField('motherCitizenship') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex items-center">
                                <label
                                    htmlFor={"motherCitizenship"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('motherCitizenship')
                                            }}
                                            checked={modal && field === 'motherCitizenship'}
                                        />
                                    }
                                    Mother - Citizenship
                                    <span className="text-[#FF4444] ml-1">*</span>
                                </label>
                                {getCount && getCount('motherCitizenship') > 0 &&
                                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                            {getCount('motherCitizenship')}
                                        </div>
                                    }
                            </div>
                            <div className="w-full">
                                <Field name="motherCitizenship">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.motherCitizenship}
                                            onChange={(val: string) =>
                                                form.setFieldValue("motherCitizenship", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="motherCitizenship"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('motherCitizenship') &&
                                <CommentSection field={'motherCitizenship'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                        <div className={!showField ? "block" : showField('motherResCountry') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex  items-center">
                                <label
                                    htmlFor={"motherResCountry"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('motherResCountry')
                                            }}
                                            checked={modal && field === 'motherResCountry'}
                                        />
                                    }
                                    Mother - Actual country of residence
                                </label>
                                {getCount && getCount('motherResCountry') > 0 &&
                                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                            {getCount('motherResCountry')}
                                        </div>
                                    }
                            </div>
                            <div className="w-full">
                                <Field name="motherResCountry">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.motherResCountry}
                                            onChange={(val: string) =>
                                                form.setFieldValue("motherResCountry", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="motherResCountry"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('motherResCountry') &&
                                <CommentSection field={'motherResCountry'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                    </div>
                }
            </div>
        </div>

    )
}

export default MotherInfoComp