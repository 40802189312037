import { Box, LinearProgress, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import { Inputfile, RadioGroup } from 'components/Form';
import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useAppSelector } from 'store/Hooks';
import { formCorrectionSelector } from 'store/slices/FormCorrection';
import { prestudySelector } from 'store/slices/Prestudy';
import { userSelector } from 'store/slices/User';
import { validateFiles } from 'utils/Helper';
import { crossBlack, documentBlack, visaBerlin } from 'utils/Images';
import { addressOptions } from 'utils/data';

const StudyEntryvisa: React.FC<any> = ({ values, uploadDocument, userType, setModal, setField, field, modal, showField, showComments, studentData, highlightedIndex, setHighlightedIndex, getCount }) => {
    const [visaFileError, setVisafileError] = useState<string | null>(null);
    const [visaFiles, setVisafiles] = useState<File[] | null>(null);
    const [stampFiles, setStampsFiles] = useState<File[] | null>(null);
    const [stmpFileError, setStmpFileError] = useState<string | null>(null);
    const { user } = useAppSelector(userSelector);
    const { currentStep } =
        useAppSelector(prestudySelector);
    const { currentStep: lawyercurentStep } =
        useAppSelector(formCorrectionSelector);
    const DateInput = DatePicker as any;
    const [progress, setProgress] = React.useState(0);
    const [uploading, setUploading] = useState(false);
    const intervalIdRef = useRef<any>();
    const [progressTwo, setProgressTwo] = React.useState(0);
    const [uploadingTwo, setUploadingtwo] = useState(false);
    const intervalIdRefTwo = useRef<any>();

    useEffect(() => {
        if (progress > 100) {
            setUploading(false);
            setProgress(0);
            clearInterval(intervalIdRef?.current);
        }
    }, [progress]);

    useEffect(() => {
        if (progressTwo > 100) {
            setUploadingtwo(false);
            setProgressTwo(0);
            clearInterval(intervalIdRefTwo?.current);
        }
    }, [progressTwo]);

    useEffect(() => {
        let binaryFiles: any = []
        values?.entryvisaColorCopy?.map(async (file: any) => {
            const response = await axios.get(file.location, {
                responseType: 'blob',
            });
            const blob = response.data;
            const binaryFile = new File([blob], file.name, { type: blob.type });
            binaryFiles.push(binaryFile);
        })
        setVisafiles(binaryFiles)
        return () => {
            clearInterval(intervalIdRef.current);
            clearInterval(intervalIdRefTwo?.current);
        };
    }, []);

    const uploadFiles = async (selectedFile: FileList | null, form: any, fieldName: string) => {
        const allowedTypes = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf",
        ];
        const isValidate = await validateFiles(selectedFile, allowedTypes);
        if (isValidate === "true") {
            if (fieldName === "entryvisaColorCopy") {
                if (selectedFile) {
                    const newFiles = Array.from(selectedFile).filter(
                        (newFile) =>
                            !visaFiles ||
                            !visaFiles.some((existingFile: any) => existingFile.name === newFile.name)
                    );
                    const updatedFiles = visaFiles ? [...visaFiles, ...newFiles] : newFiles;
                    form.setFieldValue("entryvisaColorCopy", updatedFiles);
                    setVisafiles(updatedFiles);
                    const formData: any = new FormData();
                    if (updatedFiles && updatedFiles?.length > 0) {
                        updatedFiles?.forEach((file: any) => {
                            if (!file.location) {
                                formData.append("entryvisaColorCopy", file);
                            }
                        });
                    } else {
                        formData.append("entryvisaColorCopy", null);
                    }
                    if (userType === 'lawyer') {
                        lawyercurentStep && formData.append("step", (lawyercurentStep - 1).toString());
                        studentData && formData.append("userId", studentData?._id);

                    } else {
                        currentStep && formData.append("step", (currentStep - 1).toString());
                        user && formData.append("userId", user?._id);
                    }
                    formData.append("isRequired", true);
                    formData.append(
                        "label",
                        "Upload a COLOR copy of your ENTRY VISA + Zusatzblatt if available"
                    );
                    await uploadDocument(formData);
                } else {
                    setVisafiles(null);
                }
            }
            if (fieldName === "stampsEntry") {
                if (selectedFile) {
                    const newFiles = Array.from(selectedFile).filter(
                        (newFile) =>
                            !stampFiles ||
                            !stampFiles.some((existingFile: any) => existingFile.name === newFile.name)
                    );
                    const updatedFiles = stampFiles ? [...stampFiles, ...newFiles] : newFiles;
                    form.setFieldValue("stampsEntry", updatedFiles);
                    setStampsFiles(updatedFiles);
                    const formData: any = new FormData();
                    updatedFiles?.forEach((file: any) => {
                        formData.append("stampsEntry", file);
                    });
                    if (userType === 'lawyer') {
                        lawyercurentStep && formData.append("step", (lawyercurentStep - 1).toString());
                        studentData && formData.append("userId", studentData?._id);
                    } else {
                        currentStep && formData.append("step", (currentStep - 1).toString());
                        user && formData.append("userId", user?._id);
                    }
                    formData.append("isRequired", true);
                    formData.append(
                        "label",
                        "Upload a copy of your entry and exit STAMPS before your last entry"
                    );
                    await uploadDocument(formData);
                } else {
                    setVisafiles(null);
                }
            }
        }
    };

    const fieldRefs = useRef<any>({});

    useEffect(() => {
        if (fieldRefs.current[highlightedIndex]) {
            fieldRefs.current[highlightedIndex].focus();

            const container = document.getElementById('container');

            // Scroll the container to make 'fieldRefs.current[highlightedIndex]' visible
            fieldRefs.current[highlightedIndex].scrollIntoView({
                behavior: 'smooth',  // Optional: Smooth scrolling
                block: 'nearest',    // Optional: Adjust where element aligns
                inline: 'start'      // Optional: Adjust where element aligns
            });
        }
    }, [fieldRefs, highlightedIndex]);

    return (
        <div id='conatainer'>
            <div className={`block p-2 border-2 ${highlightedIndex === 'option1Field1' ? 'border-2 !border-green-300 rounded-md' : 'border-transparent '}`} >
                <div className="mb-2 mt-4 flex justify-start gap-2 items-center">
                    {userType === 'lawyer' &&
                        <input
                            type="checkbox"
                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] "
                            onClick={(e: any) => {
                                setModal(e.target.checked);
                                setField('visaExpiryDate')
                            }}
                            checked={modal && field === 'visaExpiryDate'}
                        />}
                    <div className='flex items-center gap-2'>
                        <label
                            htmlFor={"visaExpiryDate"}
                            className="text-[#0B0D0F] font-semibold text-[16px]                    "
                        >
                            When will your entry visa expire? <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount('visaExpiryDate') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('visaExpiryDate')}
                            </div>
                        }
                    </div>
                </div>
                <Field name="visaExpiryDate"
                    ref={(el: any) => { fieldRefs.current['option1Field1'] = el }}
                    onFocus={() => setHighlightedIndex('option1Field1')}>
                    {({ field, form }: FieldProps) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateInput
                                {...field}
                                label="YYYY-MM-DD"
                                renderInput={(props: any) => <TextField {...props} />}
                                format="YYYY-MM-DD"
                                value={values.visaExpiryDate}
                                onChange={(newDate: any) => {
                                    form.setFieldValue("visaExpiryDate", newDate);
                                }}
                                className={"lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full"}
                                disablePast
                                ref={(el: any) => { fieldRefs.current['option1Field1'] = el }}
                            />
                        </LocalizationProvider>
                    )}
                </Field>
                <ErrorMessage
                    name="visaExpiryDate"
                    component="div"
                    className="text-red-600"
                />
                {userType === 'student' ?
                    showComments('visaExpiryDate') &&
                    <CommentSection field={'visaExpiryDate'} step={currentStep - 1} userType="student" />
                    : <>
                    </>
                }
            </div>
            <div className={`block p-2 border-2 ${highlightedIndex === 'option1Field2' ? 'border-2 !border-green-300 rounded-md' : 'border-transparent '}`}>
                <div className="mb-2 mt-4 flex justify-start gap-2 items-center">
                    {userType === 'lawyer' &&
                        <input
                            type="checkbox"
                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] "
                            onClick={(e: any) => {
                                setModal(e.target.checked);
                                setField('entryvisaColorCopy')
                            }}
                            checked={modal && field === 'entryvisaColorCopy'}

                        />}
                    <div className='flex items-center gap-2'>
                        <label
                            htmlFor={"entryvisaColorCopy"}
                            className="text-[#0B0D0F] font-semibold text-[16px]" ref={(el: any) => { fieldRefs.current['option1Field2'] = el }}
                        >
                            Upload a COLOR copy of your ENTRY VISA + Zusatzblatt if available <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount('entryvisaColorCopy') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('entryvisaColorCopy')}
                            </div>
                        }

                    </div>
                </div>
                <Field name="entryvisaColorCopy"
                    ref={(el: any) => { fieldRefs.current['option1Field2'] = el }}
                    onFocus={() => setHighlightedIndex('option1Field2')}
                >
                    {({ field, form }: FieldProps) => (
                        <>
                            <div ref = {(el:any) => {fieldRefs.current['option1Field2'] = el}}>
                            <Inputfile
                                {...field}
                                mainClass="lg:w-1/2 xl:w-1/2 w-full"
                                btnClass={null}
                                handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setProgress(0);
                                    setUploading(true);
                                    setVisafileError(null);
                                    intervalIdRef.current = setInterval(() => {
                                        setProgress((prevProgress) => prevProgress + 10);
                                    }, 500);
                                    uploadFiles(event.target.files, form, 'entryvisaColorCopy');
                                }}
                                multiple={true}
                            />
                            </div>
                            {values.entryvisaColorCopy &&
                                values.entryvisaColorCopy.length > 0 &&
                                values.entryvisaColorCopy.map(
                                    (item: { name: string; location: string }) => (
                                        <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                            <img src={documentBlack} alt="documentBlack"  />
                                            <label className="flex-1">{item.name}</label>
                                            <img
                                                src={crossBlack}
                                                alt="documentBlack"
                                                onClick={async () => {
                                                    const updatedFiles = values.entryvisaColorCopy?.filter(
                                                        (val: { name: string; location: string }) =>
                                                            val.name !== item.name
                                                    );
                                                    const formData: any = new FormData();
                                                    if (updatedFiles && updatedFiles?.length > 0) {
                                                        console.log("🚀 ~ onClick={ ~ updatedFiles:", updatedFiles)
                                                        updatedFiles?.forEach((file: any) => {
                                                            if (!file.location) {
                                                                formData.append("entryvisaColorCopy", file);
                                                            }
                                                        });
                                                    } else {
                                                        formData.append("entryvisaColorCopy", null);
                                                    }
                                                    if (userType === 'lawyer') {
                                                        lawyercurentStep && formData.append("step", (lawyercurentStep - 1).toString());
                                                        studentData && formData.append("userId", studentData?._id);
                                                    } else {
                                                        currentStep && formData.append("step", (currentStep - 1).toString());
                                                        user && formData.append("userId", user?._id);
                                                    }
                                                    formData.append("isRequired", true);
                                                    formData.append(
                                                        "label",
                                                        "Upload ALL the pages of your passport proving your entries and exits from Schengen area"
                                                    );
                                                    await uploadDocument(formData);
                                                    setVisafiles(updatedFiles);
                                                    if (updatedFiles.length === 0) {
                                                        form.setFieldValue("entryvisaColorCopy", null);
                                                        setVisafiles(null);
                                                    }
                                                }}
                                                className="cursor-pointer"
                                            />
                                        </div>
                                    )
                                )}
                            {uploading && (
                                <Box sx={{ width: "100%" }}>
                                    <LinearProgress variant="determinate" value={progress} />
                                </Box>
                            )}
                        </>
                    )}
                </Field>
                {visaFileError ? (
                    <div className="text-red-500 text-sm md:text-base fileError">
                        {visaFileError}
                    </div>
                ) : (
                    <ErrorMessage
                        name="entryvisaColorCopy"
                        component="div"
                        className="text-red-600 fileError"
                    />
                )}
                {userType === "student" ?
                    showComments('entryvisaColorCopy') &&
                    <CommentSection field={'entryvisaColorCopy'} step={currentStep - 1} userType="student" />
                    : <>
                    </>
                }

                <div className="flex flex-col gap-4 my-4">
                    <h3 className="text-[#FF4444] text-[18px]">
                        If the embassy labeled another sticker into your passport, called ZUSATZBLATT, you have to upload it too.

                    </h3>
                    <div className="lg:w-1/4 w-full">
                        <img src={visaBerlin} alt="image" />
                    </div>
                </div>
                <div className="mb-2 mt-4 flex gap-2 justify-start items-center">
                    {userType === 'lawyer' &&
                        <input
                            type="checkbox"
                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] "
                            onClick={(e: any) => {
                                setModal(e.target.checked);
                                setField('isSchenegenArea')
                            }}
                            checked={modal && field === 'isSchenegenArea'}
                        />}
                    <div className='flex items-center'>
                        <label
                            htmlFor={"isSchenegenArea"}
                            className="text-[#0B0D0F] font-semibold text-[16px]                    "
                        >
                            Before your last entry, have you been in the Schengen area WITHIN YOUR CURRENT VISA VALIDITY? <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount('isSchenegenArea') > 0 &&
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount('isSchenegenArea')}
                            </div>
                        }
                    </div>
                </div>
                <Field name="isSchenegenArea">
                    {({ field, form }: FieldProps) => (
                        <div className="flex flex-col gap-3 justify-center">
                            <RadioGroup
                                {...field}
                                fieldName="isSchenegenArea"
                                options={addressOptions}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    form.setFieldValue(
                                        "isSchenegenArea",
                                        event.target.value
                                    );
                                }}
                                className={
                                    "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                                }
                                checkedField={values.isSchenegenArea}
                            />
                        </div>
                    )}
                </Field>
                <ErrorMessage
                    name="isSchenegenArea"
                    component="div"
                    className="text-red-500"
                />
                {userType === "student" ?
                    showComments('isSchenegenArea') &&
                    <CommentSection field={'isSchenegenArea'} step={currentStep - 1} userType="student" />
                    : <></>
                }
                {values.isSchenegenArea === "Yes" &&
                    <>
                        <div className="mb-2 mt-4 flex justify-start gap-2 items-center">
                            {userType === 'lawyer' &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] "
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('stampsEntry')
                                    }}
                                    checked={modal && field === 'stampsEntry'}
                                />}
                            <div className='flex items-center'>
                                <label
                                    htmlFor={"stampsEntry"}
                                    className="text-[#0B0D0F] font-semibold text-[16px]                    "
                                >
                                    Upload a copy of your entry and exit STAMPS before your last entry <span className="text-[#FF4444] ml-1">*</span>
                                </label>
                                {getCount && getCount('stampsEntry') > 0 &&
                                    <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                        {getCount('stampsEntry')}
                                    </div>
                                }
                            </div>
                        </div>

                        <Field name="stampsEntry">
                            {({ field, form }: FieldProps) => (
                                <>
                                    <Inputfile
                                        {...field}
                                        mainClass="lg:w-1/2 xl:w-1/2 w-full"
                                        btnClass={null}
                                        handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                                            setProgressTwo(0);
                                            setUploadingtwo(true);
                                            setStmpFileError(null);
                                            intervalIdRefTwo.current = setInterval(() => {
                                                setProgressTwo((prevProgress) => prevProgress + 10);
                                            }, 500);
                                            uploadFiles(event.target.files, form, 'stampsEntry');
                                        }}
                                        multiple={true}
                                    />
                                    {values.stampsEntry &&
                                        values.stampsEntry.length > 0 &&
                                        values.stampsEntry.map(
                                            (item: { name: string; location: string }) => (
                                                <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                                    <img src={documentBlack} alt="documentBlack" />
                                                    <label className="flex-1">{item.name}</label>
                                                    <img
                                                        src={crossBlack}
                                                        alt="documentBlack"
                                                        onClick={async () => {
                                                            const updatedFiles = values.stampsEntry?.filter(
                                                                (val: { name: string; location: string }) =>
                                                                    val.name !== item.name
                                                            );
                                                            form.setFieldValue("stampsEntry", updatedFiles);
                                                            const formData: any = new FormData();
                                                            updatedFiles?.forEach((file: any) => {
                                                                formData.append("stampsEntry", file);
                                                            });
                                                            if (userType === 'lawyer') {
                                                                lawyercurentStep && formData.append("step", (lawyercurentStep - 1).toString());
                                                                studentData && formData.append("userId", studentData?._id);
                                                            } else {
                                                                user && formData.append("userId", user?._id);
                                                                currentStep && formData.append("step", (currentStep - 1).toString());
                                                            }
                                                            formData.append("isRequired", true);
                                                            formData.append(
                                                                "label",
                                                                "Upload a copy of your entry and exit STAMPS before your last entry "
                                                            );
                                                            await uploadDocument(formData);
                                                            setStampsFiles(updatedFiles);
                                                            if (updatedFiles.length === 0) {
                                                                form.setFieldValue("stampsEntry", null);
                                                                setStampsFiles(null);
                                                            }
                                                        }}
                                                        className="cursor-pointer"
                                                    />
                                                </div>
                                            )
                                        )}
                                    {uploadingTwo && (
                                        <Box sx={{ width: "100%" }}>
                                            <LinearProgress variant="determinate" value={progressTwo} />
                                        </Box>
                                    )}
                                </>
                            )}
                        </Field>
                        {stmpFileError ? (
                            <div className="text-red-500 text-sm md:text-base fileError">
                                {stmpFileError}
                            </div>
                        ) : (
                            <ErrorMessage
                                name="stampsEntry"
                                component="div"
                                className="text-red-600 fileError"
                            />
                        )}
                        {userType === "student" ?
                            showComments('stampsEntry') &&
                            <CommentSection field={'stampsEntry'} step={currentStep - 1} userType="student" />
                            : <></>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default StudyEntryvisa