import { useEffect } from "react";
import Router from "./routes";
import { initializePushNotifications } from "utils/Helper/PushNotification";
import authService from "services/AuthService";

const App: React.FC = () => {
  useEffect(() => {
    let checkIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream
    if(!checkIOSDevice) {
      initializePushNotifications();
    }
  }, []);

  return (
    <div className="App font-martel-sans">
      <Router />
    </div>
  );
};

export default App;
