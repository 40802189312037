import Api from "./Api";

class UniversityService {
  public async getOpenRegistrations(
    userId: string | undefined,
    query?: { page: number; limit: number; role: string } | null
  ) {
    let URL;
    if (query) {
      URL = `/university/students/open-registration/${userId}?page=${query.page}&limit=${query.limit}&role=${query.role}`;
    } else {
      URL = `/university/students/open-registration/${userId}`;
    }
    return await Api.get(URL);
  }

  public async getStudentRegisterData(studentId: string | undefined) {
    return await Api.get(`/university/${studentId}`);
  }

  public async getSearchData(filters: any) {
    return await Api.get(`/university/search?${filters}`);
  }

  public async updateStatus(
    studentId: string | undefined,
    payload: {
      status: string;
      comment: string | null;
    }
  ) {
    return await Api.patch(`/university/status/${studentId}`, payload);
  }

  public async getActiveServices(query?: string | null) {
    let URL;
    if (query) {
      URL = `/university/active-services?${query}`;
    } else {
      URL = `/university/active-services`;
    }
    return await Api.get(URL);
  }

  public async getGeneralInformation(studentId: string | null) {
    let URL = `university/services/${studentId}`;
    return await Api.get(URL);
  }

  public async saveComments(userId: string, payload: any | null) {
    return Api.post(
      `internalCommunication/std-uni-communication/${userId}`,
      payload
    );
  }

  public async getStatistics() {
    let URL = `university/statistics`;
    return await Api.get(URL);
  }
}

const universityService = new UniversityService();
export default universityService;
