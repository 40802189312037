import { Input, Inputfile, RadioGroup, SelectInput } from "components/Form";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { stepSevenSchema } from "schema/Prestudy";
import { useAppSelector } from "store/Hooks";
import { prestudySelector, setFeatureLoaded } from "store/slices/Prestudy";
import { addressOptions, maritalOptions } from "utils/data";
import { StepSevenTypes, stepProps } from "utils/types";
import { letter } from "utils/Images";
import moment from "moment";
import { validateFiles } from "utils/Helper";
import { userSelector } from "store/slices/User";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CommentSection from "components/Form/CommentSection";
import SubsistanceFileOne from "./SubsistanceFileOne";
import MatriculationLetterOne from "./MatriculationLetterOne";
import HealthInsFileOne from "./HealthInsFileOne";
import GermanEntryComp from "./GermanEntryComp";
import AuthorizationOne from "./AuthorizationOne";
import ResidenceComp from "./ResidenceComp";
import FatherInfoComp from "./FatherInfoComp";
import MotherInfoComp from "./MotherInfoComp";
import CriminalityCompOne from "./CriminalityCompOne";
import CriminalityCompTwo from "./CriminalityCompTwo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import ChildrenComp from "./ChildrenComp";
import SubsistanceFileTwo from "./SubsistanceFileTwo";
import MarriedComp from "./MarriedComp";

const StepSeven: React.FC<stepProps> = ({
  submitStepdata,
  nextActive,
  uploadDocument,
  verifyCorrection,
}) => {
  const DateInput = DatePicker as any;
  const { currentStep, stepData } = useAppSelector(prestudySelector);
  const [progress, setProgress] = React.useState(0);
  const [uploadingOne, setUploadingOne] = useState(false);
  const [uploadingTwo, setUploadingTwo] = useState(false);
  const [uploadingThree, setUploadingThree] = useState(false);
  const intervalIdRefone = useRef<any>();
  const intervalIdReftwo = useRef<any>();
  const intervalIdRefthree = useRef<any>();
  const [stepFields, setStepFields] = useState<any>({
    germanLastEntry: "",
    germanLastEntryFrom1: "",
    germanLastEntryCity1: "",
    germanLastEntryTo1: "",
    germanLastEntryFrom2: "",
    germanLastEntryCity2: "",
    germanLastEntryTo2: "",
    germanLastEntryFrom3: "",
    germanLastEntryCity3: "",
    germanLastEntryTo3: "",
    authorisation: "",
    authCountry: "",
    authDate: "",
    residence: "",
    residenceCountry: "",
    maritalStatus: "",
    children: "",
    father: "",
    fatherLname: "",
    fatherFname: "",
    fatherInfo: "",
    fatherDob: "",
    fatherBirthplcae: "",
    fatherBirthCountry: "",
    fatherCitizenship: "",
    fatherResCountry: "",
    mother: "",
    motherLname: "",
    motherFname: "",
    motherInfo: "",
    motherDob: "",
    motherBirthplcae: "",
    motherBirthCountry: "",
    motherCitizenship: "",
    motherResCountry: "",
    criminality: "",
    conviction1: "",
    convictionDate1: "",
    resume1: "",
    penalty1: "",
    conviction2: "",
    convictionDate2: "",
    resume2: "",
    penalty2: "",
    germanyRejection: "",
    germanyRejectionReason: "",
    otherRejection: "",
    otherRejectionReason: "",
    subsistence: "",
    subsistenceFile: [],
    healthInsurance: "",
    healthInsuranceFile: [],
    scholarshipFile: [],
    declarationFile: [],
    parentDeclareFile1: [],
    parentDeclareFile2: [],
    parentDeclareFile3: [],
    matriculationLetter: "",
    date: "",
    matriculationFile: [],
    studyprogramme: "",
    widowedDate: "",
    c1Lame: "",
    c1Fname: "",
    c1Gender: "",
    c1dob: "",
    c1Birthplcae: "",
    c1BirthCountry: "",
    c1Citizenship: "",
    c1ResCountry: "",
    c2dob: "",
    c2Lame: "",
    c2Fname: "",
    c2Gender: "",
    c2Birthplcae: "",
    c2BirthCountry: "",
    c2Citizenship: "",
    c2ResCountry: "",
    c3dob: "",
    c3Lame: "",
    c3Fname: "",
    c3Gender: "",
    c3Birthplcae: "",
    c3BirthCountry: "",
    c3Citizenship: "",
    c3ResCountry: "",
    dom: "",
    mrgCity: "",
    mrgCountry: "",
    mrgFile: [],
    spouseLname: "",
    spouseFname: "",
    spouseName: "",
    spouseGender: "",
    spouseDob: "",
    spousePob: "",
    spouseCob: "",
    spouseCitizenship: "",
    spouseAddress: "",
    spouseZipcode: "",
    spouseRes: "",
    spouseRescountry: "",
    check1: false,
    check2: false,
    check3: false,
  });

  const initialValues: any = {
    widowedDate: stepFields.widowedDate !== "" ? stepFields.widowedDate : "",
    c1Lame: stepFields.c1Lame !== "" ? stepFields.c1Lame : "",
    c1Fname: stepFields.c1Fname !== "" ? stepFields.c1Fname : "",
    c1Gender: stepFields.c1Gender !== "" ? stepFields.c1Gender : "",
    c1dob: stepFields.c1dob !== "" ? stepFields.c1dob : "",
    c1Birthplcae: stepFields.c1Birthplcae !== "" ? stepFields.c1Birthplcae : "",
    c1BirthCountry: stepFields.c1BirthCountry !== "" ? stepFields.c1BirthCountry : "",
    c1Citizenship: stepFields.c1Citizenship !== "" ? stepFields.c1Citizenship : "",
    c1ResCountry: stepFields.c1ResCountry !== "" ? stepFields.c1ResCountry : "",
    c2dob: stepFields.c2dob !== "" ? stepFields.c2dob : "",
    c2Lame: stepFields.c2Lame !== "" ? stepFields.c2Lame : "",
    c2Fname: stepFields.c2Fname !== "" ? stepFields.c2Fname : "",
    c2Gender: stepFields.c2Gender !== "" ? stepFields.c2Gender : "",
    c2Birthplcae: stepFields.c2Birthplcae !== "" ? stepFields.c2Birthplcae : "",
    c2BirthCountry: stepFields.c2BirthCountry !== "" ? stepFields.c2BirthCountry : "",
    c2Citizenship: stepFields.c2Citizenship !== "" ? stepFields.c2Citizenship : "",
    c2ResCountry: stepFields.c2ResCountry !== "" ? stepFields.c2ResCountry : "",
    c3dob: stepFields.c3dob !== "" ? stepFields.c3dob : "",
    c3Lame: stepFields.c3Lame !== "" ? stepFields.c3Lame : "",
    c3Fname: stepFields.c3Fname !== "" ? stepFields.c3Fname : "",
    c3Gender: stepFields.c3Gender !== "" ? stepFields.c3Gender : "",
    c3Birthplcae: stepFields.c3Birthplcae !== "" ? stepFields.c3Birthplcae : "",
    c3BirthCountry: stepFields.c3BirthCountry !== "" ? stepFields.c3BirthCountry : "",
    c3Citizenship: stepFields.c3Citizenship !== "" ? stepFields.c3Citizenship : "",
    c3ResCountry: stepFields.c3ResCountry !== "" ? stepFields.c3ResCountry : "",
    c4Lame: stepFields.c4Lame !== "" ? stepFields.c4Lame : "",
    c4Fname: stepFields.c4Fname !== "" ? stepFields.c4Fname : "",
    c4Info: stepFields.c4Info !== "" ? stepFields.c4Info : "",
    c4Gender: stepFields.c4Gender !== "" ? stepFields.c4Gender : "",
    c4Birthplcae: stepFields.c4Birthplcae !== "" ? stepFields.c4Birthplcae : "",
    c4BirthCountry: stepFields.c4BirthCountry !== "" ? stepFields.c4BirthCountry : "",
    c4Citizenship: stepFields.c4Citizenship !== "" ? stepFields.c4Citizenship : "",
    c4ResCountry: stepFields.c4ResCountry !== "" ? stepFields.c4ResCountry : "",
    c5Lame: stepFields.c5Lame !== "" ? stepFields.c5Lame : "",
    c5Fname: stepFields.c5Fname !== "" ? stepFields.c5Fname : "",
    c5Info: stepFields.c5Info !== "" ? stepFields.c5Info : "",
    c5Gender: stepFields.c5Gender !== "" ? stepFields.c5Gender : "",
    c5Birthplcae: stepFields.c5Birthplcae !== "" ? stepFields.c5Birthplcae : "",
    c5BirthCountry: stepFields.c5BirthCountry !== "" ? stepFields.c5BirthCountry : "",
    c5Citizenship: stepFields.c5Citizenship !== "" ? stepFields.c5Citizenship : "",
    c5ResCountry: stepFields.c5ResCountry !== "" ? stepFields.c5ResCountry : "",
    c6Lame: stepFields.c6Lame !== "" ? stepFields.c6Lame : "",
    c6Fname: stepFields.c6Fname !== "" ? stepFields.c6Fname : "",
    c6Info: stepFields.c6Info !== "" ? stepFields.c6Info : "",
    c6Gender: stepFields.c6Gender !== "" ? stepFields.c6Gender : "",
    c6Birthplcae: stepFields.c6Birthplcae !== "" ? stepFields.c6Birthplcae : "",
    c6BirthCountry: stepFields.c6BirthCountry !== "" ? stepFields.c6BirthCountry : "",
    c6Citizenship: stepFields.c6Citizenship !== "" ? stepFields.c6Citizenship : "",
    c6ResCountry: stepFields.c6ResCountry !== "" ? stepFields.c6ResCountry : "",
    germanLastEntry:
      stepFields.germanLastEntry !== "" ? stepFields.germanLastEntry : "",
    germanLastEntryCity1:
      stepFields.germanLastEntryCity1 !== ""
        ? stepFields.germanLastEntryCity1
        : "",
    germanLastEntryTo1:
      stepFields.germanLastEntryTo1 !== "" ? stepFields.germanLastEntryTo1 : "",
    germanLastEntryFrom1:
      stepFields.germanLastEntryFrom1 !== ""
        ? stepFields.germanLastEntryFrom1
        : "",
    germanLastEntryCity2:
      stepFields.germanLastEntryCity2 !== ""
        ? stepFields.germanLastEntryCity2
        : "",
    germanLastEntryCity3:
      stepFields.germanLastEntryCity3 !== ""
        ? stepFields.germanLastEntryCity3
        : "",
    germanLastEntryTo2:
      stepFields.germanLastEntryTo2 !== "" ? stepFields.germanLastEntryTo2 : "",
    germanLastEntryFrom2:
      stepFields.germanLastEntryFrom2 !== ""
        ? stepFields.germanLastEntryFrom2
        : "",
    germanLastEntryTo3:
      stepFields.germanLastEntryTo3 !== "" ? stepFields.germanLastEntryTo3 : "",
    germanLastEntryFrom3:
      stepFields.germanLastEntryFrom3 !== ""
        ? stepFields.germanLastEntryFrom3
        : "",
    authorisation:
      stepFields.authorisation !== "" ? stepFields.authorisation : "",
    authCountry: stepFields.authCountry !== "" ? stepFields.authCountry : "",
    authDate: stepFields.authDate !== "" ? stepFields.authDate : "",
    residenceCountry:
      stepFields.residenceCountry !== "" ? stepFields.residenceCountry : "",
    residence: stepFields.residence !== "" ? stepFields.residence : "",
    maritalStatus:
      stepFields.maritalStatus !== "" ? stepFields.maritalStatus : "",
    children: stepFields.children !== "" ? stepFields.children : "",
    father: stepFields.father !== "" ? stepFields.father : "",
    fatherLname: stepFields.fatherLname !== "" ? stepFields.fatherLname : "",
    fatherFname: stepFields.fatherFname !== "" ? stepFields.fatherFname : "",
    fatherInfo: stepFields.fatherInfo !== "" ? stepFields.fatherInfo : "",
    fatherDob: stepFields.fatherDob !== "" ? stepFields.fatherDob : "",
    fatherBirthplcae: stepFields.fatherBirthplcae !== "" ? stepFields.fatherBirthplcae : "",
    fatherBirthCountry: stepFields.fatherBirthCountry !== "" ? stepFields.fatherBirthCountry : "",
    fatherCitizenship: stepFields.fatherCitizenship !== "" ? stepFields.fatherCitizenship : "",
    fatherResCountry: stepFields.fatherResCountry !== "" ? stepFields.fatherResCountry : "",
    mother: stepFields.mother !== "" ? stepFields.mother : "",
    motherLname: stepFields.motherLname !== "" ? stepFields.motherLname : "",
    motherFname: stepFields.motherFname !== "" ? stepFields.motherFname : "",
    motherInfo: stepFields.motherInfo !== "" ? stepFields.motherInfo : "",
    motherDob: stepFields.motherDob !== "" ? stepFields.motherDob : "",
    motherBirthplcae: stepFields.motherBirthplcae !== "" ? stepFields.motherBirthplcae : "",
    motherBirthCountry: stepFields.motherBirthCountry !== "" ? stepFields.motherBirthCountry : "",
    motherCitizenship: stepFields.motherCitizenship !== "" ? stepFields.motherCitizenship : "",
    motherResCountry: stepFields.motherResCountry !== "" ? stepFields.motherResCountry : "",
    criminality: stepFields.criminality !== "" ? stepFields.criminality : "",
    conviction1: stepFields.conviction1 !== "" ? stepFields.conviction1 : "",
    convictionDate1: stepFields.convictionDate1 !== "" ? stepFields.convictionDate1 : "",
    resume1: stepFields.resume1 !== "" ? stepFields.resume1 : "",
    penalty1: stepFields.penalty1 !== "" ? stepFields.penalty1 : "",
    conviction2: stepFields.conviction2 !== "" ? stepFields.conviction2 : "",
    convictionDate2: stepFields.convictionDate2 !== "" ? stepFields.convictionDate2 : "",
    resume2: stepFields.resume2 !== "" ? stepFields.resume2 : "",
    penalty2: stepFields.penalty2 !== "" ? stepFields.penalty2 : "",
    germanyRejection:
      stepFields.germanyRejection !== "" ? stepFields.germanyRejection : "",
    germanyRejectionReason:
      stepFields.germanyRejectionReason !== ""
        ? stepFields.germanyRejectionReason
        : "",
    otherRejection:
      stepFields.otherRejection !== "" ? stepFields.otherRejection : "",
    otherRejectionReason:
      stepFields.otherRejectionReason !== ""
        ? stepFields.otherRejectionReason
        : "",
    subsistence: stepFields.subsistence !== "" ? stepFields.subsistence : "",
    subsistenceFile: stepFields.subsistenceFile
      ? stepFields.subsistenceFile
      : [],
    healthInsurance:
      stepFields.healthInsurance !== "" ? stepFields.healthInsurance : "",
    healthInsuranceFile: stepFields.healthInsuranceFile
      ? stepFields.healthInsuranceFile
      : [],
    scholarshipFile: stepFields.scholarshipFile
      ? stepFields.scholarshipFile
      : [],
    declarationFile: stepFields.declarationFile
      ? stepFields.declarationFile
      : [],
    parentDeclareFile1: stepFields.parentDeclareFile1
      ? stepFields.parentDeclareFile1
      : [],
    parentDeclareFile2: stepFields.parentDeclareFile2
      ? stepFields.parentDeclareFile2
      : [],
    parentDeclareFile3: stepFields.parentDeclareFile3
      ? stepFields.parentDeclareFile3
      : [],

    matriculationLetter:
      stepFields.matriculationLetter !== ""
        ? stepFields.matriculationLetter
        : "",
    date: stepFields.date !== "" ? stepFields.date : "",
    matriculationFile: stepFields.matriculationFile
      ? stepFields.matriculationFile
      : [],
    studyprogramme: stepFields.studyprogramme ? stepFields.studyprogramme : "",
    dom: stepFields.dom !== "" ? stepFields.dom : "",
    mrgCity: stepFields.mrgCity !== "" ? stepFields.mrgCity : "",
    mrgCountry: stepFields.mrgCountry !== "" ? stepFields.mrgCountry : "",
    mrgFile: stepFields.mrgFile ? stepFields.mrgFile : [],
    spouseLname: stepFields.spouseLname !== "" ? stepFields.spouseLname : "",
    spouseFname: stepFields.spouseFname !== "" ? stepFields.spouseFname : "",
    spouseName: stepFields.spouseName !== "" ? stepFields.spouseName : "",
    spouseGender: stepFields.spouseGender !== "" ? stepFields.spouseGender : "",
    spouseDob: stepFields.spouseDob !== "" ? stepFields.spouseDob : "",
    spousePob: stepFields.spousePob !== "" ? stepFields.spousePob : "",
    spouseCob: stepFields.spouseCob !== "" ? stepFields.spouseCob : "",
    spouseCitizenship: stepFields.spouseCitizenship !== "" ? stepFields.spouseCitizenship : "",
    spouseAddress: stepFields.spouseAddress !== "" ? stepFields.spouseAddress : "",
    spouseZipcode: stepFields.spouseZipcode !== "" ? stepFields.spouseZipcode : "",
    spouseRes: stepFields.spouseRes !== "" ? stepFields.spouseRes : "",
    spouseRescountry: stepFields.spouseRescountry !== "" ? stepFields.spouseRescountry : "",
    check1: stepFields.check1,
    check2: stepFields.check2,
    check3: stepFields.check3
  };
  const [check1, setCheck1] = useState(stepFields.check1);
  const [check2, setCheck2] = useState(stepFields.check2);
  const [check3, setCheck3] = useState(stepFields.check3);
  const durations = [
    "Select your study programme",
    "B.Eng - 36 months",
    "B.Eng - 48 months",
    "Bachelor - 36 months",
    "Bachelor + Foundation - 42 months",
    "Bachelor, Dual - 12 months",
    "Bachelor, Dual - 18 months",
    "Bachelor, Dual - 36 months",
    "Bachelor, Dual - 48 months",
    "BSc - 12 months",
    "BSc - 36 months",
    "BSc - 48 months",
    "DBA - 36 months",
    "DBA - 48 months",
    "Foundation Year - 12 months",
    "Global MBA - 12 months",
    "Global MBA - 18 months",
    "Global MBA - 24 months",
    "M.Eng - 12 months",
    "M.Eng - 18 months",
    "M.Eng - 24 months",
    "M.Eng - 30 months",
    "Master - 12 months",
    "Master - 18 months",
    "Master - 24 months",
    "Master - 36 months",
    "MSc - 12 months",
    "MSc - 18 months",
    "MSc - 24 months",
    "MSc - 30 months",
    "MSc - 36 months",
    "Pre Master - 6 months",
    "Pre University - 9 months",
    "Pre University - 12 months",
  ];

  const options = durations.map((duration) => ({
    value: duration,
    label: duration,
  }));
  const [fileError, setfileError] = useState<string | null>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const [healthfileError, setHealthfileError] = useState<string | null>(null);
  const [healthFiles, setHealthFiles] = useState<File[] | null>(null);
  const [matriculationFiles, setMatriculationFiles] = useState<File[] | null>(
    null
  );
  const { user } = useAppSelector(userSelector);
  const [matriculationFileError, setMatriculationFileError] = useState<
    string | null
  >(null);

  const uploadFiles = async (
    selectedFile: FileList | null,
    fieldName: string,
    form: any
  ) => {
    const allowedTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
    ];
    const isValidate = await validateFiles(selectedFile, allowedTypes);
    if (isValidate === "true") {
      if (selectedFile) {
        if (fieldName === "subsistenceFile") {
          setFiles(Array.from(selectedFile));
          const formData: any = new FormData();
          Array.from(selectedFile)?.forEach((file: any) => {
            formData.append("subsistenceFile", file);
          });
          user && formData.append("userId", user?._id);
          currentStep && formData.append("step", (currentStep - 1).toString());
          formData.append(
            "label",
            "Upload proof of your Blocked Amount (if you do not have this, you can contact your bank and ask for it)"
          );
          await uploadDocument(formData);
        }
        if (fieldName === "healthInsuranceFile") {
          const newFiles = Array.from(selectedFile).filter(
            (newFile) =>
              !healthFiles ||
              !healthFiles.some(
                (existingFile) => existingFile.name === newFile.name
              )
          );
          const updatedFiles = healthFiles
            ? [...healthFiles, ...newFiles]
            : newFiles;
          form.setFieldValue("healthInsuranceFile", updatedFiles);
          setHealthFiles(updatedFiles);
          const formData: any = new FormData();
          updatedFiles?.forEach((file: any) => {
            formData.append("healthInsuranceFile", file);
          });
          user && formData.append("userId", user?._id);
          currentStep && formData.append("step", (currentStep - 1).toString());
          formData.append(
            "label",
            "Upload a copy of your health insurance certificate (both sides of your insurance card OR contract OR confirmation from the insurance company)"
          );
          await uploadDocument(formData);
        }
        if (fieldName === "matriculationFile") {
          setMatriculationFiles(Array.from(selectedFile));
          const formData: any = new FormData();
          Array.from(selectedFile)?.forEach((file: any) => {
            formData.append("matriculationFile", file);
          });
          user && formData.append("userId", user?._id);
          currentStep && formData.append("step", (currentStep - 1).toString());
          formData.append(
            "label",
            `Upload your matriculation letter from ${user?.university} (if you do not have it, contact your university urgently)`
          );
          formData.append("isRequired", true);
          await uploadDocument(formData);
        }
      }
    } else {
      if (fieldName === "subsistenceFile") {
        setFiles(null);
        setfileError(isValidate);
      }
      if (fieldName === "healthInsuranceFile") {
        setHealthFiles(null);
        setHealthfileError(isValidate);
      }
      if (fieldName === "matriculationFile") {
        setMatriculationFiles(null);
        setMatriculationFileError(isValidate);
      }
    }
  };

  const removeFile = async (fileName: string, fieldName: string) => {
    if (fieldName === "subsistenceFile") {
      const updatedFiles = files?.filter((item) => item.name !== fileName);
      if (updatedFiles && updatedFiles.length === 0)
        setfileError("Files are required");
      updatedFiles && setFiles(updatedFiles);
      const formData: any = new FormData();
      updatedFiles?.forEach((file: any) => {
        formData.append("subsistenceFile", file);
      });
      user && formData.append("userId", user?._id);
      currentStep && formData.append("step", (currentStep - 1).toString());
      formData.append(
        "label",
        "Upload proof of your Blocked Amount (if you do not have this, you can contact your bank and ask for it)"
      );
      await uploadDocument(formData);
    }
    if (fieldName === "healthInsuranceFile") {
      const updatedFiles = healthFiles?.filter(
        (item) => item.name !== fileName
      );
      if (updatedFiles && updatedFiles.length === 0)
        setHealthfileError("Files are required");
      updatedFiles && setHealthFiles(updatedFiles);
    }
    if (fieldName === "matriculationFile") {
      const updatedFiles = matriculationFiles?.filter(
        (item) => item.name !== fileName
      );
      if (updatedFiles && updatedFiles.length === 0)
        setMatriculationFileError("Files are required");
      updatedFiles && setMatriculationFiles(updatedFiles);
      const formData: any = new FormData();
      updatedFiles?.forEach((file: any) => {
        formData.append("matriculationFile", file);
      });
      user && formData.append("userId", user?._id);
      currentStep && formData.append("step", (currentStep - 1).toString());
      formData.append(
        "label",
        `Upload your matriculation letter from ${user?.university} (if you do not have it, contact your university urgently)`
      );
      await uploadDocument(formData);
    }
    setProgress(0);
  };

  useEffect(() => {
    if (progress > 100) {
      setUploadingOne(false);
      setUploadingTwo(false);
      setUploadingThree(false);
      setProgress(0);
      clearInterval(intervalIdRefone?.current);
      clearInterval(intervalIdReftwo?.current);
      clearInterval(intervalIdRefthree?.current);
    }
  }, [progress]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (progress > 0) {
      dispatch(setFeatureLoaded(true));
    } else {
      dispatch(setFeatureLoaded(false));
    }
  }, [progress]);

  useEffect(() => {
    const updatedFields = { ...stepFields };
    stepData?.forEach((item) => {
      const name = item.name as keyof any;
      switch (name) {
        case "germanLastEntry":
        case "authorisation":
        case "residence":
        case "maritalStatus":
        case "children":
        case "c1Lame":
        case "c1Fname":
        case "c1Info":
        case "c1Gender":
        case "c1Birthplcae":
        case "c1BirthCountry":
        case "c1Citizenship":
        case "c1ResCountry":
        case "c2Lame":
        case "c2Fname":
        case "c2Info":
        case "c2Gender":
        case "c2Birthplcae":
        case "c2BirthCountry":
        case "c2Citizenship":
        case "c2ResCountry":
        case "c3Lame":
        case "c3Fname":
        case "c3Info":
        case "c3Gender":
        case "c3Birthplcae":
        case "c3BirthCountry":
        case "c3Citizenship":
        case "c3ResCountry":
        case "father":
        case "mother":
        case "criminality":
        case "germanyRejection":
        case "otherRejection":
        case "subsistence":
        case "matriculationLetter":
        case "healthInsurance":
        case "otherRejectionReason":
        case "germanyRejectionReason":
        case "studyprogramme":
        case "germanLastEntryCity1":
        case "germanLastEntryCity2":
        case "germanLastEntryCity3":
        case "authCountry":
        case "residenceCountry":
        case "maritalStatus":
        case "fatherLname":
        case "fatherFname":
        case "fatherInfo":
        case "fatherBirthplcae":
        case "fatherBirthCountry":
        case "fatherCitizenship":
        case "fatherResCountry":
        case "motherLname":
        case "motherFname":
        case "motherInfo":
        case "motherBirthplcae":
        case "motherBirthCountry":
        case "motherCitizenship":
        case "motherResCountry":
        case "conviction1":
        case "resume1":
        case "penalty1":
        case "conviction2":
        case "resume2":
        case "penalty2":
        case "mrgCity":
        case "mrgCountry":
        case "spouseLname":
        case "spouseFname":
        case "spouseGender":
        case "spouseName":
        case "spousePob":
        case "spouseCob":
        case "spouseCitizenship":
        case "spouseAddress":
        case "spouseZipcode":
        case "spouseRes":
        case "spouseRescountry":
        case "check1":
        case "check2":
        case "check3":
          updatedFields[name] = item.value;
          break;
        case "date":
        case "germanLastEntryFrom1":
        case "germanLastEntryTo1":
        case "germanLastEntryFrom2":
        case "germanLastEntryTo2":
        case "germanLastEntryFrom3":
        case "germanLastEntryTo3":
        case "fatherdob":
        case "authDate":
        case "motherDob":
        case "convictionDate1":
        case "convictionDate2":
        case "widowedDate":
        case "c1dob":
        case "c2dob":
        case "c3dob":
        case "dom":
        case "spouseDob":
          if (typeof item.value === "string" && item.value !== "") {
            updatedFields[name] = dayjs(
              moment(item.value).format("YYYY-MM-DD")
            );
          }
          break;
        case "subsistenceFile":
        case "matriculationFile":
          updatedFields[name] =
            item.value instanceof Array ? item.value[0]?.name : item.value;
          break;
        case "healthInsuranceFile":
        case "scholarshipFile":
        case "declarationFile":
        case "parentDeclareFile1":
        case "parentDeclareFile2":
        case "parentDeclareFile3":
        case "mrgFile":
          updatedFields[name] =
            item.value instanceof Array ? [...item.value] : item.value;
          break;

        default:
          break;
      }
    });
    setCheck1(updatedFields.check1);
    setCheck2(updatedFields.check2);
    setCheck3(updatedFields.check3);
    setStepFields(updatedFields);
  }, [stepData]);

  useEffect(() => {
    return () => {
      clearInterval(intervalIdRefone?.current);
      clearInterval(intervalIdReftwo?.current);
      clearInterval(intervalIdRefthree?.current);
    };
  }, []);
  const handleSubmit = async (values: any) => {
    try {
      if (user?.formStatus === "SUBMITTED") {
        let payload = [
          {
            name: 'check1',
            value: check1,
          },
          {
            name: 'check2',
            value: check2,
          },
          {
            name: 'check3',
            value: check3,
          },

          {
            name: "germanLastEntry",
            value: values.germanLastEntry,
          },
          {
            name: "c1Lame",
            value: values.c1Lame,
          },
          {
            name: "c1Fname",
            value: values.c1Fname,
          },
          {
            name: "c1Gender",
            value: values.c1Gender,
          },
          {
            name: "c1Birthplcae",
            value: values.c1Birthplcae,
          },
          {
            name: "c1BirthCountry",
            value: values.c1BirthCountry,
          },
          {
            name: "c1Citizenship",
            value: values.c1Citizenship,
          },
          {
            name: "c1ResCountry",
            value: values.c1ResCountry,
          },
          {
            name: "c2Lame",
            value: values.c2Lame,
          },
          {
            name: "c2Fname",
            value: values.c2Fname,
          },
          {
            name: "c2Gender",
            value: values.c2Gender,
          },
          {
            name: "c2Birthplcae",
            value: values.c2Birthplcae,
          },
          {
            name: "c2BirthCountry",
            value: values.c2BirthCountry,
          },
          {
            name: "c2Citizenship",
            value: values.c2Citizenship,
          },
          {
            name: "c2ResCountry",
            value: values.c2ResCountry,
          },
          {
            name: "c3Lame",
            value: values.c3Lame,
          },
          {
            name: "c3Fname",
            value: values.c3Fname,
          },
          {
            name: "c3Gender",
            value: values.c3Gender,
          },
          {
            name: "c3Birthplcae",
            value: values.c3Birthplcae,
          },
          {
            name: "c3BirthCountry",
            value: values.c3BirthCountry,
          },
          {
            name: "c3Citizenship",
            value: values.c3Citizenship,
          },
          {
            name: "c3ResCountry",
            value: values.c3ResCountry,
          },

          {
            name: "studyprogramme",
            value: values.studyprogramme,
          },
          {
            name: "authorisation",
            value: values.authorisation,
          },
          {
            name: "residence",
            value: values.residence,
          },
          {
            name: "maritalStatus",
            value: values.maritalStatus,
          },
          {
            name: "children",
            value: values.children,
          },
          {
            name: "father",
            value: values.father,
          },
          {
            name: "mother",
            value: values.mother,
          },
          {
            name: "criminality",
            value: values.criminality,
          },
          {
            name: "germanyRejection",
            value: values.germanyRejection,
          },
          {
            name: "germanyRejectionReason",
            value: values.germanyRejectionReason,
          },
          {
            name: "otherRejection",
            value: values.otherRejection,
          },
          {
            name: "otherRejectionReason",
            value: values.otherRejectionReason,
          },
          {
            name: "subsistence",
            value: values.subsistence,
          },
          {
            name: "healthInsurance",
            value: values.healthInsurance,
          },
          {
            name: "matriculationLetter",
            value: values.matriculationLetter,
          },
          {
            name: "germanLastEntryFrom1",
            value: values.germanLastEntryFrom1 !== "" ? new Date(moment(new Date(values.germanLastEntryFrom1)).format("YYYY-MM-DD")).toISOString() : ""
          },
          {
            name: "germanLastEntryCity1",
            value: values.germanLastEntryCity1,
          },
          {
            name: "germanLastEntryTo1",
            value: values.germanLastEntryTo1 !== "" ? new Date(moment(new Date(values.germanLastEntryTo1)).format("YYYY-MM-DD")).toISOString() : "",
          },
          {
            name: "germanLastEntryFrom2",
            value: values.germanLastEntryFrom2 !== "" ? new Date(moment(new Date(values.germanLastEntryFrom2)).format("YYYY-MM-DD")).toISOString() : ""
          }, {
            name: "germanLastEntryCity2",
            value: values.germanLastEntryCity2,
          },
          {
            name: "germanLastEntryTo2",
            value: values.germanLastEntryTo2 !== "" ? new Date(moment(new Date(values.germanLastEntryTo2)).format("YYYY-MM-DD")).toISOString() : "",
          },
          {
            name: "germanLastEntryFrom3",
            value: values.germanLastEntryFrom3 !== "" ? new Date(moment(new Date(values.germanLastEntryFrom3)).format("YYYY-MM-DD")).toISOString() : ""
          }, {
            name: "germanLastEntryCity3",
            value: values.germanLastEntryCity3,
          },
          {
            name: "germanLastEntryTo3",
            value: values.germanLastEntryTo3 !== "" ? new Date(moment(new Date(values.germanLastEntryTo3)).format("YYYY-MM-DD")).toISOString() : "",
          },
          {
            name: "authCountry",
            value: values.authCountry,
          },
          {
            name: "authDate",
            value: values.authDate !== "" ? new Date(moment(new Date(values.authDate)).format("YYYY-MM-DD")).toISOString() : ""
          },
          {
            name: "widowedDate",
            value: values.widowedDate !== "" ? new Date(moment(new Date(values.widowedDate)).format("YYYY-MM-DD")).toISOString() : ""
          },
          {
            name: "c1dob",
            value: values.c1dob !== "" ? new Date(moment(new Date(values.c1dob)).format("YYYY-MM-DD")).toISOString() : ""
          },
          {
            name: "c2dob",
            value: values.c2dob !== "" ? new Date(moment(new Date(values.c2dob)).format("YYYY-MM-DD")).toISOString() : ""
          }, {
            name: "c3dob",
            value: values.c3dob !== "" ? new Date(moment(new Date(values.c3dob)).format("YYYY-MM-DD")).toISOString() : ""
          },
          {
            name: "dom",
            value: values.maritalStatus === "Married" ?
              values.dom !== "" ? new Date(moment(new Date(values.dom)).format("YYYY-MM-DD")).toISOString() : ""
              : ""
          },
          {
            name: "spouseDob",
            value: values.maritalStatus === "Married" ?
              values.spouseDob !== "" ? new Date(moment(new Date(values.spouseDob)).format("YYYY-MM-DD")).toISOString() : ""
              : ""
          },
          {
            name: "mrgCity",
            value: values.maritalStatus === "Married" ?
              values.mrgCity !== "" ? values.mrgCity : ""
              : ""
          },
          {
            name: "mrgCountry",
            value: values.maritalStatus === "Married" ?
              values.mrgCountry !== "" ? values.mrgCountry : ""
              : ""
          },
          {
            name: "spouseLname",
            value: values.maritalStatus === "Married" ?
              values.spouseLname !== "" ? values.spouseLname : ""
              : ""
          },
          {
            name: "spouseFname",
            value: values.maritalStatus === "Married" ?
              values.spouseFname !== "" ? values.spouseFname : ""
              : ""
          },
          {
            name: "spouseName",
            value: values.maritalStatus === "Married" ?
              values.spouseName !== "" ? values.spouseName : ""
              : ""
          },
          {
            name: "spouseGender",
            value: values.maritalStatus === "Married" ?
              values.spouseGender !== "" ? values.spouseGender : ""
              : ""
          },
          {
            name: "spouseCob",
            value: values.maritalStatus === "Married" ?
              values.spouseCob !== "" ? values.spouseCob : ""
              : ""
          },
          {
            name: "spouseCitizenship",
            value: values.maritalStatus === "Married" ?
              values.spouseCitizenship !== "" ? values.spouseCitizenship : ""
              : ""
          },
          {
            name: "spouseAddress",
            value: values.maritalStatus === "Married" ?
              values.spouseAddress !== "" ? values.spouseAddress : ""
              : ""
          },
          {
            name: "spouseZipcode",
            value: values.maritalStatus === "Married" ?
              values.spouseZipcode !== "" ? values.spouseZipcode : ""
              : ""
          },
          {
            name: "spouseRes",
            value: values.maritalStatus === "Married" ?
              values.spouseRes !== "" ? values.spouseRes : ""
              : ""
          },
          {
            name: "spouseRescountry",
            value: values.maritalStatus === "Married" ?
              values.spouseRescountry !== "" ? values.spouseRescountry : ""
              : ""
          },
          {
            name: "residenceCountry",
            value: values.residenceCountry,
          },
          {
            name: "fatherLname",
            value: values.fatherLname,
          },
          {
            name: "fatherFname",
            value: values.fatherFname,
          },
          {
            name: "fatherInfo",
            value: values.fatherInfo,
          },
          {
            name: "fatherDob",
            value: values.fatherDob !== "" ? new Date(moment(new Date(values.fatherDob)).format("YYYY-MM-DD")).toISOString() : ""
          },
          {
            name: "fatherBirthplcae",
            value: values.fatherBirthplcae,
          },
          {
            name: "fatherBirthCountry",
            value: values.fatherBirthCountry,
          },
          {
            name: "fatherCitizenship",
            value: values.fatherCitizenship,
          },
          {
            name: "fatherResCountry",
            value: values.fatherResCountry,
          },
          {
            name: "motherLname",
            value: values.motherLname,
          },
          {
            name: "motherFname",
            value: values.motherFname,
          },
          {
            name: "motherInfo",
            value: values.motherInfo,
          },
          {
            name: "motherBirthplcae",
            value: values.motherBirthplcae,
          },
          {
            name: "motherBirthCountry",
            value: values.motherBirthCountry,
          },
          {
            name: "motherCitizenship",
            value: values.motherCitizenship,
          },
          {
            name: "motherDob",
            value: values.motherDob !== "" ? new Date(moment(new Date(values.motherDob)).format("YYYY-MM-DD")).toISOString() : ""
          },
          {
            name: "motherResCountry",
            value: values.motherResCountry,
          },
          {
            name: "date",
            value:
              values.date !== ""
                ? new Date(
                  moment(new Date(values.date)).format("YYYY-MM-DD")
                ).toISOString()
                : values.date,
          },
          {
            name: "conviction1",
            value: values.conviction1,
          },
          {
            name: "convictionDate1",
            value:
              values.convictionDate1 !== ""
                ? new Date(moment(new Date(values.convictionDate1)).format("YYYY-MM-DD")).toISOString()
                : values.convictionDate1,
          },
          {
            name: "resume1",
            value: values.resume1,
          },
          {
            name: "penalty1",
            value: values.penalty1,
          },
          {
            name: "conviction2",
            value: values.conviction2,
          },
          {
            name: "convictionDate2",
            value:
              values.convictionDate2 !== ""
                ? new Date(moment(new Date(values.convictionDate2)).format("YYYY-MM-DD")).toISOString()
                : values.convictionDate2,
          },
          {
            name: "resume2",
            value: values.resume2,
          },
          {
            name: "penalty2",
            value: values.penalty2,
          },
        ];
        const uniqueNames: { [name: string]: boolean } = {};
        let updatedArr: { name: string; value: string | undefined }[] = [];
        payload.map((item) => {
          let itemName = item.name as keyof StepSevenTypes;
          const oldValue = stepFields[itemName];
          if (!oldValue) {
            uniqueNames[item.name] = true;
            updatedArr.push({ ...item, value: item.value });
          }
          if (oldValue && oldValue !== item.value) {
            if (!uniqueNames[item.name]) {
              uniqueNames[item.name] = true;
              updatedArr.push({ ...item, value: item.value });
            }
          }
        });
        await verifyCorrection(updatedArr);
      } else {
        let payload = [
          {
            name: 'check1',
            value: check1,
            isShow: true,
          },
          {
            name: 'check2',
            value: check2,
            isShow: true,
          },
          {
            name: 'check3',
            value: check3,
            isShow: true,
          },
          {
            name: "c1dob",
            value: values.c1dob !== "" ? new Date(moment(new Date(values.c1dob)).format("YYYY-MM-DD")).toISOString() : "",
            isShow: true,
          },
          {
            name: "c2dob",
            value: values.c2dob !== "" ? new Date(moment(new Date(values.c2dob)).format("YYYY-MM-DD")).toISOString() : "",
            isShow: true,
          }, {
            name: "c3dob",
            value: values.c3dob !== "" ? new Date(moment(new Date(values.c3dob)).format("YYYY-MM-DD")).toISOString() : "",
            isShow: true,
          },
          {
            name: "germanLastEntry",
            label: "Have you ever been to Germany before your last entry?",
            value: values.germanLastEntry,
            isShow: true,
          },
          {
            name: "c1Lame",
            value: values.c1Lame,
            label: "Children 1 - Last name",
            isShow: true,
          },
          {
            name: "c1Fname",
            value: values.c1Fname,
            label: "Children 1 - First name",
            isShow: true,
          },
          {
            name: "c1Gender",
            label: "Children 1 - Gender",
            value: values.c1Gender,
            isShow: true,
          },
          {
            name: "c1Birthplcae",
            label: "Children 1 - Place of birth",
            value: values.c1Birthplcae,
            isShow: true,
          },
          {
            name: "c1BirthCountry",
            label: "Children 1 - Country of birth",
            value: values.c1BirthCountry,
            isShow: true,
          },
          {
            name: "c1Citizenship",
            label: "Children 1 - Citizenship",
            value: values.c1Citizenship,
            isShow: true,
          },
          {
            name: "c1ResCountry",
            label: "Children 1 - Actual country of residence",
            value: values.c1ResCountry,
            isShow: true,
          },
          {
            name: "c2Lame",
            label: "Children 2 - Last name",
            value: values.c2Lame,
            isShow: true,
          },
          {
            name: "c2Fname",
            label: "Children 2 - First name",
            value: values.c2Fname,
            isShow: true,
          },
          {
            name: "c2Gender",
            label: "Children 2 - Gender",
            value: values.c2Gender,
            isShow: true,
          },
          {
            name: "c2Birthplcae",
            label: "Children 2 - Place of birth",
            value: values.c2Birthplcae,
            isShow: true,
          },
          {
            name: "c2BirthCountry",
            label: "Children 2 - Country of birth",
            value: values.c2BirthCountry,
            isShow: true,
          },
          {
            name: "c2Citizenship",
            label: "Children 2 - Citizenship",
            value: values.c2Citizenship,
            isShow: true,
          },
          {
            name: "c2ResCountry",
            label: "Children 2 - Actual country of residence",
            value: values.c2ResCountry,
            isShow: true,
          },
          {
            name: "c3Lame",
            label: "Children 3 - Last name",
            value: values.c3Lame,
            isShow: true,
          },
          {
            name: "c3Fname",
            label: "Children 3 - First name",
            value: values.c3Fname,
            isShow: true,
          },
          {
            name: "c3Gender",
            label: "Children 3 - Gender",
            value: values.c3Gender,
            isShow: true,

          },
          {
            name: "c3Birthplcae",
            label: "Children 3 - Place of birth",
            value: values.c3Birthplcae,
            isShow: true,

          },
          {
            name: "c3BirthCountry",
            label: "Children 3 - Country of birth",
            value: values.c3BirthCountry,
            isShow: true,

          },
          {
            name: "c3Citizenship",
            label: "Children 3 - Citizenship",
            value: values.c3Citizenship,
            isShow: true,

          },
          {
            name: "c3ResCountry",
            label: "Children 3 - Actual country of residence",
            value: values.c3ResCountry,
            isShow: true,

          },

          {
            name: "widowedDate",
            label: "Widowed since when?",
            value: values?.maritalStatus === "Widowed" ? values.widowedDate : "",
            isShow: true,
          },
          {
            name: "dom",
            label: "Date of marriage",
            value: values.maritalStatus === "Married" ?
              values.dom !== "" ? new Date(moment(new Date(values.dom)).format("YYYY-MM-DD")).toISOString() : ""
              : "",
            isShow: true,

          },
          {
            name: "spouseDob",
            label: "Spouse - Date of birth",
            value: values.maritalStatus === "Married" ?
              values.spouseDob !== "" ? new Date(moment(new Date(values.spouseDob)).format("YYYY-MM-DD")).toISOString() : ""
              : "",
            isShow: true,

          },
          {
            name: "mrgCity",
            label: "Marriage city",
            value: values.maritalStatus === "Married" ?
              values.mrgCity !== "" ? values.mrgCity : ""
              : "",
            isShow: true,

          },
          {
            name: "mrgCountry",
            label: "Marriage city",
            value: values.maritalStatus === "Married" ?
              values.mrgCountry !== "" ? values.mrgCountry : ""
              : "",
            isShow: true,

          },
          {
            name: "spouseLname",
            label: "Spouse- Last name",
            value: values.maritalStatus === "Married" ?
              values.spouseLname !== "" ? values.spouseLname : ""
              : "",
            isShow: true,

          },
          {
            name: "spouseFname",
            label: "Spouse- First name",
            value: values.maritalStatus === "Married" ?
              values.spouseFname !== "" ? values.spouseFname : ""
              : "",
            isShow: true,
          },
          {
            name: "spouseName",
            label: "Spouse- Name",
            value: values.maritalStatus === "Married" ?
              values.spouseName !== "" ? values.spouseName : ""
              : "",
            isShow: true,

          },
          {
            name: "spouseGender",
            label: "Spouse- Gender",
            value: values.maritalStatus === "Married" ?
              values.spouseGender !== "" ? values.spouseGender : ""
              : "",
            isShow: true,

          },
          {
            name: "spouseCob",
            label: "Spouse- Date of birth",
            value: values.maritalStatus === "Married" ?
              values.spouseCob !== "" ? values.spouseCob : ""
              : "",
            isShow: true,

          },
          {
            name: "spouseCitizenship",
            label: "Spouse Citizenship",
            value: values.maritalStatus === "Married" ?
              values.spouseCitizenship !== "" ? values.spouseCitizenship : ""
              : "",
            isShow: true,

          },
          {
            name: "spouseAddress",
            label: "Spouse Address",
            value: values.maritalStatus === "Married" ?
              values.spouseAddress !== "" ? values.spouseAddress : ""
              : "",
            isShow: true,

          },
          {
            name: "spouseZipcode",
            label: "Spouse zipcode",
            value: values.maritalStatus === "Married" ?
              values.spouseZipcode !== "" ? values.spouseZipcode : ""
              : "",
            isShow: true,

          },
          {
            name: "spouseRes",
            label: "Spouse residence",
            value: values.maritalStatus === "Married" ?
              values.spouseRes !== "" ? values.spouseRes : ""
              : "",
            isShow: true,

          },
          {
            name: "spouseRescountry",
            label: "Spouse residence country",
            value: values.maritalStatus === "Married" ?
              values.spouseRescountry !== "" ? values.spouseRescountry : ""
              : "",
            isShow: true,

          },

          {
            name: "germanLastEntryFrom1",
            label: "From(YYYY-MM-DD)",
            value: values.germanLastEntry === "Yes" ?
              values.germanLastEntryFrom1 !== "" ? new Date(moment(new Date(values.germanLastEntryFrom1)).format("YYYY-MM-DD")).toISOString() : ""
              : "",
            isShow: true,
          },
          {
            name: "germanLastEntryCity1",
            label: "City",
            value: values.germanLastEntry === "Yes" ? values.germanLastEntryCity1 : "",
            isShow: true,
          }, {
            name: "germanLastEntryTo1",
            label: "To(YYYY-MM-DD)",
            value: values.germanLastEntry === "Yes" ?
              values.germanLastEntryTo1 !== "" ? new Date(moment(new Date(values.germanLastEntryTo1)).format("YYYY-MM-DD")).toISOString() : ""
              : "",
            isShow: true,
          },
          {
            name: "germanLastEntryFrom2",
            label: "From(YYYY-MM-DD)",
            value: values.germanLastEntry === "Yes" ?
              values.germanLastEntryFrom2 !== "" ? new Date(moment(new Date(values.germanLastEntryFrom2)).format("YYYY-MM-DD")).toISOString() : ""
              : "",
            isShow: true,
          },
          {
            name: "germanLastEntryCity2",
            label: "City",
            value: values.germanLastEntry === "Yes" ? values.germanLastEntryCity2 : "",
            isShow: true,
          }, {
            name: "germanLastEntryTo2",
            label: "To(YYYY-MM-DD)",
            value: values.germanLastEntry === "Yes" ?
              values.germanLastEntryTo2 !== "" ? new Date(moment(new Date(values.germanLastEntryTo2)).format("YYYY-MM-DD")).toISOString() : ""
              : "",
            isShow: true,
          },
          {
            name: "germanLastEntryFrom3",
            label: "From(YYYY-MM-DD)",
            value: values.germanLastEntry === "Yes" ?
              values.germanLastEntryFrom3 !== "" ? new Date(moment(new Date(values.germanLastEntryFrom3)).format("YYYY-MM-DD")).toISOString() : ""
              : "",
            isShow: true,
          },
          {
            name: "germanLastEntryCity3",
            label: "City",
            value: values.germanLastEntry === "Yes" ? values.germanLastEntryCity3 : "",
            isShow: true,
          }, {
            name: "germanLastEntryTo3",
            label: "To(YYYY-MM-DD)",
            value: values.germanLastEntry === "Yes" ?
              values.germanLastEntryTo3 !== "" ? new Date(moment(new Date(values.germanLastEntryTo3)).format("YYYY-MM-DD")).toISOString() : ""
              : "",
            isShow: true,
          },
          {
            name: "studyprogramme",
            label: "Study information",
            value: values.studyprogramme,
            isShow: true,
          },
          {
            name: "authorisation",
            label: "Authorisation to return to another country",
            value: values.authorisation,
            isShow: true,
          },
          {
            name: "authCountry",
            label: "Authorisation to return to",
            value: values.authorisation === "Yes" ? values.authCountry : "",
            isShow: true,
          },
          {
            name: "authDate",
            label: "Valid until(YYYY-MM-DD)",
            value: values.authorisation === "Yes" ?
              values.authDate !== ""
                ? new Date(moment(new Date(values.authDate)).format("YYYY-MM-DD")).toISOString()
                : values.authDate
              : "",
            isShow: true,
          },
          {
            name: "residence",
            label: "Do you intend to maintain your permanent residence outside Germany?",
            value: values.residence,
            isShow: true,
          },
          {
            name: "residenceCountry",
            label: "If yes, where?",
            value: values.residence === "Yes" ? values.residenceCountry : "",
            isShow: true,
          },
          {
            name: "fatherLname",
            label: "Father - Lastname",
            value: values.father === "Yes" ? values.fatherLname : "",
            isShow: true,
          },
          {
            name: "fatherFname",
            label: "Father - First name",
            value: values.father === "Yes" ? values.fatherFname : "",
            isShow: true,
          },
          {
            name: "fatherInfo",
            label: "Do you have information about him?",
            value: values.father === "Yes" ? values.fatherInfo : "",
            isShow: true,
          },
          {
            name: "fatherDob",
            label: "Father - Date of birth(YYYY-MM-DD)",
            value: values.fatherInfo === "Yes" ?
              values.fatherDob !== ""
                ? new Date(moment(new Date(values.fatherDob)).format("YYYY-MM-DD")).toISOString()
                : values.fatherDob
              : "",
            isShow: true,
          },
          {
            name: "fatherBirthplcae",
            label: "Father - Place of birth",
            value: values.fatherInfo === "Yes" ? values?.fatherBirthplcae : "",
            isShow: true,
          },
          {
            name: "fatherBirthCountry",
            label: "Father - Country of birth",
            value: values.fatherInfo === "Yes" ? values?.fatherBirthCountry : "",
            isShow: true,
          },
          {
            name: "fatherCitizenship",
            label: "Father - Citizenship",
            value: values.fatherInfo === "Yes" ? values?.fatherCitizenship : "",
            isShow: true,
          },
          {
            name: "fatherResCountry",
            label: "Father - Actual country of residence",
            value: values.fatherInfo === "Yes" ? values?.fatherResCountry : "",
            isShow: true,
          },
          {
            name: "motherLname",
            label: "Mother - Lastname",
            value: values.mother === "Yes" ? values.motherLname : "",
            isShow: true,
          },
          {
            name: "motherFname",
            label: "Mother - First name",
            value: values.mother === "Yes" ? values.motherFname : "",
            isShow: true,
          },
          {
            name: "motherInfo",
            label: "Do you have information about her?",
            value: values.mother === "Yes" ? values.motherInfo : "",
            isShow: true,
          },
          {
            name: "motherDob",
            label: "Mother - Date of birth(YYYY-MM-DD)",
            value: values.motherInfo === "Yes" ?
              values.motherDob !== ""
                ? new Date(moment(new Date(values.motherDob)).format("YYYY-MM-DD")).toISOString()
                : values.motherDob
              : "",
            isShow: true,
          },
          {
            name: "motherBirthplcae",
            label: "Mother - Place of birth",
            value: values.motherInfo === "Yes" ? values?.motherBirthplcae : "",
            isShow: true,
          },
          {
            name: "motherBirthCountry",
            label: "Mother - Country of birth",
            value: values.motherInfo === "Yes" ? values?.motherBirthCountry : "",
            isShow: true,
          },
          {
            name: "motherCitizenship",
            label: "Mother - Citizenship",
            value: values.motherInfo === "Yes" ? values?.motherCitizenship : "",
            isShow: true,
          },
          {
            name: "motherResCountry",
            label: "Mother - Actual country of residence",
            value: values.motherInfo === "Yes" ? values?.motherResCountry : "",
            isShow: true,
          },

          {
            name: "maritalStatus",
            label: "Marital status",
            value: values.maritalStatus,
            isShow: true,
          },
          {
            name: "children",
            label: "Do you have children?",
            value: values.children,
            isShow: true,
          },
          {
            name: "father",
            label: "Do you know your father",
            value: values.father,
            isShow: true,
          },
          {
            name: "mother",
            label: "Do you know your mother",
            value: values.mother,
            isShow: true,
          },
          {
            name: "criminality",
            label: "Have you ever been convicted of a criminal offence?",
            value: values.criminality,
            isShow: true,
          },
          {
            name: "conviction1",
            label: "Enter the official place of conviction",
            value: values.criminality === "Yes" ? values.conviction1 : "",
            isShow: true,
          },
          {
            name: "convictionDate1",
            label: "Enter the official date of conviction(YYYY-MM-DD)",
            value: values.criminality === "Yes" ?
              values.convictionDate1 !== ""
                ? new Date(moment(new Date(values.convictionDate1)).format("YYYY-MM-DD")).toISOString()
                : values.convictionDate1 : "",
            isShow: true,
          },
          {
            name: "resume1",
            label: "Resume the concrete ground(s) of conviction",
            value: values.criminality === "Yes" ? values.resume1 : "",
            isShow: true,

          },
          {
            name: "penalty1",
            label: "Describe the nature and extent of the penalties as written in the official conviction letter",
            value: values.criminality === "Yes" ? values.penalty1 : "",
            isShow: true,
          },
          {
            name: "conviction2",
            label: "Enter the official place of conviction",
            value: values.criminality === "Yes" ? values.conviction2 : "",
            isShow: true,
          },
          {
            name: "convictionDate2",
            label: "Enter the official date of conviction(YYYY-MM-DD)",
            isShow: true,
            value: values.criminality === "Yes" ?
              values.convictionDate2 !== ""
                ? new Date(moment(new Date(values.convictionDate2)).format("YYYY-MM-DD")).toISOString()
                : values.convictionDate2 : "",
          },
          {
            name: "resume2",
            label: "Resume the concrete ground(s) of conviction",
            value: values.criminality === "Yes" ? values.resume2 : "",
            isShow: true,
          },
          {
            name: "penalty2",
            label: "Describe the nature and extent of the penalties as written in the official conviction letter",
            value: values.criminality === "Yes" ? values.penalty2 : "",
            isShow: true,
          },
          {
            name: "germanyRejection",
            label:
              "Have you been expelled or deported from the Federal Rep. of Germany, had an application for a visa or residence permit rejected, or been refused entry into the Federal Republic of Germany?",
            value: values.germanyRejection,
            isShow: true,
          },
          {
            name: "germanyRejectionReason",
            label: "Reason for rejection from germany",
            value: values.germanyRejectionReason,
            isShow: true,
          },
          {
            name: "otherRejection",
            label: "Reason for rejection",
            value: values.otherRejection,
            isShow: true,
          },
          {
            name: "otherRejectionReason",
            label: "Reason for rejection(exclude germany)",
            value: values.otherRejectionReason,
            isShow: true,
          },
          {
            name: "subsistence",
            label: "Do you have a Blocked Account",
            value: values.subsistence,
            isShow: true,
          },
          {
            name: "healthInsurance",
            label: "Do you have an adequate health insurance coverage",
            value: values.healthInsurance,
            isShow: true,
          },
          {
            name: "matriculationLetter",
            label:
              "Did you receive the matriculation letter from your university?",
            value: values.matriculationLetter,
            isShow: true,
          },
          {
            name: "date",
            label: "Enter the very first expected end date of your studies",
            value:
              values.date !== ""
                ? new Date(
                  moment(new Date(values.date)).format("YYYY-MM-DD")
                ).toISOString()
                : values.date,
            isShow: true,
          },
        ];
        console.log("payload", payload);
        // {
        //   name: "germanLastEntry",
        //   label: "Have you ever been to Germany before your last entry?",
        //   value: values.germanLastEntry,
        //   isShow: true,
        // },
        const uniqueNames: { [name: string]: boolean } = {};
        let updatedArr: any = [];
        payload.map((item) => {
          let itemName = item.name as keyof StepSevenTypes;
          const oldValue = stepFields[itemName];
          if (oldValue && oldValue !== item.value) {
            if (!uniqueNames[item.name]) {
              uniqueNames[item.name] = true;
              updatedArr.push({ ...item, value: item.value });
            }
          }
        });
        await submitStepdata(
          updatedArr?.length > 0 ? updatedArr : payload,
          null
        );
      }
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
    }
  };

  const showField = (field: string) => {
    if (!stepData) return true;
    let data = stepData?.find((item) => item.name === field);
    return !user?.formStatus ? true : !data ? false : data?.isShow;
    // return !data ? false : !user?.formStatus ? true : data?.isShow
  };

  const showComments = (field: string) => {
    if (!stepData) return false;
    let data = stepData?.find(
      (item) =>
        item.name === field && item?.comments && item?.comments?.length > 0
    );
    return !data ? false : true;
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        nextActive
          ? user?.formStatus === "SUBMITTED"
            ? ""
            : stepSevenSchema
          : ""
      }
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values, handleChange }: any) => (
        <Form id={currentStep.toString()} className="w-full">
          <div className="border-[2px] border-[#E1E5EA] rounded-[8px] max-w-full bg-white p-4 h-full lg:ml-[6.3rem] ml-0">
            <div className={showField("germanLastEntry") ? "block" : "hidden"}>
              <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full">
                <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                  <label className="text-[20px] font-semibold">
                    Travels to Germany in the past
                  </label>
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <label
                      htmlFor={"germanLastEntry"}
                      className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                      Have you ever been to Germany before your last entry?
                      <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <Field name="germanLastEntry">
                    {({ field, form }: FieldProps) => (
                      <div className="flex flex-col gap-3 justify-center">
                        <RadioGroup
                          {...field}
                          fieldName="germanLastEntry"
                          options={addressOptions}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.setFieldValue(
                              "germanLastEntry",
                              event.target.value
                            );
                          }}
                          className={
                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                          }
                          checkedField={values.germanLastEntry}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="germanLastEntry"
                    component="div"
                    className="text-red-500"
                  />
                  {showComments("germanLastEntry") && (
                    <CommentSection
                      field={"germanLastEntry"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                </div>
                {values.germanLastEntry === "Yes" && (
                  <GermanEntryComp
                    values={values}
                    showField={showField}
                    showComments={showComments}
                    handleChange={handleChange}
                  />
                )}
              </div>
            </div>
            <div className={showField("authorisation") ? "block" : "hidden"}>
              <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
                <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                  <label className="text-[20px] font-semibold">
                    Authorisation to return to another country
                  </label>
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <label
                      htmlFor={"authorisation"}
                      className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                      Do you have any authorisation to return to a country which
                      is not your home country
                      <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <Field name="authorisation">
                    {({ field, form }: FieldProps) => (
                      <div className="flex flex-col gap-3 justify-center">
                        <RadioGroup
                          {...field}
                          fieldName="authorisation"
                          options={addressOptions}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.setFieldValue(
                              "authorisation",
                              event.target.value
                            );
                          }}
                          className={
                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                          }
                          checkedField={values.authorisation}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="authorisation"
                    component="div"
                    className="text-red-500"
                  />
                  {showComments("authorisation") && (
                    <CommentSection
                      field={"authorisation"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                  {values.authorisation === "Yes" && (
                    <AuthorizationOne
                      values={values}
                      showField={showField}
                      showComments={showComments}
                      handleChange={handleChange}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={showField("residence") ? "block" : "hidden"}>
              <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
                <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                  <label className="text-[20px] font-semibold">
                    Residence outside Germany
                  </label>
                </div>
                <div className="px-4 pt-4 text-[14px] text-[#71859A]">
                  <p>
                    If you intend to study and stay in Germany and consider
                    Germany as your main residency, you should select NO.
                  </p>
                  <p>
                    Only in the case that you are in Germany for a foreseen
                    short period of time (for example not longer than 12-months)
                    and you intend to keep your apartment abroad in order to
                    return, you can select YES.
                  </p>
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <label
                      htmlFor={"residence"}
                      className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                      Do you intend to maintain your permanent residence outside
                      Germany?<span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <Field name="residence">
                    {({ field, form }: FieldProps) => (
                      <div className="flex flex-col gap-3 justify-center">
                        <RadioGroup
                          {...field}
                          fieldName="residence"
                          options={addressOptions}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.setFieldValue("residence", event.target.value);
                          }}
                          className={
                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                          }
                          checkedField={values.residence}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="residence"
                    component="div"
                    className="text-red-500"
                  />
                  {showComments("residence") && (
                    <CommentSection
                      field={"residence"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                </div>
                {values?.residence === "Yes" &&
                  <ResidenceComp
                    values={values}
                    showField={showField}
                    showComments={showComments}
                    handleChange={handleChange}
                  />
                }
              </div>
            </div>
            <div className={showField("maritalStatus") ? "block" : "hidden"}>
              <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
                <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                  <label className="text-[20px] font-semibold">
                    Marital Status
                  </label>
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <label
                      htmlFor={"maritalStatus"}
                      className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                      Marital Status{" "}
                      <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <Field name="maritalStatus">
                    {({ field, form }: FieldProps) => (
                      <div className="flex flex-col gap-3 justify-center">
                        <RadioGroup
                          {...field}
                          fieldName="maritalStatus"
                          options={maritalOptions}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.setFieldValue(
                              "maritalStatus",
                              event.target.value
                            );
                          }}
                          className={
                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                          }
                          checkedField={values.maritalStatus}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="maritalStatus"
                    component="div"
                    className="text-red-500"
                  />
                  {showComments("maritalStatus") && (
                    <CommentSection
                      field={"maritalStatus"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                </div>
                {values?.maritalStatus === "Widowed" &&
                  <div className={showField('maritalStatus') ? "block p-4" : 'hidden'}>
                    <div className="flex justify-between items-center my-3">
                      <label
                        htmlFor={"widowedDate"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                      >
                        Widowed since when?
                      </label>
                    </div>
                    <Field name="widowedDate">
                      {({ field, form }: FieldProps) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateInput
                            {...field}
                            label="YYYY-MM-DD"
                            renderInput={(props: any) => (
                              <TextField {...props} />
                            )}
                            format="YYYY-MM-DD"
                            value={values.widowedDate}
                            onChange={(newDate: any) => {
                              form.setFieldValue("widowedDate", newDate);
                            }}
                            className={
                              "password-input"
                            }
                            disableFuture
                          />
                        </LocalizationProvider>
                      )}
                    </Field>
                    <ErrorMessage
                      name="widowedDate"
                      component="div"
                      className="text-red-600"
                    />
                    {showComments('widowedDate') &&
                      <CommentSection field={'widowedDate'} step={currentStep - 1} userType="student" />
                    }
                  </div>
                }
                {values?.maritalStatus === "Married" &&
                  <MarriedComp
                    values={values}
                    showField={showField}
                    showComments={showComments}
                    handleChange={handleChange}
                    uploadDocument={uploadDocument}
                  />
                }
              </div>
            </div>
            <div className={showField("children") ? "block" : "hidden"}>
              <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
                <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                  <label className="text-[20px] font-semibold">Children</label>
                </div>
                <div className="px-4 pt-4 text-[14px] text-[#71859A]">
                  <p>
                    On the next page you will need to provide information about
                    your children. Please enter the information as it is written
                    on your children's passports.{" "}
                  </p>
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <label
                      htmlFor={"children"}
                      className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                      Do you have children?
                      <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <Field name="children">
                    {({ field, form }: FieldProps) => (
                      <div className="flex flex-col gap-3 justify-center">
                        <RadioGroup
                          {...field}
                          fieldName="children"
                          options={addressOptions}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.setFieldValue("children", event.target.value);
                          }}
                          className={
                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                          }
                          checkedField={values.children}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="children"
                    component="div"
                    className="text-red-500"
                  />
                  {showComments("children") && (
                    <CommentSection
                      field={"children"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                </div>
                {values.children === "Yes" &&
                  <ChildrenComp
                    values={values}
                    showField={showField}
                    showComments={showComments}
                    handleChange={handleChange}
                  />
                }
              </div>
            </div>
            <div className={showField("father") ? "block" : "hidden"}>
              <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
                <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                  <label className="text-[20px] font-semibold">Father</label>
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <label
                      htmlFor={"father"}
                      className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                      Do you know your father
                      <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <Field name="father">
                    {({ field, form }: FieldProps) => (
                      <div className="flex flex-col gap-3 justify-center">
                        <RadioGroup
                          {...field}
                          fieldName="father"
                          options={addressOptions}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.setFieldValue("father", event.target.value);
                          }}
                          className={
                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                          }
                          checkedField={values.father}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="father"
                    component="div"
                    className="text-red-500"
                  />
                  {showComments("father") && (
                    <CommentSection
                      field={"father"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                </div>
                {values?.father === "Yes" &&
                  <FatherInfoComp
                    values={values}
                    showField={showField}
                    showComments={showComments}
                    handleChange={handleChange}
                  />
                }
              </div>
            </div>
            <div className={showField("mother") ? "block" : "hidden"}>
              <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
                <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                  <label className="text-[20px] font-semibold">Mother</label>
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <label
                      htmlFor={"mother"}
                      className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                      Do you know your mother
                      <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <Field name="mother">
                    {({ field, form }: FieldProps) => (
                      <div className="flex flex-col gap-3 justify-center">
                        <RadioGroup
                          {...field}
                          fieldName="mother"
                          options={addressOptions}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.setFieldValue("mother", event.target.value);
                          }}
                          className={
                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                          }
                          checkedField={values.mother}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="mother"
                    component="div"
                    className="text-red-500"
                  />
                  {showComments("mother") && (
                    <CommentSection
                      field={"mother"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                </div>
                {values?.mother === "Yes" &&
                  <MotherInfoComp
                    values={values}
                    showField={showField}
                    showComments={showComments}
                    handleChange={handleChange}
                  />
                }
              </div>
            </div>
            <div className={showField("criminality") ? "block" : "hidden"}>
              <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
                <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                  <label className="text-[20px] font-semibold">
                    Criminality
                  </label>
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <label
                      htmlFor={"criminality"}
                      className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                      Have you ever been convicted of a criminal offence?
                      <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <Field name="criminality">
                    {({ field, form }: FieldProps) => (
                      <div className="flex flex-col gap-3 justify-center">
                        <RadioGroup
                          {...field}
                          fieldName="criminality"
                          options={addressOptions}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.setFieldValue(
                              "criminality",
                              event.target.value
                            );
                          }}
                          className={
                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                          }
                          checkedField={values.criminality}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="criminality"
                    component="div"
                    className="text-red-500"
                  />
                  {showComments("criminality") && (
                    <CommentSection
                      field={"criminality"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                </div>
                {values.criminality === "Yes" &&
                  <>
                    <CriminalityCompOne
                      values={values}
                      showField={showField}
                      showComments={showComments}
                      handleChange={handleChange}
                    />
                    <CriminalityCompTwo
                      values={values}
                      showField={showField}
                      showComments={showComments}
                      handleChange={handleChange}
                    />
                  </>
                }
              </div>
            </div>
            <div className={showField("germanyRejection") ? "block" : "hidden"}>
              <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
                <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                  <label className="text-[20px] font-semibold">
                    Deportation or rejection from Germany
                  </label>
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <label
                      htmlFor={"germanyRejection"}
                      className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                      Have you been expelled or deported from the Federal Rep.
                      of Germany, had an application for a visa or residence
                      permit rejected, or been refused entry into the Federal
                      Republic of Germany?
                      <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <Field name="germanyRejection">
                    {({ field, form }: FieldProps) => (
                      <div className="flex flex-col gap-3 justify-center">
                        <RadioGroup
                          {...field}
                          fieldName="germanyRejection"
                          options={addressOptions}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.setFieldValue(
                              "germanyRejection",
                              event.target.value
                            );
                          }}
                          className={
                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                          }
                          checkedField={values.germanyRejection}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="germanyRejection"
                    component="div"
                    className="text-red-500"
                  />
                  {showComments("germanyRejection") && (
                    <CommentSection
                      field={"germanyRejection"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                  <div className="flex flex-col my-2">
                    <p className="font-semibold text-[16px]  text-[#0B0D0F]">
                      If you know the reasons, describe here why you have had
                      this case.
                    </p>
                    <p className="font-semibold text-[16px]  text-[#0B0D0F]">
                      If you do not know the reasons, leave this field empty{" "}
                    </p>
                  </div>
                  <div className="lg:w-1/3 xl:w-1/3 w-full">
                    <Input
                      type="text"
                      fieldName="germanyRejectionReason"
                      placeholder=""
                      setValue={values.germanyRejectionReason}
                      className={null}
                      handleChange={handleChange}
                    />
                    {showComments("germanyRejectionReason") && (
                      <CommentSection
                        field={"germanyRejectionReason"}
                        step={currentStep - 1}
                        userType="student"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={showField("otherRejection") ? "block" : "hidden"}>
              <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
                <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                  <label className="text-[20px] font-semibold">
                    Deportation or rejection from the Schengen Area (excluding
                    Germany)
                  </label>
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <label
                      htmlFor={"germanyRejection"}
                      className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                      Have you been expelled or deported from another Schengen
                      state, had an application for a visa or residence permit
                      rejected, or been refused entry into the Schengen area?
                      <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <Field name="otherRejection">
                    {({ field, form }: FieldProps) => (
                      <div className="flex flex-col gap-3 justify-center">
                        <RadioGroup
                          {...field}
                          fieldName="otherRejection"
                          options={addressOptions}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.setFieldValue(
                              "otherRejection",
                              event.target.value
                            );
                          }}
                          className={
                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                          }
                          checkedField={values.otherRejection}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="otherRejection"
                    component="div"
                    className="text-red-500"
                  />
                  {showComments("otherRejection") && (
                    <CommentSection
                      field={"otherRejection"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                  <div className="flex flex-col my-2">
                    <p className="font-semibold text-[16px]  text-[#0B0D0F]">
                      If you know the reasons, describe here why you have had
                      this case.
                    </p>
                    <p className="font-semibold text-[16px]  text-[#0B0D0F]">
                      If you do not know the reasons, leave this field empty{" "}
                    </p>
                  </div>
                  <div className="lg:w-1/3 xl:w-1/3 w-full">
                    <Input
                      type="text"
                      fieldName="otherRejectionReason"
                      placeholder=""
                      setValue={values.otherRejectionReason}
                      className={null}
                      handleChange={handleChange}
                    />
                    {showComments("otherRejectionReason") && (
                      <CommentSection
                        field={"otherRejectionReason"}
                        step={currentStep - 1}
                        userType="student"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">
                  Means of subsistence
                </label>
              </div>
              <div className="px-4 pt-4 text-[14px] text-[#71859A]">
                <p>Means of subsistence </p>
                <p>
                  The issuance of a residence permit for students presupposes
                  that their livelihood is secure. The livelihood is to be
                  regarded as secured if the person concerned can secure their
                  livelihood, including health insurance, without using public
                  services.
                </p>
                <p>
                  A Blocked Account with a German bank for 11.208 Euros (2023)
                  is accepted as proof of secure livelihood. More information
                  can be found here:
                  https://www.auswaertiges-amt.de/en/sperrkonto/388600
                </p>
              </div>
              <div className="p-4">
                <div className={showField("subsistence") ? "block" : "hidden"}>
                  <div className="flex justify-between items-center mb-2">
                    <label
                      htmlFor={"subsistence"}
                      className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                      Do you have a Blocked Account?
                      <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <Field name="residence">
                    {({ field, form }: FieldProps) => (
                      <div className="flex flex-col gap-3 justify-center">
                        <RadioGroup
                          {...field}
                          fieldName="subsistence"
                          options={addressOptions}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.setFieldValue(
                              "subsistence",
                              event.target.value
                            );
                            if (event.target.value === "No") setfileError(null);
                          }}
                          className={
                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                          }
                          checkedField={values.subsistence}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="subsistence"
                    component="div"
                    className="text-red-500"
                  />
                  {showComments("subsistence") && (
                    <CommentSection
                      field={"subsistence"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                </div>
              </div>
              {values.subsistence === "Yes" && (
                <SubsistanceFileOne
                  showField={showField}
                  setProgress={setProgress}
                  setUploadingOne={setUploadingOne}
                  setfileError={setfileError}
                  intervalIdRefone={intervalIdRefone}
                  uploadFiles={uploadFiles}
                  fileError={fileError}
                  showComments={showComments}
                  files={files}
                  uploadingOne={uploadingOne}
                  values={values}
                  removeFile={removeFile}
                  progress={progress}
                  uploadDocument={uploadDocument}
                />
              )}
              {values.subsistence === "No" && (
                <SubsistanceFileTwo
                  showField={showField}
                  showComments={showComments}
                  values={values}
                  uploadDocument={uploadDocument}
                  handleChange={handleChange}
                  check1={check1}
                  check2={check2}
                  check3={check3}
                  setCheck1={setCheck1}
                  setCheck2={setCheck2}
                  setCheck3={setCheck3}
                />
              )}
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">
                  Health insurance
                </label>
              </div>
              <div className="px-4 pt-4 text-[14px] text-[#71859A] flex flex-col gap-5">
                <p>
                  The issue of a residence permit for students presupposes that
                  their livelihood is secure. The livelihood is to be regarded
                  as secured if the person concerned can secure their livelihood
                  including adequate health insurance coverage without using
                  public services.
                </p>
                <p>
                  For the issue of the residence permit in Germany, a contract
                  must be concluded that provides adequate health insurance
                  coverage, even if travel health insurance was sufficient for
                  entry. The residence permit is not issued without sufficient
                  proof of health insurance.
                </p>
                <p>
                  The health insurance can be a German statutory or private
                  health insurance.
                </p>
                <p>
                  With statutory health insurance coverage, sufficient health
                  insurance coverage can be assumed.
                </p>
                <p>
                  The type and scope of private health insurance coverage must
                  correspond to that of statutory health insurance, i.e. it may:
                </p>
                <p>- Not include service exclusions on a larger scale;</p>
                <p>
                  - Not require the insured person to pay a deductible higher
                  than 1,200 euros per year if the income, taking the deductible
                  into account, is sufficient to generate this deductible within
                  12 months without putting the security of the livelihood at
                  risk;
                </p>
                <p>
                  - Not have any limitation of the reimbursable costs in the
                  event of illness; and
                </p>
                <p>
                  - Not have expiry or expiry clauses with regard to a certain
                  age, the abandonment of an activity, a change of purpose or
                  the loss of a legal residence status.
                </p>
                <p>
                  Sufficient insurance protection is always to be assumed in the
                  basic tariff of private health insurance, as well as if the
                  Federal Financial Supervisory Authority has confirmed to the
                  insurance company that, based on the respective insurance
                  contract, the statutory requirements relating to health
                  insurance standardised in § 257 (2a) SGB V are met and the
                  health insurance certifies this.
                </p>
                <p>
                  If private health insurance does not meet these requirements,
                  adequate health insurance coverage is generally not to be
                  assumed, even if the insurance coverage otherwise corresponds
                  to the type and scope of statutory health insurance.
                </p>
                <p>
                  Private health insurance coverage must be valid for at least
                  one year from the moment of application of the residence
                  permit application (a very actual start of the ONE year
                  validity).
                </p>
                <p>
                  Regardless of age, proof of adequate insurance cover must also
                  be presented next to the matriculation certificate.
                </p>
              </div>
              <div className="p-4">
                <div
                  className={showField("healthInsurance") ? "block" : "hidden"}
                >
                  <div className="flex justify-between items-center mb-2">
                    <label
                      htmlFor={"healthInsurance"}
                      className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                      Do you have an adequate health insurance coverage
                      <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <Field name="healthInsurance">
                    {({ field, form }: FieldProps) => (
                      <div className="flex flex-col gap-3 justify-center">
                        <RadioGroup
                          {...field}
                          fieldName="healthInsurance"
                          options={addressOptions}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.setFieldValue(
                              "healthInsurance",
                              event.target.value
                            );
                            if (event.target.value === "No") {
                              setHealthfileError(null);
                            }
                          }}
                          className={
                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                          }
                          checkedField={values.healthInsurance}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="healthInsurance"
                    component="div"
                    className="text-red-500"
                  />
                  {showComments("healthInsurance") && (
                    <CommentSection
                      field={"healthInsurance"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                </div>
                {values.healthInsurance === "Yes" && (
                  <HealthInsFileOne
                    showField={showField}
                    setProgress={setProgress}
                    setUploadingTwo={setUploadingTwo}
                    setHealthfileError={setHealthfileError}
                    intervalIdReftwo={intervalIdReftwo}
                    uploadFiles={uploadFiles}
                    healthfileError={healthfileError}
                    showComments={showComments}
                    uploadingTwo={uploadingTwo}
                    values={values}
                    removeFile={removeFile}
                    progress={progress}
                    uploadDocument={uploadDocument}
                    setHealthFiles={setHealthFiles}
                  />
                )}
              </div>
            </div>
            <div className={showField("studyprogramme") ? "block" : "hidden"}>
              <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
                <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                  <label className="text-[20px] font-semibold">
                    Study information
                  </label>
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <label
                      htmlFor={"studyprogramme"}
                      className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                      Select your study programme
                      <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <div className="lg:w-1/2 xl:w-1/2 w-full">
                    <Field name="studyprogramme">
                      {({ field, form }: FieldProps) => (
                        <SelectInput
                          {...field}
                          fieldName="studyprogramme"
                          options={options}
                          selectedOption={values.studyprogramme}
                          handleSelect={(
                            event: ChangeEvent<HTMLInputElement>
                          ) => {
                            form.setFieldValue(
                              "studyprogramme",
                              event.target.value
                            );
                          }}
                          className="w-full"
                        />
                      )}
                    </Field>
                    {showComments("studyprogramme") && (
                      <CommentSection
                        field={"studyprogramme"}
                        step={currentStep - 1}
                        userType="student"
                      />
                    )}
                  </div>
                  <ErrorMessage
                    name={"studyprogramme"}
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
            </div>
            <div className="h-fit border-[2px] rounded-md border-[#D1F0FA] max-w-full mt-4">
              <div className="radio-sections max-w-full bg-[#E8F8FD] p-4">
                <label className="text-[20px] font-semibold">
                  Matriculation letter
                </label>
              </div>
              <div className="p-4">
                <div
                  className={
                    showField("matriculationLetter") ? "block" : "hidden"
                  }
                >
                  <div className="flex justify-between items-center mb-2">
                    <label
                      htmlFor={"matriculationLetter"}
                      className="font-semibold text-[16px]  text-[#0B0D0F]"
                    >
                      Did you receive the matriculation letter from your
                      university?
                      <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                  </div>
                  <Field name="matriculationLetter">
                    {({ field, form }: FieldProps) => (
                      <div className="flex flex-col gap-3 justify-center">
                        <RadioGroup
                          {...field}
                          fieldName="matriculationLetter"
                          options={addressOptions}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            form.setFieldValue(
                              "matriculationLetter",
                              event.target.value
                            );
                            if (event.target.value === "No") {
                              setMatriculationFileError(null);
                            }
                          }}
                          className={
                            "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                          }
                          checkedField={values.matriculationLetter}
                        />
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="matriculationLetter"
                    component="div"
                    className="text-red-500"
                  />
                  {showComments("matriculationLetter") && (
                    <CommentSection
                      field={"matriculationLetter"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                  {values.matriculationLetter === "No" &&
                    <div className="flex flex-col gap-2 mt-2">
                      <label
                        className="text-[16px]  text-[#FF4444]"
                      >
                        Contact urgently <span className="font-semibold">{user?.universityEmail}</span> to get it.
                      </label>
                      <label
                        className="text-[16px]  text-[#FF4444]"
                      >
                        In order to get your status letter, you need to send your health insurance certificate to the mentioned email address, they will send you back the status letter.
                        .
                      </label>
                    </div>
                  }
                </div>
                {values.matriculationLetter === "Yes" && (
                  <MatriculationLetterOne
                    showField={showField}
                    setProgress={setProgress}
                    setUploadingThree={setUploadingThree}
                    setMatriculationFileError={setMatriculationFileError}
                    intervalIdRefthree={intervalIdRefthree}
                    uploadFiles={uploadFiles}
                    matriculationFileError={matriculationFileError}
                    showComments={showComments}
                    matriculationFiles={matriculationFiles}
                    uploadingThree={uploadingThree}
                    values={values}
                    removeFile={removeFile}
                    progress={progress}
                    uploadDocument={uploadDocument}
                  />
                )}
              </div>
              { ["No","Yes"].includes(values.matriculationLetter) && (
                <div className="flex flex-col gap-4 m-4">
                  <h3 className="text-[#0B0D0F] text-[18px]">
                    Example of matriculation letter{" "}
                  </h3>
                  <div className="w-3/4">
                    <img src={user?.uniMarticulation ? user?.uniMarticulation : letter} alt="letter" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default StepSeven;
