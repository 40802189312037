import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "store/Hooks";
import { prestudySelector } from "store/slices/Prestudy";
import { editIcon } from "utils/Images";
import { formDataType, stepDataType } from "utils/types";

const StepNine = () => {
  const { formData } = useAppSelector(prestudySelector);
  const [showData, setShowData] = useState<stepDataType[] | null>(null);

  useEffect(() => {
    const result = formData?.flatMap((obj: formDataType) => obj.data);
    if (result) {
      setShowData(result?.slice(35));
    }
  }, [formData]);

  const getValue = (
    value: any,
    name: string
  ) => {
    let val;
    if (['startDate', 'dob', 'registerDate', 'validDate', 'issueDate', 'date', 'lastEntry', 'visaExpiryDate', 'rpExpireDate', 'fcDate', 'deadLinedate'].includes(name)) {
      val = moment(value).format("YYYY-MM-DD")
    } else {
      switch (typeof value) {
        case "string":
          val = value;
          break;
        case "number":
          val = value;
          break;
        case "boolean":
          val = value ? "Yes" : "No";
          break;
        case "object":
          val = "Uploaded";
          break;
        default:
          break;
      }
    }
    return val;
  };

  return (
    <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 h-full lg:ml-[6.3rem] ml-0">
      <div className="max-w-[500px] overflow-x-scroll min-w-full">
        <table className="min-w-full rounded-lg overflow-hidden bg-[#E8F8FD] border-[1px]">
          <thead>
            <tr className="float-left">
              <th className="py-3 px-3 ">Review your inputs</th>
            </tr>
          </thead>
          {showData ?
            <tbody className="px-3 bg-white">
              {showData?.map((item: any, index: number) => (
                <>
                  {(getValue(item.value, item.name) && getValue(item.value, item.name) !== '') ?
                    <tr
                      className={`px-3 text-[#0B0D0F] text-[14px] ${index % 2 === 0 ? "bg-white" : "bg-[#F0F2F4]"
                        }`}
                      key={index}
                    >
                      <td className="px-3 py-3 w-2/3">{item.label}</td>
                      <td className="px-3 py-3">
                        <span className="text-[#0B0D0F] font-semibold">
                          {getValue(item.value, item.name)}
                        </span>
                      </td>
                      <td className="pl-3 py-3">
                        <div className="w-full flex justify-end">
                          <img
                            src={editIcon}
                            alt="editIcon"
                            className="w-4 h-4 cursor-pointer max-w-fit mr-5"
                          />
                        </div>
                      </td>
                    </tr>
                    : <></>}
                </>
              ))}
            </tbody>
            :
            <tbody className="px-3 text-[#0A4A5E] font-semibold text-[14px] text-center bg-white">
              <tr>
                <td colSpan={10} className="p-3">
                  No Record Found!
                </td>
              </tr>
            </tbody>
          }
        </table>
      </div>
    </div>
  );
};

export default StepNine;
