import React from "react";

const Loader: React.FC = () => {
  return (
    <div
      className={`loader-container w-full bg-[#FDFCFF] dark:bg-[#142333] relative`}
    >
      <div className="absolute top-[10%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
        <div className="spinner h-[30px] w-[30px] md:h-[30px] md:w-[30px] border-solid border-[4px] border-[#006497] rounded-full border-b-transparent border-r-transparent will-change-transform animate-spin" />
      </div>
    </div>
  );
};

export default Loader;
