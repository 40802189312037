import { Button } from "components/Form";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { searchIcon } from "utils/Images";
import Modal from "./Modal";
import readyTocheckService from "services/ReadyTocheckService";
import { useAppDispatch } from "store/Hooks";
import { clearUser } from "store/slices/User";
import { clearPrestudy } from "store/slices/Prestudy";
import { clearFormcorrection } from "store/slices/FormCorrection";
import { cleanAndLogout } from "utils/Helper";
import CustomLoader from "components/Loader/CustomLoader";
import { toast } from "react-toastify";

interface CorrectionStatusModalProps {
  setCorrectionModal: Dispatch<SetStateAction<boolean>>;
  student: any;
}
const ChangeCorrectionStatusModal: React.FC<CorrectionStatusModalProps> = ({
  setCorrectionModal,
  student,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [newloader, setNewLoader] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [newStatus, setNewstatus] = useState<string | null>(null);
  const [allstatus, setAllstatus] = useState<any>(null);
  const [payloadStatus, setPayloadStatus] = useState<string | null>(null);
  const [payloadNewStatus, setPayloadNewstatus] = useState<string | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const getCorrectionStatus = async () => {
    try {
      setLoading(true);
      const response = await readyTocheckService.getCorrectionStatus(
        student._id
      );
      const {
        data: { data, status, success },
      } = response;
      if (success) {
        setPayloadStatus(null);
        setStatus(data);
        const response = await readyTocheckService.getAllstatus();
        const {
          data: { data: detail, status },
        } = response;
        if (status === 200) {
          setAllstatus(detail);
          setNewstatus(null);
          setLoading(false);
        }
        if (status === 401) {
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
        }
      }
      if (status === 401) {
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const updateStatus = async () => {
    try {
      setLoader(true);
      if(payloadStatus){
        const response = await readyTocheckService.updateCorrectionStatus(
          student._id,
          payloadStatus
        );
        const {
          data: { data, status, success,message },
        } = response;
        if (success) {
          setLoader(false);
          setSearch(null);
          setNewstatus(data?.correctionStatus);
          toast(message, { className: "toast-success" });
        }
        if (status === 401) {
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
        }

      } else {
        toast.error("Please select correction status")
      }
      setLoader(false);
    } catch (error:any) {
      toast.error(error.message);
      setLoader(false);
    }
  };

  const addStatus = async () => {
    try {
      setNewLoader(true);
      if(payloadNewStatus){
        const response = await readyTocheckService.addStatus(payloadNewStatus);
        const {
          data: { status },
        } = response;
        if (status === 200) {
          setNewLoader(false);
          setSearch(null);
          setPayloadNewstatus(null);
          const resp = await readyTocheckService.getAllstatus();
          const {
            data: { data: detail, status,message },
          } = resp;
          if (status === 200) {
            setAllstatus(detail);
            setLoading(false);
            toast(message, { className: "toast-success" });
          } else {
            toast.error(message);
          }
        }
        if (status === 401) {
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
        }
  
      } else {
        toast.error("Please add correction status")
      }
      setNewLoader(false);
    } catch (error:any) {
      console.log("🚀 ~ addStatus ~ error:", error)
      toast.error(error.message);
      setNewLoader(false);
    }
  };

  const searchResult = async () => {
    try {
      const response = await readyTocheckService.search(search);
      const {
        data: { data, status },
      } = response;
      if (status === 200) {
        setAllstatus(data);
      }
      if (status === 401) {
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
    } catch (error) {
      console.log("🚀 ~ searchResult ~ error:", error);
    }
  };
  useEffect(() => {
    if (student) {
      getCorrectionStatus();
    }
  }, [student]);

  useEffect(() => {
    if (search) {
      if (search !== "") {
        searchResult();
      }
    } else {
      getCorrectionStatus();
    }
  }, [search]);

  return (
    <Modal
      title={`${student.studentId} change Correction Status`}
      handleClick={() => setCorrectionModal(false)}
    >
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="flex gap-2 items-center">
            <p className="text-[13px] text-[#0B0D0F]">
              Actual Correction Status
            </p>
            <p className="px-[16px] py-[7px] bg-[#EFF6FF] text-[#1D4ED8] font-bold text-[13px] rounded-[8px]">
              {status}
            </p>
          </div>
          <div className="my-2 flex flex-col">
            <div className="flex gap-2 bg-[#F0F2F4] w-full items-center px-4 py-2 rounded-[12px]">
              <img
                src={searchIcon}
                alt="searchIcon"
                className="w-6 h-6 ml-auto "
              />
              <input
                type="search"
                className="bg-transparent border-none w-full focus:outline-none focus:ring-0"
                placeholder="Search"
                value={search ? search : ""}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="my-3">
            <ul className="flex flex-col gap-5 px-5 ">
              {allstatus &&
                allstatus.map((item: any) => (
                  <li className="flex gap-2 items-center">
                    <input
                      type="radio"
                      id={item._id}
                      name="correction"
                      className="w-[20px] h-[20px] border-[#E1E5EA] border-[2px]"
                      value={item.title}
                      onClick={() => {
                        setPayloadStatus(item.title);
                      }}
                      checked={
                        !payloadStatus
                          ? status === item.title
                          : payloadStatus === item.title
                      }
                    />
                     <label htmlFor={item._id}>{item.title}</label>
                  </li>
                ))}
              <li className="flex flex-row gap-2 items-center">
                <input
                  type="search"
                  className="bg-transparent border-[#6B72801A] rounded-[8px] w-full focus:outline-none focus:ring-0"
                  placeholder="Add New"
                  value={payloadNewStatus ? payloadNewStatus : ""}
                  onChange={(e) => setPayloadNewstatus(e.target.value)}
                />
                <Button
                  type="submit"
                  label={!newloader ? "Add" : ""}
                  disabled={false}
                  cssClass="bg-[#0A4A5E]  text-[#FFFFFF] py-3 px-6 rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
                  handleClick={addStatus}
                >
                  {newloader && <div className="loader mx-auto" />}
                </Button>
              </li>
            </ul>
          </div>
          <div className="flex gap-2 items-center">
            <p className="text-[13px] text-[#0B0D0F]">New Correction Status</p>
            <p className="px-[16px] py-[7px] bg-[#F0FDF4] text-[#18545C] font-bold text-[13px] rounded-[8px]">
              {newStatus}
            </p>
          </div>
          <div className="submit__btn">
            <Button
              type="submit"
              label={!loader ? `Save` : ""}
              disabled={false}
              cssClass="bg-[#76B312] text-[15px] text-[#FFFFFF] py-4 w-[200px] rounded-lg text-center text-[18px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed mt-5 font-bold"
              handleClick={updateStatus}
            >
              {loader && <div className="loader mx-auto" />}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ChangeCorrectionStatusModal;
