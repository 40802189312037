import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "./Modal";
import { Button, Inputfile } from "components/Form";
import formcorrectionService from "services/Formcorrection";
import { crossBlack, documentBlack } from "utils/Images";
import prestudyService from "services/prestudy";
import { useAppDispatch } from "store/Hooks";
import { clearUser } from "store/slices/User";
import { clearPrestudy } from "store/slices/Prestudy";
import { clearFormcorrection } from "store/slices/FormCorrection";
import { cleanAndLogout, validateFiles } from "utils/Helper";
import CustomLoader from "components/Loader/CustomLoader";

interface UploadDocsModalProps {
  setFileuploadModal: Dispatch<SetStateAction<boolean>>;
  student: any;
  fileName: string;
  step: number
}

const UploadDocsModal: React.FC<UploadDocsModalProps> = ({ setFileuploadModal, student, fileName, step }) => {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [fileError, setfileError] = useState<string | null>(null);
  const [viewfileModal,setViewfileModal] = useState<boolean>(false);

  const isMultipleAllowed = (fileName: string) => {
    if(['file','stampFile','passportFile','healthInsuranceFile'].includes(fileName)){
      return true;
    } else {
      return false
    }
  }
  const getFormfield = async () => {
    try {
      setLoading(true);
      const response = await formcorrectionService.getFormField(student?._id, step, fileName);
      const {
        data: { data, status },
      } = response;
      if (status === 200) {
        setLoading(false);
        setFiles(data?.file)
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setLoading(false);
    }
  }

  const uploadDocument = async (formData: any) => {
    try {
      setLoading(true);
      let response = await prestudyService.uploadDocument(formData);
      const {
        data: { success, status },
      } = response;
      if (success) {
        setLoading(false);
        getFormfield()
      }
      if (status === 401) {
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
    } catch (error) {
      setLoading(false);
      console.log("🚀 ~ uploadDocument ~ error:", error)
    }
  }

  const uploadFiles = async (
    selectedFile: FileList | null,
    fieldName: string,
  ) => {
    setLoading(true);
    const allowedTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
    ];
    const isValidate = await validateFiles(selectedFile, allowedTypes);
    if (isValidate === "true") {
      if (selectedFile) {
        const formData: any = new FormData();
        setFiles(Array.from(selectedFile));
        Array.from(selectedFile)?.forEach((file: any) => {
          formData.append(fieldName, file);
        });
        formData.append('isRequired', true);
        student && formData.append("userId", student?._id);
        formData.append("step", step.toString());
        formData.append(
          "label",
          fieldName
        );
        await uploadDocument(formData)
      }
    } else {
      setLoading(false);
      setfileError(isValidate);
    }
  };

  useEffect(() => {
    getFormfield()
  }, [])

  return (
    <>
      <Modal title={`Upload ${fileName} for ${student?.studentId}`} handleClick={() => {
        setFileuploadModal(false)
      }}>
        {loading ? <CustomLoader /> :
          <>
            <div className="my-3">
              <p className="text-[14px] text-[#0B0D0F]">{fileName}</p>
            </div>
            <div className="flex flex-col gap-1">
              <Inputfile multiple={isMultipleAllowed(fileName)}
                handleChange={async (event: ChangeEvent<HTMLInputElement>) => {
                  let selectedFile: FileList | null = event.target.files;
                  setfileError(null);
                  await uploadFiles(
                    selectedFile,
                    fileName,
                  );
                }}
              />
              {fileError &&
                <div className="text-red-500 text-sm md:text-base fileError">
                  {fileError}
                </div>
              }
              {files && files?.map((item: any) =>
                item?.location ?
                  <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                    <img src={documentBlack} alt="documentBlack" />
                    <label className="flex-1">
                      {item.location}
                    </label>
                    <img
                      src={crossBlack}
                      alt="documentBlack"
                      onClick={async () => {
                        const formData: any = new FormData();
                        formData.append("userId", student?._id);
                        formData.append("step", step.toString());
                        formData.append(
                          "label",
                          fileName
                        );
                        formData.append('isRequired', true);
                        await uploadDocument(formData)
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                  : <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                    <img src={documentBlack} alt="documentBlack" />
                    <label className="flex-1">
                      {item.name}
                    </label>
                    <img
                      src={crossBlack}
                      alt="documentBlack"
                      onClick={async () => {
                        const formData: any = new FormData();
                        formData.append("userId", student?._id);
                        step && formData.append("step", step.toString());
                        formData.append(
                          "label",
                          fileName
                        );
                        formData.append('isRequired', true);
                        await uploadDocument(formData)
                      }}
                      className="cursor-pointer"
                    />
                  </div>

              )}
            </div>
            <div className="view__btn flex justify-center mt-10">
              {/* <Button
                type="submit"
                label="View"
                disabled={false}
                cssClass="bg-[#0A4A5E] rounded-[12px] text-white py-[18px] px-[16px] min-w-[200px] text-[18px] font-bold"
                handleClick={() => {
                  setViewfileModal(true);
                }}
              /> */}
            </div>
          </>
        }
      </Modal>
    </>
  );
};

export default UploadDocsModal;
