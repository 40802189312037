import Api from "./Api";

class FormcorrectionService {
  public async getStepcorrection(userId: string | undefined, step: number) {
    return await Api.get(
      `/prestudy/correction/${userId}?step=${step}`
    );
  }
  public async editStepcorrection(
    userId: string | undefined,
    step: number,
    payload: any
  ) {
    return await Api.patch(
      `/prestudy/correction/${userId}?step=${step}`,
      payload
    );
  }
  public async deletePages(userId: string | undefined, payload: any) {
    return await Api.post(
      `/prestudy/delete-pages/${userId}`,
      payload
    );
  }
  public async saveComment(
    userId: string | undefined,
    step: number,
    payload: { comments: { name: string; text: string }[] }
  ) {
    return await Api.patch(
      `/prestudy/comments/${userId}?step=${step}`,
      payload
    );
  }
  public async getCorrectionList(userId: string | undefined) {
    return await Api.get(`/prestudy/all-corrections/${userId}`);
  }
  public async getFieldComments(
    userId: string | undefined,
    step: number,
    field: string
  ) {
    return await Api.get(
      `prestudy/field-comments/${userId}?step=${step}&name=${field}`
    );
  }
  public async verifyCorrection(
    userId: string | undefined,
    step: number,
    payload: { corrections: { name: string; value: string }[] }
  ) {
    return await Api.patch(
      `prestudy/verify-correction/${userId}?step=${step}`,
      payload
    );
  }
  public async getCorrectionTemplate() {
    return await Api.get(`lawyer/get-correction-template`);
  }
  public async getFormField(
    userId: string | undefined,
    step: number,
    name: string
  ) {
    return await Api.get(
      `prestudy/field-docs/${userId}?step=${step}&name=${name}`
    );
  }
  public async getDocumentCount(userId: string | undefined) {
    return await Api.get(`prestudy/docs-order/${userId}`);
  }
  public async saveModifiedPdf(userId: string | undefined, data: any) {
    return await Api.patch(`lawyer/save-generated-pdf/${userId}`,data);
  }
  public async getStudentVerificationStatus(userId: string | undefined){
    return await Api.get(`lawyer/get-verificationstatus/${userId}`);
  }
}

const formcorrectionService = new FormcorrectionService();
export default formcorrectionService;
