import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../Store";
import { formDataType, stepDataType } from "utils/types";

type formStepType = {
  step: number;
  status: string;
  label: string;
  errorAvailable?: string | number;
};

export type initialStateType = {
  currentStep: number;
  formStatus: formStepType[] | null;
  stepData: stepDataType[] | null;
  formData: formDataType[] | null;
  qrImage: string | undefined;
  citizenShip: string | null;
  agreementSignature: string;
  submitSignature: string;
  featureLoaded: boolean;
  isCorrectionStartedFromLawyer: boolean,
  isCorrectionStartedFromStudent: boolean,
  studentId?: string
};

const initialState: initialStateType = {
  currentStep: 1,
  formStatus: Array(10)
    .fill(0)
    .map((_, index) => ({
      step: index,
      status: "pending",
      label: index === 0 ? "Start" : index === 9 ? "Submit" : `0${index}`,
    })),
  stepData: null,
  formData: null,
  qrImage: undefined,
  citizenShip: null,
  agreementSignature: "",
  submitSignature: "",
  featureLoaded: false,
  isCorrectionStartedFromLawyer: false,
  isCorrectionStartedFromStudent: false,
  studentId: ""
};

export const prestudySlice = createSlice({
  name: "prestudy",
  initialState,
  reducers: {
    nextStep: (state) => {
      state.currentStep += 1;
    },
    previousStep: (state) => {
      state.currentStep -= 1;
    },
    editStepData: (state, action) => {
      state.currentStep = action.payload.step;
    },
    setStep: (state, action) => {
      const { step, status } = action.payload;
      state.currentStep = step;
      state.formStatus =
        state.formStatus &&
        state.formStatus.map((item, index) =>
          index === step - 1
            ? ["Skipped", "pending", "Inprogress"].includes(status) &&
              item.status === "completed"
              ? item
              : { ...item, status }
            : item.status === "Inprogress"
            ? { ...item, status: "pending" }
            : item
        );
    },
    updateStatus: (state, action) => {
      const { step, status } = action.payload;
      state.formStatus =
        state.formStatus &&
        state.formStatus.map((item, index) =>
          index === step - 1
            ? ["Skipped", "pending", "Inprogress"].includes(status) &&
              item.status === "completed"
              ? item
              : { ...item, status }
            : item
        );
    },
    setStepData: (state, action) => {
      state.stepData = action.payload;
    },
    setFormStatus: (state, action) => {
      state.formStatus = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setQrImage: (state, action) => {
      state.qrImage = action.payload;
    },
    setCitizenship: (state, action) => {
      state.citizenShip = action.payload;
    },
    setAgreementSignature: (state, action) => {
      state.agreementSignature = action.payload;
    },
    setSubmitSignature: (state, action) => {
      state.submitSignature = action.payload;
    },
    setLawyerCorrectionStatus: (state, action) => {
     state.isCorrectionStartedFromLawyer = action.payload
    },
    setStudentCorrectionStatus: (state, action) => {
     state.isCorrectionStartedFromStudent = action.payload
    },
    setStudentId: (state, action) => {
     state.studentId = action.payload
    },
    clearPrestudy: (state) => {
      state.currentStep = 1;
      state.formStatus = null;
      state.stepData = null;
      state.formData = null;
      state.qrImage = undefined;
      state.citizenShip = null;
      state.agreementSignature = "";
      state.submitSignature = "";
    },
    setCorrectionStep: (state, action) => {
      state.currentStep = action.payload.step + 1;
      // state.formStatus =
      // state.formStatus &&
      // state.formStatus.map((item) =>
      // item.step === action.payload.step -1
      //     ? ["Skipped", "pending", "Inprogress"].includes(action.payload.status) &&
      //       item.status === "completed"
      //       ? item
      //       : { ...item, status : action.payload.status }
      //     : item
      // );
    },
    setFeatureLoaded: (state, action) => {
      console.log(state, "payload");

      state.featureLoaded = action.payload;
    },
  },
});

export const {
  nextStep,
  previousStep,
  updateStatus,
  setStep,
  setStepData,
  setFormStatus,
  setFormData,
  setQrImage,
  setCitizenship,
  clearPrestudy,
  setAgreementSignature,
  setSubmitSignature,
  setCorrectionStep,
  setFeatureLoaded,
  setLawyerCorrectionStatus,
  setStudentCorrectionStatus,
  setStudentId
} = prestudySlice.actions;
export const prestudySelector = (state: RootState) => state.prestudyReducer;
export default prestudySlice.reducer;
