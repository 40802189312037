import React, { useCallback, useEffect, useState } from "react";
import * as menu from "@radix-ui/react-context-menu";
import { rightArrow } from "utils/Images";
import { changeAppointmentStatusMenu, changeAttributesMenu, docs } from "utils/data";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/Hooks";
import { setStudent } from "store/slices/User";
import ChangeCorrectionStatusModal from "components/Modals/ChangeCorrectionStatusModal";
import UploadDocsModal from "components/Modals/UploadDocsModal";
import dashboardService from "services/DashboardService";

type item = {
  id: string;
  value: string;
  hasSubmenu: boolean;
  route?: string
};

type subItem = {
  id: string;
  value: string | string[];
};
interface ContextMenuListProps {
  menuItems: item[] | [];
  student: any;
  isTemplate: boolean;
  sendEmailMenu?: string[];
  sendReminderMenu?: string[];
  sendUniDocMenu?: string[];
  sendHsStudentMenu?: string[];
}
const ContextMenuList: React.FC<ContextMenuListProps> = ({
  menuItems,
  student,
  isTemplate,
  sendEmailMenu,
  sendReminderMenu,
  sendUniDocMenu,
  sendHsStudentMenu,
}) => {
  const dispatch = useAppDispatch();
  const [correctionModal, setCorrectionModal] = useState<boolean>(false);
  const [fileuploadModal, setFileuploadModal] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [step, setStep] = useState<number>(0);

  const navigate: any = useNavigate();
  const renderSubmenu = useCallback(
    (name: string) => {
      switch (name) {
        case "appointmentStatus":
          return changeAppointmentStatusMenu.map((val: subItem) =>
            <menu.Item
              className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer"
              onClick={async() => {
                dashboardService.updateAppointmentStatusById(student?._id,val?.id)
              }}
            >
              {val.value}
            </menu.Item>
          )
        case "attributes":
          return changeAttributesMenu.map((val: subItem) => (
            <menu.Item
              className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer"
              onClick={() => {
                if (val.id === "correctionStatus") {
                  setCorrectionModal && setCorrectionModal(true);
                } else {
                  navigate("/correction", { state: { student: student } })
                }
              }}
            >
              {val.id === "studentId" ? student.studentId : val.value}
            </menu.Item>
          ));
        case "email":
          if (isTemplate) {
            return sendEmailMenu?.map((val: string) => (
              <menu.Item
                className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer"
                onClick={() => {
                  navigate("/send-email");
                  dispatch(
                    setStudent({
                      ...student,
                      templateName: val === "Add new" ? null : val,
                      templateFor: "Send Email",
                    })
                  );
                }}
              >
                {val}
              </menu.Item>
            ));
          }
          break;
        case "reminderOpen":
          if (isTemplate) {
            return sendReminderMenu?.map((val: string) => (
              <menu.Item
                className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer"
                onClick={() => {
                  navigate("/send-reminder");
                  dispatch(
                    setStudent({
                      ...student,
                      templateName: val === "Add new" ? null : val,
                      templateFor: "Send Reminder",
                    })
                  );
                }}
              >
                {val}
              </menu.Item>
            ));
          }
          break;
        case "uniDocs":
          if (isTemplate) {
            return sendUniDocMenu?.map((val: string) => (
              <menu.Item
                className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer"
                onClick={() => {
                  navigate("/send-uni-doc-reminder");
                  dispatch(
                    setStudent({
                      ...student,
                      templateName: val === "Add new" ? null : val,
                      templateFor: "Send Uni Docs Reminder",
                    })
                  );
                }}
              >
                {val}
              </menu.Item>
            ));
          }
          break;
        // case "openDocs":
        //   return docs.map((val: { step: number; fileName: string }) => (
        //     <menu.Item className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer">
        //       {val.fileName}
        //     </menu.Item>
        //   ));
        case "uploadDocs":
          return docs.map((val: { step: number; fileName: string }) => (
            <menu.Item className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer"
              onClick={() => {
                setFileuploadModal(true)
                setFileName(val.fileName)
                setStep(val.step)
              }}
            >
              {val.fileName}
            </menu.Item>
          ));

        // case "openForm":
        //   return form.map((val: subItem) => (
        //     <menu.Item className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer">
        //       {val.value}
        //     </menu.Item>
        //   ));
        default:
          return null;
      }
    },
    [sendEmailMenu, sendReminderMenu, sendUniDocMenu]
  );

  useEffect(() => {
    const toggleBodyOverflow = () => {
      document.body.style.overflowY = (fileuploadModal || correctionModal) ? "hidden" : "auto";
    };
    toggleBodyOverflow();
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [fileuploadModal, correctionModal]);

  return (
    menuItems && menuItems?.length > 0 ?
      <>
        <menu.Portal>
          <menu.Content className="min-w-[220px] bg-white rounded-md overflow-hidden p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] z-[102]">
            <menu.Item className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer font-semibold">
              {student.studentId}
            </menu.Item>
            <menu.Separator className="h-[1px] bg-[#E1E5EA] m-[5px]" />
            {menuItems.map((item) =>
              item.hasSubmenu ? (
                <div className="max-h-[50px] overflow-y-scroll no-scrollbar">
                  <menu.Sub>
                    <menu.SubTrigger className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[state=open]:bg-violet4 data-[state=open]:text-violet11 data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 data-[highlighted]:data-[state=open]:bg-violet9 data-[highlighted]:data-[state=open]:text-violet1 cursor-pointer">
                      {item.value}
                      <div className="ml-auto pl-5 text-mauve11 group-data-[highlighted]:text-white group-data-[disabled]:text-mauve8">
                        <img src={rightArrow} alt="right-arrow" />
                      </div>
                    </menu.SubTrigger>
                    <menu.Portal>
                      <menu.SubContent
                        className="min-w-[220px] bg-white rounded-md overflow-hidden p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] max-h-[170px] overflow-y-scroll no-scrollbar"
                        sideOffset={2}
                        alignOffset={-5}
                      >
                        {renderSubmenu(item.id)}
                      </menu.SubContent>
                    </menu.Portal>
                  </menu.Sub>
                </div>
              ) : (
                <>
                  <menu.Item
                    className="group text-[14px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer"
                    onClick={() => {
                      if (item.route) {
                        if (item.route === '/send-hs-number') {
                          if (isTemplate) {
                            if (sendHsStudentMenu?.length) {
                              sendHsStudentMenu?.map((val: string) => {
                                navigate(item.route);
                                dispatch(
                                  setStudent({
                                    ...student,
                                    templateFor: "Send HS To Student",
                                  })
                                );
                              });
                            }
                            else {
                              navigate(item.route);
                              dispatch(
                                setStudent({
                                  ...student,
                                  templateFor: "Send HS To Student",
                                })
                              );
                            }
                          }
                        }
                        else {
                          if (item.id === 'correct') {
                            navigate(item.route, { state: { student: student } })
                          } else {
                            navigate(item.route)
                            console.log("student", student)
                            dispatch(setStudent(student))

                          }
                        }
                      }
                    }}
                  >
                    {item.id === "call"
                      ? student?.phoneNumber
                        ? `Call ${student?.phoneNumber}`
                        : item.value
                      : item.value}
                  </menu.Item>
                </>
              )
            )}
          </menu.Content>
        </menu.Portal>
        {correctionModal && (
          <ChangeCorrectionStatusModal setCorrectionModal={setCorrectionModal} student={student} />
        )}
        {fileuploadModal && (
          <UploadDocsModal setFileuploadModal={setFileuploadModal} student={student} fileName={fileName} step={step} />
        )}
      </>
      : <></>
  );
};

export default ContextMenuList;
