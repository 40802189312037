import Api from "./Api";
import {
  forgetPwdPayloadType,
  loginPayloadType,
  resetPwdPayloadType,
  verifyAccountPayload,
} from "./types";
class AuthService {
  public async register(payload: FormData, step: number) {
    return await Api.post(`/auth/register?step=${step}`, payload);
  }
  public async login(payload: loginPayloadType) {
    return await Api.post("/auth/login", payload);
  }
  public async forgetPassword(payload: forgetPwdPayloadType) {
    return await Api.post("/auth/forgot-password", payload);
  }
  public async resetPassword(
    payload: resetPwdPayloadType,
    token: string | null
  ) {
    return await Api.post(`/auth/reset-password?token=${token}`, payload);
  }
  public async verifyEmail(payload: verifyAccountPayload) {
    return await Api.post(`/auth/verify-email?type=EMAIL`, payload);
  }
  public async verifyPhone(payload: verifyAccountPayload) {
    return await Api.post(`/auth/verify-phone?type=PHONE`, payload);
  }
  public async resendOtp(payload: { userId: string; type: string }) {
    return await Api.post(`/auth/resend-otp?type=${payload.type}`, {
      userId: payload.userId,
    });
  }
  public async getUniversityLogo(name: string) {
    return await Api.post(`/university/`, { name });
  }
  public async skipVerification(studentId: string) {
    return await Api.put(`/auth/register/${studentId}`);
  }
  public async logout(userId:string){
    return await Api.post("/auth/logout", {userId});
  }
  public async getRedirectURL(host:string){
    return await Api.get(`users${host}`);
  }
}

const authService = new AuthService();
export default authService;
