import { RadioGroup } from "components/Form";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { stepSixSchema } from "schema/Prestudy";
import { statusOptions, visaOptions } from "utils/data";
import { stepProps, stepSixTypes } from "utils/types";
import { useAppSelector } from "store/Hooks";
import { prestudySelector } from "store/slices/Prestudy";
import moment from "moment";
import dayjs from "dayjs";
import Days90Selection from "./Days90Selection";
import StudyEntryvisa from "./StudyEntryvisa";
import RpStudypreparation from "./RpStudypreparation";
import RpStudy from "./RpStudy";
import FictionalCertificate from "./FictionalCertificate";
import OtherImmigration from "./OtherImmigration";
import { userSelector } from "store/slices/User";
import CommentSection from "components/Form/CommentSection";
import { formCorrectionSelector } from "store/slices/FormCorrection";

const StepSix: React.FC<stepProps> = ({ submitStepdata, nextActive, uploadDocument, verifyCorrection }) => {
  const { currentStep, stepData, citizenShip } =
    useAppSelector(prestudySelector);
  const [lastEntryDays, setLastEntryDays] = useState<number>(0);
  const [lastEntryExitDays, setLastEntryExitDays] = useState<number>(0);
  const { user } = useAppSelector(userSelector);

  const [stepFields, setStepFields] = useState<stepSixTypes>({
    visa: "",
    status: "",
    lastEntry: null,
    lastExit: null,
    passportFile: [],
    visaExpiryDate: null,
    entryvisaColorCopy: [],
    isSchenegenArea: "",
    stampsEntry: [],
    rpExpireDate: null,
    rpExpireFile: "",
    fcDate: null,
    fcFile: "",
    fcComment: "",
    fcDocument: "",
    deadLinedate: null,
    document: "",
    situation: "",
  });
  const initialValues: stepSixTypes = {
    visa: stepFields.visa ? stepFields.visa : "",
    status: stepFields.status ? stepFields.status : "",
    lastEntry: stepFields.lastEntry ? stepFields.lastEntry : null,
    lastExit: stepFields.lastExit ? stepFields.lastExit : null,
    passportFile: stepFields.passportFile ? stepFields.passportFile : [],
    visaExpiryDate: stepFields.visaExpiryDate ? stepFields.visaExpiryDate : null,
    entryvisaColorCopy: stepFields.entryvisaColorCopy ? stepFields.entryvisaColorCopy : [],
    isSchenegenArea: stepFields.isSchenegenArea ? stepFields.isSchenegenArea : "",
    stampsEntry: stepFields.stampsEntry ? stepFields.stampsEntry : [],
    rpExpireDate: stepFields.rpExpireDate ? stepFields.rpExpireDate : null,
    rpExpireFile: stepFields.rpExpireFile ? stepFields.rpExpireFile : [],
    fcDate: stepFields.fcDate ? stepFields.fcDate : null,
    fcFile: stepFields.fcFile ? stepFields.fcFile : [],
    fcComment: stepFields.fcComment ? stepFields.fcComment : "",
    fcDocument: stepFields.fcDocument ? stepFields.fcDocument : [],
    deadLinedate: stepFields.deadLinedate ? stepFields.deadLinedate : null,
    document: stepFields.document ? stepFields.document : [],
    situation: stepFields.situation ? stepFields.situation : "",
  };
  const [leftDays, setLeftDays] = useState<number>(0);
  const [expirydate, setExpriyDate] = useState<string | "">("");
  const handleSubmit = async (values: stepSixTypes) => {
    if (user?.formStatus === 'SUBMITTED') {
      let payload: any = [
        {
          name: "visa",
          value: values.visa,
        },
        {
          name: "status",
          value: values.status,
        }
      ];
      if (values.status === '90-days free entry allowance') {
        payload = [...payload, {
          name: "lastEntry",
          value: values.lastEntry
            ? new Date(
              moment(new Date(values.lastEntry)).format("YYYY-MM-DD")
            ).toISOString()
            : null,
        },
        {
          name: "lastExit",
          value: values.lastExit,
        },
        {
          name: "leftDays",
          value: leftDays,
        },
        {
          name: "expiryDate",
          value: expirydate,
        },
        {
          name: "lastEntryExitDays",
          value: lastEntryExitDays,
        },
        {
          name: "lastEntryDays",
          value: lastEntryDays,
        },
        ]
      }
      if (values.status === 'Study Entry Visa (issued by the Embassy in your home country)') {
        payload = [...payload, {
          name: "visaExpiryDate",
          value: values.visaExpiryDate
            ? new Date(
              moment(new Date(values.visaExpiryDate)).format("YYYY-MM-DD")
            ).toISOString()
            : null,
        },
        {
          name: "isSchenegenArea",
          value: values.isSchenegenArea,
        }
        ]
      }
      if (values.status === 'Residence permit for study preparation (Visa already extended with the immigration office IN Germany)') {
        payload = [...payload, {
          name: "rpExpireDate",
          value: values.rpExpireDate
            ? new Date(
              moment(new Date(values.rpExpireDate)).format("YYYY-MM-DD")
            ).toISOString()
            : null,
        }
        ]
      }
      if (values.status === 'Residence permit for study (Visa already extended with the immigration office IN Germany)') {
        payload = [...payload, {
          name: "rpExpireDate",
          value: values.rpExpireDate
            ? new Date(
              moment(new Date(values.rpExpireDate)).format("YYYY-MM-DD")
            ).toISOString()
            : null,
        }
        ]
      }
      if (values.status === 'Fictional Certificate (issued by the immigration office in Germany)') {
        payload = [...payload,
        {
          name: "fcDate",
          value: values.fcDate
            ? new Date(
              moment(new Date(values.fcDate)).format("YYYY-MM-DD")
            ).toISOString()
            : null,
        },
        {
          name: "fcComment",
          value: values.fcComment,
        }
        ]
      }
      if (values.status === 'Other immigration status') {
        payload = [...payload,
        {
          name: "deadLinedate",
          value: values.deadLinedate
            ? new Date(
              moment(new Date(values.deadLinedate)).format("YYYY-MM-DD")
            ).toISOString()
            : null,
        },
        {
          name: "situation",
          value: values.situation,
        }
        ]
      }
      const uniqueNames: { [name: string]: boolean } = {};
      let updatedArr: { name: string, value: string | undefined }[] = [];
      payload.map((item: any) => {
        let itemName = item.name as keyof stepSixTypes;
        const oldValue = stepFields[itemName];
        if (!oldValue) {
          uniqueNames[item.name] = true;
          updatedArr.push({ ...item, value: item.value });
        }
        if (oldValue && oldValue !== item.value) {
          if (!uniqueNames[item.name]) {
            uniqueNames[item.name] = true;
            updatedArr.push({ ...item, value: item.value });
          }
        }
      });
      await verifyCorrection(updatedArr);
    } else {
      let payload: any = [
        {
          name: "visa",
          label: "Restrictions (according to the Foreigner's Office)",
          value: values.visa,
          isShow: true
        },
        {
          name: "status",
          label: "Select the legal status you have at this moment",
          value: values.status,
          isShow: true,
        }
      ];
      if (values.status === '90-days free entry allowance') {
        payload = [...payload, {
          name: "lastEntry",
          label: "Last Entry",
          value: values.lastEntry
            ? new Date(
              moment(new Date(values.lastEntry)).format("YYYY-MM-DD")
            ).toISOString()
            : null,
          isShow: true,
        },
        {
          name: "lastExit",
          label: "Entry Exit 1",
          value: values.lastExit,
          isShow: true,
        },
        {
          name: "leftDays",
          label: "Day left on Schengen area",
          value: leftDays,
          isShow: true,
        },
        {
          name: "expiryDate",
          label: "Days period expiry date",
          value: expirydate,
          isShow: true,
        },
        {
          name: "lastEntryExitDays",
          label: "Entry exit Days",
          value: lastEntryExitDays,
          isShow: true,
        },
        {
          name: "lastEntryDays",
          label: "Last Entry days",
          value: lastEntryDays,
          isShow: true,
        },
        ]
      }
      if (values.status === 'Study Entry Visa (issued by the Embassy in your home country)') {
        payload = [...payload, {
          name: "visaExpiryDate",
          label: "When will your entry visa expire",
          value: values.visaExpiryDate
            ? new Date(
              moment(new Date(values.visaExpiryDate)).format("YYYY-MM-DD")
            ).toISOString()
            : null,
          isShow: true,
        },
        {
          name: "isSchenegenArea",
          label: "Before your last entry, have you been in the Schengen area WITHIN YOUR CURRENT VISA VALIDITY?",
          value: values.isSchenegenArea,
          isShow: true,
        }
        ]
      }
      if (values.status === 'Residence permit for study preparation (Visa already extended with the immigration office IN Germany)') {
        payload = [...payload, {
          name: "rpExpireDate",
          label: "When will your residence permit expire?",
          value: values.rpExpireDate
            ? new Date(
              moment(new Date(values.rpExpireDate)).format("YYYY-MM-DD")
            ).toISOString()
            : null,
          isShow: true,
        }
        ]
      }
      if (values.status === 'Residence permit for study (Visa already extended with the immigration office IN Germany)') {
        payload = [...payload, {
          name: "rpExpireDate",
          label: "When will your residence permit expire?",
          value: values.rpExpireDate
            ? new Date(
              moment(new Date(values.rpExpireDate)).format("YYYY-MM-DD")
            ).toISOString()
            : null,
          isShow: true,
        }
        ]
      }
      if (values.status === 'Fictional Certificate (issued by the immigration office in Germany)') {
        payload = [...payload,
        {
          name: "fcDate",
          label: "When will your fictional certificate expire?",
          value: values.fcDate
            ? new Date(
              moment(new Date(values.fcDate)).format("YYYY-MM-DD")
            ).toISOString()
            : null,
          isShow: true,
        },
        {
          name: "fcComment",
          label: "What was your legal status before obtaining the fictional certificate?",
          value: values.fcComment,
          isShow: true,
        }
        ]
      }
      if (values.status === 'Other immigration status') {
        payload = [...payload,
        {
          name: "deadLinedate",
          label: "Specify if a deadline is applicable to your situation",
          value: values.deadLinedate
            ? new Date(
              moment(new Date(values.deadLinedate)).format("YYYY-MM-DD")
            ).toISOString()
            : null,
          isShow: true,
        },
        {
          name: "situation",
          label: "Explain your situation",
          value: values.situation,
          isShow: true,
        }
        ]
      }
      await submitStepdata(
        payload,
        null
      );
    }
  };

  useEffect(() => {
    const updatedFields = { ...stepFields };
    stepData?.forEach((item) => {
      const name = item.name as keyof stepSixTypes;
      switch (name) {
        case "status":
        case "lastExit":
        case "isSchenegenArea":
        case "fcComment":
        case "situation":
          updatedFields[name] = item.value;
          break;
        case "lastEntry":
        case "visaExpiryDate":
        case "rpExpireDate":
        case "fcDate":
        case "deadLinedate":
          if (typeof item.value === "string" && item.value !== "") {
            updatedFields[name] = dayjs(
              moment(item.value).format("YYYY-MM-DD")
            );
          }
          break;
        case "passportFile":
        case "rpExpireFile":
        case "fcFile":
        case "fcDocument":
        case "document":
        case "entryvisaColorCopy":
        case "stampsEntry":
          updatedFields[name] =
            item.value instanceof Array ? [...item.value] : item.value;
          break;
        case "leftDays":
          setLeftDays(item.value);
          break;
        case "expirydate":
          setExpriyDate(item.value);
          break;
        case "lastEntryExitDays":
          setLastEntryExitDays(item.value);
          break;
        case "lastEntryDays":
          setLastEntryDays(item.value);
          break;
        default:
          break;
      }
    });
    setStepFields(updatedFields);
  }, [stepData]);

  const showField = (field: string) => {
    if (!stepData) return true;
    let data = stepData?.find(item => item.name === field);
    return !user?.formStatus ? true : !data ? false : data?.isShow
    // return !data ? false : !user?.formStatus ? true : data?.isShow
  }

  const showComments = (field: string) => {
    if (!stepData) return false;
    let data = stepData?.find((item:any) => item.name === field && item?.comments && item?.comments?.length > 0);
    return !data ? false : true
  }
  
  return (
    <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 h-full lg:ml-[6.3rem] ml-0">
      <label className="text-[#0B0D0F] font-semibold text-[20px]">
        Immigration status
      </label>
      <p className="text-[#71859A] leading-[27.36px] text-[14px] mt-5 lg:w-1/2 xl:w-1/2 w-full">
        This field is automatically selected as soon as you select your country
        of nationality{" "}
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={nextActive ? user?.formStatus === 'SUBMITTED' ? "" : stepSixSchema : ""}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, values, handleChange, errors }: any) => (
          <Form className="mx-auto" id={currentStep.toString()}>
            <div className={showField('visa') ? "block" : 'hidden'}>
              <div className="mb-2 mt-4 flex justify-between items-center">
                <label
                  htmlFor={"status"}
                  className="text-[#0B0D0F] font-semibold text-[16px]"
                >
                  Restrictions (according to the Foreigner's Office)
                  <span className="text-[#FF4444] ml-1">*</span>
                </label>
              </div>
              <Field name="visa">
                {({ field }: FieldProps) => (
                  <div className="text-[#71859A]">
                    <RadioGroup
                      {...field}
                      fieldName="visa"
                      options={visaOptions}
                      className={"ml-3 text-[#71859A]"}
                      disabled={true}
                      checkedField={
                        citizenShip === "Germany"
                          ? "You DO NOT need an entry visa"
                          : "You need an entry visa"
                      }
                    />
                  </div>
                )}
              </Field>
              {showComments('visa') &&
                <CommentSection field={'visa'} step={currentStep - 1} userType="student" />
              }
              <p className="my-5 text-[#71859A] text-[14px]">
                3: You can stay 90 days in any 180-day period within the Schengen
                area. In order to study in Germany, a Residence permit for stays
                of more than 3 months can be applied for after entering Germany
                without any German Study Visa. It must be applied for within the
                90 days visa free period. In case you have still applied and got
                your German Study Visa from your home country, just choose this
                option in the following questions.{" "}
              </p>
              <hr className="bg-[#E1E5EA]" />
            </div>
            <div className={showField('status') ? "block" : 'hidden'}>
              <div className="mb-2 mt-4 flex justify-between items-center">
                <label
                  htmlFor={"status"}
                  className="text-[#0B0D0F] font-semibold text-[16px]"
                >
                  Select the legal status you have at this moment?
                  <span className="text-[#FF4444] ml-1">*</span>
                </label>
              </div>
              <Field name="status">
                {({ field, form }: FieldProps) => (
                  <div className="text-[#71859A] leading-7">
                    <RadioGroup
                      {...field}
                      fieldName="status"
                      options={statusOptions}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        form.setFieldValue("status", event.target.value)
                      }
                      className={"ml-3"}
                      checkedField={values.status}
                    />
                  </div>
                )}
              </Field>
              <ErrorMessage
                name="status"
                component="div"
                className="text-red-500"
              />
              {showComments('status') &&
                <CommentSection field={'status'} step={currentStep - 1} userType="student" />
              }
            </div>
            {values.status === 'Study Entry Visa (issued by the Embassy in your home country)' &&
              <StudyEntryvisa
                values={values}
                uploadDocument={uploadDocument}
                showField={showField}
                showComments={showComments}
                userType={'student'}
              />
            }
            {values.status === '90-days free entry allowance' &&
              <Days90Selection
                lastEntryDays={lastEntryDays}
                setLastEntryDays={setLastEntryDays}
                setLastEntryExitDays={setLastEntryExitDays}
                setLeftDays={setLeftDays}
                lastEntryExitDays={lastEntryExitDays}
                setExpriyDate={setExpriyDate}
                leftDays={leftDays}
                expirydate={expirydate}
                values={values}
                uploadDocument={uploadDocument}
                showField={showField}
                showComments={showComments}
                userType={'student'}
              />
            }
            {values.status === 'Residence permit for study preparation (Visa already extended with the immigration office IN Germany)' &&
              <RpStudypreparation
                values={values}
                uploadDocument={uploadDocument}
                showField={showField}
                showComments={showComments}
                userType={'student'}
              />
            }
            {values.status === 'Residence permit for study (Visa already extended with the immigration office IN Germany)' &&
              <RpStudy
                values={values}
                uploadDocument={uploadDocument}
                showField={showField}
                showComments={showComments}
                userType={'student'}
              />
            }
            {values.status === 'Fictional Certificate (issued by the immigration office in Germany)' &&
              <FictionalCertificate
                values={values}
                uploadDocument={uploadDocument}
                showField={showField}
                showComments={showComments}
                userType={'student'}
              />
            }
            {values.status === 'Other immigration status' &&
              <OtherImmigration
                values={values}
                uploadDocument={uploadDocument}
                showField={showField}
                showComments={showComments}
                userType={'student'}
              />
            }
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StepSix;
