import Api from "./Api";

class DashboardService {
  public async getStudentDetails(userId: string | undefined) {
    return await Api.get(`/dashboard/${userId}`);
  }
  // Change appointment status of student
  public async updateAppointmentStatusById(query?: string | null,status?:any) {
    let url = status ? `userId=${query}&appointmentStatus=${status}` : `userId=${query}`
    return await Api.get(`lawyer/appointment-count?${url}`);
  }
}

const dashboardService = new DashboardService();
export default dashboardService;