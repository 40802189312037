import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../Store";
import { registrationDataType, userType } from "services/types";

export type initialStateType = {
  user: userType | null;
  registerData: any | null;
  sidebarOpen: boolean;
  studentId: string | null;
  registrationData: registrationDataType[] | null;
  activeservicesData: registrationDataType[] | null;
  student : any
  uniDocdata:any
};

const initialState: initialStateType = {
  user: null,
  registerData: null,
  sidebarOpen: window.innerWidth > 1024,
  studentId: null,
  registrationData: null,
  activeservicesData: null,
  student : null,
  uniDocdata:null
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setRegisterData: (state, action: PayloadAction<object | null>) => {
      state.registerData = { ...state.registerData, ...action.payload };
    },
    setSidebar: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpen = action.payload;
    },
    setStudentId: (state, action: PayloadAction<string | null>) => {
      state.studentId = action.payload;
    },
    setRegistrationData: (
      state,
      action: PayloadAction<registrationDataType[] | null>
    ) => {
      state.registrationData = action.payload;
    },
    setActiveServicesData: (state, action: PayloadAction<registrationDataType[] | null>) => {
      state.activeservicesData = action.payload;
    },
    setStudent:(state,action : PayloadAction<any>) => {
      state.student = action.payload
    },  
    setUnidocData : (state,action:PayloadAction<any>) => {
      state.uniDocdata = action.payload
    },
    clearUser: (state) => {
      state.user = null;
      state.registerData = null;
      state.sidebarOpen = window.innerWidth > 1024;
      state.studentId = null;
      state.registrationData = null;
      state.activeservicesData = null;
      state.student = null;
      state.uniDocdata = null;
    },
  },
});

export const {
  setUser,
  setRegisterData,
  setSidebar,
  setStudentId,
  clearUser,
  setRegistrationData,
  setActiveServicesData,
  setStudent,
  setUnidocData
} = userSlice.actions;
export const userSelector = (state: RootState) => state.userReducer;
export default userSlice.reducer;
