import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import authService from 'services/AuthService';

const Host = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const getRedirectURL = async () => {
            if (pathname) {
                try {
                    let resp = await authService.getRedirectURL(pathname);
                    const { status, data } = resp.data;
                    if (status === 200) {
                        navigate("/"+data?.redirectUrl);
                    } else {
                        navigate("/unauthorized");
                    }
                } catch (error: any) {
                    console.log("error", error)
                }
            }
        }
        getRedirectURL()
    }, [])

    return (
        <>
        </>
    )
}

export default Host