import Layout from "components/UI/Layout";
import React, { useEffect, useState } from "react";
import { chart, toggle } from "utils/Images";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import { clearUser, userSelector } from "store/slices/User";
import * as menu from "@radix-ui/react-context-menu";
import {
  VEDcontextMenu,
  readyForleaOptions,
  readyMenuItems,
  reminderMenuItems,
  servicesData,
} from "utils/data";
import ContextMenuList from "components/ContextMenuList";
import BarChartModal from "components/Modals/BarChartModal";
import moment from "moment";
import readyTocheckService from "services/ReadyTocheckService";
import { clearPrestudy, prestudySelector, setStudentCorrectionStatus, setStudentId } from "store/slices/Prestudy";
import { clearFormcorrection } from "store/slices/FormCorrection";
import lawyerService from "services/LawyerService";
import { cleanAndLogout } from "utils/Helper";
import CustomLoader from "components/Loader/CustomLoader";
import ContextMenuModal from "components/Modals/ContextMenuModal";
import ChangeCorrectionStatusModal from "components/Modals/ChangeCorrectionStatusModal";
import ContextVEDMenuModal from "components/Modals/ContextVEDMenuModal";
import ContextReadyCheckMenuModal from "components/Modals/ContextReadyCheckMenuModal";
import UploadDocsModal from "components/Modals/UploadDocsModal";
import ContextReminderMenuModal from "components/Modals/ContextReminderMenuModal";
import prestudyService from "services/prestudy";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export type dataType = {
  [x: string]: any;
  studentId: string;
  study: string;
  city: string;
  visaExpiryDate?: string;
  name?: string;
  phoneNumber?: number | string;
  verificationStatus?:string;
  reason?:string;
};

const LawyerDashboard: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { sidebarOpen } = useAppSelector(userSelector);
  const [sendEmailMenu, setSendEmailMenu] = useState<string[]>(["Add new"]);
  const [sendReminderMenu, setSendReminderMenu] = useState<string[]>([
    "Add new",
  ]);
  const [sendUniDocMenu, setSendUniDocMenu] = useState<string[]>(["Add new"]);
  const [sendHsStudentMenu, setSendHsStudentMenu] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [newData, setNewdata] = useState<any | null>(null);
  const [vedData, setVedData] = useState<any | null>(null);
  const [readyTocheckData, setReadytocheckdata] = useState<any | null>(null);
  const [reminders, setReminders] = useState<any | null>(null);
  const [LeaReadyData, setLeaReadyData] = useState<any | null>(null);
  const [services, setServices] = useState<any | null>(null);

  // For VED to check Modal Content
  const [selectedVED, setSelectedVED] = useState<any | null>({});
  const [modalVED, setModalVED] = useState<boolean>(false);
  const [correctionModalVED, setCorrectionModalVED] = useState<boolean>(false);

  // For Ready to check Modal Content
  const [selectedReadyCheck, setSelectedReadyCheck] =
    useState<dataType | null>();
  const [modalReadyCheck, setModalReadyCheck] = useState<boolean>(false);
  const [correctionModalReadyCheck, setCorrectionModalReadyCheck] =
    useState<boolean>(false);

  // For modal selected student ready for lea
  const [selectedStudentLEA, setSelectedStudentLEA] = useState<any | null>({});
  const [modal, setModal] = useState<boolean>(false);
  const [correctionModal, setCorrectionModal] = useState<boolean>(false);
  const [fileuploadModal, setFileuploadModal] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const [step, setStep] = useState<number>(0);

  // For Reminders for Today
  const [selectedReminder, setSelectedReminder] = useState<dataType | null>();
  const [modalReminder, setModalReminder] = useState<boolean>(false);
  const [correctionModalReminder, setCorrectionModalReminder] =
    useState<boolean>(false);
  
  const navigate = useNavigate();
  const { isCorrectionStartedFromStudent } = useAppSelector(prestudySelector)  

  const dispatch = useAppDispatch();
  type statusType = {
    status: string;
    count: number;
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const getLabel = (status: string) => {
    let label = servicesData
      ?.filter(
        (item: { value: string; label: string }) => item.value === status
      )
      ?.map((item: { value: string; label: string }) => item.label);
    return label;
  };

  const getLawyerDashboard = async () => {
    try {
      setLoading(true);
      const response = await lawyerService.getLawyerDashboard();
      const {
        data: { data, status },
      } = response;
      if (status === 200) {
        const newData = data
          ?.filter((item: any) => item.status === "New")
          ?.map((item: any) => item.data);
        setNewdata(newData);
        const vedData = data
          ?.filter((item: any) => item.status === "VED to check")
          ?.map((item: any) => item.data);
        setVedData(vedData);
        const readyTocheckData = data
          ?.filter((item: any) => item.status === "Ready to check")
          ?.map((item: any) => item.data);
        setReadytocheckdata(readyTocheckData);
        const reminders = data
          ?.filter((item: any) => item.status === "Reminders for today")
          ?.map((item: any) => item.data);
        console.log("🚀 ~ getLawyerDashboard ~ reminders:", reminders);
        setReminders(reminders);
        const LeaReadyData = data
          ?.filter((item: any) => item.status === "Ready for LEA")
          ?.map((item: any) => item.data);
        setLeaReadyData(LeaReadyData);
        const services = data
          ?.filter((item: any) => item.status === "Services")
          ?.map((item: any) => item.data);
        setServices(services);
        setLoading(false);
      }
      if (status === 401) {
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const getEmailTemplates = async (templateFor: string) => {
    try {
      const response = await readyTocheckService.getTemplates(templateFor);
      const {
        data: { data, status },
      } = response;
      if (status === 200) {
        if (templateFor === "Send Email") {
          const existingItems = new Set(sendEmailMenu);
          const newData = data.filter((item: any) => !existingItems.has(item));
          const updatedMenu = [...sendEmailMenu, ...newData];
          setSendEmailMenu(updatedMenu);
        } else if (templateFor === "Send Reminder") {
          const existingItems = new Set(sendReminderMenu);
          const newData = data.filter((item: any) => !existingItems.has(item));
          const updatedMenu = [...sendReminderMenu, ...newData];
          setSendReminderMenu(updatedMenu);
        } else if (templateFor === "Send HS To Student") {
          const existingItems = new Set(sendHsStudentMenu);
          const newData = data.filter((item: any) => !existingItems.has(item));
          const updatedMenu = [...sendHsStudentMenu, ...newData];
          setSendHsStudentMenu(updatedMenu);
        } else {
          const existingItems = new Set(sendUniDocMenu);
          const newData = data.filter((item: any) => !existingItems.has(item));
          const updatedMenu = [...sendUniDocMenu, ...newData];
          setSendUniDocMenu(updatedMenu);
        }
      }
    } catch (error) {
      console.log("🚀 ~ getEmailTemplates ~ error:", error);
    }
  };
  useEffect(() => {
    getLawyerDashboard();
    getEmailTemplates("Send Email");
    getEmailTemplates("Send Reminder");
    getEmailTemplates("Send Uni Docs Reminder");
    getEmailTemplates("Send HS To Student");
  }, []);

  useEffect(() => {
    const toggleBodyOverflow = () => {
      document.body.style.overflowY = "hidden";
    };
    toggleBodyOverflow();
    return () => {
      document.body.style.overflowY = "auto";
    };
  });

  useEffect(() => {
    const toggleBodyOverflow = () => {
      document.body.style.overflowY =
        isModalOpen ||
        correctionModalReminder ||
        correctionModalReadyCheck ||
        correctionModalVED ||
        modalReminder ||
        modalReadyCheck ||
        modalVED ||
        modal
          ? "hidden"
          : "auto";
    };
    toggleBodyOverflow();
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [
    isModalOpen,
    correctionModalReminder,
    correctionModalReadyCheck,
    correctionModalVED,
    modalReminder,
    modalReadyCheck,
    modalVED,
    modal,
  ]);

  const preStudyData = async (studentId: any) => {
    let response = await prestudyService.getStepdata(studentId, 1);
    const { data } = response;
    dispatch(setStudentCorrectionStatus(data?.data?.isCorrectionStartedFromStudent));
    dispatch(setStudentId(data?.data?.studentId));
  }

  return (
    <Layout cssClass="lg:overflow-hidden">
      <div
        className="pb-16 overflow-x-scroll no-scrollbar max-h-[750px]"
        onContextMenu={(e) => e.preventDefault()}
      >
        {loading ? (
          <CustomLoader />
        ) : (
          <>
            <h1 className="p-4 text-[22px] md:text-[24px] font-semibold sticky top-0 bg-[#E1E5EA] z-[50]">
              Dashboard Overview
            </h1>
            <div className="flex flex-col xl:flex-row justify-between gap-2 md:gap-4 p-4">
              <div className="w-full md:w-full  xl:w-1/3 flex flex-col ">
                <h1 className="text-[20px] md:text-[24px] font-semibold my-3 martel-sans">
                  New
                </h1>
                <div className="overflow-x-scroll overflow-y-scroll no-scrollbar max-h-[250px] md:max-h-[192px] min-w-full text-[15px] md:overflow-x-hidden rounded-[8px] border-[1px]">
                  <table className="min-w-full h-[250px] md:h-[192px]">
                    <thead className="bg-[#C3CCD5] sticky top-0">
                      <tr className="text-[#0A4A5E] font-semibold border-b-[2px] border-[#C3CCD5]">
                        <td className="py-3 px-3 w-1/3">Student ID</td>
                        <td className="py-3 px-3 w-1/3">VED</td>
                        <td className="py-3 px-3 w-1/3">Process</td>
                        <td className="py-3 px-3 w-1/3">City</td>
                      </tr>
                    </thead>
                    {newData && newData[0] !== "No records found." ? (
                      <tbody className="px-3 bg-white">
                        {newData[0].map((item: dataType) => (
                          <tr
                            className={`px-3 text-[#0B0D0F]
                                  bg-white border-b-[2px] border-[#C3CCD5]
                                  }`}
                          >
                            <td className="px-3 py-3 w-1/3">
                              {item.studentId}
                            </td>
                            <td className="px-3 py-3 w-1/3">
                              {moment(item.visaExpiryDate)
                                .utc()
                                .format("YYYY-MM-DD")}
                            </td>
                            <td className="px-3 py-3 w-1/3">{item.study}</td>
                            <td className="px-3 py-3 w-1/3">{item.city}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody className="px-3 text-[#0A4A5E] font-semibold text-[14px] text-center bg-white max-h-full">
                        <tr>
                          <td colSpan={10} className="p-3">
                            No Record Found!
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <div className="w-full md:w-full xl:w-1/3 flex flex-col ">
                <h1 className="text-[20px] md:text-[24px] font-semibold my-3 martel-sans">
                  VED to check
                </h1>
                <div className="overflow-x-scroll overflow-y-scroll no-scrollbar max-h-[250px] md:max-h-[192px] min-w-full text-[15px] md:overflow-x-hidden rounded-[8px] border-[1px]">
                  <table className="min-w-full h-[250px] md:h-[192px]">
                    <thead className="bg-[#C3CCD5] sticky top-0">
                      <tr className="text-[#0A4A5E] font-semibold border-b-[2px] border-[#C3CCD5]">
                        <td className="py-3 px-3 w-1/4">Student ID</td>
                        <td className="py-3 px-3 w-1/4">VED</td>
                        <td className="py-3 px-3 w-1/4">Process</td>
                        <td className="py-3 px-3 w-1/4">City</td>
                      </tr>
                    </thead>
                    {vedData && vedData[0] !== "No records found." ? (
                      <tbody className="px-3 bg-white">
                        {vedData[0]?.map((item: dataType) => (
                          <menu.Root>
                            <tr
                              className={`px-3 text-[#0B0D0F] cursor-pointer
                                 ${
                                   item?.studentId === selectedVED?.studentId
                                     ? "bg-[#d7e0e9]"
                                     : "bg-white"
                                 } border-b-[2px] border-[#C3CCD5]
                                }`}
                              onClick={() => {
                                setSelectedStudentLEA({});
                                setSelectedReminder(null);
                                setSelectedReadyCheck(null);
                                if (
                                  item?.studentId === selectedVED?.studentId
                                ) {
                                  setSelectedVED({});
                                } else {
                                  setSelectedVED(item);
                                }
                              }}
                              onContextMenu={() => {
                                preStudyData(item?._id)
                              }}
                            >
                              <menu.Trigger className="contents">
                                <td className="px-3 py-3 w-1/4">
                                  {item.studentId}
                                </td>
                                <td className="px-3 py-3 w-1/4">
                                  {moment(item.visaExpiryDate)
                                    .utc()
                                    .format("YYYY-MM-DD")}
                                </td>
                                <td className="px-3 py-3 w-1/4">
                                  {item.study}
                                </td>
                                <td className="px-3 py-3 w-1/4">{item.city}</td>
                              </menu.Trigger>
                            </tr>
                            <ContextMenuList
                              menuItems={VEDcontextMenu}
                              student={item}
                              isTemplate={true}
                              sendEmailMenu={sendEmailMenu}
                              sendReminderMenu={sendReminderMenu}
                              sendUniDocMenu={sendUniDocMenu}
                            />
                          </menu.Root>
                        ))}
                      </tbody>
                    ) : (
                      <tbody className="px-3 text-[#0A4A5E] font-semibold text-[14px] text-center bg-white max-h-full">
                        <tr>
                          <td colSpan={10} className="p-3">
                            No Record Found!
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <div className="w-full md:w-full  xl:w-1/3 flex flex-col ">
                <h1 className=" text-[20px] md:text-[24px] font-semibold my-3 martel-sans">
                  Ready to check
                </h1>
                <div className="overflow-x-scroll overflow-y-scroll no-scrollbar max-h-[250px] md:max-h-[192px] min-w-full text-[15px] md:overflow-x-hidden rounded-[8px] border-[1px]">
                  <table className="min-w-full h-[250px] md:h-[192px]">
                    <thead className="bg-[#C3CCD5] sticky top-0">
                      <tr className="text-[#0A4A5E] font-semibold border-b-[2px] border-[#C3CCD5]">
                        <td className="py-3 px-3 w-1/4">Student ID</td>
                        <td className="py-3 px-3 w-1/4">VED</td>
                        <td className="py-3 px-3 w-1/4">Process</td>
                        <td className="py-3 px-3 w-1/4">City</td>
                      </tr>
                    </thead>
                    {readyTocheckData &&
                    readyTocheckData[0] !== "No records found." ? (
                      <tbody className="px-3 bg-white">
                        {readyTocheckData[0] &&
                          readyTocheckData[0].map((item: dataType) => (
                            <menu.Root>
                              <tr
                                className={`px-3 text-[#0B0D0F] cursor-pointer
                                ${
                                  item?.studentId ===
                                  selectedReadyCheck?.studentId
                                    ? "bg-[#d7e0e9]"
                                    : "bg-white"
                                } border-b-[2px] border-[#C3CCD5] 
                                                        }`}
                                onClick={() => {
                                  setSelectedStudentLEA({});
                                  setSelectedReminder(null);
                                  setSelectedVED({});
                                  if (
                                    item?.studentId ===
                                    selectedReadyCheck?.studentId
                                  ) {
                                    setSelectedReadyCheck(null);
                                  } else {
                                    setSelectedReadyCheck(item);
                                  }
                                }}
                              >
                                <menu.Trigger className="contents">
                                  <td className="px-3 py-3 w-1/4">
                                    {item.studentId}
                                  </td>
                                  <td className="px-3 py-3 w-1/4">
                                    {moment(item.visaExpiryDate)
                                      .utc()
                                      .format("YYYY-MM-DD")}
                                  </td>
                                  <td className="px-3 py-3 w-1/4">
                                    {item.study}
                                  </td>
                                  <td className="px-3 py-3 w-1/4">
                                    {item.city}
                                  </td>
                                </menu.Trigger>
                              </tr>
                              <ContextMenuList
                                menuItems={readyMenuItems}
                                student={item}
                                isTemplate={true}
                                sendEmailMenu={sendEmailMenu}
                                sendReminderMenu={sendReminderMenu}
                                sendUniDocMenu={sendUniDocMenu}
                              />
                            </menu.Root>
                          ))}
                      </tbody>
                    ) : (
                      <tbody className="px-3 text-[#0A4A5E] font-semibold text-[14px] text-center bg-white max-h-full">
                        <tr>
                          <td colSpan={10} className="p-3">
                            No Record Found!
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
            <div className="flex flex-col xl:flex-row justify-between gap-2 md:gap-4 p-4">
              <div className="flex flex-col gap-2 md:gap-4 flex-[2]">
                <div className="w-full md:w-full flex flex-col flex-1">
                  <h1 className="text-[20px] md:text-[24px] font-semibold my-3 martel-sans">
                    Reminders for today
                  </h1>
                  <div className="overflow-x-scroll overflow-y-scroll no-scrollbar max-h-[250px] md:max-h-[192px] min-w-full text-[15px] md:overflow-x-hidden rounded-[8px] border-[1px]">
                    <table className="min-w-full h-[250px] md:h-[192px]">
                      <thead className="bg-[#C3CCD5] sticky top-0">
                        <tr className="text-[#0A4A5E] font-semibold border-b-[2px] border-[#C3CCD5]">
                          <td className="py-3 px-3 w-1/5">Student ID</td>
                          <td className="py-3 px-3 w-1/5">VED</td>
                          <td className="py-3 px-3 w-1/5">Status</td>
                          <td className="py-3 px-3 w-1/5">Reason</td>
                          <td className="py-3 px-3 w-1/5">Name</td>
                        </tr>
                      </thead>
                      {reminders && reminders[0] !== "No records found." ? (
                        <tbody className="px-3 bg-white">
                          {reminders[0] &&
                            reminders[0].map((item: dataType) => (
                              <menu.Root>
                                <tr
                                  className={`px-3 text-[#0B0D0F] cursor-pointer
                                  ${
                                    item?.studentId ===
                                    selectedReminder?.studentId
                                      ? "bg-[#d7e0e9]"
                                      : "bg-white"
                                  } border-b-[2px] border-[#C3CCD5]
                                    }`}
                                  onClick={() => {
                                    setSelectedStudentLEA({});
                                    setSelectedReadyCheck(null);
                                    setSelectedVED({});
                                    if (
                                      item?.studentId ===
                                      selectedReminder?.studentId
                                    ) {
                                      setSelectedReminder(null);
                                    } else {
                                      setSelectedReminder(item);
                                    }
                                  }}
                                >
                                  <menu.Trigger className="contents">
                                    <td className="px-3 py-3 w-1/5">
                                      {item.studentId}
                                    </td>
                                    <td className="px-3 py-3 w-1/5">
                                      {moment(item.visaExpiryDate)
                                        .utc()
                                        .format("YYYY-MM-DD")}
                                    </td>
                                    <td className="px-3 py-3 w-1/5">
                                      {item?.verificationStatus}
                                    </td>
                                    <td className="px-3 py-3 w-1/5">
                                    {item?.reason}
                                    </td>
                                    <td className="px-3 py-3 w-1/5"> {item?.name}</td>
                                  </menu.Trigger>
                                </tr>
                                <ContextMenuList
                                  menuItems={reminderMenuItems}
                                  student={item}
                                  isTemplate={true}
                                  sendEmailMenu={sendEmailMenu}
                                  sendReminderMenu={sendReminderMenu}
                                  sendUniDocMenu={sendUniDocMenu}
                                />
                              </menu.Root>
                            ))}
                        </tbody>
                      ) : (
                        <tbody className="px-3 text-[#0A4A5E] font-semibold text-[14px] text-center bg-white max-h-full">
                          <tr>
                            <td colSpan={10} className="p-3">
                              No Record Found!
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
                <div className="w-full md:w-full flex flex-col flex-1">
                  <h1 className="text-[20px] md:text-[24px] font-semibold my-3 martel-sans">
                    File Ready for LEA
                  </h1>
                  <div className="overflow-x-scroll overflow-y-scroll no-scrollbar max-h-[250px] md:max-h-[192px] min-w-full text-[15px] md:overflow-x-hidden rounded-[8px] border-[1px]">
                    <table className="min-w-full h-[250px] md:h-[192px]">
                      <thead className="bg-[#C3CCD5] sticky top-0">
                        <tr className="text-[#0A4A5E] font-semibold border-b-[2px] border-[#C3CCD5]">
                          <td className="py-3 px-3 w-1/5">Student ID</td>
                          <td className="py-3 px-3 w-1/5">VED</td>
                          <td className="py-3 px-3 w-1/5">Status</td>
                          <td className="py-3 px-3 w-1/5">Reason</td>
                          <td className="py-3 px-3 w-1/5">Name</td>
                        </tr>
                      </thead>
                      {LeaReadyData &&
                      LeaReadyData[0] !== "No records found." ? (
                        <tbody className="px-3 bg-white">
                          {LeaReadyData[0] &&
                            LeaReadyData[0].map((item: dataType) => (
                              <menu.Root>
                                <tr
                                  className={`px-3 text-[#0B0D0F] cursor-pointer
                                  ${
                                    item?.studentId ===
                                    selectedStudentLEA?.studentId
                                      ? "bg-[#d7e0e9]"
                                      : "bg-white"
                                  }  border-b-[2px] border-[#C3CCD5]
                                  }`}
                                  onClick={() => {
                                    setSelectedReminder(null);
                                    setSelectedReadyCheck(null);
                                    setSelectedVED({});
                                    if (
                                      item?.studentId ===
                                      selectedStudentLEA?.studentId
                                    ) {
                                      setSelectedStudentLEA({});
                                    } else {
                                      setSelectedStudentLEA(item);
                                    }
                                  }}
                                >
                                  <menu.Trigger className="contents">
                                    <td className="px-3 py-3 w-1/5">
                                      {item.studentId}
                                    </td>
                                    <td className="px-3 py-3 w-1/5">
                                      {moment(item.visaExpiryDate)
                                        .utc()
                                        .format("YYYY-MM-DD")}
                                    </td>
                                    <td className="px-3 py-3 w-1/5">
                                      {"Ready for LEA"}
                                    </td>
                                    <td className="px-3 py-3 w-1/5">
                                      Blocked account
                                    </td>
                                    <td className="px-3 py-3 w-1/5">
                                      {item.name}
                                    </td>
                                  </menu.Trigger>
                                </tr>
                                <ContextMenuList
                                  menuItems={readyForleaOptions}
                                  student={item}
                                  isTemplate={true}
                                  sendEmailMenu={sendEmailMenu}
                                  sendReminderMenu={sendReminderMenu}
                                  sendUniDocMenu={sendUniDocMenu}
                                  sendHsStudentMenu={sendHsStudentMenu}
                                />
                              </menu.Root>
                            ))}
                        </tbody>
                      ) : (
                        <tbody className="px-3 text-[#0A4A5E] font-semibold text-[14px] text-center bg-white max-h-full">
                          <tr>
                            <td colSpan={10} className="p-3">
                              No Record Found!
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
              <div className="flex flex-col flex-1">
                <div className="w-full flex flex-col flex-1">
                  <h1 className="text-[20px] md:text-[24px] font-semibold my-3 martel-sans">
                    Services
                  </h1>
                  <div className=" min-w-full text-[15px] flex flex-1 max-h-[330px] md:max-h-[480px] overflow-y-scroll no-scrollbar border-[1px] rounded-[8px]">
                    <table className="min-w-full h-[250px] md:h-[192px]">
                      {services &&
                      services[0] &&
                      services[0] !== "No records found." ? (
                        <tbody className="px-3 bg-white">
                          {services[0] &&
                            services[0].map((item: statusType) => (
                              <tr
                                className={`px-3 text-[#0B0D0F]
                                    bg-white border-b-[2px] border-[#C3CCD5]
                                    }`}
                              >
                                <td className="px-3 py-3 w-1/3">
                                  {item.count}
                                </td>
                                <td className="px-3 py-3 w-1/3">
                                  {getLabel(item.status)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ) : (
                        <tbody className="px-3 text-[#0A4A5E] font-semibold text-[14px] text-center bg-white max-h-full">
                          <tr>
                            <td colSpan={10} className="p-3">
                              No Record Found!
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {isModalOpen && (
          <BarChartModal toggleModal={toggleModal} isModalOpen={isModalOpen} />
        )}
        <footer className="bg-white p-4 text-[#71859A] fixed right-0 bottom-0 w-full">
          <div className="w-full flex items-center justify-between gap-1">
            <span
              className={`text-[15px] ${sidebarOpen ? "lg:ml-60" : "lg:ml-16"}`}
            >
              @2024 SIAP. All rights reserved.
            </span>
            <div className="flex justify-between gap-2">
              <img
                src={toggle}
                alt="toggle-icon"
                className="w-6 cursor-pointer"
                onClick={() => {
                  if (Object.values(selectedVED)?.length > 0) {
                    setModalVED(true);
                  }
                  if (selectedReadyCheck) {
                    setModalReadyCheck(true);
                  }
                  if (Object.values(selectedStudentLEA)?.length > 0) {
                    setModal(true);
                  }
                  if (selectedReminder) {
                    setModalReminder(true);
                  }
                }}
              />
              <img
                src={chart}
                alt="chart-icon"
                className="w-6 cursor-pointer"
                onClick={toggleModal}
              />
            </div>
          </div>
        </footer>

        {/* For selectedStudentLEA context Modal Menu */}
        {modal && (
          <ContextMenuModal
            setSelectedStudentLEA={setSelectedStudentLEA}
            setCorrectionModal={setCorrectionModal}
            isTemplate={true}
            student={selectedStudentLEA}
            menuItems={readyForleaOptions}
            sendEmailMenu={sendEmailMenu}
            sendReminderMenu={sendReminderMenu}
            sendUniDocMenu={sendUniDocMenu}
            sendHsStudentMenu={sendHsStudentMenu}
            handleClose={() => {
              setSelectedStudentLEA({});
              setModal(false);
            }}
          />
        )}

        {/* For selectedVED context Modal Menu */}
        {modalVED && (
          <ContextVEDMenuModal
            student={selectedVED}
            handleClose={() => {
              setSelectedVED({});
              setModalVED(false);
            }}
            setSelectedVED={setSelectedVED}
            isTemplate={true}
            menuItems={VEDcontextMenu}
            sendEmailMenu={sendEmailMenu}
            sendReminderMenu={sendReminderMenu}
            sendUniDocMenu={sendUniDocMenu}
            setCorrectionModalVED={setCorrectionModalVED}
          />
        )}

        {/* For selectedReadyCheck context Modal Menu */}
        {modalReadyCheck && (
          <ContextReadyCheckMenuModal
            student={selectedReadyCheck}
            handleClose={() => {
              setSelectedReadyCheck(null);
              setModalReadyCheck(false);
            }}
            setSelectedReadyCheck={setSelectedReadyCheck}
            isTemplate={true}
            menuItems={readyMenuItems}
            sendEmailMenu={sendEmailMenu}
            sendReminderMenu={sendReminderMenu}
            sendUniDocMenu={sendUniDocMenu}
            setCorrectionModalReadyCheck={setCorrectionModalReadyCheck}
            setFileuploadModal={setFileuploadModal}
            setFileName={setFileName}
            setStep={setStep}
          />
        )}

        {/* For selectedReminder context Modal Menu */}
        {modalReminder && (
          <ContextReminderMenuModal
            student={selectedReminder}
            handleClose={() => {
              setSelectedReminder(null);
              setModalReminder(false);
            }}
            setSelectedReminder={setSelectedReminder}
            isTemplate={true}
            menuItems={reminderMenuItems}
            sendEmailMenu={sendEmailMenu}
            sendReminderMenu={sendReminderMenu}
            sendUniDocMenu={sendUniDocMenu}
            setCorrectionModalReminder={setCorrectionModalReminder}
          />
        )}
      </div>
      {correctionModal && (
        <ChangeCorrectionStatusModal
          setCorrectionModal={setCorrectionModal}
          student={selectedStudentLEA}
        />
      )}

      {correctionModalVED && (
        <ChangeCorrectionStatusModal
          setCorrectionModal={setCorrectionModalVED}
          student={selectedVED}
        />
      )}

      {correctionModalReadyCheck && (
        <ChangeCorrectionStatusModal
          setCorrectionModal={setCorrectionModalReadyCheck}
          student={selectedReadyCheck}
        />
      )}

      {correctionModalReminder && (
        <ChangeCorrectionStatusModal
          setCorrectionModal={setCorrectionModalReminder}
          student={selectedReminder}
        />
      )}

      {fileuploadModal && (
        <UploadDocsModal
          setFileuploadModal={setFileuploadModal}
          student={selectedReadyCheck}
          fileName={fileName}
          step={step}
        />
      )}
    </Layout>
  );
};

export default LawyerDashboard;
