import React, { ChangeEvent } from 'react';
import { SelectProps } from 'utils/types';

const SelectInput: React.FC<SelectProps> = ({fieldName,options,selectedOption, setSelectedOption,className,setError,handleSelect,disabled,ref,onFocus}) => {
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption && setSelectedOption(value);
    if(setError){
      setError(null)
    }
  };

  return (
    <select ref={ref}
    onFocus={onFocus} value={selectedOption} onChange={handleSelect ? handleSelect :handleSelectChange} className={`border-[2px] border-[#F0F2F4] text-[#71859A] text-[14px] rounded-md password-input placeholder-[#71859A] placeholder:text-[10px] ${className && className}`} name={fieldName} disabled={disabled}>
      {options?.map((option:{label :string,value:string}, index:number) => (
        <option key={index} value={option.value} selected={selectedOption === option.value ? true : false}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectInput;
