import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, LinearProgress, TextField } from '@mui/material';
import CommentSection from 'components/Form/CommentSection';
import { useAppSelector } from 'store/Hooks';
import { prestudySelector } from 'store/slices/Prestudy';
import { Inputfile } from 'components/Form';
import { crossBlack, documentBlack } from 'utils/Images';
import { userSelector } from 'store/slices/User';

interface matriculationLetterOneProps {
    showField: (args: string) => boolean;
    setProgress: Dispatch<SetStateAction<number>>;
    setUploadingThree: Dispatch<SetStateAction<boolean>>;
    setMatriculationFileError: Dispatch<SetStateAction<string | null>>;
    intervalIdRefthree: any;
    uploadFiles: (selectedFile: FileList | null,
        fieldName: string,
        form: any) => void;
    matriculationFileError: string | null;
    showComments: (args: string) => boolean;
    matriculationFiles: File[] | null;
    uploadingThree: boolean;
    values: any;
    removeFile: (args1: string, args2: string) => void;
    progress: number;
    uploadDocument: (formData: FormData) => void
}

const MatriculationLetterOne: React.FC<matriculationLetterOneProps> = ({showField,values,showComments,setProgress,progress,uploadFiles,setUploadingThree,setMatriculationFileError,uploadingThree,removeFile,intervalIdRefthree,matriculationFileError,matriculationFiles}) => {
    const DateInput = DatePicker as any;
    const { currentStep } = useAppSelector(prestudySelector);
    const { user } = useAppSelector(userSelector);

    return (
        <>
            <div className={showField('date') ? "block" : 'hidden'}>
                <div className="my-2 flex justify-between items-center">
                    <label
                        htmlFor={"issueDate"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                    >
                        Enter the very first expected end date of your studies
                        <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                </div>
                <Field name="date">
                    {({ field, form }: FieldProps) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateInput
                                {...field}
                                label="YYYY-MM-DD"
                                renderInput={(props: any) => (
                                    <TextField {...props} />
                                )}
                                format="YYYY-MM-DD"
                                value={values.date}
                                onChange={(newDate: any) => {
                                    form.setFieldValue("date", newDate);
                                }}
                                className={
                                    "lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full password-input"
                                }
                            />
                        </LocalizationProvider>
                    )}
                </Field>
                <ErrorMessage
                    name="date"
                    component="div"
                    className="text-red-600"
                />
                {showComments('date') &&
                    <CommentSection field={'date'} step={currentStep - 1} userType="student" />
                }
            </div>
            <div className={showField('matriculationFile') ? "block" : 'hidden'}>
                <p className="text-[#FF4444] text-[14px] font-semibold mt-1">
                    You can find the end date of your studies on your FIRST
                    matriculation letter.
                </p>
                <div className="mb-2 mt-4 flex justify-between items-center">
                    <label
                        htmlFor={"subsistenceFile"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                    >
                        Upload your matriculation letter from {user?.university} (if you do
                        not have it, contact your university urgently)
                        <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                </div>
                <Field name="matriculationFile">
                    {({ field, form }: FieldProps) => (
                        <>
                            <Inputfile
                                {...field}
                                mainClass="lg:w-1/3 xl:w-1/3 w-full"
                                btnClass={null}
                                handleChange={(
                                    event: ChangeEvent<HTMLInputElement>
                                ) => {
                                    setProgress(0);
                                    setUploadingThree(true);
                                    let selectedFile: FileList | null =
                                        event.target.files;
                                    selectedFile &&
                                        form.setFieldValue(
                                            "matriculationFile",
                                            Array.from(selectedFile)
                                        );
                                    setMatriculationFileError(null);
                                    intervalIdRefthree.current = setInterval(() => {
                                        setProgress(
                                            (prevProgress) => prevProgress + 10
                                        );
                                    }, 500);
                                    uploadFiles(
                                        event.target.files,
                                        "matriculationFile",
                                        form
                                    );
                                }}
                                multiple={false}
                            />
                            {typeof values.matriculationFile === "string" && (
                                <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                                    <img src={documentBlack} alt="documentBlack" />
                                    <label className="flex-1">
                                        {values.matriculationFile}
                                    </label>
                                    <img
                                        src={crossBlack}
                                        alt="documentBlack"
                                        onClick={() => {
                                            form.setFieldValue("matriculationFile", []);
                                            setMatriculationFileError(
                                                "Files are required"
                                            );
                                        }}
                                        className="cursor-pointer"
                                    />
                                </div>
                            )}
                            {uploadingThree && (
                                <Box sx={{ width: "100%" }}>
                                    <LinearProgress
                                        variant="determinate"
                                        value={progress}
                                    />
                                </Box>
                            )}
                        </>
                    )}
                </Field>
                {matriculationFiles &&
                    matriculationFiles?.map((item) => (
                        <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                            <img src={documentBlack} alt="documentBlack" />
                            <label className="flex-1">{item.name}</label>
                            <img
                                src={crossBlack}
                                alt="documentBlack"
                                onClick={() =>
                                    removeFile(item.name, "matriculationFile")
                                }
                                className="cursor-pointer"
                            />
                        </div>
                    ))}
                {matriculationFileError ? (
                    <div className="text-red-500 text-sm md:text-base fileError">
                        {matriculationFileError}
                    </div>
                ) : (
                    <ErrorMessage
                        name="matriculationFile"
                        component="div"
                        className="text-red-600 fileError"
                    />
                )}
                {showComments('matriculationFile') &&
                    <CommentSection field={'matriculationFile'} step={currentStep - 1} userType="student" />
                }
            </div>
        </>
    )
}

export default MatriculationLetterOne