import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/User";
import prestudyReducer from "./slices/Prestudy";
import formCorrectionReducer from "./slices/FormCorrection"
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
  
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  userReducer,
  prestudyReducer,
  formCorrectionReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
