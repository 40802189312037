import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Input } from 'components/Form';
import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { Dispatch, SetStateAction } from 'react'
import { useAppSelector } from 'store/Hooks';
import { prestudySelector } from 'store/slices/Prestudy';

interface germanEntryCompProps {
    values: any;
    showField?: (args: string) => boolean;
    showComments?: (args: string) => boolean;
    modal?: boolean;
    setModal?: any
    field?: string
    setField?: any
    handleChange: any;
    userType?: boolean;
    getCount?: (args: string) => number
}
const GermanEntryComp: React.FC<germanEntryCompProps> = ({ values, showField, showComments, modal, setModal, field, setField, handleChange, userType, getCount }) => {
    const DateInput = DatePicker as any;
    const { currentStep } = useAppSelector(prestudySelector);
    return (
        <div className={`GermanEntryComp flex flex-col gap-2 px-4 mb-3`}>
            <span className="text-[#FF4444]">
                Enter here the last three travels to Germany, if applicable, BEFORE your last entry. The sorting is not important.
            </span>
            <div className={`flex flex-col lg:flex-row gap-2`}>
                <div className={!showField ? "block" : showField('germanLastEntryFrom1') ? "block" : 'hidden'}>
                    <div className="flex justify-between items-center my-3">
                        <label
                            htmlFor={"germanLastEntryFrom1"}
                            className="font-400 text-[12px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('germanLastEntryFrom1')
                                    }}
                                    checked={modal && field === 'germanLastEntryFrom1'}
                                />
                            }
                            From(YYYY-MM-DD)
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount("germanLastEntryFrom1") > 0 && (
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount("germanLastEntryFrom1")}
                            </div>
                        )}

                    </div>
                    <Field name="germanLastEntryFrom1">
                        {({ field, form }: FieldProps) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateInput
                                    {...field}
                                    label="YYYY-MM-DD"
                                    renderInput={(props: any) => (
                                        <TextField {...props} />
                                    )}
                                    format="YYYY-MM-DD"
                                    value={values.germanLastEntryFrom1}
                                    onChange={(newDate: any) => {
                                        form.setFieldValue("germanLastEntryFrom1", newDate);
                                    }}
                                    className={
                                        "password-input"
                                    }
                                />
                            </LocalizationProvider>
                        )}
                    </Field>
                    <ErrorMessage
                        name="germanLastEntryFrom1"
                        component="div"
                        className="text-red-600"
                    />
                    {showComments && showComments('germanLastEntryFrom1') &&
                        <CommentSection field={'germanLastEntryFrom1'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className={!showField ? "block" : showField('germanLastEntryTo1') ? "block" : 'hidden'}>
                    <div className="flex justify-between items-center my-3">
                        <label
                            htmlFor={"germanLastEntryTo1"}
                            className="font-400 text-[12px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('germanLastEntryTo1')
                                    }}
                                    checked={modal && field === 'germanLastEntryTo1'}
                                />
                            }
                            To(YYYY-MM-DD)
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount("germanLastEntryTo1") > 0 && (
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount("germanLastEntryTo1")}
                            </div>
                        )}

                    </div>
                    <Field name="germanLastEntryTo1">
                        {({ field, form }: FieldProps) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateInput
                                    {...field}
                                    label="YYYY-MM-DD"
                                    renderInput={(props: any) => (
                                        <TextField {...props} />
                                    )}
                                    format="YYYY-MM-DD"
                                    value={values.germanLastEntryTo1}
                                    onChange={(newDate: any) => {
                                        form.setFieldValue("germanLastEntryTo1", newDate);
                                    }}
                                    className={
                                        "password-input"
                                    }
                                />
                            </LocalizationProvider>
                        )}
                    </Field>
                    <ErrorMessage
                        name="germanLastEntryTo1"
                        component="div"
                        className="text-red-600"
                    />
                    {showComments && showComments('germanLastEntryTo1') &&
                        <CommentSection field={'germanLastEntryTo1'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className={!showField ? "block" : showField('germanLastEntryCity1') ? "block" : 'hidden'}>
                    <div className="flex justify-between items-center my-3">
                        <label
                            htmlFor={"germanLastEntryCity1"}
                            className="font-400 text-[12px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('germanLastEntryCity1')
                                    }}
                                    checked={modal && field === 'germanLastEntryCity1'}
                                />
                            }
                            City
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount("germanLastEntryCity1") > 0 && (
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount("germanLastEntryCity1")}
                            </div>
                        )}
                    </div>
                    <Input
                        type="text"
                        fieldName="germanLastEntryCity1"
                        setValue={values.germanLastEntryCity1}
                        className={null}
                        handleChange={handleChange}
                    />
                    {showComments && showComments('germanLastEntryTo1') &&
                        <CommentSection field={'germanLastEntryTo1'} step={currentStep - 1} userType="student" />
                    }
                </div>
            </div>
            <div className={`flex flex-col lg:flex-row gap-2`}>
                <div className={!showField ? "block" : showField('germanLastEntryFrom2') ? "block" : 'hidden'}>
                    <div className="flex justify-between items-center my-3">
                        <label
                            htmlFor={"germanLastEntryFrom2"}
                            className="font-400 text-[12px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('germanLastEntryFrom2')
                                    }}
                                    checked={modal && field === 'germanLastEntryFrom2'}
                                />
                            }
                            From(YYYY-MM-DD)
                        </label>
                        {getCount && getCount("germanLastEntryFrom2") > 0 && (
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount("germanLastEntryFrom2")}
                            </div>
                        )}
                    </div>
                    <Field name="germanLastEntryFrom2">
                        {({ field, form }: FieldProps) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateInput
                                    {...field}
                                    label="YYYY-MM-DD"
                                    renderInput={(props: any) => (
                                        <TextField {...props} />
                                    )}
                                    format="YYYY-MM-DD"
                                    value={values.germanLastEntryFrom2}
                                    onChange={(newDate: any) => {
                                        form.setFieldValue("germanLastEntryFrom2", newDate);
                                    }}
                                    className={
                                        "password-input"
                                    }
                                />
                            </LocalizationProvider>
                        )}
                    </Field>
                    <ErrorMessage
                        name="germanLastEntryFrom2"
                        component="div"
                        className="text-red-600"
                    />
                    {showComments && showComments('germanLastEntryFrom2') &&
                        <CommentSection field={'germanLastEntryFrom2'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className={!showField ? "block" : showField('germanLastEntryTo2') ? "block" : 'hidden'}>
                    <div className="flex justify-between items-center my-3">
                        <label
                            htmlFor={"germanLastEntryTo2"}
                            className="font-400 text-[12px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('germanLastEntryTo2')
                                    }}
                                    checked={modal && field === 'germanLastEntryTo2'}
                                />
                            }
                            To(YYYY-MM-DD)
                        </label>
                        {getCount && getCount("germanLastEntryTo2") > 0 && (
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount("germanLastEntryTo2")}
                            </div>
                        )}

                    </div>
                    <Field name="germanLastEntryTo2">
                        {({ field, form }: FieldProps) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateInput
                                    {...field}
                                    label="YYYY-MM-DD"
                                    renderInput={(props: any) => (
                                        <TextField {...props} />
                                    )}
                                    format="YYYY-MM-DD"
                                    value={values.germanLastEntryTo2}
                                    onChange={(newDate: any) => {
                                        form.setFieldValue("germanLastEntryTo2", newDate);
                                    }}
                                    className={
                                        "password-input"
                                    }
                                />
                            </LocalizationProvider>
                        )}
                    </Field>
                    <ErrorMessage
                        name="germanLastEntryTo2"
                        component="div"
                        className="text-red-600"
                    />
                    {showComments && showComments('germanLastEntryTo2') &&
                        <CommentSection field={'germanLastEntryTo2'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className={!showField ? "block" : showField('germanLastEntryCity2') ? "block" : 'hidden'}>
                    <div className="flex justify-between items-center my-3">
                        <label
                            htmlFor={"germanLastEntryCity2"}
                            className="font-400 text-[12px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('germanLastEntryCity2')
                                    }}
                                    checked={modal && field === 'germanLastEntryCity2'}
                                />
                            }
                            City
                        </label>
                        {getCount && getCount("germanLastEntryCity2") > 0 && (
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount("germanLastEntryCity2")}
                            </div>
                        )}
                    </div>
                    <Input
                        type="text"
                        fieldName="germanLastEntryCity2"
                        setValue={values.germanLastEntryCity2}
                        className={null}
                        handleChange={handleChange}
                    />
                    {showComments && showComments('germanLastEntryCity2') &&
                        <CommentSection field={'germanLastEntryCity2'} step={currentStep - 1} userType="student" />
                    }
                </div>
            </div>
            <div className={`flex flex-col lg:flex-row gap-2`}>
                <div className={!showField ? "block" : showField('germanLastEntryFrom3') ? "block" : 'hidden'}>
                    <div className="flex justify-between items-center my-3">
                        <label
                            htmlFor={"germanLastEntryFrom3"}
                            className="font-400 text-[12px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('germanLastEntryFrom3')
                                    }}
                                    checked={modal && field === 'germanLastEntryFrom3'}
                                />
                            }
                            From(YYYY-MM-DD)
                        </label>
                        {getCount && getCount("germanLastEntryFrom3") > 0 && (
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount("germanLastEntryFrom3")}
                            </div>
                        )}

                    </div>
                    <Field name="germanLastEntryFrom3">
                        {({ field, form }: FieldProps) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateInput
                                    {...field}
                                    label="YYYY-MM-DD"
                                    renderInput={(props: any) => (
                                        <TextField {...props} />
                                    )}
                                    format="YYYY-MM-DD"
                                    value={values.germanLastEntryFrom3}
                                    onChange={(newDate: any) => {
                                        form.setFieldValue("germanLastEntryFrom3", newDate);
                                    }}
                                    className={
                                        "password-input"
                                    }
                                />
                            </LocalizationProvider>
                        )}
                    </Field>
                    <ErrorMessage
                        name="germanLastEntryFrom3"
                        component="div"
                        className="text-red-600"
                    />
                    {showComments && showComments('germanLastEntryFrom3') &&
                        <CommentSection field={'germanLastEntryFrom3'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className={!showField ? "block" : showField('germanLastEntryTo3') ? "block" : 'hidden'}>
                    <div className="flex justify-between items-center my-3">
                        <label
                            htmlFor={"germanLastEntryTo3"}
                            className="font-400 text-[12px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('germanLastEntryTo3')
                                    }}
                                    checked={modal && field === 'germanLastEntryTo3'}
                                />
                            }
                            To(YYYY-MM-DD)
                        </label>
                        {getCount && getCount("germanLastEntryTo3") > 0 && (
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount("germanLastEntryTo3")}
                            </div>
                        )}
                    </div>
                    <Field name="germanLastEntryTo3">
                        {({ field, form }: FieldProps) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateInput
                                    {...field}
                                    label="YYYY-MM-DD"
                                    renderInput={(props: any) => (
                                        <TextField {...props} />
                                    )}
                                    format="YYYY-MM-DD"
                                    value={values.germanLastEntryTo3}
                                    onChange={(newDate: any) => {
                                        form.setFieldValue("germanLastEntryTo3", newDate);
                                    }}
                                    className={
                                        "password-input"
                                    }
                                />
                            </LocalizationProvider>
                        )}
                    </Field>
                    <ErrorMessage
                        name="germanLastEntryTo3"
                        component="div"
                        className="text-red-600"
                    />
                    {showComments && showComments('germanLastEntryTo3') &&
                        <CommentSection field={'germanLastEntryTo3'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className={!showField ? "block" : ('germanLastEntryCity3') ? "block" : 'hidden'}>
                    <div className="flex justify-between items-center my-3">
                        <label
                            htmlFor={"germanLastEntryCity3"}
                            className="font-400 text-[12px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('germanLastEntryCity3')
                                    }}
                                    checked={modal && field === 'germanLastEntryCity3'}
                                />
                            }
                            City
                        </label>
                        {getCount && getCount("germanLastEntryCity3") > 0 && (
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount("germanLastEntryCity3")}
                            </div>
                        )}

                    </div>
                    <Input
                        type="text"
                        fieldName="germanLastEntryCity3"
                        setValue={values.germanLastEntryCity3}
                        className={null}
                        handleChange={handleChange}
                    />
                    {showComments && showComments('germanLastEntryCity3') &&
                        <CommentSection field={'germanLastEntryCity3'} step={currentStep - 1} userType="student" />
                    }
                </div>
            </div>
        </div>
    )
}

export default GermanEntryComp