import { Input, Inputfile, RadioGroup, SelectInput } from "components/Form";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { steptwoSchema } from "schema/Prestudy";
import { addressOptions, cityOptions, genderOptions } from "utils/data";
import { stepDataType, stepProps, stepTwoTypes } from "utils/types";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import { prestudySelector, setFeatureLoaded } from "store/slices/Prestudy";
import PhoneInput from "react-phone-input-2";
import { address, crossBlack, documentBlack } from "utils/Images";
import { validateFiles } from "utils/Helper";
import { pdfjs } from "react-pdf";
import { userSelector } from "store/slices/User";
import moment from "moment";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { CorrectionsteptwoSchema } from "schema/FormCorrection";
import CommentSection from "components/Form/CommentSection";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const StepTwo: React.FC<stepProps> = ({
  submitStepdata,
  nextActive,
  uploadDocument,
  verifyCorrection,
}) => {
  const DateInput = DatePicker as any;
  const CountrySelect = CountryDropdown as any;
  const RegionSelect = RegionDropdown as any;
  const [progress, setProgress] = React.useState(0);
  const [uploading, setUploading] = useState(false);
  const dispatch = useAppDispatch();
  const [stepFields, setStepFields] = useState<stepTwoTypes>({
    lastName: "",
    firstName: "",
    middleName: "",
    gender: "",
    city: "",
    zipcode: "",
    address: "",
    houseNo: "",
    phoneNumber: "",
    dob: null,
    birthPlace: "",
    birthCountry: "",
    region: "",
    addressRegister: "",
    registerDate: null,
    addressFile: [],
  });
  const initialValues: stepTwoTypes = {
    lastName: stepFields.lastName !== "" ? stepFields.lastName : "",
    firstName: stepFields.firstName !== "" ? stepFields.firstName : "",
    middleName: stepFields.middleName !== "" ? stepFields.middleName : "",
    gender: stepFields.gender !== "" ? stepFields.gender : "",
    city: stepFields.city !== "" ? stepFields.city : "",
    zipcode: stepFields.zipcode !== "" ? stepFields.zipcode : "",
    address: stepFields.address !== "" ? stepFields.address : "",
    houseNo: stepFields.houseNo !== "" ? stepFields.houseNo : "",
    phoneNumber: stepFields.phoneNumber !== "" ? stepFields.phoneNumber : "",
    dob: stepFields.dob ? stepFields.dob : "",
    birthPlace: stepFields.birthPlace ? stepFields.birthPlace : "",
    birthCountry: stepFields.birthCountry ? stepFields.birthCountry : "",
    region: stepFields.region ? stepFields.region : "",
    addressRegister: stepFields.addressRegister
      ? stepFields.addressRegister
      : "",
    registerDate: stepFields.registerDate ? stepFields.registerDate : "",
    addressFile: stepFields.addressFile ? stepFields.addressFile : [],
  };
  const intervalIdRef = useRef<any>();
  const [files, setFiles] = useState<File[] | null>(null);
  const [fileError, setfileError] = useState<string | null>(null);
  const { currentStep, stepData, featureLoaded } =
    useAppSelector(prestudySelector);
  const { user } = useAppSelector(userSelector);
  const zipcodeOption = [
    { value: "", label: "Select zipcode" },
    { value: "14467", label: "14467" },
    { value: "14469", label: "14469" },
    { value: "14471", label: "14471" },
    { value: "14473", label: "14473" },
    { value: "14476", label: "14476" },
    { value: "14478", label: "14478" },
    { value: "14480", label: "14480" },
    { value: "14482", label: "14482" },
  ];
  useEffect(() => {
    if (progress > 100) {
      setUploading(false);
      setProgress(0);
      clearInterval(intervalIdRef?.current);
    }
  }, [progress]);

  useEffect(() => {
    if (progress > 0) {
      dispatch(setFeatureLoaded(true));
    } else {
      dispatch(setFeatureLoaded(false));
    }
  }, [progress]);

  useEffect(() => {
    const updatedFields = { ...stepFields };
    stepData?.forEach((item) => {
      const name = item.name as keyof stepTwoTypes;
      switch (name) {
        case "lastName":
        case "firstName":
        case "middleName":
        case "gender":
        case "city":
        case "zipcode":
        case "address":
        case "houseNo":
        case "phoneNumber":
        case "birthPlace":
        case "birthCountry":
        case "region":
        case "addressRegister":
          updatedFields[name] = item.value;
          break;
        case "registerDate":
        case "dob":
          if (typeof item.value === "string" && item.value !== "") {
            updatedFields[name] = dayjs(
              moment(item.value).format("YYYY-MM-DD")
            );
          }
          break;
        case "addressFile":
          updatedFields[name] =
            item.value instanceof Array ? item.value[0]?.name : item.value;

          break;
        default:
          break;
      }
    });
    setStepFields(updatedFields);
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [stepData]);

  const handleSubmit = async (values: stepTwoTypes) => {
    try {
      if (user?.formStatus === "SUBMITTED") {
        let payload = [
          {
            name: "lastName",
            value: values.lastName,
          },
          {
            name: "firstName",
            value: values.firstName,
          },
          {
            name: "middleName",
            value: values.middleName,
          },
          {
            name: "gender",
            value: values.gender,
          },
          {
            name: "city",
            value: values.city,
          },
          {
            name: "zipcode",
            value: values.zipcode,
          },
          {
            name: "address",
            value: values.address,
          },
          {
            name: "houseNo",
            value: values.houseNo,
          },
          // {
          //   name: "phoneNumber",
          //   value: !values.phoneNumber.includes("+")
          //     ? `+${values.phoneNumber}`
          //     : values.phoneNumber,
          // },
          {
            name: "dob",
            value:
              values.dob !== ""
                ? new Date(
                    moment(new Date(values.dob)).format("YYYY-MM-DD")
                  ).toISOString()
                : "",
          },
          {
            name: "birthPlace",
            value: values.birthPlace,
          },
          {
            name: "birthCountry",
            value: values.birthCountry,
          },
          {
            name: "region",
            value: values.region,
          },
          {
            name: "addressRegister",
            value: values.addressRegister,
          },
          {
            name: "registerDate",
            value:
              values.registerDate !== ""
                ? new Date(
                    moment(values.registerDate).format("YYYY-MM-DD")
                  ).toISOString()
                : "",
          },
        ];
        const uniqueNames: { [name: string]: boolean } = {};
        let updatedArr: { name: string; value: string | undefined }[] = [];
        payload.map((item) => {
          let itemName = item.name as keyof stepTwoTypes;
          const oldValue = stepFields[itemName];
          if (!oldValue) {
            uniqueNames[item.name] = true;
            updatedArr.push({ ...item, value: item.value });
          }
          if (oldValue && oldValue !== item.value) {
            if (!uniqueNames[item.name]) {
              uniqueNames[item.name] = true;
              updatedArr.push({ ...item, value: item.value });
            }
          }
        });
        await verifyCorrection(updatedArr);
      } else {
        if (!fileError) {
          let payload = [
            {
              name: "lastName",
              label: "Last Name",
              value: values.lastName,
              isShow: true,
            },
            {
              name: "firstName",
              label: "First Name",
              value: values.firstName,
              isShow: true,
            },
            {
              name: "middleName",
              label: "Middle Name (other given name)",
              value: values.middleName,
              isShow: true,
            },
            {
              name: "gender",
              label: "Gender",
              value: values.gender,
              isShow: true,
            },
            {
              name: "city",
              label: "City",
              value: values.city,
              isShow: true,
            },
            {
              name: "zipcode",
              label: "Zip/Postal code",
              value: values.zipcode,
              isShow: true,
            },
            {
              name: "address",
              label: "Street Address",
              value: values.address,
              isShow: true,
            },
            {
              name: "houseNo",
              label: "House N.",
              value: values.houseNo,
              isShow: true,
            },
            // {
            //   name: "phoneNumber",
            //   label: "Phone Number",
            //   value: !values.phoneNumber.includes("+")
            //     ? `+${values.phoneNumber}`
            //     : values.phoneNumber,
            //   isShow: true,
            // },
            {
              name: "dob",
              label: "Date of Birth",
              value:
                values.dob !== ""
                  ? new Date(
                      moment(new Date(values.dob)).format("YYYY-MM-DD")
                    ).toISOString()
                  : "",
              isShow: true,
            },
            {
              name: "birthPlace",
              label: "Place of Birth",
              value: values.birthPlace,
              isShow: true,
            },
            {
              name: "birthCountry",
              label: "Country of Birth",
              value: values.birthCountry,
              isShow: true,
            },
            {
              name: "region",
              label: "Region",
              value: values.region,
              isShow: true,
            },
            {
              name: "addressRegister",
              label:
                "Do you have an address registration (called ANMELDUNG or Meldebestätigung)?",
              value: values.addressRegister,
              isShow: true,
            },
            {
              name: "registerDate",
              label: "Registration Date",
              value:
                values.registerDate !== ""
                  ? new Date(
                      moment(values.registerDate).format("YYYY-MM-DD")
                    ).toISOString()
                  : "",
              isShow: true,
            },
          ];
          const uniqueNames: { [name: string]: boolean } = {};
          let updatedArr: stepDataType[] = [];
          payload.map((item) => {
            let itemName = item.name as keyof stepTwoTypes;
            const oldValue = stepFields[itemName];
            if (!oldValue) {
              uniqueNames[item.name] = true;
              updatedArr.push({ ...item, value: item.value });
            }
            if (oldValue && oldValue !== item.value) {
              if (!uniqueNames[item.name]) {
                uniqueNames[item.name] = true;
                updatedArr.push({ ...item, value: item.value });
              }
            }
          });
          await submitStepdata(
            updatedArr?.length > 0 ? updatedArr : payload,
            null
          );
        }
      }
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
    }
  };

  const removeFile = async (fileName: string) => {
    const updatedFiles = files?.filter((item) => item.name !== fileName);
    if (updatedFiles && updatedFiles.length === 0 && nextActive) {
      setfileError("Files are required");
    }
    updatedFiles && setFiles(updatedFiles);
    const formData: any = new FormData();
    updatedFiles?.forEach((file: any) => {
      formData.append("addressFile", file);
    });
    user && formData.append("userId", user?._id);
    currentStep && formData.append("step", (currentStep - 1).toString());
    formData.append(
      "label",
      "Address registration (called Anmeldung or Meldebestätigung)"
    );
    await uploadDocument(formData);
    setProgress(0);
  };

  const uploadFiles = async (selectedFile: FileList | null) => {
    const allowedTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
    ];
    const isValidate = await validateFiles(selectedFile, allowedTypes);
    if (isValidate === "true") {
      if (selectedFile) {
        setFiles(Array.from(selectedFile));
        const formData: any = new FormData();
        Array.from(selectedFile)?.forEach((file: any) => {
          formData.append("addressFile", file);
        });
        user && formData.append("userId", user?._id);
        currentStep && formData.append("step", (currentStep - 1).toString());
        formData.append("isRequired", true);
        formData.append(
          "label",
          "Address registration (called Anmeldung or Meldebestätigung)"
        );
        await uploadDocument(formData);
      }
    } else {
      setFiles(null);
      setfileError(isValidate);
    }
  };

  const showField = (field: string) => {
    if (!stepData) return true;
    let data = stepData?.find((item) => item.name === field);
    return !user?.formStatus ? true : !data ? false : data?.isShow;
    // return !data ? false : !user?.formStatus ? true : data?.isShow
  };

  const showComments = (field: string) => {
    if (!stepData) return false;
    let data = stepData?.find(
      (item) =>
        item.name === field && item?.comments && item?.comments?.length > 0
    );
    return !data ? false : true;
  };

  return (
    <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 h-full lg:ml-[6.3rem] ml-0">
      <label className="text-[#0B0D0F] font-semibold text-[20px]">
        Personal Information
      </label>
      <p className="text-[#71859A] leading-[27.36px] text-[15px] mt-5 lg:w-1/2 xl:w-1/2 w-full">
        Enter your last name(s) family name(s) as written on your passport. If
        you have more than one last name, write them all as they are written on
        your passport. If you do not have a last name written on your passport,
        let this field empty.{" "}
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={
          nextActive
            ? user?.formStatus === "SUBMITTED"
              ? ""
              : steptwoSchema
            : ""
        }
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, handleChange, errors }: any) => (
          <Form className="mx-auto" id={currentStep.toString()}>
            <div className={showField("lastName") ? "block" : "hidden"}>
              <div className="mb-2 mt-4 flex justify-between items-center">
                <label
                  htmlFor={"email"}
                  className="font-400 text-[14px] text-[#0B0D0F]"
                >
                  Last Name
                </label>
              </div>
              <div className="lg:w-1/2 xl:w-1/2 w-full">
                <Input
                  type="text"
                  fieldName="lastName"
                  placeholder="Last Name"
                  setValue={values.lastName}
                  className={null}
                  handleChange={handleChange}
                />
                {showComments("lastName") && (
                  <CommentSection
                    field={"lastName"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
              </div>
              <hr className="bg-[#E1E5EA]" />
              <p className="mt-3 text-[#71859A] text-[15px]">
                Enter your first given name as written on your passport.
              </p>
            </div>
            <div className={showField("firstName") ? "block" : "hidden"}>
              <div className="mb-2 mt-4 flex justify-between items-center">
                <label
                  htmlFor={"firstName"}
                  className="font-400 text-[14px] text-[#0B0D0F]                    "
                >
                  First Name <span className="text-[#FF4444] ml-1">*</span>
                </label>
              </div>
              <div className="lg:w-1/2 xl:w-1/2 w-full">
                <Input
                  type="text"
                  fieldName="firstName"
                  placeholder="First Name"
                  setValue={values.firstName}
                  className={null}
                  handleChange={handleChange}
                />
                {showComments("firstName") && (
                  <CommentSection
                    field={"firstName"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
              </div>
              <hr className="bg-[#E1E5EA]" />
              <p className="my-3 text-[#71859A] text-[15px]">
                ↓ If you have more than one first name written on your passport,
                use the field below ↓
              </p>
              <hr className="bg-[#E1E5EA]" />
              <p className="mt-3 text-[#71859A] text-[15px]">
                Enter your other given name as written on your passport.
              </p>
              <p className="text-[#71859A] text-[15px]">
                If you have more than one middle name, write them all as they
                are written on your passport.
              </p>
            </div>
            <div className={showField("middleName") ? "block" : "hidden"}>
              <div className="mb-2 mt-4 flex justify-between items-center">
                <label
                  htmlFor={"middleName"}
                  className="font-400 text-[14px] text-[#0B0D0F]                    "
                >
                  Middle Name (other given name)
                </label>
              </div>
              <div className="lg:w-1/2 xl:w-1/2 w-full">
                <Input
                  type="text"
                  fieldName="middleName"
                  placeholder="Middle Name"
                  setValue={values.middleName}
                  className={null}
                  handleChange={handleChange}
                />
                {showComments("middleName") && (
                  <CommentSection
                    field={"middleName"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
              </div>
            </div>
            <div className={showField("gender") ? "block" : "hidden"}>
              <div className="mb-2 mt-4 flex justify-between items-center">
                <label
                  htmlFor={"gender"}
                  className="font-400 text-[14px] text-[#0B0D0F]                    "
                >
                  Gender <span className="text-[#FF4444] ml-1">*</span>
                </label>
              </div>
              <div className="lg:w-1/2 xl:w-1/2 w-full">
                <Field name="gender">
                  {({ field, form }: FieldProps) => (
                    <SelectInput
                      {...field}
                      fieldName="gender"
                      options={genderOptions}
                      selectedOption={values.gender}
                      handleSelect={(event: ChangeEvent<HTMLInputElement>) => {
                        form.setFieldValue("gender", event.target.value);
                      }}
                      className="w-full"
                    />
                  )}
                </Field>
              </div>
              <ErrorMessage
                name={"gender"}
                component="div"
                className="text-red-500"
              />
              {showComments("gender") && (
                <CommentSection
                  field={"gender"}
                  step={currentStep - 1}
                  userType="student"
                />
              )}
              <hr className="bg-[#E1E5EA] h-[8px] my-5" />
            </div>
            <div className={showField("city") ? "block" : "hidden"}>
              <p className="text-[#71859A] text-[15px]">
                The residence permit application procedure depends on your
                current address.
              </p>
              <div className="mb-2 mt-4 flex justify-between items-center">
                <label
                  htmlFor={"city"}
                  className="font-400 text-[14px] text-[#0B0D0F]                    "
                >
                  City <span className="text-[#FF4444] ml-1">*</span>
                </label>
              </div>
              <div className="lg:w-1/2 xl:w-1/2 w-full">
                <Field name="city">
                  {({ field, form }: FieldProps) => (
                    <SelectInput
                      {...field}
                      fieldName="city"
                      options={cityOptions}
                      selectedOption={values.city}
                      handleSelect={(event: ChangeEvent<HTMLInputElement>) => {
                        form.setFieldValue("city", event.target.value);
                        console.log("city", values.city, values.zipcode);
                        // form.setFieldValue("zipcode", '14467');
                      }}
                      className="w-full"
                    />
                  )}
                </Field>
              </div>
              <ErrorMessage
                name="city"
                component="div"
                className="text-red-500"
              />
              {showComments("city") && (
                <CommentSection
                  field={"city"}
                  step={currentStep - 1}
                  userType="student"
                />
              )}
              <hr className="bg-[#E1E5EA] my-5" />
            </div>
            <div className={showField("zipcode") ? "block" : "hidden"}>
              <div className="mb-2 mt-4 flex justify-between items-center">
                <label
                  htmlFor={"zipcode"}
                  className="font-400 text-[14px] text-[#0B0D0F]                    "
                >
                  Zip/Postal code <span className="text-[#FF4444] ml-1">*</span>
                </label>
              </div>
              <div className="lg:w-1/2 xl:w-1/2 w-full">
                {values.city !== "Potsdam" ? (
                  <Input
                    type="text"
                    fieldName="zipcode"
                    setValue={values.zipcode}
                    className={null}
                    handleChange={handleChange}
                  />
                ) : (
                  <>
                    <Field name="zipcode">
                      {({ field, form }: FieldProps) => (
                        <SelectInput
                          {...field}
                          fieldName="zipcode"
                          options={zipcodeOption}
                          selectedOption={values.zipcode}
                          handleSelect={(
                            event: ChangeEvent<HTMLSelectElement>
                          ) => {
                            form.setFieldValue("zipcode", event.target.value);
                          }}
                          className="w-full"
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="zipcode"
                      component="div"
                      className="text-red-500"
                    />
                    {showComments("zipcode") && (
                      <CommentSection
                        field={"zipcode"}
                        step={currentStep - 1}
                        userType="student"
                      />
                    )}
                  </>
                )}
              </div>
              <hr className="bg-[#E1E5EA] h-[8px] my-5" />
            </div>
            <div className={showField("address") ? "block" : "hidden"}>
              <p className="text-[#71859A] text-[15px]">
                Your current address in Germany{" "}
              </p>
              <div className="mb-2 mt-4 flex justify-between items-center">
                <label
                  htmlFor={"address"}
                  className="font-400 text-[14px] text-[#0B0D0F]                    "
                >
                  Street Address
                  <span className="text-[#FF4444] ml-1">*</span>
                </label>
              </div>
              <div className="lg:w-1/2 xl:w-1/2 w-full">
                <Input
                  type="text"
                  fieldName="address"
                  placeholder="Enter street address"
                  setValue={values.address}
                  className={null}
                  handleChange={handleChange}
                />
                {showComments("address") && (
                  <CommentSection
                    field={"address"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
              </div>
            </div>
            <div className={showField("houseNo") ? "block" : "hidden"}>
              <div className="mb-2 mt-4 flex justify-between items-center">
                <label
                  htmlFor={"houseNo"}
                  className="font-400 text-[14px] text-[#0B0D0F]                    "
                >
                  House No.<span className="text-[#FF4444] ml-1">*</span>
                </label>
              </div>
              <div className="lg:w-1/2 xl:w-1/2 w-full">
                <Input
                  type="text"
                  fieldName="houseNo"
                  placeholder="123"
                  setValue={values.houseNo}
                  className={null}
                  handleChange={handleChange}
                />
                {showComments("houseNo") && (
                  <CommentSection
                    field={"houseNo"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
              </div>
              <hr className="bg-[#E1E5EA] h-[8px] my-5" />
            </div>
            {/* <div className={showField("phoneNumber") ? "block" : "hidden"}>
              <p className="text-[#71859A] text-[15px]">
                We require your mobile phone number for reminders or for urgent
                cases. Please provide a German or international mobile phone
                number.{" "}
              </p>
              <p className="text-[#71859A] text-[15px]">
                Please enter the country code area and your phone number as you
                would dial it usually.{" "}
              </p>
              <div className="my-2 flex justify-between items-center">
                <label
                  htmlFor={"phoneNumber"}
                  className="font-400 text-[14px] text-[#0B0D0F]                    "
                >
                  Phone Number<span className="text-[#FF4444] ml-1">*</span>
                </label>
              </div>
              <div className="lg:w-1/2 xl:w-1/2 w-full">
                <Field name="phoneNumber">
                  {({ field, form }: FieldProps) => (
                    <PhoneInput
                      inputClass="border-[2px] border-[#E1E5EA] rounded-md w-full placeholder-[#71859A] placeholder:text-[14px] password-input py-5"
                      {...field}
                      country={"de"}
                      value={values.phoneNumber}
                      onChange={(phone) =>
                        form.setFieldValue("phoneNumber", phone)
                      }
                      placeholder="Phone Number"
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="text-red-500"
                />
                {showComments("phoneNumber") && (
                  <CommentSection
                    field={"phoneNumber"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
              </div>
              <hr className="bg-[#E1E5EA] h-[8px] my-5" />
            </div> */}
            <div className={showField("dob") ? "block" : "hidden"}>
              <p className="text-[#71859A] text-[15px]">
                The fields on this section must match your passport.{" "}
              </p>
              <div className="my-2 flex justify-between items-center">
                <label
                  htmlFor={"dob"}
                  className="font-400 text-[14px] text-[#0B0D0F]                    "
                >
                  Date of Birth
                  <span className="text-[#FF4444] ml-1">*</span>
                </label>
              </div>
              <Field name="dob">
                {({ field, form }: FieldProps) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateInput
                      {...field}
                      label="YYYY-MM-DD"
                      renderInput={(props: any) => <TextField {...props} />}
                      format="YYYY-MM-DD"
                      value={values.dob}
                      onChange={(newDate: any) => {
                        form.setFieldValue("dob", newDate);
                      }}
                      className={"lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full"}
                      disableFuture
                    />
                  </LocalizationProvider>
                )}
              </Field>
              <ErrorMessage
                name="dob"
                component="div"
                className="text-red-600"
              />
              {showComments("dob") && (
                <CommentSection
                  field={"dob"}
                  step={currentStep - 1}
                  userType="student"
                />
              )}
            </div>
            <div className={showField("birthPlace") ? "block" : "hidden"}>
              <div className="mb-2 mt-4 flex justify-between items-center">
                <label
                  htmlFor={"zipcode"}
                  className="font-400 text-[14px] text-[#0B0D0F]                    "
                >
                  Place of Birth <span className="text-[#FF4444] ml-1">*</span>
                </label>
              </div>
              <div className="lg:w-1/2 xl:w-1/2 w-full">
                <Input
                  type="text"
                  fieldName="birthPlace"
                  placeholder="Enter Place"
                  setValue={values.birthPlace}
                  className={null}
                  handleChange={handleChange}
                />
                {showComments("birthPlace") && (
                  <CommentSection
                    field={"birthPlace"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
              </div>
            </div>
            <div className={showField("birthCountry") ? "block" : "hidden"}>
              <div className="mb-2 mt-4 flex justify-between items-center">
                <label
                  htmlFor={"zipcode"}
                  className="font-400 text-[14px] text-[#0B0D0F]                    "
                >
                  Country of Birth{" "}
                  <span className="text-[#FF4444] ml-1">*</span>
                </label>
              </div>
              <div className="lg:w-1/2 xl:w-1/2 w-full">
                <Field name="birthCountry">
                  {({ field, form }: FieldProps) => (
                    <CountrySelect
                      {...field}
                      value={values.birthCountry}
                      onChange={(val: string) =>
                        form.setFieldValue("birthCountry", val)
                      }
                      className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                    />
                  )}
                </Field>
              </div>
              <ErrorMessage
                name="birthCountry"
                component="div"
                className="text-red-500"
              />
              {showComments("birthCountry") && (
                <CommentSection
                  field={"birthCountry"}
                  step={currentStep - 1}
                  userType="student"
                />
              )}
              <hr className="bg-[#E1E5EA] h-[8px] my-5" />
            </div>
            <div className={showField("region") ? "block" : "hidden"}>
              <div className="mb-2 mt-4 flex justify-between items-center">
                <label
                  htmlFor={"zipcode"}
                  className="font-400 text-[14px] text-[#0B0D0F]                    "
                >
                  Region <span className="text-[#FF4444] ml-1">*</span>
                </label>
              </div>
              <div className="lg:w-1/2 xl:w-1/2 w-full">
                <Field name="region">
                  {({ field, form }: FieldProps) => (
                    <RegionSelect
                      {...field}
                      country={values.birthCountry}
                      value={values.region}
                      onChange={(val: string) =>
                        form.setFieldValue("region", val)
                      }
                      className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                    />
                  )}
                </Field>
              </div>
              <ErrorMessage
                name="region"
                component="div"
                className="text-red-500"
              />
              {showComments("region") && (
                <CommentSection
                  field={"region"}
                  step={currentStep - 1}
                  userType="student"
                />
              )}
              <hr className="bg-[#E1E5EA] h-[8px] my-5" />
            </div>
            <div className={"block"}>
              <div
                className={showField("addressRegister") ? "block" : "hidden"}
              >
                <label className="text-[#0B0D0F] font-semibold text-[18px]">
                  Address
                </label>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"zipcode"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Do you have an address registration (called ANMELDUNG or
                    Meldebestätigung)?
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="addressRegister">
                  {({ field, form }: FieldProps) => (
                    <div className="flex gap-8">
                      <RadioGroup
                        {...field}
                        fieldName="addressRegister"
                        options={addressOptions}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          form.setFieldValue(
                            "addressRegister",
                            event.target.value
                          );
                          if (event.target.value === "No") setfileError(null);
                        }}
                        className={"ml-1"}
                        checkedField={values.addressRegister}
                      />
                    </div>
                  )}
                </Field>
                <ErrorMessage
                  name="addressRegister"
                  component="div"
                  className="text-red-500"
                />
                {showComments("addressRegister") && (
                  <CommentSection
                    field={"addressRegister"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
              </div>
              {values.addressRegister === "Yes" && (
                <>
                  <div
                    className={showField("registerDate") ? "block" : "hidden"}
                  >
                    <hr className="bg-[#E1E5EA] my-5" />
                    <p className="mt-3 text-[#71859A] text-[15px]">
                      The registration date can be found on your adress
                      registration (called Anmeldung or Meldebestätigung).
                    </p>
                    <p className="text-[#71859A] text-[15px] mb-5">
                      See example on previous page (fiels highlighted in green).{" "}
                    </p>
                    <div className="my-2 flex justify-between items-center">
                      <label
                        htmlFor={"registerDate"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                      >
                        Registration Date
                        <span className="text-[#FF4444] ml-1">*</span>
                      </label>
                    </div>
                    <Field name="registerDate">
                      {({ field, form }: FieldProps) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateInput
                            {...field}
                            label="YYYY-MM-DD"
                            renderInput={(props: any) => (
                              <TextField {...props} />
                            )}
                            format="YYYY-MM-DD"
                            value={values.registerDate}
                            onChange={(newDate: any) => {
                              form.setFieldValue("registerDate", newDate);
                            }}
                            className={"lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full"}
                          />
                        </LocalizationProvider>
                      )}
                    </Field>
                    <ErrorMessage
                      name="registerDate"
                      component="div"
                      className="text-red-600"
                    />
                    {showComments("registerDate") && (
                      <CommentSection
                        field={"registerDate"}
                        step={currentStep - 1}
                        userType="student"
                      />
                    )}
                  </div>
                  <div
                    className={showField("addressFile") ? "block" : "hidden"}
                  >
                    <hr className="bg-[#E1E5EA] h-[8px] my-5" />
                    <div className="mb-2 mt-4 flex justify-between items-center">
                      <label
                        htmlFor={"addressFile"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                      >
                        Upload a copy of your address registration (called hello
                        Anmeldung or Meldebestätigung)
                        <span className="text-[#FF4444] ml-1">*</span>
                      </label>
                    </div>
                    <Field name="addressFile">
                      {({ field, form }: FieldProps) => (
                        <>
                          <Inputfile
                            {...field}
                            mainClass="lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full"
                            btnClass={null}
                            handleChange={async (
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              setProgress(0);
                              setUploading(true);
                              let selectedFile: FileList | null =
                                event.target.files;
                              selectedFile &&
                                form.setFieldValue(
                                  "addressFile",
                                  Array.from(selectedFile)
                                );
                              setfileError(null);
                              intervalIdRef.current = setInterval(() => {
                                setProgress(
                                  (prevProgress) => prevProgress + 10
                                );
                              }, 500);
                              uploadFiles(event.target.files);
                            }}
                            multiple={false}
                          />
                          {typeof values.addressFile === "string" && (
                            <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                              <img src={documentBlack} alt="documentBlack" />
                              <label className="flex-1">
                                {values.addressFile}
                              </label>
                              <img
                                src={crossBlack}
                                alt="documentBlack"
                                onClick={() => {
                                  form.setFieldValue("addressFile", null);
                                  setfileError("Files are required");
                                }}
                                className="cursor-pointer"
                              />
                            </div>
                          )}
                          {uploading && (
                            <Box sx={{ width: "100%" }}>
                              <LinearProgress
                                variant="determinate"
                                value={progress}
                              />
                            </Box>
                          )}
                          
                        </>
                      )}
                    </Field>
                    {files &&
                      files?.map((item) => (
                        <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                          <img src={documentBlack} alt="documentBlack" />
                          <label className="flex-1">{item.name}</label>
                          <img
                            src={crossBlack}
                            alt="documentBlack"
                            onClick={() => removeFile(item.name)}
                            className="cursor-pointer"
                          />
                        </div>
                      ))}
                    {fileError ? (
                      <div className="text-red-500 text-sm md:text-base fileError">
                        {fileError}
                      </div>
                    ) : (
                      <ErrorMessage
                        name="addressFile"
                        component="div"
                        className="text-red-600 fileError"
                      />
                    )}
                    {showComments("addressFile") && (
                      <CommentSection
                        field={"addressFile"}
                        step={currentStep - 1}
                        userType="student"
                      />
                    )}
                  </div>
                  <div className="flex flex-col gap-4 my-4">
                    <h3 className="text-[#0B0D0F] text-[18px]">
                      Example of address registration (called ANMELDUNG or
                      Meldebestätigung)
                    </h3>

                    <div className="w-3/4">
                      <img src={address} alt="image" />
                    </div>
                  </div>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StepTwo;
