import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Input, Inputfile, SelectInput } from "components/Form";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import { stepthreeSchema } from "schema/Prestudy";
import { stepDataType, stepProps, stepThreeTypes } from "utils/types";
import { documentTypeOptions } from "utils/data";
import { crossBlack, documentBlack } from "utils/Images";
import { useAppSelector } from "store/Hooks";
import { prestudySelector } from "store/slices/Prestudy";
import { validateFiles } from "utils/Helper";
import moment from "moment";
import { userSelector } from "store/slices/User";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/material";
import { CorrectionstepthreeSchema } from "schema/FormCorrection";
import CommentSection from "components/Form/CommentSection";
import axios from "axios";
import { setFeatureLoaded } from "store/slices/Prestudy";
import { useDispatch } from "react-redux";

const StepThree: React.FC<stepProps> = ({
  submitStepdata,
  nextActive,
  uploadDocument,
  verifyCorrection,
}) => {
  const DateInput = DatePicker as any;
  const { currentStep, stepData } = useAppSelector(prestudySelector);
  const [fileError, setfileError] = useState<string | null>(null);
  const [files, setFiles] = useState<any>(null);
  const { user } = useAppSelector(userSelector);
  const [stepFields, setStepFields] = useState<stepThreeTypes>({
    file: [],
    docType: "",
    authority: "",
    docNumber: "",
    issueDate: null,
    validDate: null,
  });
  const [progress, setProgress] = React.useState(0);
  const [uploading, setUploading] = useState(false);
  const intervalIdRef = useRef<any>();
  const initialValues: stepThreeTypes = {
    file: stepFields.file ? stepFields.file : [],
    docType: stepFields.docType ? stepFields.docType : "",
    authority: stepFields.authority ? stepFields.authority : "",
    docNumber: stepFields.docNumber ? stepFields.docNumber : "",
    issueDate: stepFields.issueDate ? stepFields.issueDate : null,
    validDate: stepFields.validDate ? stepFields.validDate : null,
  };
  useEffect(() => {
    if (progress > 100) {
      setUploading(false);
      setProgress(0);
      clearInterval(intervalIdRef?.current);
    }
  }, [progress]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (progress > 0) {
      dispatch(setFeatureLoaded(true));
    } else {
      dispatch(setFeatureLoaded(false));
    }
  }, [progress]);

  const uploadFiles = async (selectedFile: FileList | null, form: any) => {
    const allowedTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
    ];
    const isValidate = await validateFiles(selectedFile, allowedTypes);
    if (isValidate === "true") {
      if (selectedFile) {
        const newFiles = Array.from(selectedFile).filter(
          (newFile: any) =>
            !files ||
            !files.some(
              (existingFile: any) => existingFile.name === newFile.name
            )
        );
        const updatedFiles = files ? [...files, ...newFiles] : newFiles;
        form.setFieldValue("file", updatedFiles);
        setFiles(updatedFiles);
        const formData: any = new FormData();
        user && formData.append("userId", user?._id);
        currentStep && formData.append("step", (currentStep - 1).toString());
        formData.append(
          "label",
          "Upload a COLOR copy of your passport (details page with picture and passport number)"
        );
        console.log("updatedFiles", updatedFiles);
        if (updatedFiles && updatedFiles?.length > 0) {
          updatedFiles?.forEach(async (file: any) => {
            if (!file?.location) {
              formData.append("file", file);
            }
          });
        } else {
          formData.append("file", null);
        }
        formData.append("isRequired", true);
        uploadDocument(formData);
      }
    } else {
      setFiles(null);
      setfileError(isValidate);
    }
  };

  useEffect(() => {
    const updatedFields = { ...stepFields };
    stepData?.forEach((item) => {
      const name = item.name as keyof stepThreeTypes;
      switch (name) {
        case "docType":
        case "authority":
        case "docNumber":
          updatedFields[name] = item.value;
          break;
        case "issueDate":
        case "validDate":
          if (typeof item.value === "string" && item.value !== "") {
            updatedFields[name] = dayjs(
              moment(item.value).format("YYYY-MM-DD")
            );
          }
          break;
        case "file":
          updatedFields[name] =
            item.value instanceof Array ? [...item.value] : item.value;
          break;
        default:
          break;
      }
    });
    let binaryFiles: any = [];
      Array.isArray(updatedFields?.file) &&updatedFields?.file?.map(async (file: any) => {
      const response = await axios.get(file.location, {
        responseType: "blob",
      });
      const blob = response.data;
      const binaryFile = new File([blob], file.name, { type: blob.type });
      binaryFiles.push(binaryFile);
    });
    setFiles(binaryFiles);
    setStepFields(updatedFields);
  }, [stepData]);

  useEffect(() => {
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, []);
  const handleSubmit = async (values: stepThreeTypes) => {
    try {
      if (user?.formStatus === "SUBMITTED") {
        let payload = [
          {
            name: "docType",
            value: values.docType,
          },
          {
            name: "docNumber",
            value: values.docNumber,
          },
          {
            name: "authority",
            value: values.authority,
          },
          {
            name: "issueDate",
            value: values.issueDate
              ? new Date(
                  moment(new Date(values.issueDate)).format("YYYY-MM-DD")
                ).toISOString()
              : null,
          },
          {
            name: "validDate",
            value: values.validDate
              ? new Date(
                  moment(new Date(values.validDate)).format("YYYY-MM-DD")
                ).toISOString()
              : null,
          },
        ];
        const uniqueNames: { [name: string]: boolean } = {};
        let updatedArr: { name: string; value: string | null | undefined }[] =
          [];
        payload.map((item) => {
          let itemName = item.name as keyof stepThreeTypes;
          const oldValue = stepFields[itemName];
          if (!oldValue) {
            uniqueNames[item.name] = true;
            updatedArr.push({ ...item, value: item.value });
          }
          if (oldValue && oldValue !== item.value) {
            if (!uniqueNames[item.name]) {
              uniqueNames[item.name] = true;
              updatedArr.push({ ...item, value: item.value });
            }
          }
        });
        await verifyCorrection(updatedArr);
      } else {
        if (!fileError) {
          let payload = [
            {
              name: "docType",
              label: "Document Type",
              value: values.docType,
              isShow: true,
            },
            {
              name: "docNumber",
              label: "Document Number",
              value: values.docNumber,
              isShow: true,
            },
            {
              name: "authority",
              label: "Issuing Authority",
              value: values.authority,
              isShow: true,
            },
            {
              name: "issueDate",
              label: "Date of Issue",
              value: values.issueDate
                ? new Date(
                    moment(new Date(values.issueDate)).format("YYYY-MM-DD")
                  ).toISOString()
                : null,
              isShow: true,
            },
            {
              name: "validDate",
              label: "Valid Until Date",
              value: values.validDate
                ? new Date(
                    moment(new Date(values.validDate)).format("YYYY-MM-DD")
                  ).toISOString()
                : null,
              isShow: true,
            },
          ];
          const uniqueNames: { [name: string]: boolean } = {};
          let updatedArr: stepDataType[] = [];
          payload.map((item) => {
            let itemName = item.name as keyof stepThreeTypes;
            const oldValue = stepFields[itemName];
            if (oldValue && oldValue !== item.value) {
              if (!uniqueNames[item.name]) {
                uniqueNames[item.name] = true;
                updatedArr.push({ ...item, value: item.value });
              }
            }
          });
          await submitStepdata(
            updatedArr?.length > 0 ? updatedArr : payload,
            null
            // values?.file[0] && !values?.file[0].hasOwnProperty("location") ? formData : null
          );
        }
      }
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
    }
  };

  const showField = (field: string) => {
    if (!stepData) return true;
    let data = stepData?.find((item) => item.name === field);
    return !user?.formStatus ? true : !data ? false : data?.isShow;
    // return !data ? false : !user?.formStatus ? true : data?.isShow
  };

  const showComments = (field: string) => {
    if (!stepData) return false;
    let data = stepData?.find(
      (item) =>
        item.name === field && item?.comments && item?.comments?.length > 0
    );
    return !data ? false : true;
  };
  return (
    <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 h-full lg:ml-[6.3rem] ml-0">
      <label className="text-[#0B0D0F] font-semibold text-[20px]">
        Passport
      </label>
      <p className="text-[#71859A] leading-[27.36px] text-[15px] mt-5 lg:w-1/2 xl:w-1/2 w-full">
        Make sure your fingers are not visible on the copy you upload{" "}
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={
          nextActive
            ? user?.formStatus === "SUBMITTED"
              ? ""
              : stepthreeSchema
            : ""
        }
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, handleChange }: any) => (
          <Form className="mx-auto" id={currentStep.toString()}>
            <>
              <div className={showField("file") ? "block" : "hidden"}>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"email"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Upload a COLOR copy of your passport (details page with
                    picture and passport number){" "}
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="file">
                  {({ field, form }: FieldProps) => (
                    <>
                      <Inputfile
                        {...field}
                        mainClass="lg:w-1/2 xl:w-1/2 w-full"
                        btnClass={null}
                        handleChange={(
                          event: ChangeEvent<HTMLInputElement>
                        ) => {
                          setfileError(null);
                          setProgress(0);
                          setUploading(true);
                          intervalIdRef.current = setInterval(() => {
                            setProgress((prevProgress) => prevProgress + 10);
                          }, 500);
                          uploadFiles(event.target.files, form);
                        }}
                        multiple={true}
                      />
                      {values.file &&
                        values.file.length > 0 &&
                        values.file.map(
                          (item: { name: string; location: string }) => (
                            <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                              <img src={documentBlack} alt="documentBlack" />
                              <label className="flex-1">{item.name}</label>
                              <img
                                src={crossBlack}
                                alt="documentBlack"
                                onClick={async () => {
                                  const updatedFiles = values.file?.filter(
                                    (val: { name: string; location: string }) =>
                                      val.name !== item.name
                                  );
                                  form.setFieldValue("file", updatedFiles);
                                  const formData: any = new FormData();
                                  user && formData.append("userId", user?._id);
                                  currentStep &&
                                    formData.append(
                                      "step",
                                      (currentStep - 1).toString()
                                    );
                                  formData.append(
                                    "label",
                                    "Upload a COLOR copy of your passport (details page with picture and passport number)"
                                  );
                                  formData.append("isRequired", true);
                                  if (
                                    updatedFiles &&
                                    updatedFiles?.length > 0
                                  ) {
                                    updatedFiles?.forEach((file: any) => {
                                      if (!file?.location) {
                                        formData.append("file", file);
                                      }
                                    });
                                  } else {
                                    formData.append("file", null);
                                  }
                                  setFiles(updatedFiles);
                                  await uploadDocument(formData);
                                  if (updatedFiles.length === 0 && nextActive) {
                                    form.setFieldValue("file", null);
                                    setFiles(null);
                                    setfileError("Files are required");
                                    setProgress(0);
                                  }
                                }}
                                className="cursor-pointer"
                              />
                            </div>
                          )
                        )}
                      {uploading && (
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </Field>
                {fileError ? (
                  <div className="text-red-500 text-sm md:text-base fileError">
                    {fileError}
                  </div>
                ) : (
                  <ErrorMessage
                    name="file"
                    component="div"
                    className="text-red-600 fileError"
                  />
                )}
                {showComments("file") && (
                  <CommentSection
                    field={"file"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
                <hr className="bg-[#E1E5EA] my-5" />
              </div>
              <div className={showField("docType") ? "block" : "hidden"}>
                <p className="mt-3 text-[#71859A] text-[15px]">
                  The residence permit application procedure depends on your
                  current address.{" "}
                </p>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"gender"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Document Type <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <div className="lg:w-1/2 xl:w-1/2 w-full">
                  <Field name="docType">
                    {({ field, form }: FieldProps) => (
                      <SelectInput
                        {...field}
                        fieldName="docType"
                        options={documentTypeOptions}
                        selectedOption={values.docType}
                        handleSelect={(
                          event: ChangeEvent<HTMLInputElement>
                        ) => {
                          form.setFieldValue("docType", event.target.value);
                        }}
                        className="w-full"
                      />
                    )}
                  </Field>
                </div>
                <ErrorMessage
                  name={"docType"}
                  component="div"
                  className="text-red-500"
                />
                {showComments("docType") && (
                  <CommentSection
                    field={"docType"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}

                <hr className="bg-[#E1E5EA] my-5" />
              </div>
              <div className={showField("authority") ? "block" : "hidden"}>
                <p className="mt-3 text-[#71859A] text-[15px]">
                  If the issuing authority is not mentioned in your passport,
                  write the PLACE of the passport issuance
                </p>
                <p className="text-[#71859A] text-[15px]">
                  You find the issuing authority usually on the details page of
                  your passport{" "}
                </p>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"authority"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Issuing Authority{" "}
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <div className="lg:w-1/2 xl:w-1/2 w-full">
                  <Input
                    type="text"
                    fieldName="authority"
                    placeholder="Unknown"
                    setValue={values.authority}
                    className={null}
                    handleChange={handleChange}
                  />
                  {showComments("authority") && (
                    <CommentSection
                      field={"authority"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                </div>
                <hr className="bg-[#E1E5EA] my-5" />
              </div>
              <div className={showField("docNumber") ? "block" : "hidden"}>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"gender"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Document Number{" "}
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <div className="lg:w-1/2 xl:w-1/2 w-full">
                  <Field name="docNumber">
                    {({ field, form }: FieldProps) => (
                      <Input
                        type="text"
                        fieldName="docNumber"
                        placeholder="Document Number"
                        setValue={values.docNumber}
                        className={null}
                        handleChange={handleChange}
                      />
                    )}
                  </Field>
                  {showComments("docNumber") && (
                    <CommentSection
                      field={"docNumber"}
                      step={currentStep - 1}
                      userType="student"
                    />
                  )}
                </div>
                <hr className="bg-[#E1E5EA] my-5" />
              </div>
              <div className={showField("issueDate") ? "block" : "hidden"}>
                <div className="my-2 flex justify-between items-center">
                  <label
                    htmlFor={"issueDate"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Date of Issue
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="issueDate">
                  {({ field, form }: FieldProps) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateInput
                        {...field}
                        label="YYYY-MM-DD"
                        renderInput={(props: any) => <TextField {...props} />}
                        format="YYYY-MM-DD"
                        value={values.issueDate}
                        onChange={(newDate: any) => {
                          form.setFieldValue("issueDate", newDate);
                        }}
                        className={
                          "lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full password-input"
                        }
                        disableFuture
                      />
                    </LocalizationProvider>
                  )}
                </Field>
                <ErrorMessage
                  name="issueDate"
                  component="div"
                  className="text-red-600"
                />
                {showComments("issueDate") && (
                  <CommentSection
                    field={"issueDate"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
                <hr className="bg-[#E1E5EA] my-5" />
              </div>
              <div className={showField("validDate") ? "block" : "hidden"}>
                <div className="my-2 flex justify-between items-center">
                  <label
                    htmlFor={"validDate"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Valid Until Date
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="validDate">
                  {({ field, form }: FieldProps) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateInput
                        {...field}
                        label="YYYY-MM-DD"
                        renderInput={(props: any) => <TextField {...props} />}
                        format="YYYY-MM-DD"
                        value={values.validDate}
                        onChange={(newDate: any) => {
                          form.setFieldValue("validDate", newDate);
                        }}
                        className={
                          "lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full password-input"
                        }
                        disablePast
                      />
                    </LocalizationProvider>
                  )}
                </Field>
                <ErrorMessage
                  name="validDate"
                  component="div"
                  className="text-red-600"
                />
                {showComments("validDate") && (
                  <CommentSection
                    field={"validDate"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
              </div>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StepThree;
