import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React from 'react'
import { CountryDropdown } from 'react-country-region-selector';
import { useAppSelector } from 'store/Hooks';
import { prestudySelector } from 'store/slices/Prestudy';

interface ResidenceCompProps {
    values: any;
    showField?: (args: string) => boolean;
    showComments?: (args: string) => boolean;
    modal?: boolean;
    setModal?: any;
    field?: string;
    setField?: any;
    handleChange: any
    userType?: boolean;
    getCount?: (args: string) => number;
}

const ResidenceComp: React.FC<ResidenceCompProps> = ({ values, showField, showComments, handleChange, userType, modal, setModal, field, setField, getCount }) => {
    const CountrySelect = CountryDropdown as any;
    const { currentStep } = useAppSelector(prestudySelector);

    return (
        <div className={`ResidenceComp flex flex-col lg:flex-row gap-2 px-4 mb-3`}>
            <div className={!showField ? "block" : showField('residenceCountry') ? "block" : 'hidden'}>
                <div className="mb-2 mt-4 flex items-center">
                    <label
                        htmlFor={"residenceCountry"}
                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                    >
                        {userType &&
                            <input
                                type="checkbox"
                                className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                onClick={(e: any) => {
                                    setModal(e.target.checked);
                                    setField('residenceCountry')
                                }}
                                checked={modal && field === 'residenceCountry'}
                            />
                        }
                        If yes, where?
                        <span className="text-[#FF4444] ml-1">*</span>
                    </label>
                    {getCount && getCount("residenceCountry") > 0 && (
                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                            {getCount("residenceCountry")}
                        </div>
                    )}
                </div>
                <div className="w-full">
                    <Field name="authCountry">
                        {({ field, form }: FieldProps) => (
                            <CountrySelect
                                {...field}
                                value={values.residenceCountry}
                                onChange={(val: string) =>
                                    form.setFieldValue("residenceCountry", val)
                                }
                                className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                            />
                        )}
                    </Field>
                </div>
                <ErrorMessage
                    name="residenceCountry"
                    component="div"
                    className="text-red-500"
                />
                {showComments && showComments('residenceCountry') &&
                    <CommentSection field={'residenceCountry'} step={currentStep - 1} userType="student" />
                }
            </div>
        </div>
    )
}

export default ResidenceComp