import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Input, RadioGroup } from 'components/Form';
import CommentSection from 'components/Form/CommentSection';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { ChangeEvent } from 'react'
import { CountryDropdown } from 'react-country-region-selector';
import { useAppSelector } from 'store/Hooks';
import { prestudySelector } from 'store/slices/Prestudy';
import { addressOptions } from 'utils/data';

interface FatherInfoCompProps {
    values: any;
    modal?: boolean;
    setModal?: any;
    field?: string;
    setField?: any;
    showField?: (args: string) => boolean;
    showComments?: (args: string) => boolean;
    handleChange: any;
    userType?: boolean;
    getCount?: (args: string) => number
}
const FatherInfoComp: React.FC<FatherInfoCompProps> = ({ values, showField, showComments, handleChange, userType, modal, setModal, field, setField, getCount }) => {
    const { currentStep } = useAppSelector(prestudySelector);
    const DateInput = DatePicker as any;
    const CountrySelect = CountryDropdown as any;

    return (
        <div className={`FatherInfoComp  px-4 mb-3`}>
            <div className='flex flex-col lg:flex-row gap-2'>
                <div className={!showField ? "block" : showField('fatherLname') ? "block" : 'hidden'}>
                    <div className="flex items-center my-3">
                        <label
                            htmlFor={"fatherLname"}
                            className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2"
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('fatherLname')
                                    }}
                                    checked={modal && field === 'fatherLname'}
                                />
                            }
                            Father - Lastname
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount("fatherLname") > 0 && (
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount("fatherLname")}
                            </div>
                        )}
                    </div>
                    <Input
                        type="text"
                        fieldName="fatherLname"
                        setValue={values.fatherLname}
                        className={null}
                        handleChange={handleChange}
                    />
                    {showComments && showComments('fatherLname') &&
                        <CommentSection field={'fatherLname'} step={currentStep - 1} userType="student" />
                    }
                </div>
                <div className={!showField ? "block" : showField('fatherFname') ? "block" : 'hidden'}>
                    <div className="flex items-center my-3">
                        <label
                            htmlFor={"fatherFname"}
                            className="font-400 text-[14px] text-[#0B0D0F]                    "
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('fatherFname')
                                    }}
                                    checked={modal && field === 'fatherFname'}
                                />
                            }
                            Father - First name
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount("fatherFname") > 0 && (
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount("fatherFname")}
                            </div>
                        )}

                    </div>
                    <Input
                        type="text"
                        fieldName="fatherFname"
                        setValue={values.fatherFname}
                        className={null}
                        handleChange={handleChange}
                    />
                    {showComments && showComments('fatherFname') &&
                        <CommentSection field={'fatherFname'} step={currentStep - 1} userType="student" />
                    }
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                <div className={!showField ? "block" : showField('fatherInfo') ? "block" : 'hidden'}>
                    <div className="flex items-center mb-2">
                        <label
                            htmlFor={"fatherInfo"}
                            className="font-semibold text-[16px]  text-[#0B0D0F]"
                        >
                            {userType &&
                                <input
                                    type="checkbox"
                                    className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                    onClick={(e: any) => {
                                        setModal(e.target.checked);
                                        setField('fatherInfo')
                                    }}
                                    checked={modal && field === 'fatherInfo'}
                                />
                            }
                            Do you have information about him?
                            <span className="text-[#FF4444] ml-1">*</span>
                        </label>
                        {getCount && getCount("fatherInfo") > 0 && (
                            <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                {getCount("fatherInfo")}
                            </div>
                        )}
                    </div>
                    <Field name="fatherInfo">
                        {({ field, form }: FieldProps) => (
                            <div className="flex flex-col gap-3 justify-center">
                                <RadioGroup
                                    {...field}
                                    fieldName="fatherInfo"
                                    options={addressOptions}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                        form.setFieldValue("fatherInfo", event.target.value);
                                    }}
                                    className={
                                        "ml-3 text-[#2A333C] fomt-semiboold text-[14px]"
                                    }
                                    checkedField={values.fatherInfo}
                                />
                            </div>
                        )}
                    </Field>
                    <ErrorMessage
                        name="fatherInfo"
                        component="div"
                        className="text-red-500"
                    />
                    {showComments && showComments('fatherInfo') &&
                        <CommentSection field={'fatherInfo'} step={currentStep - 1} userType="student" />
                    }
                </div>
                {values.fatherInfo === "Yes" &&
                    <div className='flex flex-col gap-2'>
                        <div className='flex lg:flex-row flex-col gap-2'>
                            <div className={!showField ? "block" : showField('fatherDob') ? "block" : 'hidden'}>
                                <div className="flex items-center my-3">
                                    <label
                                        htmlFor={"fatherDob"}
                                        className="font-400 text-[14px] text-[#0B0D0F]                    "
                                    >
                                        {userType &&
                                            <input
                                                type="checkbox"
                                                className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                                onClick={(e: any) => {
                                                    setModal(e.target.checked);
                                                    setField('fatherDob')
                                                }}
                                                checked={modal && field === 'fatherDob'}
                                            />
                                        }
                                        Father - Date of birth(YYYY-MM-DD)
                                        <span className="text-[#FF4444] ml-1">*</span>
                                    </label>
                                    {getCount && getCount("fatherDob") > 0 && (
                                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                            {getCount("fatherDob")}
                                        </div>
                                    )}
                                </div>
                                <Field name="fatherDob">
                                    {({ field, form }: FieldProps) => (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateInput
                                                {...field}
                                                label="YYYY-MM-DD"
                                                renderInput={(props: any) => (
                                                    <TextField {...props} />
                                                )}
                                                format="YYYY-MM-DD"
                                                value={values.fatherDob}
                                                onChange={(newDate: any) => {
                                                    form.setFieldValue("fatherDob", newDate);
                                                }}
                                                className={
                                                    "password-input"
                                                }
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Field>
                                <ErrorMessage
                                    name="fatherDob"
                                    component="div"
                                    className="text-red-600"
                                />
                                {showComments && showComments('fatherDob') &&
                                    <CommentSection field={'fatherDob'} step={currentStep - 1} userType="student" />
                                }
                            </div>
                            <div className={!showField ? "block" : showField('fatherBirthplcae') ? "block" : 'hidden'}>
                                <div className="flex items-center my-3">
                                    <label
                                        htmlFor={"fatherBirthplcae"}
                                        className="font-400 text-[14px] text-[#0B0D0F]"
                                    >
                                        {userType &&
                                            <input
                                                type="checkbox"
                                                className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                                onClick={(e: any) => {
                                                    setModal(e.target.checked);
                                                    setField('fatherBirthplcae')
                                                }}
                                                checked={modal && field === 'fatherBirthplcae'}
                                            />
                                        }
                                        Father - Place of birth
                                        <span className="text-[#FF4444] ml-1">*</span>
                                    </label>
                                    {getCount && getCount("fatherBirthplcae") > 0 && (
                                        <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                            {getCount("fatherBirthplcae")}
                                        </div>
                                    )}
                                </div>
                                <Input
                                    type="text"
                                    fieldName="fatherBirthplcae"
                                    setValue={values.fatherBirthplcae}
                                    className={null}
                                    handleChange={handleChange}
                                />
                                {showComments && showComments('fatherBirthplcae') &&
                                    <CommentSection field={'fatherBirthplcae'} step={currentStep - 1} userType="student" />
                                }
                            </div>
                        </div>
                        <div className={!showField ? 'block' : showField('fatherBirthCountry') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex items-center">
                                <label
                                    htmlFor={"fatherBirthCountry"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('fatherBirthCountry')
                                            }}
                                            checked={modal && field === 'fatherBirthCountry'}
                                        />
                                    }
                                    Father - Country of birth{" "}
                                    <span className="text-[#FF4444] ml-1">*</span>
                                </label>
                                {getCount && getCount("fatherBirthCountry") > 0 && (
                                    <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                        {getCount("fatherBirthCountry")}
                                    </div>
                                )}

                            </div>
                            <div className="w-full">
                                <Field name="fatherBirthCountry">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.fatherBirthCountry}
                                            onChange={(val: string) =>
                                                form.setFieldValue("fatherBirthCountry", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="fatherBirthCountry"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('fatherBirthCountry') &&
                                <CommentSection field={'fatherBirthCountry'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                        <div className={!showField ? "block" : showField('fatherCitizenship') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex items-center">
                                <label
                                    htmlFor={"fatherCitizenship"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('fatherCitizenship')
                                            }}
                                            checked={modal && field === 'fatherCitizenship'}
                                        />
                                    }
                                    Father - Citizenship
                                    <span className="text-[#FF4444] ml-1">*</span>
                                </label>
                                {getCount && getCount("fatherCitizenship") > 0 && (
                                    <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                        {getCount("fatherCitizenship")}
                                    </div>
                                )}

                            </div>
                            <div className="w-full">
                                <Field name="fatherCitizenship">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.fatherCitizenship}
                                            onChange={(val: string) =>
                                                form.setFieldValue("fatherCitizenship", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="fatherCitizenship"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('fatherCitizenship') &&
                                <CommentSection field={'fatherCitizenship'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                        <div className={!showField ? "block" : showField('fatherResCountry') ? "block" : 'hidden'}>
                            <div className="mb-2 mt-4 flex items-center">
                                <label
                                    htmlFor={"fatherResCountry"}
                                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                                >
                                    {userType &&
                                        <input
                                            type="checkbox"
                                            className="border-[2px] border-[#E1E5EA]  rounded-[4px] cursor-pointer w-[24px] h-[24px] mr-2 "
                                            onClick={(e: any) => {
                                                setModal(e.target.checked);
                                                setField('fatherResCountry')
                                            }}
                                            checked={modal && field === 'fatherResCountry'}
                                        />
                                    }
                                    Father - Actual country of residence
                                </label>
                                {getCount && getCount("fatherResCountry") > 0 && (
                                    <div className="comment-section bg-[#FF4444] border-[1px] rounded-[8px] text-white flex items-center justify-center h-5 w-5">
                                        {getCount("fatherResCountry")}
                                    </div>
                                )}
                            </div>
                            <div className="w-full">
                                <Field name="fatherResCountry">
                                    {({ field, form }: FieldProps) => (
                                        <CountrySelect
                                            {...field}
                                            value={values.fatherResCountry}
                                            onChange={(val: string) =>
                                                form.setFieldValue("fatherResCountry", val)
                                            }
                                            className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px] md:w-1/3"
                                        />
                                    )}
                                </Field>
                            </div>
                            <ErrorMessage
                                name="fatherResCountry"
                                component="div"
                                className="text-red-500"
                            />
                            {showComments && showComments('fatherResCountry') &&
                                <CommentSection field={'fatherResCountry'} step={currentStep - 1} userType="student" />
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default FatherInfoComp