import { Button } from 'components/Form';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import formcorrectionService from 'services/Formcorrection';
import { useAppDispatch, useAppSelector } from 'store/Hooks';
import { clearFormcorrection } from 'store/slices/FormCorrection';
import { clearPrestudy, prestudySelector } from 'store/slices/Prestudy';
import { clearUser, userSelector } from 'store/slices/User';
import { cleanAndLogout } from 'utils/Helper';
import { user } from "utils/Images";


type modalPropTypes = {
    field: string,
    step: number,
    userType: string
};

type commentsType = {
    text: string,
    addedBy: string,
    addedAt: string,
    role: string
}

const CommentSection = ({ field, step }: modalPropTypes) => {
    const [text, setText] = useState<string | null>(null)
    const [loading, setLoading] = useState<boolean>(false);
    const [comments, setComments] = useState<any | null>(null);
    const { user: userDetail } = useAppSelector(userSelector);
    const { currentStep } = useAppSelector(prestudySelector);
    const dispatch = useAppDispatch();

    const getFieldcommentHistory = async () => {
        try {
            setText(null)
            setLoading(true);
            const response = await formcorrectionService.getFieldComments(userDetail?._id, step, field)
            const { message, success, status, data } = response.data;
            if (success) {
                setLoading(false);
                setComments(data);

            } else {
                toast.error(message);
                setLoading(false);
            }
            if (status === 401) {
                setLoading(false);
                dispatch(clearUser());
                dispatch(clearPrestudy());
                dispatch(clearFormcorrection());
                await cleanAndLogout();
            }
        } catch (error) {
            setLoading(false);
        }
    }
    useEffect(() => {
        getFieldcommentHistory();
    }, [])

    const saveComment = async () => {
        try {
            setLoading(true);
            let payload = {
                comments:
                    [{
                        name: field || '',
                        text: text || ''
                    }]

            }
            const response = await formcorrectionService.saveComment(userDetail?._id, currentStep - 1, payload)
            const { message, success, status } = response.data;
            if (success) {
                toast(message, { className: "toast-success" });
                setLoading(false);
                getFieldcommentHistory()
            } else {
                // toast.error(message);
                setLoading(false);
                setText(null)
            }
            if (status === 401) {
                setLoading(false);
                dispatch(clearUser());
                dispatch(clearPrestudy());
                dispatch(clearFormcorrection());
                await cleanAndLogout();
            }

        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <div className="bg-[#E8F8FD] rounded-lg max-w-full w-[450px] md:w-[700px]  border-[2px] border-[#C3CCD5] lg:mx-0 mx-2 lg:mt-0 mt-2">
            <div className="chat-section m-5 flex flex-col gap-[10px] text-[14px]">
                {comments && comments?.length > 0 && comments?.map((item: commentsType) =>
                    item.role === "lawyer" ?
                        <div className="flex items-start break-all">
                            <div className="flex items-start lawyer-message  bg-white w-1/2 rounded-lg p-2">
                                <img src={user} alt="user" />
                                <label className="ml-1">{item.text}</label>
                            </div>
                        </div> :
                        <div className="flex items-end justify-end break-all">
                            <div className="flex justify-between lawyer-message bg-white w-1/2 rounded-lg p-2">
                                <label className="ml-1">{item.text}</label>
                                <img src={user} alt="user" />
                            </div>
                        </div>
                )}
            </div>
            <div className="send-message m-5 text-[14px] flex flex-col">
                <label>Messages</label>
                <textarea
                    name="messages"
                    placeholder="Type a Messages..."
                    rows={5}
                    className="password-input border-[2px] border-[#E1E5EA] rounded-md placeholder-[#71859A] placeholder:text-[14px] w-full mt-1"
                    onChange={(e) => setText(e.target.value)}
                    value={ text || ''}
                />
                <Button
                    type="button"
                    label={loading ? "" : "Send"}
                    disabled={false}
                    cssClass="bg-[#0A4A5E] text-[15px] text-[#FFFFFF] py-2 w-[141px] rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed mt-5"
                    handleClick={() => { saveComment(); setText(null) }}
                >
                    {loading && <div className="loader mx-auto" />}
                </Button>
            </div>
        </div>
    )
}

export default CommentSection