import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/Hooks";
import { cross } from "utils/Images";
import { setStudent } from "store/slices/User";
import { changeAttributesMenu, docs } from "utils/data";
import Select from "react-select";
import { dataType } from "pages/LawyerDashboard";

type item = {
  id: string;
  value: string;
  hasSubmenu: boolean;
  route?: string;
};

interface ModalProps {
  student: dataType | undefined | null;
  handleClose: () => void;
  setSelectedReminder: any;
  isTemplate?: boolean;
  menuItems: item[];
  sendEmailMenu?: string[];
  sendReminderMenu?: string[];
  sendUniDocMenu?: string[];
  setCorrectionModalReminder?: (value: boolean) => void;
}

const ContextReminderMenuModal = ({
  student,
  handleClose,
  setSelectedReminder,
  isTemplate,
  menuItems,
  sendEmailMenu,
  sendReminderMenu,
  sendUniDocMenu,
  setCorrectionModalReminder,
}: ModalProps) => {
  const dispatch = useAppDispatch();
  const navigate: any = useNavigate();
  const customStyles = {
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      border: "none",
      padding: "0px !important",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: "3px",
    }),
    input: (provided: any) => ({
      ...provided,
      width: "0px",
      fontSize: "16px",
      color: "#0B0D0F",
      fontFamily: {
        "martel-sans": ["Martel Sans", "sans-serif"],
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#0B0D0F",
      fontSize: "16px",
      fontFamily: {
        "martel-sans": ["Martel Sans", "sans-serif"],
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 99999999, // Ensure the dropdown menu is above other elements
      margin: 0,
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: 0,
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 99999999, // Ensure the dropdown menu is above other elements
    }),
    option: (
      provided: { [x: string]: any },
      state: { isSelected: any; isFocused: any }
    ) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "transparent"
        : state.isFocused
        ? "transparent"
        : "transparent",
      color: "#0B0D0F",
      ":active": {
        ...provided[":active"],
        backgroundColor: "transparent",
      },
    }),
  };

  const renderSubmenu = useCallback(
    (name: string, value: string) => {
      console.log({name});
      
      switch (name) {
        case "attributes":
          return (
            <div className="w-full">
              <Select
                styles={customStyles}
                name="colors"
                options={changeAttributesMenu?.map((item) => {
                  return {
                    label: item?.value,
                    value: item?.value,
                  };
                })}
                className="basic-multi-select rounded-[10px]"
                isSearchable={true}
                placeholder={value}
                classNamePrefix="select"
                menuPortalTarget={document.body}
                onChange={(data) => {
                  if (data?.value === "Correction Status") {
                    handleClose();
                    setSelectedReminder(student);
                    setCorrectionModalReminder && setCorrectionModalReminder(true);
                  } else {
                    navigate("/correction",{state : {student : student}});
                  }
                }}
              />
            </div>
          );
        case "email":
          if (isTemplate) {
            return (
              <div className="w-full">
                <Select
                  styles={customStyles}
                  name="colors"
                  options={sendEmailMenu?.map((item) => {
                    return {
                      label: item,
                      value: item,
                    };
                  })}
                  className="basic-multi-select rounded-[10px]"
                  isSearchable={true}
                  placeholder={value}
                  classNamePrefix="select"
                  menuPortalTarget={document.body}
                  onChange={(data) => {
                    navigate("/send-email");
                    dispatch(
                      setStudent({
                        ...student,
                        templateName:
                          data?.value === "Add new" ? null : data?.value,
                        templateFor: "Send Email",
                      })
                    );
                  }}
                />
              </div>
            );
          }
          break;
        case "reminderOpen":
        if (isTemplate) {
              return (
                <div className="w-full">
                  <Select
                    styles={customStyles}
                    name="colors"
                    options={sendReminderMenu?.map((item) => {
                      return {
                        label: item,
                        value: item,
                      };
                    })}
                    className="basic-multi-select rounded-[10px]"
                    isSearchable={true}
                    placeholder={value}
                    classNamePrefix="select"
                    menuPortalTarget={document.body}
                    onChange={(data) => {
                      navigate("/send-reminder");
                      dispatch(
                        setStudent({
                          ...student,
                          templateName:
                            data?.value === "Add new" ? null : data?.value,
                          templateFor: "Send Reminder",
                        })
                      );
                    }}
                  />
                </div>
              );
        }
        break;
        default:
        return null;
      }
    },
    [sendEmailMenu, sendReminderMenu, sendUniDocMenu]
  );

  return (
    <>
      <div className="fixed inset-0 z-[9999999] flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-xl max-w-[400px] w-[450px] md:w-[550px] lg:mx-0 mx-2 lg:mt-0 px-5">
          <div className="flex justify-between py-4 items-center">
            <h2 className="text-base md:text-xl font-bold text-[#0B0D0F] flex break-words  overflow-hidden mr-4">
              {student?.studentId}
            </h2>
            <img
              src={cross}
              alt="cross"
              className="float-right cursor-pointer w-[35px] md:w-[40px]"
              onClick={handleClose}
            />
          </div>
          <hr />
          <div className="overflow-y-auto max-h-[400px]">
            {menuItems.map((item: any) => {
              return item.hasSubmenu ? (
                <>{renderSubmenu(item.id, item?.value)}</>
              ) : (
                <div
                  className="group text-[16px] leading-5 text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative py-[1.2rem] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 cursor-pointer"
                  onClick={() => {
                    if (item.route) {
                      navigate(item.route);
                      dispatch(setStudent(student));
                    }
                  }}
                >
                  {item?.id === "call"
                    ? student?.phoneNumber
                      ? `Call ${student?.phoneNumber}`
                      : item.value
                    : item.value}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContextReminderMenuModal;
