import { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Notfound from "components/Notfound";
import Loader from "components/Loader";
import Unauthorized from "components/Unauthorized";
import AuthenticationGuard from "components/AuthenticationGuard";
import authService from "services/AuthService";
import Host from "components/Host";

const DashboardPage = lazy(() => import("pages/Dashboard"));
const DocumentOne = lazy(() => import("pages/ApproveDocument/SendDocument/DocumentOne"));
const DocumentTwo = lazy(() => import("pages/ApproveDocument/SendDocument/DocumentTwo"))
const LoginPage = lazy(() => import("pages/Auth/Login"));
const PrestudyPage = lazy(() => import("pages/Prestudy"));
const ForgetPasswordPage = lazy(() => import("pages/Auth/ForgetPassword"));
const ResetPasswordPage = lazy(() => import("pages/Auth/ResetPassword"));
const UniversityDashboard = lazy(() => import("pages/UniversityDashboard"));
const RegisterPage = lazy(() => import("pages/Auth/Register"))
const OverviewPage = lazy(() => import("pages/ActiveServices/Overview"));
const ApprovalOpenPage = lazy(() => import("pages/Approval"));
const ApproveDocumentPage = lazy(() => import("pages/ApproveDocument"));
const ReviewFormPage = lazy(() => import("pages/Approval/ReviewForm"));
const ActiveServicesPage = lazy(() => import("pages/ActiveServices"));
const FaqPage = lazy(() => import("pages/Faq"));
const ProfilePage = lazy(() => import("pages/Profile"));
const LawyerDashboardPage = lazy(() => import("pages/LawyerDashboard"));
const ServiceListingPage = lazy(() => import("pages/ServiceListing"));
const AppointmentPage = lazy(() => import("pages/Appointment"));
const FormcorrectionPage = lazy(() => import("pages/FormCorrection"));
const AddInfoInvoicePage = lazy(() => import("pages/AddInfoInvoice"));
const CheckVedPage = lazy(() => import("pages/CheckVed"));
const SendEmailPage = lazy(() => import("pages/SendEmail"));
const SendReminderPage = lazy(() => import("pages/SendReminder"));
const SendUniDocReminderPage = lazy(() => import("pages/SendUniDocReminder"));
const PhoneCallPage = lazy(() => import("pages/PhoneCall"));
const SendToLeaPage = lazy(() => import("pages/SendLea"));
const ApproveToStudnetPage = lazy(() => import("pages/ApprovalStudent"));
const AddHsNumberPage = lazy(() => import("pages/AddHsNumber"));
const SendHsNumberPage = lazy(() => import("pages/SendHsNumber"));
const SendFingerprintAppointmentPage = lazy(
  () => import("pages/SendFingerprintAppointment")
);
const CorrectionList = lazy(() => import("pages/CorrectionList"));
const VerifyAccount = lazy(() => import("pages/Auth/VerifyAccount"))
const Croporganize = lazy(() => import("pages/Croporganize"))

const Router = () => {
  const routeItems = [
    {
      path: "/dashboard",
      element: (
        <Suspense fallback={<Loader />}>
          <DashboardPage />
        </Suspense>
      ),
    },
    {
      path: "/pre-study",
      element: (
        <Suspense fallback={<Loader />}>
          <PrestudyPage />
        </Suspense>
      ),
    },
    {
      path: "/login",
      element: (
        <Suspense fallback={<Loader />}>
          <LoginPage />
        </Suspense>
      ),
    },
    {
      path: "/register",
      element: (
        <Suspense fallback={<Loader />}>
          <RegisterPage />
        </Suspense>
      ),
    },
    {
      path: "/forgot-password",
      element: (
        <Suspense fallback={<Loader />}>
          <ForgetPasswordPage />
        </Suspense>
      ),
    },
    {
      path: "/auth/reset-password",
      element: (
        <Suspense fallback={<Loader />}>
          <ResetPasswordPage />
        </Suspense>
      ),
    },
    {
      path: "/university-dashboard",
      element: (
        <Suspense fallback={<Loader />}>
          <UniversityDashboard />
        </Suspense>
      ),
    },
    {
      path: "/send-International-document",
      element: (
        <Suspense fallback={<Loader />}>
          <DocumentOne />
        </Suspense>
      ),
    },
    {
      path: "/send-financial-document",
      element: (
        <Suspense fallback={<Loader />}>
          <DocumentTwo />
        </Suspense>
      ),
    },
    {
      path: "/overview",
      element: (
        <Suspense fallback={<Loader />}>
          <OverviewPage />
        </Suspense>
      ),
    },
    {
      path: "/approval-open",
      element: (
        <Suspense fallback={<Loader />}>
          <ApprovalOpenPage />
        </Suspense>
      ),
    },
    {
      path: "/approval-document",
      element: (
        <Suspense fallback={<Loader />}>
          <ApproveDocumentPage />
        </Suspense>
      ),
    },
    {
      path: "/review-form",
      element: (
        <Suspense fallback={<Loader />}>
          <ReviewFormPage />
        </Suspense>
      ),
    },
    {
      path: "/active-services",
      element: (
        <Suspense fallback={<Loader />}>
          <ActiveServicesPage />
        </Suspense>
      ),
    },
    {
      path: "/faq",
      element: (
        <Suspense fallback={<Loader />}>
          <FaqPage />
        </Suspense>
      ),
    },
    {
      path: "/profile",
      element: (
        <Suspense fallback={<Loader />}>
          <ProfilePage />
        </Suspense>
      ),
    },
    {
      path: "/lawyer-dashboard",
      element: (
        <Suspense fallback={<Loader />}>
          <LawyerDashboardPage />
        </Suspense>
      ),
    },
    {
      path: "/service-listing",
      element: (
        <Suspense fallback={<Loader />}>
          <ServiceListingPage />
        </Suspense>
      ),
    },
    {
      path: "/appointment",
      element: (
        <Suspense fallback={<Loader />}>
          <AppointmentPage />
        </Suspense>
      ),
    },
    {
      path: "/correction",
      element: (
        <Suspense fallback={<Loader />}>
          <FormcorrectionPage />
        </Suspense>
      ),
    },
    {
      path: "/add-info-invoice",
      element: (
        <Suspense fallback={<Loader />}>
          <AddInfoInvoicePage />
        </Suspense>
      ),
    },
    {
      path: "/check-ved",
      element: (
        <Suspense fallback={<Loader />}>
          <CheckVedPage />
        </Suspense>
      ),
    },
    {
      path: "/send-email",
      element: (
        <Suspense fallback={<Loader />}>
          <SendEmailPage />
        </Suspense>
      ),
    },
    {
      path: "/send-reminder",
      element: (
        <Suspense fallback={<Loader />}>
          <SendReminderPage />
        </Suspense>
      ),
    },
    {
      path: "/send-uni-doc-reminder",
      element: (
        <Suspense fallback={<Loader />}>
          <SendUniDocReminderPage />
        </Suspense>
      ),
    },
    {
      path: "/phone-call",
      element: (
        <Suspense fallback={<Loader />}>
          <PhoneCallPage />
        </Suspense>
      ),
    },
    {
      path: "/send-to-lea",
      element: (
        <Suspense fallback={<Loader />}>
          <SendToLeaPage />
        </Suspense>
      ),
    },
    {
      path: "/approval-to-student",
      element: (
        <Suspense fallback={<Loader />}>
          <ApproveToStudnetPage />
        </Suspense>
      ),
    },
    {
      path: "/add-hs-number",
      element: (
        <Suspense fallback={<Loader />}>
          <AddHsNumberPage />
        </Suspense>
      ),
    },
    {
      path: "/send-hs-number",
      element: (
        <Suspense fallback={<Loader />}>
          <SendHsNumberPage />
        </Suspense>
      ),
    },
    {
      path: "/send-fingerprint-appointment",
      element: (
        <Suspense fallback={<Loader />}>
          <SendFingerprintAppointmentPage />
        </Suspense>
      ),
    },
    {
      path: "/correction-list",
      element: (
        <Suspense fallback={<Loader />}>
          <CorrectionList />
        </Suspense>
      ),
    },
    {
      path: "/verify-email",
      element: (
        <Suspense fallback={<Loader />}>
          <VerifyAccount />
        </Suspense>
      )
    },
    {
      path: "/verify-phone",
      element: (
        <Suspense fallback={<Loader />}>
          <VerifyAccount />
        </Suspense>
      )
    },
    {
      path: "/crop-organize",
      element: (
        <Suspense fallback={<Loader />}>
          <Croporganize />
        </Suspense>
      )
    }
  ];
  return (
    <Routes>
      <Route element={<AuthenticationGuard />} path="/">
        {routeItems.map((item) => (
          <Route path={item.path} element={item.element} key={item.path} />
        ))}
      </Route>
      <Route path="*" element={<Notfound />} />
      <Route path="/:host" element={<Host />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
    </Routes>
  );
};

export default Router;
