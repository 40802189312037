import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Inputfile } from "components/Form";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import { stepFourSchema } from "schema/Prestudy";
import { stepFourTypes, stepProps } from "utils/types";
import { crossBlack, documentBlack, stamp } from "utils/Images";
import { prestudySelector } from "store/slices/Prestudy";
import { useAppSelector } from "store/Hooks";
import { validateFiles } from "utils/Helper";
import moment from "moment";
import { userSelector } from "store/slices/User";
import { CountryDropdown } from "react-country-region-selector";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/material";
import { CorrectionstepfourSchema } from "schema/FormCorrection";
import CommentSection from "components/Form/CommentSection";
import { useDispatch } from "react-redux";
import { setFeatureLoaded } from "store/slices/Prestudy";

const StepFour: React.FC<stepProps> = ({
  submitStepdata,
  nextActive,
  uploadDocument,
  verifyCorrection,
}) => {
  const DateInput = DatePicker as any;
  const CountrySelect = CountryDropdown as any;
  const [stepFields, setStepFields] = useState<stepFourTypes>({
    stampFile: [],
    date: null,
    lastEntrycity: "",
  });
  const initialValues: stepFourTypes = {
    stampFile: stepFields.stampFile ? stepFields.stampFile : [],
    date: stepFields.date ? stepFields.date : null,
    lastEntrycity: stepFields.lastEntrycity ? stepFields.lastEntrycity : "",
  };
  const [fileError, setfileError] = useState<string | null>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const { currentStep, stepData } = useAppSelector(prestudySelector);
  const { user } = useAppSelector(userSelector);
  const [progress, setProgress] = React.useState(0);
  const [uploading, setUploading] = useState(false);
  const intervalIdRef = useRef<any>();

  useEffect(() => {
    if (progress > 100) {
      setUploading(false);
      setProgress(0);
      clearInterval(intervalIdRef?.current);
    }
  }, [progress]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (progress > 0) {
      dispatch(setFeatureLoaded(true));
    } else {
      dispatch(setFeatureLoaded(false));
    }
  }, [progress]);

  useEffect(() => {
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, []);
  const handleSubmit = async (values: stepFourTypes) => {
    try {
      if (user?.formStatus === "SUBMITTED") {
        let payload = [
          {
            name: "date",
            value: values.date
              ? new Date(
                  moment(new Date(values.date)).format("YYYY-MM-DD")
                ).toISOString()
              : null,
          },
          {
            name: "lastEntrycity",
            value: values.lastEntrycity,
          },
        ];
        await verifyCorrection(payload);
      } else {
        if (!fileError) {
          let payload = [
            {
              name: "date",
              label:
                "Enter the date of your “last” entry in the Schengen area (including Germany)",
              value: values.date
                ? new Date(
                    moment(new Date(values.date)).format("YYYY-MM-DD")
                  ).toISOString()
                : null,
              isShow: true,
            },
            {
              name: "lastEntrycity",
              label: "From",
              value: values.lastEntrycity,
              isShow: true,
            },
          ];
          await submitStepdata(payload, null);
        }
      }
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
    }
  };

  useEffect(() => {
    const updatedFields = { ...stepFields };
    stepData?.forEach((item) => {
      const name = item.name as keyof stepFourTypes;
      switch (name) {
        case "lastEntrycity":
          updatedFields[name] = item.value;
          break;
        case "date":
          if (typeof item.value === "string" && item.value !== "") {
            updatedFields[name] = dayjs(
              moment(item.value).format("YYYY-MM-DD")
            );
          }
          break;
        case "stampFile":
          updatedFields[name] =
            item.value instanceof Array ? item.value[0]?.name : item.value;
          break;
        default:
          break;
      }
    });
    setStepFields(updatedFields);
  }, [stepData]);

  const removeFile = async (fileName: string) => {
    const updatedFiles = files?.filter((item, index) => item.name !== fileName);
    if (updatedFiles && updatedFiles.length === 0 && nextActive)
      setfileError("Files are required");
    updatedFiles && setFiles(updatedFiles);
    setProgress(0);
    const formData: any = new FormData();
    user && formData.append("userId", user?._id);
    currentStep && formData.append("step", (currentStep - 1).toString());
    formData.append(
      "label",
      "Upload a copy of your last ENTRY STAMP in the Schengen area (including Germany)"
    );
    updatedFiles?.forEach((file: any) => {
      formData.append("stampFile", file);
    });
    formData.append("isRequired", true);
    await uploadDocument(formData);
  };

  const uploadFiles = async (selectedFile: FileList | null) => {
    const allowedTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
    ];
    const isValidate = await validateFiles(selectedFile, allowedTypes);
    if (isValidate === "true") {
      if (selectedFile) {
        setFiles(Array.from(selectedFile));
        const formData: any = new FormData();
        user && formData.append("userId", user?._id);
        currentStep && formData.append("step", (currentStep - 1).toString());
        formData.append(
          "label",
          "Upload a copy of your last ENTRY STAMP in the Schengen area (including Germany)"
        );
        Array.from(selectedFile)?.forEach((file: any) => {
          formData.append("stampFile", file);
        });
        formData.append("isRequired", true);
        await uploadDocument(formData);
      }
    } else {
      setFiles(null);
      setfileError(isValidate);
    }
  };

  const showField = (field: string) => {
    if (!stepData) return true;
    let data = stepData?.find((item) => item.name === field);
    return !user?.formStatus ? true : !data ? false : data?.isShow;
    // return !data ? false : !user?.formStatus ? true : data?.isShow
  };
  const showComments = (field: string) => {
    if (!stepData) return false;
    let data = stepData?.find(
      (item) =>
        item.name === field && item?.comments && item?.comments?.length > 0
    );
    return !data ? false : true;
  };

  return (
    <div className="border-[2px] border-[#E1E5EA] rounded-[8px] w-full bg-white p-4 h-full lg:ml-[6.3rem] ml-0">
      <label className="text-[#0B0D0F] font-semibold text-[20px]">
        Last entry in the Schengen area (including Germany)
      </label>
      <Formik
        initialValues={initialValues}
        validationSchema={
          nextActive
            ? user?.formStatus === "SUBMITTED"
              ? ""
              : stepFourSchema
            : ""
        }
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values }: any) => (
          <Form className="mx-auto" id={currentStep.toString()}>
            <>
              <div className="flex flex-col gap-6 my-6">
                <h3 className="text-[#71859A] text-[14px]">
                  Here is an example of last entry stamp in Schengen area{" "}
                </h3>
                <div className="w-3/4">
                  <img src={stamp} alt="image" />
                </div>
              </div>

              <div className={showField("stampFile") ? "block" : "hidden"}>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"email"}
                    className="font-400 text-[14px] text-[#0B0D0F]"
                  >
                    Upload a copy of your last ENTRY STAMP in the Schengen area
                    (including Germany)
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="stampFile">
                  {({ field, form }: FieldProps) => (
                    <>
                      <Inputfile
                        {...field}
                        mainClass="lg:w-1/2 xl:w-1/2 w-full"
                        btnClass={null}
                        handleChange={(
                          event: ChangeEvent<HTMLInputElement>
                        ) => {
                          setProgress(0);
                          setUploading(true);
                          let selectedFile: FileList | null =
                            event.target.files;
                          selectedFile &&
                            form.setFieldValue(
                              "stampFile",
                              Array.from(selectedFile)
                            );
                          setfileError(null);
                          intervalIdRef.current = setInterval(() => {
                            setProgress((prevProgress) => prevProgress + 10);
                          }, 500);
                          uploadFiles(event.target.files);
                        }}
                        multiple={false}
                      />
                      {typeof values.stampFile === "string" && (
                        <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                          <img src={documentBlack} alt="documentBlack" />
                          <label className="flex-1">{values.stampFile}</label>
                          <img
                            src={crossBlack}
                            alt="documentBlack"
                            onClick={() => {
                              form.setFieldValue("stampFile", null);
                              setfileError("Files are required");
                            }}
                            className="cursor-pointer"
                          />
                        </div>
                      )}
                      {uploading && (
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </Field>
                {files &&
                  files?.map((item) => (
                    <div className="file-lables bg-[#F7E3D4] text-[14px] px-4 py-1 flex items-center gap-2 rounded-lg max-w-fit mt-2 break-all">
                      <img src={documentBlack} alt="documentBlack" />
                      <label className="flex-1">{item.name}</label>
                      <img
                        src={crossBlack}
                        alt="documentBlack"
                        onClick={() => removeFile(item.name)}
                        className="cursor-pointer"
                      />
                    </div>
                  ))}
                {fileError ? (
                  <div className="text-red-500 text-sm md:text-base fileError">
                    {fileError}
                  </div>
                ) : (
                  <ErrorMessage
                    name="stampFile"
                    component="div"
                    className="text-red-600 fileError"
                  />
                )}
                {showComments("stampFile") && (
                  <CommentSection
                    field={"stampFile"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
              </div>
              <div className={showField("date") ? "block" : "hidden"}>
                <hr className="bg-[#E1E5EA] my-5" />
                <p className="mt-3 text-[#71859A] text-[15px]">
                  The residence permit application procedure depends on your
                  current address.{" "}
                </p>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"gender"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    Enter the date of your “last” entry in the Schengen area
                    (including Germany){" "}
                    <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <Field name="date">
                  {({ field, form }: FieldProps) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateInput
                        {...field}
                        label="YYYY-MM-DD"
                        renderInput={(props: any) => <TextField {...props} />}
                        format="YYYY-MM-DD"
                        value={values.date}
                        onChange={(newDate: any) => {
                          form.setFieldValue("date", newDate);
                        }}
                        className={
                          "lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full password-input"
                        }
                        disableFuture
                      />
                    </LocalizationProvider>
                  )}
                </Field>
                <ErrorMessage
                  name="date"
                  component="div"
                  className="text-red-600"
                />
                {showComments("date") && (
                  <CommentSection
                    field={"date"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
                <hr className="bg-[#E1E5EA] my-5" />
              </div>
              <div className={showField("lastEntrycity") ? "block" : "hidden"}>
                <div className="mb-2 mt-4 flex justify-between items-center">
                  <label
                    htmlFor={"gender"}
                    className="font-400 text-[14px] text-[#0B0D0F]                    "
                  >
                    From <span className="text-[#FF4444] ml-1">*</span>
                  </label>
                </div>
                <div className="lg:w-1/2 xl:w-1/2 w-full">
                  <Field name="lastEntrycity">
                    {({ field, form }: FieldProps) => (
                      <CountrySelect
                        {...field}
                        value={values.lastEntrycity}
                        onChange={(val: string) =>
                          form.setFieldValue("lastEntrycity", val)
                        }
                        className="border-[2px] border-[#E1E5EA] rounded-md w-full focus:outline-none placeholder-[#71859A] placeholder:text-[14px] text-[#71859A] text-[14px]"
                      />
                    )}
                  </Field>
                </div>
                <ErrorMessage
                  name={"lastEntrycity"}
                  component="div"
                  className="text-red-500"
                />
                {showComments("lastEntrycity") && (
                  <CommentSection
                    field={"lastEntrycity"}
                    step={currentStep - 1}
                    userType="student"
                  />
                )}
              </div>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StepFour;
