import Layout from "components/UI/Layout";
import React, {
  useState,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import AgreementForm from "./AgreementForm";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import { Button } from "components/Form";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import {
  setStepData,
  nextStep,
  prestudySelector,
  previousStep,
  setStep,
  updateStatus,
  setFormStatus,
  setQrImage,
  clearPrestudy,
  setCorrectionStep,
  setStudentCorrectionStatus,
  setLawyerCorrectionStatus,
} from "store/slices/Prestudy";
import StepSeven from "./StepSeven";
import StepEight from "./StepEight";
import StepNine from "./StepNine";
import StepSix from "./StepSix";
import SubmitForm from "./SubmitForm";
import { clearUser, userSelector } from "store/slices/User";
import MobileStepper from "components/MobileStepper";
import MandatoryFieldsModal from "components/Modals/MandatoryFieldsModal";
import StepTwo from "./StepTwo";
import Loader from "components/Loader";
import prestudyService from "services/prestudy";
import { cleanAndLogout } from "utils/Helper";
import { toast } from "react-toastify";
import { stepDataType } from "utils/types";
import { formStepType, saveStepDataType } from "services/types";
import { clearFormcorrection } from "store/slices/FormCorrection";
import DesktopStepper from "components/DesktopStepper";
import formcorrectionService from "services/Formcorrection";
import { useNavigate, useLocation } from "react-router-dom";
import StepThree from "./StepThree";

const Prestudy: React.FC = () => {
  const { sidebarOpen, user } = useAppSelector(userSelector);
  const { currentStep, formStatus, isCorrectionStartedFromLawyer } = useAppSelector(prestudySelector);
  const dispatch = useAppDispatch();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [nextActive, setNextActive] = useState<boolean>(false);
  const [formStatusData, setFormstatusData] = useState<formStepType[] | null>(
    null
  );
 
  const navigate = useNavigate();

  useEffect( () => {
   
    if (isCorrectionStartedFromLawyer) {
      toast.error("Lawyer is correcting the form!")
      return navigate("/dashboard")
    }
    else {
      const updateStatus = async () => {
        
        dispatch(setStudentCorrectionStatus(true))
        dispatch(setLawyerCorrectionStatus(false))
        if (user && user.role === "student") {
          const data = await prestudyService.updateFormCorrectionStatus({
            userId: user?._id,
            role: user?.role,
            isCorrectionStartedFromStudent: !isCorrectionStartedFromLawyer && true,
            isCorrectionStartedFromLawyer: false,
          });
        }
      }
      updateStatus()
    }

    return () => {
      const updateStatus = async () => {
        dispatch(setStudentCorrectionStatus(false))
        if (user && user.role === "student" && !isCorrectionStartedFromLawyer) {
          const data = await prestudyService.updateFormCorrectionStatus({
            userId: user?._id,
            role: user?.role,
            isCorrectionStartedFromStudent: false,
            isCorrectionStartedFromLawyer: isCorrectionStartedFromLawyer,
          });
        }
      }
      updateStatus()
    }
  }, [isCorrectionStartedFromLawyer]);


  useEffect(() => {
    window.scrollTo(0, 0);
    setIsSubmitted(false);
    setNextActive(false);
  }, [currentStep]);

  const { featureLoaded } = useAppSelector(prestudySelector);

  useEffect(() => {
    if (nextActive) {
      if (isSubmitted) {
        if (currentStep !== 1) {
          handleNext();
        }
      } else {
        if ([8, 9].includes(currentStep)) {
          handleNext();
        } else {
          if (user?.formStatus !== "SUBMITTED") {
            setModal(true);
          }
        }
      }
    }
  }, [isSubmitted, nextActive]);

  useEffect(() => {
    if (user?.formStatus === "SUBMITTED") {
      let transformedData: any = [
        {
          step: 0,
          status: "completed",
          label: "Start",
          errorAvailable: 0,
        },
      ];
      const newData = user?.stepDetails?.map(
        ({ stepStatus, errorAvailable, stepNumber }) => ({
          status: "Skipped",
          errorAvailable,
          step: stepNumber,
          label: stepNumber < 10 ? `0${stepNumber}` : stepNumber.toString(),
        })
      );
      if (newData) {
        transformedData = [
          ...transformedData,
          ...newData,
          {
            step: 9,
            status: "completed",
            label: "Submit",
            errorAvailable: 0,
          },
        ];
      }
      dispatch(setFormStatus(transformedData));
      setFormstatusData(transformedData);
    }
  }, [user]);

  useEffect(() => {
    if (user?.formStatus !== "SUBMITTED") {
      if (!formStatus) {
        let formStatusArr = Array(10)
          .fill(0)
          .map((_, index) => ({
            step: index,
            status: "pending",
            label: index === 0 ? "Start" : index === 9 ? "Submit" : `0${index}`,
          }));
        dispatch(setFormStatus(formStatusArr));
      }
    } else {
      formStatus && dispatch(setFormStatus(formStatus));
      dispatch(setFormStatus(formStatusData));
    }
  }, [formStatus, formStatusData]);

  const handleNext = async () => {
    if (user?.formStatus === "SUBMITTED") {
      const currentIndex = formStatus?.findIndex(
        (item) => item.step === currentStep - 1
      );
      if (currentIndex !== -1 && currentIndex && formStatus) {
        dispatch(setCorrectionStep({ step: currentStep }));
        dispatch(
          setCorrectionStep({ step: formStatus[currentIndex + 1].step })
        );
      }
    } else {
      if ([8, 9].includes(currentStep)) {
        submitFormStep(setLoading, null);
      }
      if ([8, 9, 10].includes(currentStep)) {
        dispatch(updateStatus({ step: currentStep, status: "completed" }));
      }
      dispatch(updateStatus({ step: currentStep + 1, status: "Inprogress" }));
      dispatch(nextStep());
    }
  };

  const handlePrevious = () => {
    if (user?.formStatus === "SUBMITTED") {
      const currentIndex = formStatus?.findIndex(
        (item) => item.step === currentStep - 1
      );
      if (currentIndex !== -1 && currentIndex && formStatus) {
        dispatch(setCorrectionStep({ step: currentStep }));
        dispatch(
          setCorrectionStep({ step: formStatus[currentIndex - 1].step })
        );
      }
    } else {
      dispatch(previousStep());
      dispatch(updateStatus({ step: currentStep, status: "pending" }));
      dispatch(updateStatus({ step: currentStep - 1, status: "Inprogress" }));
    }
  };

  const getCurrentStepData = async (currentStep: number) => {
    try {
      setLoading(true);
      let response = await prestudyService.getStepdata(
        user?._id,
        currentStep - 1
      );
      const {
        data: { data, success, status },
      } = response;
      if (success) {
        if (data?.imageUrl) {
          dispatch(setQrImage(data?.imageUrl));
        }
        dispatch(setStepData(data?.data));
        setLoading(false);
      }
      if (status === 401) {
        setLoading(false);
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const verifyCorrection = async (
    correctionPayload: { name: string; value: string }[]
  ) => {
    try {
      setLoading(true);
      let response = await formcorrectionService.verifyCorrection(
        user?._id,
        currentStep - 1,
        { corrections: correctionPayload }
      );
      const {
        data: { success, status },
      } = response;
      if (success) {
        if (nextActive) {
          if (currentStep !== 1) {
            handleNext();
          }
        } else {
          getCurrentStepData(currentStep);
        }
      } else {
        setLoading(false);
      }
      if (status === 401) {
        setLoading(false);
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const uploadDocument = async (
    formData: FormData | null,
    setLoading?: Dispatch<SetStateAction<boolean>>
  ) => {
    try {
      if (!!formData) {
        setLoading && setLoading(true);
        let response = await prestudyService.uploadDocument(formData);
        const {
          data: { success, status },
        } = response;
        if (success) {
          setLoading && setLoading(false);
          return true;
        }
        if (status === 401) {
          setLoading && setLoading(false);
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
          return false;
        }
      } else {
        return true;
      }
    } catch (error) {
      setLoading && setLoading(false);
    }
  };

  const submitFormStep = async (
    setLoading: Dispatch<SetStateAction<boolean>>,
    payload: stepDataType[] | null
  ) => {
    try {
      let formStatusArr;
      if (currentStep === 1) {
        formStatusArr = Array(10)
          .fill(0)
          .map((_, index) => ({
            step: index,
            status: "pending",
            label: index === 0 ? "Start" : index === 9 ? "Submit" : `0${index}`,
          }));
      } else {
        formStatusArr = formStatus && [...formStatus];
      }
      if (formStatusArr) {
        let formStatussData: formStepType[] = formStatusArr?.map((obj) => ({
          ...obj,
        }));
        if (formStatussData[currentStep - 1].hasOwnProperty("status")) {
          formStatussData[currentStep - 1].status = "completed";
          if (currentStep < formStatussData.length) {
            formStatussData[currentStep].status = "Inprogress";
          }
        }
        const stepDataPayload: saveStepDataType = {
          stepStatus: formStatussData,
          stepData: payload,
        };
        setLoading(true);
        let response = await prestudyService.saveStepData(
          user?._id,
          currentStep - 1,
          stepDataPayload
        );
        const {
          data: { data, success, status, message },
        } = response;
        if (success) {
          if (data?.imageUrl) {
            dispatch(setQrImage(data?.imageUrl));
          }
          toast("Form step details updated successfully!", {
            className: "toast-success",
          });

          setLoading(false);
          return true;
        } else {
          // toast.error(message)
        }
        if (status === 401) {
          setLoading(false);
          dispatch(clearUser());
          dispatch(clearPrestudy());
          dispatch(clearFormcorrection());
          await cleanAndLogout();
          return false;
        }
      }
    } catch (error) {
      console.log("🚀 ~ error:", error);
      setLoading(false);
    }
  };

  const submitStepdata = async (
    payload: stepDataType[] | null,
    formData: FormData | null
  ) => {
    console.log("payload", payload)
    try {
      setModal(false);
      let isSubmitDocument, isSubmitData;
      setLoading(true);
      if (nextActive) {
        setIsSubmitted(true);
      }
      isSubmitData = await submitFormStep(setLoading, payload);
      if (currentStep - 1 === 0) {
        isSubmitDocument = await uploadDocument(formData, setLoading);
        if (isSubmitDocument && isSubmitData) {
          dispatch(updateStatus({ step: currentStep, status: "completed" }));
          if (currentStep === 1) {
            dispatch(
              updateStatus({ step: currentStep + 1, status: "Inprogress" })
            );
            dispatch(nextStep());
          }
        }
      } else {
        if (isSubmitData) {
          dispatch(updateStatus({ step: currentStep, status: "completed" }));
        }
      }
      if (!nextActive) {
        console.log("🚀 ~ nextActive: submitStepdata", nextActive);
        getCurrentStepData(currentStep);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getFormData = async () => {
    try {
      setLoading(true);
      let response = await prestudyService.getFormData(user?._id);
      const {
        data: { success, status, data },
      } = response;
      if (success) {
        setLoading(false);
        if (data?.stepStatus) {
          if (data?.stepStatus && data?.stepStatus?.length > 0) {
            dispatch(setFormStatus(data?.stepStatus));
          }
        }
      }
      if (status === 401) {
        setLoading(false);
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
      }
    } catch (error) { }
  };

  useEffect(() => {
    getFormData();
  }, []);

  const submitCorrection = async () => {
    if (user?._id) {
      let response = await prestudyService.submitCorrection(user?._id);
      const {
        data: { status, message },
      } = response;
      if (status === 200) {
        toast(message, { className: "toast-success" });
        navigate("/dashboard");
      }
      if (status === 401) {
        setLoading(false);
        dispatch(clearUser());
        dispatch(clearPrestudy());
        dispatch(clearFormcorrection());
        await cleanAndLogout();
        return false;
      }
    }
  };

  useEffect(() => {
    getCurrentStepData(currentStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const renderForm: React.ReactNode = useMemo(() => {
    switch (currentStep) {
      case 1:
        return <AgreementForm submitStepdata={submitStepdata} />;
      case 2:
        return (
          <StepTwo
            submitStepdata={submitStepdata}
            nextActive={nextActive}
            uploadDocument={uploadDocument}
            verifyCorrection={verifyCorrection}
          />
        );
      case 3:
        return (
          <StepThree
            submitStepdata={submitStepdata}
            nextActive={nextActive}
            uploadDocument={uploadDocument}
            verifyCorrection={verifyCorrection}
          />
        );
      case 4:
        return (
          <StepFour
            submitStepdata={submitStepdata}
            nextActive={nextActive}
            uploadDocument={uploadDocument}
            verifyCorrection={verifyCorrection}
          />
        );
      case 5:
        return (
          <StepFive
            submitStepdata={submitStepdata}
            nextActive={nextActive}
            verifyCorrection={verifyCorrection}
          />
        );
      case 6:
        return (
          <StepSix
            submitStepdata={submitStepdata}
            nextActive={nextActive}
            uploadDocument={uploadDocument}
            verifyCorrection={verifyCorrection}
          />
        );
      case 7:
        return (
          <StepSeven
            submitStepdata={submitStepdata}
            nextActive={nextActive}
            uploadDocument={uploadDocument}
            verifyCorrection={verifyCorrection}
          />
        );
      case 8:
        return <StepEight />;
      case 9:
        return <StepNine />;
      case 10:
        return <SubmitForm submitStepdata={submitStepdata} />;
      default:
        return <AgreementForm submitStepdata={submitStepdata} />;
    }
  }, [currentStep, nextActive]);

  return (
    <Layout>
      <MobileStepper
        isDropdownVisible={isDropdownVisible}
        currentStep={currentStep}
        toogleDropdown={() =>
          setDropdownVisible((isDropdownVisible) => !isDropdownVisible)
        }
        formStatus={formStatus}
        handleStep={setStep}
        userType={"student"}
      />

      <div
        className={`pre-study-form lg:mt-5 mt-12 overflow-x-hidden max-h-full pb-16`}
      >
        <h1 className="font-bold text-[24px] lg:mx-0 mx-4 lg:fixed">Form 1</h1>
        <div className="flex lg:mt-12 mt-8 gap-3 mb-3 lg:mx-0 mx-4">
          <DesktopStepper
            currentStep={currentStep}
            formStatus={formStatus}
            handleStep={setStep}
          />

          {loading ? <Loader /> : renderForm}
        </div>
      </div>
      <footer className="bg-white p-4 text-[#71859A] fixed right-0 bottom-0 w-full">
        <div className="w-full flex ">
          <span
            className={`hidden md:flex text-[15px] ${sidebarOpen ? "lg:ml-60" : "lg:ml-16"
              }`}
          >
            @2024 SIAP. All rights reserved.
          </span>
          {currentStep === 1 ? (
            <div className="mx-auto flex gap-4">
              <Button
                type="submit"
                label="Start"
                disabled={featureLoaded}
                cssClass="bg-[#0A4A5E] text-[15px] text-[#FFFFFF] lg:w-[100px] xl:w-[141px] 2xl:w-[141px] w-[80px] py-2 rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
                form={currentStep.toString()}
                handleClick={() => setNextActive(true)}
              />
            </div>
          ) : (
            <div className="mx-auto flex gap-4">
              <Button
                type="submit"
                label="Previous"
                disabled={featureLoaded}
                cssClass="bg-[#E39D6B] text-[15px] text-[#FFFFFF] lg:w-[100px] xl:w-[141px] 2xl:w-[141px] w-[80px] py-2 rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
                handleClick={currentStep > 1 ? handlePrevious : () => { }}
              />
              <Button
                type="submit"
                label={currentStep === 10 ? "Submit" : "Save"}
                disabled={featureLoaded}
                cssClass="bg-[#76B312] text-[15px] text-[#FFFFFF] lg:w-[100px] xl:w-[141px] 2xl:w-[141px] w-[80px] py-2 rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed lg:mx-3 2xl:mx-3 xl:mx-3 mx-1"
                handleClick={() => {
                  if (user?.formStatus === "SUBMITTED" && currentStep === 10) {
                    submitCorrection();
                  }
                }}
                form={currentStep.toString()}
              />
              {currentStep !== 10 && (
                <Button
                  type="submit"
                  label="Next"
                  disabled={featureLoaded}
                  cssClass="bg-[#0A4A5E] text-[15px] text-[#FFFFFF] lg:w-[100px] xl:w-[141px] 2xl:w-[141px] w-[80px] py-2 rounded-lg text-center text-[15px] hover:opacity-75 disabled:opacity-60 disabled:cursor-not-allowed"
                  handleClick={() => setNextActive(true)}
                  form={currentStep.toString()}
                />
              )}
            </div>
          )}
        </div>
      </footer>
      {modal && (
        <MandatoryFieldsModal
          setModal={setModal}
          skipStep={handleNext}
          setNextActive={setNextActive}
        />
      )}
    </Layout>
  );
};

export default Prestudy;
