import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "store/Hooks";
import { formCorrectionSelector } from "store/slices/FormCorrection";

interface Props {
  isMulti?: boolean;
  optionsList?: any;
  handleChange?: (args: any) => void;
  isShowDefault?: boolean;
}

const options = [
  { value: "Berlin", label: "Berlin" },
  { value: "Study", label: "Study" },
];

const CustomOption = ({ innerProps, innerRef, data }: any) => (
  <div ref={innerRef} {...innerProps}>
    <div className="select-option">
      <input
        type="checkbox"
        checked={data.checked}
        className="cursor-pointer border-[2px] border-[#E1E5EA] w-5 h-5"
      />
      <span>{data.label}</span>
    </div>
  </div>
);

const MultiSelectInput = ({
  isMulti = true,
  optionsList = options,
  handleChange,
  isShowDefault,
}: Props) => {
  const { deletedPages } = useAppSelector(formCorrectionSelector);
  return (
    <>
      {isShowDefault ? (
        <Select
          isMulti={isMulti}
          name="colors"
          options={optionsList}
          className="basic-multi-select rounded-[10px]"
          classNamePrefix="select"
          onChange={handleChange}
          components={{ Option: CustomOption }}
          defaultValue={deletedPages}
        />
      ) : (
        <Select
          isMulti={isMulti}
          name="colors"
          options={optionsList}
          className="basic-multi-select rounded-[10px]"
          classNamePrefix="select"
          onChange={handleChange}
          components={{ Option: CustomOption }}
        />
      )}
    </>
  );
};

export default MultiSelectInput;
